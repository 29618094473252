import { API } from './instanda'

const questions = `${window.location.origin}/SicCodesCoterie.json`

export const getCoterieQuestions = async (): Promise<any> => {
  const response = await API.get(questions)
  return response.data
}

export const createCoterieQuote = async (payload: any): Promise<any> => {
  const response = await API.post('/coterie/createCoterieQuote', payload)
  return response
}
