import { useState, useEffect, createContext, ReactElement } from 'react'
import { GetLocalQuoteData } from '../services/instanda'

interface FormType {
  values: any
  setValues: Function
  setValue: Function
}

interface ContactFormType {
  ContactEmail_TXT: string
  ContactTitle_TXT: string
  ContactFirstName_TXT: string
  ContactLastName_TXT: string
  ContactPhone_TXT: string
  BusinessNameDBA_TXT: string
  BusinessAddressLine1_TXT: string
  BusinessAddressLine2_TXT: string
  BusinessCity_TXT: string
  BusinessState_TXT: string
  BusinessZip_TXT: string
  BusinessSubClass_CHOICE: string
  numEmployess: string
  policyType: string
  manually?: boolean
}

export const ContactFormContext = createContext<FormType>({
  values: {},
  setValues: () => {},
  setValue: () => {}
})

export default function ContactFormProvider ({
  children
}: any): ReactElement {
  const [values, setValues] = useState<ContactFormType>({
    ContactTitle_TXT: '',
    ContactFirstName_TXT: '',
    ContactLastName_TXT: '',
    ContactEmail_TXT: '',
    ContactPhone_TXT: '',
    BusinessNameDBA_TXT: '',
    BusinessAddressLine1_TXT: '',
    BusinessAddressLine2_TXT: '',
    BusinessCity_TXT: '',
    BusinessState_TXT: '',
    BusinessZip_TXT: '',
    BusinessSubClass_CHOICE: '',
    numEmployess: '',
    policyType: 'commercial',
    manually: false
  })

  const setValue = (name: string, value: string): void => {
    const newValues = { ...values, [name]: value }
    // console.log({ name, value })
    // console.log({ newValues })
    setValues(newValues)
  }

  useEffect(() => {
    async function getQuoteData (): Promise<any> {
      try {
        const res = await GetLocalQuoteData()
        const data = Object.assign(
          {},
          values,
          ...Object.keys(values).map(
            (k) => k in res.data.result && { [k]: res.data.result[k] }
          )
        ) as ContactFormType
        setValues(data)
        return res.data
      } catch (error) {
        console.log(error)
      }
    }
    // Execute the created function directly
    getQuoteData().catch(error => console.log(error))
  }, [])

  return (
    <ContactFormContext.Provider value={{ values, setValues, setValue }}>
      {children}
    </ContactFormContext.Provider>
  )
}
