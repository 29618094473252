import React, { useEffect, useState } from 'react'
import { Box, Button, InputLabel } from '@material-ui/core'
import { useFormik } from 'formik'
import Collapse from '../components/Collapse/Collapse'
import { Person as UserIcon } from '@material-ui/icons'
import Title from 'components/Title'
import AddButton from 'views/Join/components/AddButton'
import { DriverSchema, DriverSchemaMTA, errOB, validateError } from 'utils/validations'
import { ErrorMessage, Form, InputContainer, Label, SelectImput, ToggleContainer } from '../../../UI/CustomElement'
import { ISelectDriverProps } from './DiverInfo'
import TypedInput from '../components/TypedInput/TypedInput'
import { IPageValues } from 'interfaces/pouch-redux'
import * as Yup from 'yup'
import { get } from 'lodash'
import { formatDate, generateId } from 'utils/functions'
import { GetLocalQuoteData } from 'services/instanda'
import joinQuoteFiller from 'utils/joinQuoteFiller'
import errorHandler from 'utils/errorHandler'
import { useSnackbar } from 'notistack'
import { useAppSelector } from 'hooks/useAppSelector'
import { getInfoQuote, updateQuotePouch } from 'redux/slices/quote';
import { dispatchApp } from 'redux/store'
import { usStates } from 'services/instadaModel'
import './DriverInfo.scss'

const driverGenerator = (addOffset: number): any => {
  return {
    DriverVIN_TXT: '',
    DriverYear_TXT: '',
    DriverMake_TXT: '',
    DriverUpdate_DATE: Date.now() + addOffset,
    DriverStatedAmount_CHOICE: '$4,000'
  }
}

const pageValues: IPageValues = {
  DriverFirstName_TXT: '',
  DriverLastName_TXT: '',
  DriverDOB_DATE: '',
  DriverIncidentsThreeYears_YNP: 'No',
  DriverIncidentsTickets_NUM: 0,
  DriverIncidentsViolations_NUM: 0,
  DriverIncidentsAccidents_NUM: 0,
  DriverUpdate_DATE: Date.now(),
  DriverLicenseState_CHOICE: '',
  DriverLicenseOverride_TXT: ''
}


const DriverInfo = ({ isAdjust, verifyErrors, setVerifyErrors, isSending, isDisabled }: any) => {

  const [id, setId] = useState('');
  const [isActiveYesBtn, setIsActiveYesBtn] = useState(false)
  const [isActiveNoBtn, setIsActiveNoBtn] = useState(false)
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch);
  const { enqueueSnackbar } = useSnackbar();

  const validateField = Yup.object().shape({
    Dirver: Yup.array().of(
      Yup.object().shape({
        DriverFirstName_TXT: Yup.string()
          .required(errOB.required)
      })
    )
  })

  const formikFormDrivers = useFormik({
    // validateOnChange: true,
    // enableReinitialize: true,
    initialValues: [pageValues],
    validationSchema: DriverSchemaMTA,
    onSubmit: () => {
      //complete();
    }
  })

  const setValues = async () => {
    const driverValues = { Drivers: formikFormDrivers.values }
    dispatchApp(updateQuotePouch({
      ...quote,
      Drivers: driverValues.Drivers
    }))
  }

  const addButtonDriver = async (): Promise<void> => {
    await formikFormDrivers.setValues([
      ...formikFormDrivers.values,
      driverGenerator(formikFormDrivers.values.length)
    ])
  }

  const delButtonDriver = async (index: number): Promise<void> => {
    const modArray = [...formikFormDrivers.values]
    modArray.splice(index, 1)
    await formikFormDrivers.setValues(modArray)
  }

  const toggleActiveNoBtn = (index: number, active: boolean) => {
    console.log(formikFormDrivers?.values[index].DriverIncidentsThreeYears_YNP)
    setIsActiveNoBtn(active);
    setIsActiveYesBtn(!active);
    formikFormDrivers?.setFieldValue(`[${index}].DriverIncidentsThreeYears_YNP`, 'No');
    formikFormDrivers?.setFieldValue(`[${index}].DriverIncidentsAccidents_NUM`, '0');
  }

  const toggleActiveYesBtn = (index: number, active: boolean) => {
    console.log(formikFormDrivers?.values[index].DriverIncidentsThreeYears_YNP)
    setIsActiveYesBtn(active);
    setIsActiveNoBtn(!active);
    formikFormDrivers?.setFieldValue(`[${index}].DriverIncidentsThreeYears_YNP`, 'Yes');
  }
  useEffect(() => {
    setId(generateId() + 'DriverInfo');

    try {
      setTimeout(() => {
        if (quote?.Drivers) {
          const newDrivers = quote?.Drivers?.map((driver: any, index: number) => {
            const originalValues = driverGenerator(index)
            const driverLicense = driver.DriverLicenseOverride_TXT === undefined ? driver.DriverLicenseOverride_TXT_Default : driver.DriverLicenseOverride_TXT
            const driverValues = {
              ...driver,
              DriverFirstName_TXT: driver.DriverFirstName_TXT || '',
              DriverLastName_TXT: driver.DriverLastName_TXT || '',
              DriverDOB_DATE: formatDate(String(driver?.DriverDOB_DATE)) || '',
              DriverIncidentsThreeYears_YNP: driver.DriverIncidentsThreeYears_YNP || 'No',
              DriverIncidentsAccidents_NUM: String(driver.DriverIncidentsAccidents_NUM) || '0',
              DriverLicenseState_CHOICE: quote.IsMTA ? driver.DriverLicenseState_CHOICE : driver.DriverLicenseOverride_TXT_Default || '',
              DriverLicenseOverride_TXT: driver.DriverLicenseOverride_TXT_Default || ''
            }
            if (quote.Drivers) {
              quote?.Drivers[index].DriverIncidentsThreeYears_YNP === 'Yes' ? setIsActiveYesBtn(true) : setIsActiveYesBtn(false)
              quote?.Drivers[index].DriverIncidentsThreeYears_YNP === 'No' ? setIsActiveNoBtn(true) : setIsActiveNoBtn(false)

            } else {
              formikFormDrivers.values[index].DriverIncidentsThreeYears_YNP === 'Yes' ? setIsActiveYesBtn(true) : setIsActiveYesBtn(false)
              formikFormDrivers.values[index].DriverIncidentsThreeYears_YNP === 'No' ? setIsActiveNoBtn(true) : setIsActiveNoBtn(false)
            }
            return { ...originalValues, ...driverValues }
          })
          formikFormDrivers
            .setValues(newDrivers)
            .catch((formError: any) => console.log({ formError }))
        } else if (previousQuote?.Drivers) {
          const newDrivers = previousQuote?.Drivers?.map((driver: any, index: number) => {
            const originalValues = driverGenerator(index)
            const driverLicense = driver.DriverLicenseOverride_TXT === undefined ? driver.DriverLicense_TXT_Default : driver.DriverLicenseOverride_TXT
            const driverValues = {
              ...driver,
              DriverFirstName_TXT: driver.DriverFirstName_TXT || '',
              DriverLastName_TXT: driver.DriverLastName_TXT || '',
              DriverDOB_DATE: formatDate(String(driver?.DriverDOB_DATE)) || '',
              DriverIncidentsThreeYears_YNP: driver.DriverIncidentsThreeYears_YNP || 'No',
              DriverIncidentsAccidents_NUM: driver.DriverIncidentsAccidents_NUM || 0,
              DriverLicenseState_CHOICE: previousQuote.IsMTA ? driver.DriverLicenseState_CHOICE : driver.DriverLicenseOverride_TXT_Default || '',
              DriverLicenseOverride_TXT: previousQuote.IsMTA ? driverLicense : driver.DriverLicenseOverride_TXT_Default || ''
            }
            if (previousQuote.Drivers) {
              previousQuote?.Drivers[index].DriverIncidentsThreeYears_YNP === 'Yes' ? setIsActiveYesBtn(true) : setIsActiveYesBtn(false)
              previousQuote?.Drivers[index].DriverIncidentsThreeYears_YNP === 'No' ? setIsActiveNoBtn(true) : setIsActiveNoBtn(false)

            } else {
              formikFormDrivers.values[index].DriverIncidentsThreeYears_YNP === 'Yes' ? setIsActiveYesBtn(true) : setIsActiveYesBtn(false)
              formikFormDrivers.values[index].DriverIncidentsThreeYears_YNP === 'No' ? setIsActiveNoBtn(true) : setIsActiveNoBtn(false)
            }
            return { ...originalValues, ...driverValues }
          })
          formikFormDrivers
            .setValues(newDrivers)
            .catch((formError: any) => console.log({ formError }))
        }
      }, 100);
    } catch (error) {
      errorHandler(error, enqueueSnackbar)
    }

  }, []);

  useEffect(() => {
    if (id !== '') {
      verifyErrors[id] = Object.keys(formikFormDrivers.errors).length > 0 ? true : false;
      console.log('DriverInfo', id, verifyErrors[id])
      setVerifyErrors({
        ...verifyErrors
      });
    }
  }, [formikFormDrivers.errors]);

  useEffect(() => {
    setValues();
  }, [formikFormDrivers.values])
  console.log(formikFormDrivers.errors)
  return (
    <>
      <form
        onSubmit={formikFormDrivers.handleSubmit}
      >
        <Box maxWidth={860} margin='0 auto' padding={5}>
          <div className='col-12 mt-10'>
            <Title>Drivers and Additional Insureds</Title>
          </div>
          {
            formikFormDrivers.values?.map((_: any, index: number) => {
              const mainAccidents = get(formikFormDrivers, `errors.${index}`)
              const key = String(formikFormDrivers?.values[index]?.DriverUpdate_DATE)
              const subtitle = isAdjust ? `
                ${formikFormDrivers.values[index].DriverFirstName_TXT} ${formikFormDrivers.values[index].DriverLastName_TXT}                
                 - ${formikFormDrivers.values[index].DriverLicenseState_CHOICE} ${formikFormDrivers.values[index].DriverLicenseOverride_TXT}
                ` :
                formikFormDrivers?.values[index]?.DriverFirstName_TXT
              const hasError =
                formikFormDrivers?.submitCount !== 0 &&
                formikFormDrivers?.errors[index] != null

              return (
                <Collapse
                  key={key}
                  index={index}
                  title='Driver'
                  image={<UserIcon />}
                  subtitle={subtitle}
                  hasError={validateError(formikFormDrivers.errors[index])}
                  delButton={delButtonDriver}
                  className='multi-item-card'
                >
                  <div className="row">
                    <div className='col-6' style={{ position: 'relative' }}>
                      <TypedInput
                        inputType='text'
                        className='search-input'
                        inputName='DriverFirstName_TXT'
                        inputValue={`[${index}].DriverFirstName_TXT`}
                        // inputIndex={inputIndex}
                        inputTitle='First Name'
                        inputPlaceholder='First Name'
                        formikForm={formikFormDrivers}
                        disabled={isViewMode}
                        hasErrors={validateError(formikFormDrivers.errors[index]?.DriverFirstName_TXT)}
                      />
                      {hasError && (
                        <ErrorMessage>{formikFormDrivers?.errors[index]?.DriverFirstName_TXT}</ErrorMessage>
                      )}
                    </div>
                    <div className='col-6' style={{ position: 'relative' }}>
                      <TypedInput
                        inputType='text'
                        className='search-input'
                        inputName='DriverLastName_TXT'
                        inputValue={`[${index}].DriverLastName_TXT`}
                        // inputIndex={inputIndex}
                        inputTitle='Last Name'
                        inputPlaceholder='Last Name'
                        formikForm={formikFormDrivers}
                        disabled={isViewMode}
                        hasErrors={validateError(formikFormDrivers.errors[index]?.DriverLastName_TXT)}
                      />
                      {hasError && (
                        <ErrorMessage>{formikFormDrivers?.errors[index]?.DriverLastName_TXT}</ErrorMessage>
                      )}
                    </div>
                    <div className='col-4' style={{ position: 'relative' }}>
                      <TypedInput
                        inputType='date'
                        className='search-input'
                        inputName='DriverDOB_DATE'
                        inputValue={`[${index}].DriverDOB_DATE`}
                        // inputIndex={inputIndex}
                        inputTitle='Day of Birth'
                        inputPlaceholder='Day of Birth'
                        formikForm={formikFormDrivers}
                        disabled={isViewMode}
                        hasErrors={validateError(formikFormDrivers.errors[index]?.DriverDOB_DATE)}
                      />
                      {hasError && (
                        <ErrorMessage>{formikFormDrivers?.errors[index]?.DriverDOB_DATE}</ErrorMessage>
                      )}
                    </div>
                    <div className='col-4' style={{ position: 'relative' }}>
                      <Label>Driver License State</Label>
                      <InputContainer
                        className={formikFormDrivers.errors[index]?.DriverLicenseState_CHOICE ? 'border-red' : ''}
                      >
                        <SelectImput
                          disabled={isDisabled}
                          className='search-input'
                          name='DriverLicenseState_CHOICE'
                          onChange={(evt) => { formikFormDrivers.setFieldValue(`[${index}].DriverLicenseState_CHOICE`, evt.target.value) }}
                          value={formikFormDrivers.values[index].DriverLicenseState_CHOICE}
                        // defaultValue={`[${index}].DriverLicenseState_CHOICE`}
                        // hasErrors={validateError(formikFormDrivers.errors[index]?.DriverLicenseState_CHOICE)}
                        >
                          <option value=''>Select an option...</option>
                          {usStates.map((val: string) => (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          ))}
                        </SelectImput>
                      </InputContainer>
                      {hasError && (
                        <ErrorMessage>{formikFormDrivers?.errors[index]?.DriverLicenseState_CHOICE}</ErrorMessage>
                      )}
                    </div>
                    <div className='col-4' style={{ position: 'relative' }}>
                      <TypedInput
                        inputType='text'
                        inputName='DriverLicenseOverride_TXT'
                        inputValue={`[${index}].DriverLicenseOverride_TXT`}
                        inputTitle='Driver License Number'
                        formikForm={formikFormDrivers}
                        disabled={isViewMode}
                        hasErrors={validateError(formikFormDrivers.errors[index]?.DriverLicenseOverride_TXT)}
                      />
                      {hasError && (
                        <ErrorMessage>{formikFormDrivers?.errors[index]?.DriverLicenseOverride_TXT}</ErrorMessage>
                      )}
                    </div>
                    <div className='col-12' style={{ position: 'relative' }}>
                      <Label>
                        Any At-Fault Accidents in the last 3 years? &nbsp;
                      </Label>
                    </div>
                  </div>
                  <div className='col-5' style={{ position: 'relative' }}>
                    <ToggleContainer className='mb-10'>
                      <Button
                        classes={{ root: 'yes-btn' }}
                        disabled={isDisabled}
                        onClick={() => toggleActiveYesBtn(index, true)}
                        className={isActiveYesBtn ? 'active' : 'not-active'}
                      >
                        Yes
                      </Button>
                      <Button
                        classes={{ root: 'no-btn' }}
                        disabled={isDisabled}
                        onClick={() => toggleActiveNoBtn(index, true)}
                        className={isActiveNoBtn ? 'active' : 'not-active'}
                      >
                        No
                      </Button>
                    </ToggleContainer>
                    {
                      (formikFormDrivers.values[index].DriverIncidentsThreeYears_YNP === 'Yes') ? (
                        <>
                          <TypedInput
                            inputType='number'
                            className='search-input'
                            inputName='DriverIncidentsAccidents_NUM'
                            inputValue={`[${index}].DriverIncidentsAccidents_NUM`}
                            inputTitle='At-Fault Accidents'
                            inputPlaceholder='At-Fault Accidents'
                            formikForm={formikFormDrivers}
                            disabled={isViewMode}
                            hasErrors={validateError(formikFormDrivers.errors[index]?.DriverIncidentsAccidents_NUM)}
                          />
                          {validateError(formikFormDrivers.errors[index]?.DriverIncidentsAccidents_NUM) && (
                            <ErrorMessage>{formikFormDrivers.errors[index]?.DriverIncidentsAccidents_NUM}</ErrorMessage>
                          )}
                        </>
                      ) : null
                    }
                  </div>
                </Collapse>
              )
            })}
          <AddButton
            text='Add a Driver'
            action={addButtonDriver}
            disable={!(formikFormDrivers.values.length < 50) || isViewMode}
          />
        </Box>
      </form>
    </>
  )
}

export default DriverInfo