import { FormikProps } from 'formik'
import { ITypedInput } from 'interfaces/pouch-redux'
import { find, get } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { ReactElement, useState } from 'react'
import { supportedStates, usStatesFull } from 'services/instadaModel'
import { getLocationDetails, GetMapList } from 'services/instanda'

import { InputContainer, Label, Input, SelectImput } from 'UI/CustomElement'
import 'UI/Error/error-styles.scss'
import { simpleErrorHandler } from 'utils/errorHandler'
import './TypedInput.scss'

const getAsString = (mainObject: FormikProps<any> | any, mainString: string): string => {
  const newValue = get(mainObject, mainString)
  return typeof newValue === 'string' ? newValue : ''
}

const TypedInput = ({
  formikForm,
  inputType = "text",
  inputTitle,
  inputValue,
  className,
  defaultInputValue,
  tempInput,
  inputfield,
  inputOptions,
  inputPlaceholder = "",
  inputName = '',
  onMouseEnter,
  onMouseLeave,
  noEmptyDropdown = false,
  minDate = "",
  maxDate = "",
  noRadiusBottom = false,
  backgroundColor,
  onChange,
  minNum,
  maxNum,
  // labelIcon,
  tooltipText,
  index,
  hidden,
  disabled,
  hasErrors,
  setVerifyErrors,
  placePicker = false,
  isLienholder = false,
  onKeyUp,
  isAddInsured
}: ITypedInput): ReactElement => {
  setVerifyErrors?.(hasErrors)
  const { enqueueSnackbar } = useSnackbar()
  const value = getAsString(formikForm, `values.${inputValue}`)
  const valueString = value !== 'not provided' ? value : ''
  const selectValue = (index != null && formikForm?.values[index] != null
    ? formikForm.values[index][inputName]
    : formikForm.values[inputName]) ?? ''
  const errorString = getAsString(formikForm, `errors.${inputValue}`)
  const touchString = get(formikForm, `touched.${inputValue}`)

  const [placeResult, setPlaceResult] = useState<number[]>([])

  const searchPlace = async (value: string): Promise<void> => {
    formikForm.setFieldValue(inputValue, value)
    if (value.length >= 4) {
      GetMapList(value)
        .then((res) => setPlaceResult(res.data.result))
        .catch((error) => console.log(error))
    }
  }
  const cleanValues = (): void => {
    formikForm.setFieldValue('BusinessCity_TXT', '')
    formikForm.setFieldValue('BusinessState_TXT', '')
    formikForm.setFieldValue('BusinessZip_TXT', '')
  }
  const cleanLienholderValues = (): void => {
    formikForm.setFieldValue('LienholderCountry_TXT', '')
    formikForm.setFieldValue('LienholderCity_TXT', '')
    formikForm.setFieldValue('LienholderZipCode_TXT', '')
    formikForm.setFieldValue('VehicleAdditionalInsured_YN', 'No')
  }
  const setLienholderPlaceValue = (value: string): void => {
    cleanLienholderValues()
    getLocationDetails(value)
      .then((res) => {
        res.data.result[0].address_components.forEach((val: any) => {
          console.log('do something', val, value)
          formikForm.setFieldValue(index !== null ? `[${index}].${inputName}` : inputName, value)
          val.types.forEach((typeName: any) => {
            if (typeName === 'country') {
              formikForm.setFieldValue(`[${index}].LienholderCountry_TXT`, val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'locality') {
              formikForm.setFieldValue(`[${index}].LienholderCity_TXT`, val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'administrative_area_level_1') {
              formikForm.setFieldValue(`[${index}].LienholderState_TXT`, val.short_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'postal_code') {
              formikForm.setFieldValue(`[${index}].LienholderZipCode_TXT`, val.long_name)
            }
          })
        })
      })
      .catch((err) => console.log(err))
    setPlaceResult([])
  }
  const setAddInsuredPlaceValue = (value: string): void => {
    formikForm.setFieldValue(index != null ? `[${index}].${inputName}` : inputName, value)
    // cleanLienholderValues()
    getLocationDetails(value)
      .then((res) => {
        res.data.result[0].address_components.forEach((val: any) => {
          console.log('do something', val)
          val.types.map((typeName: any) => {
            if (typeName === 'country') {
              formikForm.setFieldValue(`[${index}].PolAdditionalInsuredCountry_TXT`, val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'locality') {
              formikForm.setFieldValue(`[${index}].PolAddlInsuredCity_TXT`, val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'administrative_area_level_1') {
              formikForm.setFieldValue(`[${index}].PolAddlInsuredState_TXT`, val.short_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'postal_code') {
              formikForm.setFieldValue(`[${index}].PolAddlInsuredZipCode_TXT`, val.long_name)
            }
          })
        })
      })
      .catch((err) => console.log(err))
    setPlaceResult([])
  }
  // console.log(formikForm.values)
  const setPlaceValue = (value: string): void => {
    cleanValues()
    let validState = true as any
    getLocationDetails(value)
      .then((res) => {
        res.data.result[0].address_components.forEach((val: any) => {
          console.log(value)
          formikForm.setFieldValue(inputValue, value)
          const foundState = find(
            usStatesFull,
            (current) => current.name === val.long_name
          )
          const stateAbbreviation =
            foundState != null ? foundState.abbr : val.long_name

          val.types.forEach((typeName: any) => {
            if (typeName === 'locality') {
              formikForm.setFieldValue('BusinessCity_TXT', val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'administrative_area_level_1') {
              validState = find(
                supportedStates,
                (current) => current.value === stateAbbreviation
              )
              formikForm.setFieldValue('BusinessState_TXT', stateAbbreviation)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'postal_code') {
              formikForm.setFieldValue('BusinessZip_TXT', val.long_name)
            }
          })
        })

        if (validState === undefined) {
          simpleErrorHandler('Your state is not supported', enqueueSnackbar)
          cleanValues()
        }
      })
      .catch((err) => console.log(err))
    setPlaceResult([])
  }

  return (
    <>
      {
        inputType === 'select' ?

          <>
            <Label>{inputTitle ?? ''}</Label>
            <InputContainer
              className={`${formikForm.errors}.${inputName} ? 'border-red' : ''`}
            >
              <SelectImput
                // onChange={(evt) => { formikForm.setFieldValue(`${[index]}.${inputName}}`, evt.target.value) }}
                // value={`${formikForm.values}${[index]}.${inputName}}`}
                onChange={(evt) => {
                  if (onChange !== undefined) {
                    formikForm.setFieldValue(
                      index != null ? `[${index}].${inputName}` : inputValue,
                      evt.target.value
                    )
                    onChange(evt.target.value)
                  } else {
                    console.log("Select: ", evt, index != null ? `[${index}].${inputName}` : inputValue)
                    formikForm.setFieldValue(
                      index != null ? `[${index}].${inputName}` : inputValue,
                      evt.target.value
                    )
                  }
                }}
                value={selectValue.trim()}
                name={inputName}
                disabled={disabled}
              >
                <option value=''>Select an option...</option>
                {inputOptions?.map((val: any, index: number) => (
                  <option key={index} value={val.value.trim()}>
                    {val.label}
                  </option>
                ))}

              </SelectImput>
            </InputContainer>
          </>
          :
          <>
            <Label>{inputTitle ?? ''}</Label>
            <InputContainer
              className={`${hasErrors ? 'border-red' : ''} ${className}`}
            >
              {
                isLienholder ?
                  <Input
                    type={inputType}
                    name={inputName}
                    className={className}
                    placeholder={inputPlaceholder}
                    value={inputType === 'date' ? value : valueString || (index !== null && index !== undefined ? formikForm.values[index].inputValue : '')}
                    onChange={(event: any) => {
                      if (onChange !== undefined) {
                        placePicker
                          ? searchPlace(event.target.value)
                          : formikForm.setFieldValue(inputValue, event.target.value)
                      } else {
                        placePicker
                          ? searchPlace(event.target.value)
                          : formikForm.setFieldValue(inputValue, event.target.value)
                      }
                    }}
                    min={minNum === undefined ? minDate : minNum.toString()}
                    max={maxNum === undefined ? maxDate : maxNum.toString()}
                    onKeyUp={(e: any) => {
                      if (onKeyUp !== undefined) {
                        onKeyUp(e)
                      }
                    }}
                    disabled={disabled}
                  >
                  </Input>
                  :
                  <Input
                    type={inputType}
                    name={inputName}
                    className={className}
                    placeholder={inputPlaceholder}
                    value={inputType === 'date' ? value : valueString}
                    onChange={(event: any) => {
                      if (onChange !== undefined) {
                        placePicker
                          ? searchPlace(event.target.value)
                          : formikForm.setFieldValue(inputValue, event.target.value)
                        onChange(event.target.value)
                      } else {
                        placePicker
                          ? searchPlace(event.target.value)
                          : formikForm.setFieldValue(inputValue, event.target.value)
                      }
                    }}
                    min={minNum === undefined ? minDate : minNum.toString()}
                    max={maxNum === undefined ? maxDate : maxNum.toString()}
                    onKeyUp={(e: any) => {
                      if (onKeyUp !== undefined) {
                        onKeyUp(e)
                      }
                    }}
                    disabled={disabled}
                  >
                  </Input>
              }
              {placePicker && placeResult.length !== 0 && (
                <div className='result-list'>
                  {placeResult.map((place: any, index: number) => (
                    <div
                      className='list-item'
                      key={index}
                      onClick={() => (isLienholder) ? setLienholderPlaceValue(place.description) : (isAddInsured) ? setAddInsuredPlaceValue(place.description) : setPlaceValue(place.description)}
                    >
                      {place.description}
                    </div>
                  ))}
                </div>
              )}
            </InputContainer>
          </>
      }

    </>
  )
}

export default TypedInput