import React from 'react'
import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import Collapse from '../components/Collapse/Collapse'
import { Person as UserIcon } from '@material-ui/icons'
import Title from 'components/Title'
import AddButton from 'views/Join/components/AddButton'
import { AddlInsuredSchema, validateError } from 'utils/validations'
import { Form, InputContainer } from '../../../UI/CustomElement'
import TypedInput from '../components/TypedInput/TypedInput'
import { IAddlInsured } from 'interfaces/pouch-redux'
import './AddlInsuredInfo.scss'

const addInsuredGenerator = (addOffset: number): any => {
  return {
    PolAddlInsuredName_TXT: '',
    PolAddlInsuredAddressLine1_TXT: '',
    PolAddlInsuredAddressLine2_TXT: '',
    PolAddlInsuredCity_TXT: '',
    PolAddlInsuredState_TXT: '',
    PolAddlInsuredZipCode_TXT: '',
    PolAdditionalInsuredCountry_TXT: ''
  }
}

const pageValues: IAddlInsured = {
  PolAddlInsuredName_TXT: '',
  PolAddlInsuredAddressLine1_TXT: '',
  PolAddlInsuredAddressLine2_TXT: '',
  PolAddlInsuredCity_TXT: '',
  PolAddlInsuredState_TXT: '',
  PolAddlInsuredZipCode_TXT: '',
  PolAdditionalInsuredCountry_TXT: ''
}

const AddlInsuredInfo = ( {formikFormAddInsured}: any ) => {
  const isAdjust = localStorage.getItem('isAdjustPolicy');

  const onSubmit = () => {

  }

  

  const addButtonAddInsured = async (): Promise<void> => {
    await formikFormAddInsured.setValues([
      ...formikFormAddInsured.values,
      addInsuredGenerator(formikFormAddInsured.values.length)
    ])
  }

  const delButtonAddInsured = async (index: number): Promise<void> => {
    const modArray = [...formikFormAddInsured.values]
    modArray.splice(index, 1)
    await formikFormAddInsured.setValues(modArray)
  }



  return (
    <>
      <Box maxWidth={860} margin='0 auto' padding={5} className='no-padding'>
        <Form >
          {!isAdjust && <Title>Additional Insured</Title>}
          {formikFormAddInsured.values?.map((_: any, index: number) => {
            // const key = String(formikFormAddInsured?.values[index]?.DriverUpdate_DATE)
            const subtitle = formikFormAddInsured?.values[index]?.PolAddlInsuredName_TXT
            const hasError =
              formikFormAddInsured?.submitCount !== 0 &&
              formikFormAddInsured?.errors[index] != null

            return (
              <Collapse
                key={index}
                index={index}
                title='Name'
                image={<UserIcon />}
                subtitle={subtitle}
                hasError={hasError}
                delButton={delButtonAddInsured}
              >
                <div className="row">
                  <div className='col-12' style={{ position: 'relative' }}>
                    <div className="container">
                      <TypedInput
                        className='search-input'
                        inputName='PolAddlInsuredName_TXT'
                        // inputIndex={inputIndex}
                        inputTitle='Full Name'
                        inputPlaceholder='Full Name'
                        formikForm={formikFormAddInsured}
                        hasErrors={validateError(formikFormAddInsured.errors[index]?.PolAddlInsuredName_TXT)}
                        //   index={index}
                      //   form={form}
                        inputValue={`[${index}].PolAddlInsuredName_TXT`}
                      // form={form}
                      // value={form.values[inputIndex].DriverFirstName_TXT}
                      // onMouseEnter={formContext.changeIndication}
                      // onMouseLeave={formContext.changeIndication}
                      // onKeyUp={(e: any) => {
                      //     if (e.keyCode === 13) {
                      //     searchVehicleByVIN(form.values[inputIndex].DriverFirstName_TXT, inputIndex)
                      //     }
                      // }}
                      />
                    </div>
                  </div>
                  <div className='col-12' style={{ position: 'relative' }}>
                    <div className="container">
                      <TypedInput
                        index={index}
                        // inputTitle='Address'
                        inputName='PolAddlInsuredAddressLine1_TXT'
                        inputPlaceholder='Address'
                        placePicker
                        formikForm={formikFormAddInsured}
                        inputValue={`[${index}].PolAddlInsuredAddressLine1_TXT`}
                        // onChange={(evt) => { formikFormAddInsured.setFieldValue(`[${index}].PolAddlInsuredAddressLine1_TXT`,evt.target.value) }}
                        isAddInsured
                      // form={form}
                      // value={form.values[inputIndex].DriverLastName_TXT}
                      // onMouseEnter={formContext.changeIndication}
                      // onMouseLeave={formContext.changeIndication}
                      // onKeyUp={(e: any) => {
                      //     if (e.keyCode === 13) {
                      //     searchVehicleByVIN(form.values[inputIndex].DriverLastName_TXT, inputIndex)
                      //     }
                      // }}
                      />
                    </div>
                  </div>                  
                </div>
              </Collapse>
            )
          })}
          <AddButton
            text='Additional Insured'
            action={addButtonAddInsured}
            disable={!(formikFormAddInsured.values.length < 5)}
          />
        </Form>
      </Box>
    </>
  )
}

export default AddlInsuredInfo