import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { Container, Grid } from '@material-ui/core'

import Alert from './Alert'
import Loader from './Loader'
import { getPolicyData } from 'services/instanda'
import UniversalToggle from 'components/UniversalToggle'
import { useUpperFormStyles } from '../styles/UpperForm.styles'
import { PolicyDataTypes } from '../interfaces'

const UpperForm: React.FC<{
  dataPolicy: PolicyDataTypes | null
  setDataPolicy: React.Dispatch<React.SetStateAction<PolicyDataTypes | null>>
}> = ({ dataPolicy, setDataPolicy }) => {
  const classes = useUpperFormStyles()

  const [loading, setLoading] = React.useState(false)

  const policyNumberSchema = Yup.object().shape({
    policyNumber: Yup.string().required('Policy number field is mandatory')
  })

  const policyDataFromApi = async (policyNumber: string): Promise<any> => {
    try {
      setLoading(true)
      const data = await getPolicyData(policyNumber)
      setDataPolicy(data.data.dataPolicy)
      setDataPolicy(data.data.dataPolicy)
    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }

  const toggleMM = (): void => {
    setDataPolicy(currentPolicy => {
      if (currentPolicy != null) {
        const newAmmount = currentPolicy.FinalPremiumMonthlyInclTaxes_NUM
        return { ...currentPolicy, amount: newAmmount, PayPlan_CHOICE: 'Monthly' }
      }
      return null
    })
  }

  const toggleYY = (): void => {
    setDataPolicy(currentPolicy => {
      if (currentPolicy != null) {
        const newAmmount = currentPolicy.FinalPremiumAnnualInclTaxes_NUM
        return { ...currentPolicy, amount: newAmmount, PayPlan_CHOICE: 'Yearly' }
      }
      return null
    })
  }

  const handleSubmit = (policyNumber: string): any => {
    return policyDataFromApi(policyNumber)
  }

  if (loading) {
    return <Loader />
  } else {
    return (
      <Formik
        initialValues={{
          policyNumber: ''
        }}
        onSubmit={({ policyNumber }, { resetForm }) => {
          handleSubmit(policyNumber)
          resetForm({ values: { policyNumber: policyNumber } })
        }}
        validationSchema={policyNumberSchema}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <Container maxWidth='md'>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Policy Number</label>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Field type='text' name='policyNumber' />
                    {errors.policyNumber !== null && touched.policyNumber !== null ? <Alert>{errors.policyNumber}</Alert> : null}
                  </Grid>
                  <Grid item xs={12} md={2} className={classes.searchButton}>
                    <button type='submit'>Search</button>
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>State</label>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <input type='text' name='state' value={dataPolicy?.state} disabled />
                  </Grid>
                  <Grid item xs={12} md={1} className={classes.textRight}>
                    <label>City</label>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <input type='text' name='city' value={dataPolicy?.city} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Policy Holder</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <input type='text' name='policyHolder' value={dataPolicy?.policyHolderName} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Agency Name</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <input type='text' name='agencyName' value={dataPolicy?.agentGroupName} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Sold Date</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <input type='text' name='soldDate' value={dataPolicy?.releaseDate?.slice(0, 10)?.replace(/-/g, '/')} disabled />
                  </Grid>
                </Grid>
                <hr className={classes.customHr} />
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Amount</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <input type='text' name='amount' value={dataPolicy?.amount} disabled />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Payment Type</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <UniversalToggle
                      isTrue={dataPolicy?.PayPlan_CHOICE?.includes('Monthly') ?? true}
                      action1={toggleMM}
                      action2={toggleYY}
                    />
                  </Grid>
                </Grid>
                <hr className={classes.customHr} />
              </Container>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default UpperForm
