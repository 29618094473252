// import { FormikProps } from 'formik'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, ReactElement, useState } from 'react'
import { GetTrailerAllYears, GetTrailerMakeByYear } from '../services/instanda'

import { DeductTSA } from 'utils/joinQuoteSelect'

const useStyles = makeStyles({
  wrapper: {
    margin: '10px 10px',
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: '#fff',
    border: '2px solid #dddddd'
  },
  label: {
    left: '5px',
    top: '-10px',
    border: 'none',
    padding: '0 5px',
    fontSize: '0.7em',
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundColor: '#fff'
  },
  labelError: {
    color: 'red',
    fontSize: '0.7em'
  },
  select: {
    width: '100%',
    border: 'none',
    padding: '10px'
  },
  input: {
    border: 'none',
    width: '100%',
    padding: '10px'
  },
  vinError: {
    color: 'red',
    fontSize: '11px',
    padding: '0 20px'
  }
})

export interface SelectTruckProps {
  TrailerVIN_TXT: string
  TrailerYear_TXT: string
  TrailerMake_TXT: string
  TrailerUpdate_DATE: number
  TrailerStatedAmount_CHOICE: string
}

// FormikProps<SelectTruckProps[]>
export default function SelectTruck ({ form, inputIndex, state }: { form: any, inputIndex: number, state: any }): ReactElement {
  const classes = useStyles()

  const enableStatedAmount = state === 'GA' || state === 'TX' || state === 'TN' || state === 'IL' || state === 'AZ' ? true : false;
  const [yearList, setYearList] = useState([])
  const [makeList, setMakeList] = useState([])
  const [errors, setErrors] = useState(false)

  const setYearFunction = (year: string): void => {
    GetTrailerMakeByYear(year)
      .then((resYear) => setMakeList(resYear.data.result))
      .catch((errYear) => console.log({ errYear }))
  }

  useEffect(() => {
    async function getAllYears (): Promise<void> {
      const responseYears = await GetTrailerAllYears()
      setYearList(responseYears.data.result.sort().reverse());
      if (form.values[inputIndex].TrailerMake_TXT != null) { // If make is already filled get make list based on year
        setYearFunction(form.values[inputIndex].TrailerYear_TXT)
      }
    }
    getAllYears().catch((initialError) => console.log({ initialError }))
  }, [])

  const setYear = (year: string): void => {
    form.setFieldValue(`[${inputIndex}].TrailerYear_TXT`, year)
    form.setFieldValue(`[${inputIndex}].TrailerMake_TXT`, '') // Make needs to be cleared because current may not be valid for new year
    setYearFunction(year)
  }

  const setMake = (make: string): void => {
    form.setFieldValue(`[${inputIndex}].TrailerMake_TXT`, make)
  }

  const setTrailerVin = (vin: string): void => {
    form.setFieldValue(`[${inputIndex}].TrailerVIN_TXT`, vin)
  }

  const setStatedAmount = (value: string): void => {
    form.setFieldValue(`[${inputIndex}].TrailerStatedAmount_CHOICE`, value)
  }
  const currentErrors: any = form.errors[inputIndex]

  useEffect(() => {
    if (currentErrors?.TrailerVIN_TXT !== '') {
      setErrors(true)
    }
  }, [currentErrors])

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.wrapper} style={currentErrors?.TrailerVIN_TXT != null ? { borderColor: 'red' } : {}}>
          <label className={classes.label}>Vin</label>
          <input className={classes.input} type='text' placeholder='Vin (optional)' name='TrailerVIN_TXT' value={form.values[inputIndex].TrailerVIN_TXT} onChange={(event) => setTrailerVin(event.target.value)} />
        </div>
      </Grid>
      <Grid item xs={12}>
        {errors ? <label className={classes.vinError}>{currentErrors?.TrailerVIN_TXT}</label> : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className={classes.wrapper} style={currentErrors?.TrailerYear_TXT != null ? { borderColor: 'red' } : {}}>
          <label className={classes.label}>Year</label>
          <select
            className={classes.select}
            value={form.values[inputIndex].TrailerYear_TXT}
            onChange={(event) => setYear(event.target.value)}
          >
            <option value=''>Select an option...</option>
            {
              yearList.map((value: string, index: number) => (
                <option key={index} value={value}>{value}</option>
              ))
            }
          </select>
        </div>
      </Grid>
      <Grid item xs={12} sm={ enableStatedAmount ? 4 : 6 }>
        <div className={classes.wrapper} style={currentErrors?.TrailerMake_TXT != null ? { borderColor: 'red' } : {}}>
          <label className={classes.label}>Make</label>
          <select
            className={classes.select}
            value={form.values[inputIndex].TrailerMake_TXT}
            onChange={(event) => setMake(event.target.value)}
          >
            <option value=''>Select an option...</option>
            {
              makeList.map((value: string, index: number) => (
                <option key={index} value={value}>{value}</option>
              ))
            }
          </select>
        </div>
      </Grid>
      {enableStatedAmount ? (
        <Grid item xs={12} sm={4}>
        
          {currentErrors ? (
            <label className={classes.labelError}>
              {currentErrors.TrailerStatedAmount_CHOICE}
            </label>
          ) : null}
        </Grid>
      ) : null}
    </Grid>
  )
}
