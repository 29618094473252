import React from 'react'
import Alert from './Alert'
import * as Yup from 'yup'
import { API } from 'services/instanda'
import { DataPolicy } from '../interfaces'
import { Formik, Form, Field } from 'formik'
import { Container, Grid } from '@material-ui/core'
import { useUpperFormStyles } from '../styles/UpperForm.styles'
import StandaloneModal, { IOpenProps } from './StandaloneModal'
import { StandaloneCaptchaTest } from '../../StandaloneCaptcha/StandaloneCaptchaTest'

const creditCardSchema = Yup.object().shape({
  clientMail: Yup.string().email('Must be a valid email').required('Email is necessary'),
  nameOnCard: Yup.string().required('Credit card name is necessary'),
  creditCardNumber: Yup.number().required('Credit Card number is necessary'),
  expDate: Yup.string().required('Expiration date is necessary'),
  cvc: Yup.number().min(3, '3 numbers are needed').required('CVC number is necessary'),
  postalCode: Yup.number().required('Postal code field is necessary')
})

const CreditCardForm: React.FC<DataPolicy> = ({ dataPolicy, paymentMethodChoice }) => {
  const classes = useUpperFormStyles()
  const [transactionNumber, setTransactionNumber] = React.useState('')

  const [openStandalone, setOpenStandalone] = React.useState<IOpenProps>({
    title: null,
    content: null,
    isSuccess: false
  })

  const submitForm = (values: any): void => {
    console.log('submitForm', paymentMethodChoice, values)
    API.post('/authorize/creditCard', { // pouch.eastus.cloudapp.azure.com
      totalAmount: dataPolicy?.amount,
      invoiceNumber: `${Date.now()}`,
      cardNumber: values?.creditCardNumber,
      cardDate: values?.expDate,
      cardCode: values?.cvc,
      country: 'USA',
      firstName: dataPolicy?.firstName ?? 'DefaultFirstName',
      lastName: dataPolicy?.lastName ?? 'DefaultLastName',
      company: dataPolicy?.agentGroupName ?? 'DefaultCompany',
      address: dataPolicy?.address ?? 'DefaultAddress',
      city: dataPolicy?.city ?? 'DefaultCity',
      state: dataPolicy?.state ?? 'DefaultState',
      zip: dataPolicy?.zip ?? '66612',
      email: values?.clientMail ?? 'mail@pouchinsurance.com', // dataPolicy?.email
      phone: dataPolicy?.phone ?? '8185555555'
    })
      .then((res) => {
        console.log('resSend', res)
        setTransactionNumber(res.data.transactionId)
        setOpenStandalone({
          title: 'Payment Complete',
          content: null,
          isSuccess: true
        })
      })
      .catch((err) => {
        console.log('errSend', err)
        console.log('errSendSmall', err?.response?.data)
        setOpenStandalone({
          title: 'Payment Declined',
          content: 'Oh snap! The Credit Card payment was declined, try again with another payment method.',
          isSuccess: false
        })
      })
  }

  return (
    <div>
      <StandaloneModal openStandalone={openStandalone} setOpenStandalone={setOpenStandalone} email={dataPolicy?.email} transactionNumber={transactionNumber} hideFirstMessage />
      <Formik
        initialValues={{
          clientMail: '',
          nameOnCard: '',
          creditCardNumber: '',
          expDate: '',
          cvc: '',
          postalCode: '',
          notes: ''
        }}
        onSubmit={(v) => submitForm(v)}
        validationSchema={creditCardSchema}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <Container maxWidth='md'>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Email</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='clientMail' />
                    {errors.clientMail !== null && touched.clientMail !== null ? <Alert>{errors.clientMail}</Alert> : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Name on Card</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='nameOnCard' />
                    {errors.nameOnCard !== null && touched.nameOnCard !== null ? <Alert>{errors.nameOnCard}</Alert> : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Credit Card Number</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='creditCardNumber' />
                    {errors.creditCardNumber !== null && touched.creditCardNumber !== null ? <Alert>{errors.creditCardNumber}</Alert> : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Expiration Date</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='expDate' />
                    {errors.expDate !== null && touched.expDate !== null ? <Alert>{errors.expDate}</Alert> : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>CVC</label>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field type='text' name='cvc' />
                    {errors.cvc !== null && touched.cvc !== null ? <Alert>{errors.cvc}</Alert> : null}
                  </Grid>
                  <Grid item xs={12} md={2} className={classes.textRight}>
                    <label>Postal Code</label>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field type='text' name='postalCode' />
                    {errors.postalCode !== null && touched.postalCode !== null ? <Alert>{errors.postalCode}</Alert> : null}
                  </Grid>
                </Grid>
                <hr className={classes.customHr} />
                <Grid container spacing={1} className={`${classes.notesArea} ${classes.paymentSection}`}>
                  <Grid item xs={12} md={3}>
                    <label>Notes</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field as='textarea' name='notes' />
                  </Grid>
                </Grid>
                <div style={{ textAlign: 'center' }}>
                  <StandaloneCaptchaTest />
                </div>
                <div className={classes.submitButton}>
                  <button type='submit'>SEND</button>
                </div>
              </Container>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CreditCardForm
