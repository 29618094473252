import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import modalImage from '../assets/modal-pouch.png'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Dialog, DialogContent } from '@material-ui/core'

const useStyles = makeStyles({
  modal: {
    padding: '25px',
    maxWidth: '500px',
    borderRadius: '20px'
  },
  image: {
    width: '100%'
  },
  message: {
    fontSize: '20px'
  },
  team: {
    textAlign: 'right',
    alignItems: 'right'
  },
  center: {
    paddingTop: '30px',
    textAlign: 'center',
    alignItems: 'center'
  },
  button: {
    color: 'white',
    borderStyle: 'none',
    padding: '12px 40px',
    borderRadius: '10px',
    backgroundColor: '#ff6c1a'
  }
})

interface IErrorModal {
  open: boolean
  toggle: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ErrorModal ({ open, toggle }: IErrorModal): ReactElement {
  const classes = useStyles()
  const history = useHistory()

  const handleClose = (): void => {
    toggle(false)
    history.push('/')
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent className={classes.modal}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <img className={classes.image} src={modalImage} alt='pouch-icon' />
          </Grid>
          <Grid item xs={12} md={9}>
            <div className={classes.message}>
              Thanks for your interest in Pouch, at this time we are unable to offer you a coverage.
              <div className={classes.team}>
                Team Pouch
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.center}>
          <button className={classes.button} onClick={handleClose}>Go Back</button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
