import { ReactNode, ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 700,
    margin: '25px 0 25px 0',
    fontSize: '1.5em',
    fontStyle: 'normal'
  }
})

export default function Title ({ className = '', children }: { className?: string, children: ReactNode }): ReactElement {
  const classes = useStyles()
  return (
    <h2 className={`${classes.title} ${className}`}>{children}</h2>
  )
}
