import { quotePouch } from "interfaces/pouch-redux";
import { defaultSalesPerson, GenerateMTAData, GetLocalQuoteData, getPolicyDataForAdjustment, getQuoteMultiItems, getSalesPerson, salesPersonLocalStorage } from "services/instanda";
import { getQuote, inPage, updating } from "./quote"
import { merge } from "lodash";

export const getInfoQuote:any = (quoteorpolicy:string,page:string) => {
    return async( dispatch:any ) =>{
        try{
            dispatch(updating({
                page:'updating',
                loading:true,
                quote: {},
                errorMessage:''
            }));

            let response = {
                data: {}
            };
            
            if(page === 'mta-form'){
                response = await GenerateMTAData(quoteorpolicy);
            }else{
                response = await GetLocalQuoteData(quoteorpolicy);
            }
            const quoteResponse = response.data as quotePouch;
            if(quoteResponse.IsMTA){ 
                if(quoteResponse.PolicyNumber){
                    const responsePrevious = await getPolicyDataForAdjustment(quoteResponse.PolicyNumber);
                    const multiItems = await getQuoteMultiItems(quoteResponse?.OriginalQuoteRef || '');
                    const fullQuote = merge(quoteResponse, multiItems.data.data.response) as quotePouch
                    const previousQuoteData = responsePrevious.data as quotePouch;
                    const previousQuote = merge(previousQuoteData, multiItems)
                    dispatch(getQuote({
                        page,
                        previousQuote,
                        quote: fullQuote,
                        multiItems,
                        errorMessage: ''
                    }))
                }                
            }else{
                dispatch(getQuote({
                    page,
                    previousQuote:{},
                    quote: quoteResponse,
                    errorMessage: ''
                }))
            }
            
        }catch(e){
            dispatch(getQuote({
                page,
                previousQuote:{},
                quote: {},                
                errorMessage: (<Error>e).message
            }))
        }
        
    }
}


export const updateQuotePouch:any = (quote:quotePouch) => {
    return ( dispatch:any ) =>{             
        dispatch(inPage({             
            page:'update-quote',        
            quote,
            errorMessage:''
        }))                
    }
}