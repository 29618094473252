import { ReactElement } from 'react'
import { Grid } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  addBtn: {
    padding: '12px',
    cursor: 'pointer',
    margin: '20px 0px',
    borderRadius: '15px',
    border: '2px solid #dddddd'
  },
  addText: {
    fontSize: '1.1rem',
    textAlign: 'center'
  },
  disable: {
    color: '#c6c6c6',
    cursor: 'not-allowed'
  }
})

export default function AddButton ({ text, action, disable = false }: { text: string, action: () => void, disable?: boolean }): ReactElement {
  const classes = useStyles()
  return (
    <section className={classes.addBtn + (disable ? ` ${classes.disable}` : '')} onClick={disable ? () => null : action}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <AddIcon />
        </Grid>
        <Grid item xs={8} className={classes.addText}>
          {text}
        </Grid>
      </Grid>
    </section>
  )
}
