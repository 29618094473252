import { ReactElement } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LabelPerks from '../generalities/LabelPerks'
import WhatWeOfferSection from '../generalities/WhatWeOfferSection'

const sectionStyles = makeStyles({
  sectionContainer: {
    width: '90%',
    paddingTop: '40px',
    margin: '0 auto',
    maxWidth: '1250px'
  },
  carMainBox: {
    textAlign: 'center',
    alignItems: 'center',
    height: '250px',
    paddingTop: '60px',
    borderRadius: '20px',
    border: '1px solid #e2e2e2'
  },
  cardSubtitle: {
    fontSize: '18px',
    paddingTop: '20px'
  }
})

interface IRightItems {
  title?: string
  subtitle?: string
  text?: string
}

interface IFreeVehicleSection {
  leftItems: any[]
  rightItems: IRightItems
  pickOtherSide?: boolean
  renderAsBoxes?: boolean
  changeSectionSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const FreeVehicleSection = ({ leftItems, rightItems, pickOtherSide = false, renderAsBoxes = false, changeSectionSize = 6 }: IFreeVehicleSection): ReactElement => {
  const classes = sectionStyles()

  const textContentFragment = (
    <Grid item xs={12} md={changeSectionSize}>
      <WhatWeOfferSection title={rightItems.title} subtitle={rightItems.subtitle} text={rightItems.text} />
    </Grid>
  )

  return (
    <Grid container spacing={5} className={classes.sectionContainer}>
      {pickOtherSide ? textContentFragment : null}
      <Grid item xs={12} md>
        {
          renderAsBoxes ? (
            <Grid container spacing={3}>
              {
                leftItems.map((value, index) => (
                  <Grid item xs={12} md key={index}>
                    <div className={classes.carMainBox}>
                      <value.image />
                      <p className={classes.cardSubtitle}>{value.title}</p>
                    </div>
                  </Grid>
                ))
              }
            </Grid>
          ) : (
            leftItems.map((value, index) => (
              <LabelPerks key={index} Img={value.image} title={value.title} />
            ))
          )
        }
      </Grid>
      {pickOtherSide ? null : textContentFragment}
    </Grid>
  )
}

export default FreeVehicleSection
