import React from 'react'
import * as Yup from 'yup'
import Alert from './Alert'
import { API } from 'services/instanda'
import { DataPolicy } from '../interfaces'
import { Formik, Form, Field } from 'formik'
import { Container, Grid } from '@material-ui/core'
import { useUpperFormStyles } from '../styles/UpperForm.styles'
import StandaloneModal, { IOpenProps } from './StandaloneModal'
import { StandaloneCaptchaTest } from '../../StandaloneCaptcha/StandaloneCaptchaTest'

const bankAccountSchema = Yup.object().shape({
  bankAccountHolder: Yup.string().required('Account holder name is needed'),
  routingNumber: Yup.number().required('Routing Number is needed'),
  accountNumber: Yup.number().required('Account number is needed'),
  confirmAccountNumber: Yup.number().oneOf(
    [Yup.ref('accountNumber'), null],
    'Account numbers must match'
  )
})

const BankAccountForm: React.FC<DataPolicy> = ({
  dataPolicy,
  paymentMethodChoice
}) => {
  const classes = useUpperFormStyles()

  const [openStandalone, setOpenStandalone] = React.useState<IOpenProps>({
    title: null,
    content: null,
    isSuccess: false
  })

  const paymentDeclined = (): void => {
    setOpenStandalone({
      title: 'Payment Declined',
      content: 'Oh snap! The ACH payment was declined, try again with another payment method.',
      isSuccess: false
    })
  }

  const submitForm = (values: any): void => {
    console.log('submitForm', paymentMethodChoice, values)
    API.post('/quickbooks/createDebit', { // pouch.eastus.cloudapp.azure.com
      quoteRef: `${Date.now()}`,
      debitBody: {
        bankAccount: {
          phone: dataPolicy?.phone ?? '8185555555',
          routingNumber: values?.routingNumber,
          name: values?.bankAccountHolder,
          accountType: values?.accountType ?? 'PERSONAL_CHECKING',
          accountNumber: values?.accountNumber
        },
        description: dataPolicy?.policyHolderName,
        checkNumber: '12345678',
        paymentMode: 'WEB',
        amount: dataPolicy?.amount
      }
    })
      .then((res) => {
        console.log('resSend', res)
        if (res?.data?.status === 'DECLINED') {
          paymentDeclined()
        } else if (res?.data?.status === 'PENDING') {
          setOpenStandalone({
            title: 'Your payment is being processed!',
            content: '',
            isSuccess: true
          })
        } else {
          setOpenStandalone({
            title: 'Payment Complete',
            content: null,
            isSuccess: true
          })
        }
      })
      .catch((err) => {
        console.log('errSend', err)
        console.log('errSendSmall', err?.response?.data)
        paymentDeclined()
      })
  }

  return (
    <div>
      <StandaloneModal
        openStandalone={openStandalone}
        setOpenStandalone={setOpenStandalone}
        email={dataPolicy?.ContactEmail_TXT}
      />
      <Formik
        initialValues={{
          bankAccountHolder: '',
          routingNumber: '',
          accountNumber: '',
          confirmAccountNumber: '',
          notes: ''
        }}
        onSubmit={(v) => submitForm(v)}
        validationSchema={bankAccountSchema}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <Container maxWidth='md'>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Bank Account Holder</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='bankAccountHolder' />
                    {errors.bankAccountHolder !== null &&
                    touched.bankAccountHolder !== null
                      ? (
                        <Alert>{errors.bankAccountHolder}</Alert>
                        )
                      : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Routing Number</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='routingNumber' />
                    {errors.routingNumber !== null &&
                    touched.routingNumber !== null
                      ? (
                        <Alert>{errors.routingNumber}</Alert>
                        )
                      : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Account Number</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='accountNumber' />
                    {errors.accountNumber !== null &&
                    touched.accountNumber !== null
                      ? (
                        <Alert>{errors.accountNumber}</Alert>
                        )
                      : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Confirm Account Number</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field type='text' name='confirmAccountNumber' />
                    {errors.confirmAccountNumber !== null &&
                    touched.confirmAccountNumber !== null
                      ? (
                        <Alert>{errors.confirmAccountNumber}</Alert>
                        )
                      : null}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.paymentSection}>
                  <Grid item xs={12} md={3}>
                    <label>Account Type</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field as='select' name='accountType'>
                      <option value='PERSONAL_CHECKING'>
                        Personal Checking
                      </option>
                      <option value='PERSONAL_SAVINGS'>Personal Savings</option>
                      <option value='BUSINESS_CHECKING'>
                        Business Checking
                      </option>
                      <option value='BUSINESS_SAVINGS'>Business Savings</option>
                    </Field>
                  </Grid>
                </Grid>
                <hr className={classes.customHr} />
                <Grid
                  container
                  spacing={1}
                  className={`${classes.notesArea} ${classes.paymentSection}`}
                >
                  <Grid item xs={12} md={3}>
                    <label>Notes</label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field as='textarea' name='notes' />
                  </Grid>
                </Grid>
                <div style={{ textAlign: 'center' }}>
                  <StandaloneCaptchaTest />
                </div>
                <div className={classes.submitButton}>
                  <button type='submit'>SEND</button>
                </div>
              </Container>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default BankAccountForm
