import { createContext, ReactElement } from 'react'

export const AppContext = createContext(null) as any

export default function AppContextProvider ({ children }: { children: any }): ReactElement {
  return (
    <AppContext.Provider value={{}}>
      {children}
    </AppContext.Provider>
  )
}
