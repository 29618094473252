import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect, ReactNode, ReactElement } from 'react'
import { Clear as ClearIcon, ExpandMore, ErrorOutline } from '@material-ui/icons'
import { Accordion, IconButton, AccordionSummary, AccordionDetails } from '@material-ui/core'

const useStyles = makeStyles({
  form: {
    boxShadow: 'none',
    margin: '20px 0px',
    borderRadius: '15px',
    border: '2px solid #dddddd',
    '&::before': {
      content: '"something"',
      display: 'none'
    }
  },
  summary: {
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between'
    }
  },
  driver: {
    paddingLeft: '6px',
    '& span': {
      top: '2px',
      left: '8px',
      color: 'gray',
      position: 'relative'
    }
  },
  children: {
    width: '100%',
    display: 'block'
  },
  delete: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
})

interface ICollapse {
  index: number
  title: string
  image: ReactNode
  subtitle: string
  children: ReactNode
  ignoreIndex?: boolean
  hasError?: boolean
  delButton?: (index: number) => void
}

export default function Collapse ({ index, ignoreIndex = false, title, image, subtitle, children, hasError = false, delButton = () => null }: ICollapse): ReactElement {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  useEffect(() => setExpanded(true), [hasError])
  const removeButton = (): void => delButton(index)
  const toggleExpanded = (): void => setExpanded(!expanded)

  return (
    <Accordion className={classes.form} expanded={hasError || expanded} onChange={hasError ? () => null : toggleExpanded}>
      <AccordionSummary className={classes.summary} expandIcon={hasError ? <ErrorOutline /> : <ExpandMore />}>
        <div className={classes.driver}>
          {image}
          <span>
            {title} {index + 1}
          </span>
          <span>{expanded ? '' : subtitle !== '' ? ` - ${subtitle}` : ''}</span>
        </div>
        {index !== 0 || ignoreIndex ?
        (
          <div>
            <IconButton size='small' onClick={removeButton}>
              <ClearIcon className={classes.delete} />
            </IconButton>
          </div>
        )
        : null}
      </AccordionSummary>
      <AccordionDetails>
        <section className={classes.children}>{children}</section>
      </AccordionDetails>
    </Accordion>
  )
}
