import { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
// Assets
import partyGoodie from '../assets/Party_goodie.svg'

interface IQuoteCard {
  typeCard: 0 | 1 | 2 // Package | Custom | Good
  bkgColor?: string
  discount?: number
  toggleMM: () => void
  toggleYY: () => void
  clickCrd?: () => void
  updateQT: () => void
  buyNowQT: () => void
  isYearly: boolean
  costYear: number
  costMont: number
  costDown: number
  needsUpdate: boolean
  isAgentSite: boolean
  payPlanOptions?: Array<{ label: string, value: string }>
  selectedOption?: string
  changeSelectedOption: (e: any) => void
  quoteNum2?: number
  disablePositioning?: boolean
  policyDate?: string
  isArizona?: boolean
  isGeorgia?: boolean
  policyHasTelematics?: boolean
  policyTerm?: string
  icon4?: string
  disabledBuyNow?: boolean
}

interface IStyleProps {
  sideSize: number
  sideDirt: 'left' | 'right'
  sideText: number
  isActive: boolean
  bkgColor: string
  zzzIndex: number
  isYearly: boolean
  disablePositioning: boolean
}

const activeToggle = {
  color: '#ff6c1a',
  fontWeight: 'bold',
  backgroundColor: '#ffffff',
  borderTop: '2px solid #dddddd',
  borderBottom: '2px solid #dddddd'
}

const inactiveToggle = {
  border: 'none',
  fontWeight: 600,
  color: '#313131',
  backgroundColor: '#f7f7f7'
}

const useStyles = makeStyles((theme) => ({
  cardMain: (props: IStyleProps) => ({
    paddingTop: props.disablePositioning ? '15px' : '0px',
    width: props.disablePositioning ? '100%' : '70%',
    height: props.disablePositioning ? '455px' : '482px%',
    cursor: 'pointer',
    borderRadius: '8px',
    position: props.disablePositioning ? 'relative' : 'absolute',
    zIndex: props.zzzIndex,
    border: '2px solid #dddddd',
    backgroundColor: props.bkgColor,
    left: props.disablePositioning ? '0px' : `${props?.sideText * (props.sideSize + 1)}px`,
    '@media (max-width:780px)': {
      height: '115%',
      left: props.disablePositioning ? '0px' : `${props?.sideText * (props.sideSize - 6)}px`
    },
    '@media (max-width:520px)': {
      width: '350px'
    }
  }),
  cardType: (props: IStyleProps) => ({
    textAlign: props.sideDirt,
    padding: props.isActive ? '6px' : '6px 0px',
    '& div': {
      fontSize: '12px',
      padding: '6px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
      borderRadius: '18px',
      display: 'inline-block',
      color: props.isActive ? '#ff6c1a' : '#000000',
      width: props.isActive ? '65px' : `${props.sideSize}px`,
      backgroundColor: props.isActive ? '#f7f7f7' : props.bkgColor
    }
  }),
  innerCard: (props: IStyleProps) => ({
    // height: '75%',
    padding: '0px',
    overflow: 'hidden',
    display: props.isActive ? 'block' : 'none',
    '& h4': {
      fontWeight: 'bold',
      paddingBottom: '12px',
      fontSize: '16px',
      margin: 0
    },
    '& h5': {
      fontWeight: 'bold',
      fontSize: '14px'
    },
    '& h6': {
      paddingBottom: '12px',
      fontSize: '13px'
    },
    '& .border-y': {
      borderTop: '2px solid rgb(221, 221, 221)',
      padding: '10px 0 0 10px',
      margin: '0 -10px 0px -10px',
      '& .disabled-btn-buy': {
        color: 'lightgrey',
        cursor: 'not-allowed'
      }
    },
    '& .border-y:hover': {
      backgroundColor: '#FD5645',
      color: 'white',
      '& span, .btn-buy': {
        color: 'white'
      },
      '& .disabled-btn-buy': {
        color: 'lightgrey',
        cursor: 'not-allowed'
      }
    },
    '& .border-y .MuiGrid-root': {
      padding: '10px 0px'
    },
    '& .border-y span': {
      position: 'absolute',
      left: '10px',
      marginTop: '-5px',
      fontSize: '12px',
      color: 'black'
    },
    '& .img-container': {
      height: '295px',
      textAlign: 'center'
    },
    '& .img-container img': {
      width: '50%'
    },
    '& .btn-buy': {
      border: 'none',
      background: 'none',
      color: '#FD5645',
      fontWeight: 'bold',
      textDecoration: 'underline',
      marginLeft: '10px',
      padding: '0px'
    },
    '& .orangeP': {
      color: 'rgb(253, 86, 69)',
      fontWeight: 'bold'
    }

  }),
  coverageIcon: {
    paddingTop: '6px',
    paddingBottom: '0',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '& img': {
      width: '30px'
    },
    '& span': {
      left: '8px',
      opacity: 0.76,
      color: '#313131',
      fontSize: '10px',
      position: 'relative'
    },
    '& .MuiBox-root': {
      margin: '0 10px'
    },
    '& .MuiGrid-item': {
      padding: '10px'
    }
  },
  byCoterie: {
    paddingTop: '6px',
    paddingBottom: '0',
    display: 'flex',
    justifyContent: 'center',
    gap: 5
  },
  timeSelector: (props: IStyleProps) => ({
    textAlign: 'center',
    width: '100%',
    display: props.isActive ? 'block' : 'none',
    position: props.disablePositioning ? 'static' : 'absolute',
    bottom: props.disablePositioning ? '' : 60,
    marginTop: props.disablePositioning ? '2px' : '10px',
    '@media (max-width:780px)': {
      bottom: 50
    }
  }),
  timeToggle: (props: IStyleProps) => ({
    paddingTop: '20px',
    textAlign: 'center',
    display: props.isActive ? 'block' : 'none',
    width: '100%',
    '& div': {
      borderRadius: '22px',
      display: 'inline-block',
      backgroundColor: '#f7f7f7',
      border: '2.5px solid #dddddd'
    },
    '& span': {
      bottom: '1px',
      fontSize: '12px',
      padding: '5px 15px',
      position: 'relative',
      borderRadius: 'inherit'
    },
    '& span:first-child': !props.isYearly
      ? {
          ...activeToggle,
          borderRight: '2px solid #dddddd'
        }
      : {
          ...inactiveToggle
        },
    '& span:nth-child(2)': props.isYearly
      ? {
          ...activeToggle,
          borderLeft: '2px solid #dddddd'
        }
      : {
          ...inactiveToggle
        }
  }),
  buyButton: (props: IStyleProps) => ({
    border: 0,
    bottom: 0,
    width: '100%',
    color: 'white',
    padding: '15px',
    position: 'absolute',
    backgroundColor: '#ff6c1a',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    display: props.isActive ? 'block' : 'none'
  }),
  select: {
    width: '96%',
    textAlign: 'center',
    '& .MuiSelect-select': {
      padding: '5px',
      backgroundColor: 'initial'
    },
    '& fieldset': {
      top: '0px',
      bottom: '-2px'
    },
    '& .MuiSelect-icon': {
      position: 'relative',
      marginLeft: '-24px',
      '@media (max-width: 780px)': {
        marginLeft: '-10px'
      },
      '@media (max-width: 420px)': {
        marginLeft: '-8px'
      }
    }
  },
  cardPackage: (props: IStyleProps) => ({
    textAlign: props.sideDirt,
    padding: props.isActive ? '6px' : '6px 0px',
    '& div': {
      fontSize: '12px',
      padding: '6px 0px',
      fontWeight: 'bold',
      textAlign: 'center',
      borderRadius: '8px',
      display: 'inline-block',
      color: props.isActive ? '#FFFFFF' : '#000000',
      width: props.isActive ? '65px' : `${props.sideSize}px`,
      backgroundColor: props.isActive ? '#4223FF' : props.bkgColor
    }
  }),
  packageCoverages: {
    fontWeight: 'bold',
    fontSize: 13,
    color: 'black'
  },
  discountContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 5
  },
  discountText: {
    fontSize: '0.7em',
    fontWeight: 'bold'
  },
  date: {
    '@media (max-width: 500px)': {
      marginBottom: '1.2rem'
    },
    '@media (max-width: 300px)': {
      marginBottom: '2rem'
    }
  }
}))

export default function QuoteCard ({
  typeCard,
  bkgColor = '#ffffff',
  discount = undefined,
  toggleMM,
  toggleYY,
  clickCrd = () => null,
  updateQT,
  buyNowQT,
  isYearly,
  costYear,
  costMont,
  costDown,
  needsUpdate,
  isAgentSite,
  payPlanOptions,
  selectedOption,
  changeSelectedOption,
  quoteNum2,
  disablePositioning = false,
  policyDate,
  isArizona = false,
  isGeorgia = false,
  policyHasTelematics = false,
  icon4,
  disabledBuyNow = false,
  policyTerm
}: IQuoteCard): ReactElement {
  const canBeCustom = false
  const [policyType] = useState(localStorage.getItem('policyType'))
  const styleProps: IStyleProps = {
    sideSize: 0,
    sideDirt: (quoteNum2 ?? 0) > typeCard ? 'left' : 'right',
    sideText: typeCard - (policyType === 'glbop' ? 0 : 1) - (canBeCustom ? 0 : policyType === 'glbop' && typeCard === 0 ? 0 : 1),
    isActive: (quoteNum2 ?? 0) === typeCard,
    bkgColor: bkgColor,
    zzzIndex: (quoteNum2 ?? 0) >= typeCard ? 10 + typeCard : 10 - typeCard,
    isYearly: isYearly,
    disablePositioning: disablePositioning
  }

  const coterieInfo = JSON.parse(localStorage.getItem('coterieInfo') as string)
  let cc = 0
  let ach = 0
  let pif = 0
  const fullQuote = JSON.parse(localStorage.getItem('instandaFullQuote') as string)
  const validatePayment = (paymentMethod: string): void => {
    if (paymentMethod === 'CC') {
      fullQuote.PayPlan_CHOICE = 'Monthly - Recurring Credit Card'
      fullQuote.PayPlanSelected_TXT = 'Monthly-CC'
      fullQuote.PayPlan_CHOICE_Default = 'Monthly - Recurring Credit Card'
    } else if (paymentMethod === 'ACH') {
      fullQuote.PayPlan_CHOICE = 'Monthly - Recurring EFT'
      fullQuote.PayPlanSelected_TXT = 'Monthly-EFT'
      fullQuote.PayPlan_CHOICE_Default = 'Monthly - Recurring EFT'
    } else {
      fullQuote.PayPlan_CHOICE = 'Paid in Full'
      fullQuote.PayPlanSelected_TXT = 'Annual'
      fullQuote.PayPlan_CHOICE_Default = 'Paid in Full'
    }

    localStorage.setItem('instandaFullQuote', JSON.stringify(fullQuote))

    updateQT()

    buyNowQT()
  }

  const payPlanCHOICE = fullQuote.PayPlan_CHOICE ?? 'Monthly - Recurring Credit Card'
  if (payPlanCHOICE === 'Monthly - Recurring Credit Card') {
    cc = costYear
    ach = costYear - (costYear * 0.05)
    pif = costYear - (costYear * 0.10)
  } else if (payPlanCHOICE === 'Monthly - Recurring EFT') {
    cc = costYear + (costYear * 0.05)
    ach = costYear
    pif = costYear - (costYear * 0.05)
  } else if (payPlanCHOICE === 'Paid in Full') {
    pif = costYear
    cc = pif + (pif * 0.10)
    ach = pif + (pif * 0.05)
  }

  const termType = {
    monthly: '6 Months',
    annual: '12 Months'
  }


  const term = policyTerm === termType.annual ? 12 : 6

  const setCostYCC = (): number => Number(cc)
  const setCostMCC = (): number => setCostYCC()  / term
  const setCostYEFT = (): number => Number(ach)
  const setCostMEFT = (): number => setCostYEFT() / term
  const setCostPIF = (): number => Number(pif)
  // const setCostYEFT = (): number => costYear * 0.950
  // const setCostMEFT = (): number => setCostYEFT() / 6
  // const setCostPIF = (): number => costYear * 0.900

  const classes = useStyles(styleProps)

  return (
    <section className={classes.cardMain} onClick={clickCrd}>
      <div className={classes.innerCard}>
        <section className={classes.coverageIcon}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <Grid className='img-container' item xs={12}>
                <img src={partyGoodie} alt='Party Goodie' />
                <p className='orangeP'>*Prices may vary based on the drivers and vehicle updates</p>
              </Grid>
            </Grid>
            {
              !isGeorgia
                ? (
                  <>
                    <Grid className='border-y' container spacing={4}>
                      <Grid item xs={4}>
                        <h4><strong>${setCostYCC().toFixed(2)}</strong></h4>
                        <span>Per term with Credit card.</span>
                      </Grid>
                      <Grid item xs={2}>
                        <h4>{'>>'}</h4>
                      </Grid>
                      <Grid item xs={3}>
                        <h4><strong>${setCostMCC().toFixed(2)}</strong><sub>/mo</sub></h4>
                      </Grid>
                      <Grid item xs={3}>
                        <h6><button className={`btn-buy ${disabledBuyNow ? 'disabled-btn-buy' : ''}`} onClick={() => validatePayment('CC')} disabled={disabledBuyNow}>Buy Now</button></h6>
                      </Grid>
                    </Grid>
                  </>
                )
                : null
            }
            {/* <Grid className='border-y' container spacing={4}>
              <Grid item xs={4}>
                <h4><strong>${setCostYEFT()?.toFixed(2)}</strong></h4>
                <span>Per term with ACH.</span>
              </Grid>
              <Grid item xs={2}>
                <h4>{'>>'}</h4>
              </Grid>
              <Grid item xs={3}>
                <h4><strong>${setCostMEFT()?.toFixed(2)}</strong><sub>/mo</sub></h4>
              </Grid>
              <Grid item xs={3}>
                <h6><button className={`btn-buy ${disabledBuyNow ? 'disabled-btn-buy' : ''}`} onClick={() => validatePayment('ACH')} disabled={disabledBuyNow}>Buy Now</button></h6>
              </Grid>
            </Grid> */}
            <Grid className='border-y' container spacing={4}>
              <Grid item xs={9}>
                <h4><strong>${setCostPIF().toFixed(2)}</strong></h4>
                <span>10% Discount for paying your Premium in full.</span>
              </Grid>
              <Grid item xs={3}>
                <h6><button className={`btn-buy ${disabledBuyNow ? 'disabled-btn-buy' : ''}`} onClick={() => validatePayment('Paid in Full')} disabled={disabledBuyNow}>Buy Now</button></h6>
              </Grid>
            </Grid>
          </Box>
        </section>
      </div>
    </section>
  )
}
