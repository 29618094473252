import { makeStyles } from '@material-ui/core'
import { IStyleProps } from 'interfaces'

const activeToggle = {
  color: '#ff6c1a',
  fontWeight: 'bold',
  backgroundColor: '#ffffff',
  borderTop: '2px solid #dddddd',
  borderBottom: '2px solid #dddddd'
}

const inactiveToggle = {
  border: 'none',
  fontWeight: 600,
  color: '#313131',
  backgroundColor: '#c4c4c4'
}

export const resposiveStyles = makeStyles({
  cardContainer: {
    '@media (max-width: 600px)': {
      justifyContent: 'center'
    }
  },
  cards: {
    '@media (max-width: 600px)': {
      margin: '1rem 0'
    }
  }
})

export const useStyles = makeStyles({
  select: {
    padding: '5px 50px 10px 50px ',
    color: 'black'
  },
  btnLayOut: {
    margin: '2rem auto'
  },
  buyNowBtn: {
    border: '1px solid #E5E5E5',
    borderRadius: '10px',
    backgroundColor: '#ff6c1a',
    padding: '5px 30px',
    textTransform: 'capitalize',
    color: '#ffff',
    height: '50px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  noBtn: {
    border: '1px solid #E5E5E5',
    padding: '5px 30px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#E5E5E5',
      fontWeight: 'bold'
    }
  },
  input: {
    color: 'black'
  },
  active: {
    backgroundColor: '#E5E5E5',
    fontWeight: 'bold'
  },
  notActive: {
    backgroundColor: '#fff',
    fontWeight: 500
  },
  container: {
    marginTop: 55,
    fontFamily: 'Libre Franklin'
  },
  vinTXT: {
    fontSize: '1rem',
    '@media (max-width:400px)': {
      fontSize: 14
    },
    '@media (max-width:330px)': {
      fontSize: 13
    },
    '@media (max-width:300px)': {
      fontSize: 11
    }
  },
  title: {
    fontWeight: 700,
    margin: '0 0 20px 0',
    fontSize: '2.4em',
    fontStyle: 'normal',
    textAlign: 'center'
  },
  paragraph: {
    fontSize: 17,
    textAlign: 'justify',
    marginBottom: 0,
    '@media (max-width:780px)': {
      fontSize: 16
    },
    '@media (max-width:740px)': {
      fontSize: 14
    }
  },
  forceByMojioLink: {
    color: '#39C7C2'
  },
  timeToggle: (props: IStyleProps) => ({
    textAlign: 'center',
    display: props.isActive ? 'block' : 'none',
    width: '100%',
    marginTop: '2rem',
    '& div': {
      borderRadius: '12px',
      display: 'inline-block',
      backgroundColor: '#c4c4c4',
      border: '2.5px solid #dddddd',
      height: '50px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& span': {
      top: '10px',
      fontSize: '12px',
      padding: '15px 30px',
      position: 'relative',
      borderRadius: 'inherit'
    },
    '& span:first-child': !props.isYearly
      ? {
          ...activeToggle,
          borderRight: '2px solid #dddddd'
        }
      : {
          ...inactiveToggle
        },
    '& span:nth-child(2)': props.isYearly
      ? {
          ...activeToggle,
          borderLeft: '2px solid #dddddd'
        }
      : {
          ...inactiveToggle
        }
  })
})
