import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface ISideExports {
  goNextPage: () => void
  goBackPage: () => void
  thisPage: number
  displayGlBopView: boolean
  setDisplayGlBopView: React.Dispatch<React.SetStateAction<boolean>>
  changePage: (newNumber: number) => void
}

export default function useSideButtons (): ISideExports {
  const history = useHistory()
  const [thisPage, setThisPage] = useState(Number(localStorage.getItem('instandaThisPage') ?? '1'))
  const [displayGlBopView, setDisplayGlBopView] = useState(false) // to show and hide glbop view

  const changePage = (newNumber: number): void => {
    const policyType = localStorage.getItem('policyType') ?? ''
    if (newNumber === 1) history.push('/join/about-you')
    if (newNumber === 2) history.push('/join/vehicles')
    if (newNumber === 3) history.push('/join/drivers')
    if (newNumber === 4 && policyType === 'glbop' && thisPage === 3) setDisplayGlBopView(true)
    if (newNumber === 4) history.push('/join/quote')
    if (newNumber === 5) history.push('/join/additional-info')
    if (newNumber === 6) history.push('/join/final-quote')
    if (newNumber === 7) history.push('/join/final-quote-2')
    if (newNumber === 8) history.push('/join/documents')
    if (newNumber === 9) history.push('/join/payment')
    if (newNumber === 10) history.push('/join/congrats')
    setThisPage(newNumber) // Change variable in React State
    localStorage.setItem('instandaThisPage', String(newNumber)) // Change variable in localStorage
  }

  useEffect(() => {
    changePage(thisPage)
  }, [])
  
  const goNextPage = (): void => {
    localStorage.setItem('comingBack', JSON.stringify(false))
    const instandaThisPage = Number(localStorage.getItem('instandaThisPage'))
    const isComingBackFromPage6 = Boolean(localStorage.getItem('isComingBackFromPage6'))
    const agentFinish = String(localStorage.getItem('agentFinish'))
    const isCompleteQuote = agentFinish === 'true' ? true : false 
    console.log(instandaThisPage, isComingBackFromPage6)
    if (instandaThisPage === 3 && isComingBackFromPage6) {
      console.log(thisPage)
      setThisPage(instandaThisPage)
      changePage(instandaThisPage + 1)
    } else if(instandaThisPage === 1 && isCompleteQuote) {
      changePage(thisPage + 3)
    } else {
      changePage(thisPage + 1)
    }
  }

  const goBackPage = (): void => {
    localStorage.setItem('comingBack', JSON.stringify(true))
    const policyType = localStorage.getItem('policyType') as string
    const backToDriver = localStorage.getItem('isComingBack')
    const instandaThisPage = Number(localStorage.getItem('instandaThisPage'))
    if (thisPage === 1) {
      console.log('You are on Join first page (forcing page refresh to disable forward button)')
      history.push('/') // Keep this, so we don't go back to the exact same page, otherwise the page won't refresh
    } else if (thisPage === 4 && policyType === 'glbop' && !displayGlBopView) {
      changePage(thisPage)
      setDisplayGlBopView(true)
    } else if(instandaThisPage === 6){
      const isBackToDriver = backToDriver === 'true' ? true : false      
      if (isBackToDriver) {
        changePage(thisPage - 3)        
      } else {
        changePage(thisPage - 1)
      }
    } else {
      changePage(thisPage - 1)
    }
  }

  useEffect(() => {
    window.onpopstate = () => {
      console.log('popstate')
      goBackPage()
    }
  })

  return { goNextPage, goBackPage, thisPage, displayGlBopView, setDisplayGlBopView, changePage }
}
