import { useFormContext } from 'context/FormContext'
import { useAppSelector } from 'hooks/useAppSelector'
import { updateQuotePouch } from '../../redux/slices/quote/thunks'
import { IDriver, IMVRCall, IPage6A } from 'interfaces/pouch-redux'
import React, { useEffect, useState } from 'react'
import { dispatchApp } from 'redux/store'
import { API, GetLocalQuoteData } from 'services/instanda'
import MVRColapse from 'views/MTA/components/MVRCollapse/MVRColapse'

const Page6A = ({setMVRError}: IPage6A) => {  
  const [oneTime, setOneTime] = useState(false)
  const [quoteData, setQuoteData] = useState<any>({})
  const [drivers, setDrivers] = useState<IDriver[]>([])
  const [MVRCall, setMVRCall] = useState<IMVRCall>({
    businessState: '',
    drivers: []
  })
  const [violations, setViolations] = useState<any[]>([])
  const [accidents, setAccidents] = useState<Number[]>([])
  const [driverIndex, setDriverIndex] = useState(0)
  const formContext =  useFormContext()

  useEffect(() => {
    localStorage.removeItem('isComingBackFromPage6')
    
    getDriver().then( async (data) => {
    if (!oneTime && MVRCall.drivers.length > 0) {
        console.log(data, MVRCall)
          if (violations.length < 1) {
            setOneTime(true)
            const mvr = await API.post('/polkServices/MVRData', MVRCall, { headers: { 'Content-Type': 'application/json' } })
            const mvrResult = await mvr.data.response
            setViolations(mvrResult.slice(0, driverIndex))      
            console.log(mvrResult)                  
          }
        }
      })      
  }, [quoteData])
  const getDriver = async () => {
    if (violations.length > 0) {
      //  formContext?.setLoading(false)
      return
    }
    formContext?.setProgress(18)
    const joinStorage = JSON.parse(localStorage.getItem('instandaFullQuote') ?? '{}')
    if (MVRCall.drivers.length < 1) {      
      const result = await GetLocalQuoteData()
      const quote = await result.data
      const driversCount = quote?.Drivers_Count;
      setDriverIndex(driversCount)
      console.log(driversCount)
      setQuoteData({ ...quote, ...joinStorage })
      setDrivers(quote.Drivers)
      const driversData = API.get(`/polkServices/DriverData?QuoteRef=${quote.QuoteRef}`)
        .then((driverData) => driverData.data.response)
        .then(async (driversResponse) => {
          console.log(driversResponse)
          
          const accidentsArr = drivers.map((driver: any, index: number) => driversResponse[index].DriverIncidentsAccidents_NUM * 4)
  
          const driversArr = drivers.slice(0, driversCount).map((driver: any, index: number) => {
            return {
              licenseNumber: driver.DriverLicenseFinal_TXT,
              firstName: driver.DriverFirstName_TXT,
              lastName: driver.DriverLastName_TXT,
              streetNumber: 0,
              streetName: quoteData.BusinessAddressLine1_TXT,
              licenseState: driver.DriverLicenseStateFinal_TXT,
              dob: driver.DriverDOB_DATE_TXT,
              city: quoteData.BusinessCity_TXT,
              selfReportedMinorViolationPoints: driversResponse[index].DriverIncidentsAccidents_NUM || 0
            }
          })
          const MVRCallBoddy = {
            businessState: quote.BusinessState_TXT,
            drivers: driversArr
          }
          console.log(MVRCallBoddy)
          console.log(accidentsArr)
          setTimeout(() => {
            setMVRCall(MVRCallBoddy)
            setAccidents(accidentsArr.slice(0, driverIndex))
          }, 1000);
          return MVRCallBoddy
        })
        return driversData
    }
  }
  return (
    <div className='container'>
      {drivers.length > 0 ?
        <MVRColapse 
          title='Driver' 
          drivers={drivers} 
          violations={violations} 
          setMVRError={setMVRError}
          driverIndex={driverIndex}
          accidents={accidents}
          quoteData={quoteData}
          >

        </MVRColapse>
        : <></>
      }
    </div>
  )
}

export default Page6A