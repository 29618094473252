import { API, quoteRef } from './instanda'

export async function findTerm (payload: any): Promise<any> {
  return await API.post(`/chargeBee/findTerm`, payload)
}

export async function findGWPremiumMTA (QuoteRef: any): Promise<any> {
  return await API.get(`/chargeBee/findGWPremiumMTA/${String(QuoteRef)}`)
}

export async function PayWithChargebee (payload: any, QuoteRef?:string): Promise<any> {
  return await API.post(`/chargeBee/pay/${QuoteRef ?? quoteRef()}`, payload)
}

export async function PayMTAWithChargebee (payload: any): Promise<any> {
  return await API.post(`/chargeBee/payMTA`, payload)
}

export async function getInvoices (CustomerId: any): Promise<any> {
  return await API.get(`/chargeBee/invoices/${String(CustomerId)}`)
}

export async function DownloadPDF (ChargeBeeInvoice_TXT?: any, QuoteRef?:string): Promise<any> {
  return await API.post('/payment/invoicePDF', {
    quoteRef: QuoteRef ?? quoteRef(),
    ChargeBeeInvoice_TXT
  })
}

export async function DownloadCreditNote(creditNote?: any): Promise<any> {
  return await API.post(`/chargeBee/CreditNote/${creditNote}`)
}
