import { useContext, useEffect, useState } from 'react';
import { dispatchApp } from 'redux/store';
import { useFormik } from 'formik'
import { GetBussinesClass, GetLocalQuoteData, isAGENT } from 'services/instanda';
import { Box } from '@material-ui/core'
import Title from 'components/Title'
import { dateAddDays, dateOfToday, getEffectiveChangeDate, previousECD } from 'utils/calculations';

import { getInfoQuote, updateQuotePouch } from 'redux/slices/quote';
import TypedInput from '../components/TypedInput/TypedInput';
import { ContactSchema, validateError } from 'utils/validations';
import { ContactFormContext } from 'context/ContactFormContext';
import { ErrorMessage } from 'UI/CustomElement';
import { IContactFormType } from 'interfaces/pouch-redux';
import { formatDate, generateId } from 'utils/functions';
import joinQuoteFiller from 'utils/joinQuoteFiller';
import errorHandler from 'utils/errorHandler';
import { useSnackbar } from 'notistack';
import { useAppSelector } from 'hooks/useAppSelector';
import './CustomerInfo.scss'


const CustomerInfo = ({ isAdjust, verifyErrors, setVerifyErrors }: any) => {
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch);
  const [id, setId] = useState('');
  const [classes, setClasses] = useState([]);
  const contactFormContext = useContext(ContactFormContext);
  const { enqueueSnackbar } = useSnackbar();

  const setValues = async () => {
    console.log("setValues", formikForm)
    const {
      ContactFirstName_TXT,
      ContactLastName_TXT,
      ContactEmail_TXT,
      PolicyEffective_DATE,
      EffectiveChangeDate,
      BusinessNameDBA_TXT,
      ContactPhone_TXT,
      BusinessSubClass_CHOICE,
      BusinessAddressLine1_TXT,
      BusinessZip_TXT,
      BusinessCity_TXT,
      BusinessState_TXT,
      ContactTitle_TXT,
      ...quoteWithout
    } = quote ?? {};
    console.log(quoteWithout)
    dispatchApp(updateQuotePouch({
      ...quoteWithout,
      ContactFirstName_TXT: formikForm.values.ContactFirstName_TXT,
      ContactLastName_TXT: formikForm.values.ContactLastName_TXT,
      ContactEmail_TXT: formikForm.values.ContactEmail_TXT,
      PolicyEffective_DATE: formikForm.values.PolicyEffective_DATE,
      EffectiveChangeDate: formikForm.values.EffectiveChangeDate,
      BusinessNameDBA_TXT: formikForm.values.BusinessNameDBA_TXT,
      ContactPhone_TXT: formikForm.values.ContactPhone_TXT,
      BusinessSubClass_CHOICE: formikForm.values.BusinessSubClass_CHOICE,
      BusinessAddressLine1_TXT: formikForm.values.BusinessAddressLine1_TXT,
      BusinessZip_TXT: formikForm.values.BusinessZip_TXT,
      BusinessCity_TXT: formikForm.values.BusinessCity_TXT,
      BusinessState_TXT: formikForm.values.BusinessState_TXT,
      ContactTitle_TXT: formikForm.values.ContactTitle_TXT
    }));
  }

  const setErrors = () => {
    if (id !== '') {
      verifyErrors[id] = Object.keys(formikForm.errors).length > 0 ? true : false;
      console.log('CustomerInfo', id, verifyErrors, formikForm?.errors)
      setVerifyErrors({
        ...verifyErrors
      });
    }
  }

  const formikForm = useFormik({
    enableReinitialize: true,
    validateOnChange: true,    
    initialValues: contactFormContext.values as IContactFormType,
    validationSchema: ContactSchema,    
    onSubmit: () => {      
      setValues();
    }
  });

  useEffect(() => {    
    setId(generateId()+'CustomerInfo');
    if (isAdjust || isViewMode) {      
      try {
        setTimeout(() => {
          console.log(quote)
          console.log(getEffectiveChangeDate(String(quote?.EffectiveChangeDate)))
          console.log(formatDate(String(quote?.PolicyEffective_DATE)))
          console.log(formatDate(String(quote?.EffectiveChangeDate), true))
          formikForm.setFieldValue('ContactFirstName_TXT', quote?.ContactFirstName_TXT)
          formikForm.setFieldValue('ContactLastName_TXT', quote?.ContactLastName_TXT)
          formikForm.setFieldValue('ContactEmail_TXT', quote?.ContactEmail_TXT)
          formikForm.setFieldValue('PolicyEffective_DATE', isAGENT() ? formatDate(String(quote?.PolicyEffective_DATE)) : '')
          formikForm.setFieldValue('EffectiveChangeDate', quote?.EffectiveChangeDate ? formatDate(String(getEffectiveChangeDate(quote?.EffectiveChangeDate)), true) : '')
          formikForm.setFieldValue('BusinessNameDBA_TXT', quote?.BusinessNameDBA_TXT)
          formikForm.setFieldValue('ContactPhone_TXT', quote?.ContactPhone_TXT)
          formikForm.setFieldValue('BusinessSubClass_CHOICE', quote?.BusinessSubClass_CHOICE)
          formikForm.setFieldValue('BusinessAddressLine1_TXT', quote?.BusinessAddressLine1_TXT)
          formikForm.setFieldValue('BusinessZip_TXT', quote?.BusinessZip_TXT?.substring(0, 5))
          formikForm.setFieldValue('BusinessCity_TXT', quote?.BusinessCity_TXT)
          formikForm.setFieldValue('BusinessState_TXT', quote?.BusinessState_TXT)
          formikForm.setFieldValue('ContactTitle_TXT', quote?.ContactTitle_TXT)          
          formikForm.setFieldValue('IsMTA', quote?.IsMTA )          
          console.log(formikForm.values.BusinessState_TXT, quote?.BusinessState_TXT)
        }, 100);
      } catch (error) {        
        errorHandler(error, enqueueSnackbar)
      }
    }

    async function getAllClasses(): Promise<any> {
      try {
        let values = [] as any
        const result = await GetBussinesClass()
        result.data.result.map((item: any) =>
          values.push({ value: item.className, label: item.className })
        )

        values = values.sort(SortArray)
        setClasses(values)
      } catch (error) {
        console.log(error)
      }
    }
    getAllClasses().catch((error) => console.log({ error }))

    if (isAdjust) {
      formikForm.setFieldValue('BusinessState_TXT', quote?.BusinessState_TXT)
      formikForm.setFieldValue('BusinessCity_TXT', quote?.BusinessCity_TXT)
      formikForm.setFieldValue('ContactTitle_TXT', quote?.ContactTitle_TXT)
    }

    setErrors()
  }, []);


  function SortArray(x: any, y: any) {
    if (x.label < y.label) { return -1 }
    if (x.label > y.label) { return 1 }
    return 0
  }

  useEffect(() => {
    setErrors()
  }, [formikForm.errors]);

  useEffect(() => {
    setValues()
  }, [formikForm.values]);
  
  return (
    <form
      onSubmit={formikForm.handleSubmit}      
    >
      <Box maxWidth={860} margin='0 auto' padding={5}>
        {isAdjust && <Title>Policy Adjusment</Title>}
        <div className='col-12 mt-10 ml-0 mx-4'>
          {!isAdjust ? <Title>Customer Name</Title> : <Title isAdjust>Personal & Business Information</Title>}
        </div>
        <>
          <div className='row'>
            <div className={isAdjust ? 'col-3' : 'col-6'}>
              <TypedInput
                inputName='ContactFirstName_TXT'
                inputPlaceholder='First Name'
                inputTitle='First Name'
                inputType='text'
                formikForm={formikForm}
                hasErrors={validateError(formikForm.errors.ContactFirstName_TXT)}
                inputValue='ContactFirstName_TXT'
                disabled={isViewMode}
              />
              {validateError(formikForm.errors.ContactFirstName_TXT) && (
                <ErrorMessage>{formikForm.errors.ContactFirstName_TXT}</ErrorMessage>
              )}
            </div>
            <div className={isAdjust ? 'col-3' : 'col-6'}>
              <TypedInput
                inputName='ContactLastName_TXT'
                inputPlaceholder='Last Name'
                inputTitle='Last Name'
                inputType='text'
                formikForm={formikForm}
                hasErrors={validateError(formikForm.errors.ContactLastName_TXT)}
                inputValue='ContactLastName_TXT'
                disabled={isViewMode}                
              />
              {validateError(formikForm.errors.ContactLastName_TXT) && (
                <ErrorMessage>{formikForm.errors.ContactLastName_TXT}</ErrorMessage>
              )}
            </div>
            <div className={isAdjust ? 'col-3' : 'col-6'}>
              <TypedInput
                inputName='ContactEmail_TXT'
                inputPlaceholder='Email'
                inputTitle='Email'
                inputType='email'
                formikForm={formikForm}
                hasErrors={validateError(formikForm.errors.ContactEmail_TXT)}
                inputValue='ContactEmail_TXT'
                disabled={isViewMode}
              />
              {validateError(formikForm.errors.ContactEmail_TXT) && (
                <ErrorMessage>{formikForm.errors.ContactEmail_TXT}</ErrorMessage>
              )}
            </div>
            <div className={isAdjust ? 'col-3' : 'col-6'}>
              <TypedInput
                inputName='BusinessSubClass_CHOICE'
                inputPlaceholder='Business Class'
                inputTitle='Business Class'
                inputType='select'
                inputOptions={classes}
                formikForm={formikForm}
                hasErrors={validateError(formikForm.errors.BusinessSubClass_CHOICE)}
                inputValue='BusinessSubClass_CHOICE'
                disabled={isViewMode}                
              />
              {validateError(formikForm.errors.BusinessSubClass_CHOICE) && (
                <ErrorMessage>{formikForm.errors.BusinessSubClass_CHOICE}</ErrorMessage>
              )}
            </div>
            { isAGENT()
              ? (
                <>
                  {
                    (isAdjust && !isViewMode) && (
                      <div className='col-12'>
                        <TypedInput
                          formikForm={formikForm}
                          inputType='date'
                          inputTitle='Endorsement Effective Date'
                          inputName='EffectiveChangeDate'
                          minDate={previousQuote?.EffectiveChangeDate !== undefined ? dateAddDays(formatDate(String(previousQuote?.EffectiveChangeDate), true), 2) : dateOfToday() }
                          maxDate={dateAddDays(dateOfToday(), 30)}
                          hasErrors={validateError(formikForm.errors.EffectiveChangeDate)}
                          inputValue='EffectiveChangeDate'
                          disabled={isViewMode}                          
                        />
                        {validateError(formikForm.errors.EffectiveChangeDate) && (
                          <ErrorMessage>{formikForm.errors.EffectiveChangeDate}</ErrorMessage>
                        )}
                      </div>
                    )
                  }
                  <div className='col-12'>
                    <TypedInput
                      formikForm={formikForm}
                      inputType='date'
                      inputTitle='Effective Policy Date'
                      inputName='PolicyEffective_DATE'
                      minDate={dateOfToday()}
                      maxDate={dateAddDays(dateOfToday(), 30)}
                      hasErrors={validateError(formikForm.errors.PolicyEffective_DATE)}
                      inputValue='PolicyEffective_DATE'
                      disabled={isViewMode || isAdjust}                      
                    />
                    {validateError(formikForm.errors.PolicyEffective_DATE) && (
                      <ErrorMessage>{formikForm.errors.PolicyEffective_DATE}</ErrorMessage>
                    )}
                  </div>
                </>
              )
              : null}
            <div className='col-12 mt-10 mx-4'>
              {!isAdjust && <Title>Business Information</Title>}
            </div>
            <div className='col-6'>
              <TypedInput
                inputName='BusinessNameDBA_TXT'
                inputPlaceholder='Business Name'
                inputTitle='Business Name'
                inputType='text'
                formikForm={formikForm}
                hasErrors={validateError(formikForm.errors.BusinessNameDBA_TXT)}
                inputValue='BusinessNameDBA_TXT'
                disabled={isViewMode}
              />
              {validateError(formikForm.errors.BusinessNameDBA_TXT) && (
                <ErrorMessage>{formikForm.errors.BusinessNameDBA_TXT}</ErrorMessage>
              )}
            </div>
            <div className='col-6'>
              <TypedInput
                inputName='ContactPhone_TXT'
                inputPlaceholder='Business Phone'
                inputTitle='Business Phone'
                inputType='text'
                formikForm={formikForm}                
                inputValue='ContactPhone_TXT'                
                disabled={isViewMode}                
              />
              {validateError(formikForm.errors.ContactPhone_TXT) && (
                <ErrorMessage>{formikForm.errors.ContactPhone_TXT}</ErrorMessage>
              )}
            </div>

            <div className='col-6'>
              <TypedInput
                placePicker={!isAdjust}
                inputName='BusinessAddressLine1_TXT'
                inputPlaceholder='Business Address'
                inputTitle='Business Address'
                inputType='text'
                formikForm={formikForm}
                hasErrors={validateError(formikForm.errors.BusinessAddressLine1_TXT)}
                inputValue='BusinessAddressLine1_TXT'
                disabled={isViewMode}              
              />
              {validateError(formikForm.errors.BusinessAddressLine1_TXT) && (
                <ErrorMessage>{formikForm.errors.BusinessAddressLine1_TXT}</ErrorMessage>
              )}
            </div>
            <div className={isAdjust ? 'col-6' : 'col-12'}>
              <TypedInput
                inputName='BusinessZip_TXT'
                inputPlaceholder='ZIP'
                inputTitle='ZIP'
                inputType='text'
                formikForm={formikForm}
                hasErrors={validateError(formikForm.errors.BusinessZip_TXT)}
                inputValue='BusinessZip_TXT'
                disabled={isViewMode || isAdjust}                
              />
              {validateError(formikForm.errors.BusinessZip_TXT) && !isAdjust && (
                <ErrorMessage>{formikForm.errors.BusinessZip_TXT}</ErrorMessage>
              )}
            </div>
          </div>
        </>
      </Box>
    </form>
  )
}

export default CustomerInfo