import { makeStyles } from '@material-ui/core';

export const DialogBoxStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #aaaa',
    width: '70%',
    alignItems: 'center',
    margin: '0 auto',
    padding: '1rem'
  },
  subContainer: {
    display: 'flex',
    width: '70%',
    justifyContent: 'space-between',
    '& input': {
      border: 'none',
      textAlign: 'end',
      width: '50%',
      backgroundColor: '#fff'
    },
    '& input[name="total"]': {
      color: '#37c3b8'
    }
  }
})
