import { useEffect } from 'react'

interface IFields {
  allFields: number
  fullFields: number
}

const countObject = (insideEach: any, fields: IFields): IFields => {
  Object.values(insideEach).forEach(arrayItem => {
    fields.allFields += 1
    if (arrayItem !== '') fields.fullFields += 1
  })
  return fields
}

export default function useFormPercent (
  minNumber: number,
  formikForm: any,
  formContext: any
): void {
  const fillupLogic = (): void => {
    let fields = { allFields: 0, fullFields: 0 }
    if (formikForm?.values != null) {
      if (Array.isArray(formikForm.values)) {
        formikForm.values.forEach((insideEach: any) => {
          fields = countObject(insideEach, fields)
        })
      } else {
        fields = countObject(formikForm.values, fields)
      }
    }
    const maybeIsNan = ((fields.fullFields / fields.allFields) * 100)
    const percentage = isNaN(maybeIsNan) ? 0 : maybeIsNan
    const actualProgress = minNumber + (Math.floor(percentage / 3)) - 1
    formContext.setProgress(actualProgress >= 100 ? 100 : actualProgress)
  }

  useEffect(() => {
    fillupLogic()
  }, [formikForm, formContext])
}
