import { ReactElement, useEffect, useState } from 'react';
import { Box, Card, CircularProgress, Typography } from '@material-ui/core'
import { CardForm } from 'components/CardForm'
import Title from 'components/Title';
import { useFormik } from 'formik';
import { ChargebeeInitialValues, chargebeeSchema } from 'utils/validations';
import { paymentStyles } from './Payment.css';
import { ReactComponent as GOODIE_P1 } from '../Join/assets/Goodie_p1_icon.svg'
import Button from 'views/Landing/components/generalities/Button';
import { getQuoteValuesChargerBee } from 'interfaces/chargerbee';
import { BindExistingQuote, GetLocalQuoteData, updateQuoteChargerBee } from 'services/instanda';
import { PayWithChargebee, getInvoices } from '../../services/chargeBee'
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import { dispatchApp } from 'redux/store';
import { updateQuotePouch } from 'redux/slices/quote';


export const Payment = (): ReactElement => {
    //States Contexts    
    const { quote } = useAppSelector(state=> state.pouch);     
    const [ loading, setLoading] = useState(false);
    const [ payOk, setPayOK] = useState(false);
    const { enqueueSnackbar } = useSnackbar();    
    const history = useHistory();
    //Hooks
    const formikChargerBee = useFormik({
        initialValues: ChargebeeInitialValues,
        validationSchema: chargebeeSchema,
        onSubmit: (values) => console.log(values)
    });
    
    useEffect(()=>{
        setLoading(true);           
        const actualDate = new Date();                          
        actualDate.setDate( actualDate.getDate() - 30)
        //Pay already        
        if(quote?.ChargeBeeID_TXT && quote?.ChargeBeeInvoice_TXT){          
          setPayOK(true);              
        }else{
          //Pay offline
          if(quote?.PayPlan_CHOICE_Default == 'Offline Paid in Full' || quote?.PayPlan_CHOICE_Default == 'Offline Monthly'){    
            onSubmitChargebee(quote);
          }else if(quote?.PolicyEffective_DATE && quote?.PolicyEffective_DATE < actualDate){            
            callBindExistingQuoteService()            
          }else{
            setLoading(false); 
          }
        }          
    },[quote?.ChargeBeeID_TXT, quote?.ChargeBeeInvoice_TXT,
      quote?.PayPlan_CHOICE_Default,quote?.PayPlan_CHOICE_Default]);

    //Functions
    const callBindExistingQuoteService = async (): Promise<void> => {
        setLoading(true)
        BindExistingQuote({},quote?.QuoteRef)
          .then((res: any) => {
            //formContext.savePolicyNumber(res.data.policyNumber)
            //formContext.setProgress(100)
            //formContext.goNextPage()
          })
          .catch((err: any) => {
            setLoading(false)
            enqueueSnackbar(err.message, { variant: 'error' })
          }).finally(() => {
            setLoading(false)
          })
    }    

    const onSubmitChargebee = async (quoteRes: any): Promise<void> => {
      try{
        setLoading(true)
        const pay = await PayWithChargebee({ ...formikChargerBee.values, ...getQuoteValuesChargerBee(quote) }, quoteRes.QuoteRef)
        toThankYou()
      }catch(err:any){        
        enqueueSnackbar(err?.response?.data ?? 'Try again', { variant: 'error' })
        setLoading(false);
      }      
    }

    const toThankYou = () => {
      history.push('thank-you')
    }

    return (                
      <Card style={paymentStyles.card}>
        {
          quote?.IsRenewal ?
          <Box sx={paymentStyles.flexBetween}>
              <Title>Renewal</Title>
              <GOODIE_P1 width={80} />
          </Box>
          :
          <Box sx={paymentStyles.flexBetween}>
              <Title>Pay with card</Title>
              <GOODIE_P1 width={80} />
          </Box>
        }        
          <Box >
            {
            quote?.IsRenewal ?
              <Box sx={{ ...paymentStyles.flexCenter, ...paymentStyles.mx1 }}>
                <Typography style={paymentStyles.inherit}>
                  Your renewal quote will be collected from your current payment method.
                  To change it, go to Payments button under your policy.
                </Typography>                
                <Box sx={paymentStyles.flexCenter}>
                    <Button disabled={loading} onClick={()=>{onSubmitChargebee(quote)}}>
                        { 
                          loading ? 
                          <CircularProgress/> :
                          <Typography style={paymentStyles.inherit} >Renew</Typography>
                        }
                    </Button>
                </Box>
              </Box>
              :
              payOk ? 
              <Box sx={{...paymentStyles.flexCenter, ...paymentStyles.mx1}}>
                <Typography style={paymentStyles.inherit}>
                  The current term for the Policy { quote?.PolicyNumber } is already paid, to see your sold policies
                </Typography>
                <Button onClick={toThankYou}>
                  Click here
                </Button>
              </Box>
              :
              <>
                <CardForm formikForm={formikChargerBee}/>
                <Box sx={paymentStyles.flexCenter}>
                    <Button disabled={loading} onClick={()=>{onSubmitChargebee(quote)}}>
                        { 
                          loading ? 
                          <CircularProgress/> :
                          <Typography style={paymentStyles.inherit} >Pay</Typography>
                        }
                    </Button>
                </Box>
              </>
            }       
          </Box>        
      </Card>
    )
}

