import { useAppSelector } from 'hooks/useAppSelector'
import { IMVRCall, IMVRInfo } from 'interfaces/pouch-redux'
import React, { useEffect, useState } from 'react'
import { dispatchApp } from 'redux/store'
import MVRColapse from '../components/MVRCollapse/MVRColapse'
import { IDriver } from '../DriverInfo/DiverInfo'
import { updateQuotePouch, updating } from 'redux/slices/quote'
import './MVRInfo.scss'
import { API, GetLocalQuoteData } from 'services/instanda'
import { Box, Dialog } from '@material-ui/core'
import Loader from '../components/Loader/Loader'
import StepperSidebar from 'UI/StepperSidebar/StepperSidebar'

const MVRInfo = () => {
  const [MVRError, setMVRError] = useState(false)
  const { quote, previousQuote, loading } = useAppSelector(state => state.pouch)
  const [oneTime, setOneTime] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [quoteData, setQuoteData] = useState<any>({})
  const [drivers, setDrivers] = useState<any>([])
  const [MVRCall, setMVRCall] = useState<IMVRCall>({
    businessState: '',
    drivers: []
  })
  const [violations, setViolations] = useState<any[]>([])
  const [accidents, setAccidents] = useState<Number[]>([])
  const [driverIndex, setDriverIndex] = useState(0)
  const complete = async () => {
    const {
      
      ...quoteWithout
    } = quote ?? {};
    console.log(quoteWithout)
    dispatchApp(updateQuotePouch({
      ...quoteWithout,
      thisPage: 6
    }));
  }

  useEffect(() => {
   complete()
  }, [])
  

  useEffect(() => {
    localStorage.removeItem('isComingBackFromPage6')
    
    getDriver().then( async (data) => {
      if (!oneTime && MVRCall.drivers.length > 0) {
      setIsLoading(true)
        console.log(data, MVRCall)
          if (violations.length < 1) {
            setOneTime(true)
            const mvr = await API.post('/polkServices/MVRData', MVRCall, { headers: { 'Content-Type': 'application/json' } })
            const mvrResult = await mvr.data.response
            setViolations(mvrResult.slice(0, driverIndex))      
            console.log(mvrResult)                  
          }
        }
        setIsLoading(false)
      })      
  }, [quoteData])
  
  const getDriver = async () => {
    if (violations.length > 0) {
    //    formContext?.setLoading(false)
      return
    }
    // formContext?.setProgress(18)
    
    if (MVRCall.drivers.length < 1) {      
      const result = await GetLocalQuoteData(quote?.QuoteRef)
      const quoteResult = await result.data
      const driversCount = quote?.Drivers_Count;
      driversCount && setDriverIndex(driversCount)
      console.log(driversCount)
      const originalQuoteData = quote?.Drivers ? quote : previousQuote
      setQuoteData({ ...quoteResult, ...originalQuoteData })
      setDrivers(quoteResult.Drivers)
      const driversData = API.get(`/polkServices/DriverData?QuoteRef=${quoteResult.QuoteRef}`)
        .then((driverData) => driverData.data.response)
        .then(async (driversResponse) => {
          console.log(driversResponse)
          
          const accidentsArr = drivers.map((driver: any, index: number) => driversResponse[index].DriverIncidentsAccidents_NUM * 4)
  
          const driversArr = drivers.slice(0, driversCount).map((driver: any, index: number) => {
            return {
              licenseNumber: driver.DriverLicenseFinal_TXT,
              firstName: driver.DriverFirstName_TXT,
              lastName: driver.DriverLastName_TXT,
              streetNumber: 0,
              streetName: quoteData.BusinessAddressLine1_TXT,
              licenseState: driver.DriverLicenseStateFinal_TXT,
              dob: driver.DriverDOB_DATE_TXT,
              city: quoteData.BusinessCity_TXT,
              selfReportedMinorViolationPoints: driversResponse[index].DriverIncidentsAccidents_NUM || 0
            }
          })
          const MVRCallBoddy = {
            businessState: quoteResult.BusinessState_TXT,
            drivers: driversArr
          }
          console.log(MVRCallBoddy)
          console.log(accidentsArr)
          setTimeout(() => {
            setMVRCall(MVRCallBoddy)
            setAccidents(accidentsArr.slice(0, driverIndex))
          }, 1000);
          return MVRCallBoddy
        })
        return driversData
    }
  }
  
  return (
    <>
    {
      isLoading ?
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Dialog
                            PaperProps={{
                                className: 'custom-dialog'
                            }}
                            open={true}
                        >
                            <Loader />
                        </Dialog>
                    </Box>
                    :
      <Box display={'flex'}>
        <Box width={'65%'}  margin={5}>
          <div className='container'>
            {drivers.length > 0  ?
              <MVRColapse 
                title='Driver' 
                drivers={drivers} 
                violations={violations} 
                setMVRError={setMVRError}
                driverIndex={driverIndex}
                accidents={accidents}>
              </MVRColapse>
              : <></>
            }
          </div>
        </Box>
        <Box width={'30%'} margin={5}>
          <StepperSidebar sideMain='MVR Report' sideText='One last step, please add a couple more details to create the final quote.' MVRError={MVRError}/>
        </Box>
      </Box>
    }
    </>
  )
}

export default MVRInfo