import { ReactElement } from 'react'
import { IGenericCard, PouchCardProps } from 'interfaces'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core'
import icon1 from '../assets/card-icon-1.svg'
import icon2 from '../assets/card-icon-2.svg'
import icon3 from '../assets/card-icon-3.svg'
import icon4 from '../assets/quote-icon-4-black.svg'

const useStyles = makeStyles({
  headerContainer: {
    marginBottom: '1rem'
  },
  coveragesContainer: {
    margin: '0.5rem 0'
  },
  dateContainer: {
    margin: '1.5rem 0 0.5rem'
  },
  logoImg: {
    width: '200px',
    margin: '0 auto'
  },
  coterieLogoImg: {
    width: '200px',
    height: '87px',
    padding: '2rem 0',
    margin: '0 auto'
  },
  iconImg: (props: PouchCardProps) => ({
    marginRight: props.policyType ? 5 : 8,
    width: '25px'
  }),
  labelFont: (props: PouchCardProps) => ({
    fontSize: props.policyType ? '0.75rem' : '0.95rem',
    color: '#676767'
  }),
  amount: {
    fontWeight: 'bold',
    fontSize: '2rem'
  },
  yearlyMonthly: (props: PouchCardProps) => ({
    fontWeight: 'bold',
    fontSize: props.policyType ? '0.75rem' : '0.95rem'
  }),
  downPayment: (props: PouchCardProps) => ({
    fontSize: props.policyType ? '0.75rem' : '0.95rem'
  })
})

const PouchCard = ({ isArizona = false, ...cardProps }: IGenericCard): ReactElement => {
  const { policyDate, paymentType, isMonthly, isYearly, policyType, policyHasTelematics } = cardProps

  const styleProps: any = {
    policyType
  }

  const classes = useStyles(styleProps)

  return (
    <Card variant='outlined'>
      <CardContent>
        <Grid className={classes.headerContainer} container direction='column'>
          <Typography className={classes.amount}>{`$ ${paymentType ?? ''}`}</Typography>
          <Typography className={classes.yearlyMonthly}>{isMonthly}</Typography>
          <Typography className={classes.downPayment}>{isYearly ? `Paid in full of ${paymentType}` : `Plus a ${paymentType} down payment`}</Typography>
        </Grid>
        <Grid className={classes.coveragesContainer} container justifyContent='space-around' alignItems='center'>
          <img className={classes.iconImg} src={icon1} alt='icon_img' />
          <Typography className={classes.labelFont} style={{ maxWidth: policyType ? 223 : 283 }}>Body Injury and Property Damage Liability</Typography>
        </Grid>
        <Grid className={classes.coveragesContainer} container justifyContent='space-around' style={{ visibility: isArizona ? 'hidden' : 'visible' }}>
          <img className={classes.iconImg} src={icon2} alt='icon_img' />
          <Typography className={classes.labelFont}>Uninsured / Underinsured Motorist Bodily <br /> Injury and Property Damage</Typography>
        </Grid>
        <Grid className={classes.coveragesContainer} container justifyContent='space-around' alignItems='center'>
          <img className={classes.iconImg} src={icon3} alt='icon_img' />
          <Typography className={classes.labelFont}>Medical Payments &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </Typography>
        </Grid>
        {policyHasTelematics
          ? (
            <Grid className={classes.coveragesContainer} container justifyContent='space-around' alignItems='center'>
              <img className={classes.iconImg} src={icon4} alt='icon_img' />
              <Typography className={classes.labelFont}>Telematics &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </Typography>
            </Grid>
            )
          : null}
        <Grid className={classes.dateContainer} container direction='column' alignContent='center'>
          <Typography className={classes.labelFont}>Policy Start Date</Typography>
          <Typography className={classes.downPayment}>&nbsp;&nbsp;{policyDate}</Typography>
        </Grid>
        <div className={classes.logoImg}>
          <img
            alt='logo'
            src='https://quote.pouchinsurance.com/videos/home_video.svg'
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default PouchCard
