
import { Box, Card, Grid, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect, ReactElement } from 'react'
// Assets
import spacing from './assets/stepper-spacing.svg'
import reading from './assets/stepper-reading.svg'
// Context
import { useFormContext } from 'context/FormContext'
// hooks
import { useWindowSize } from 'views/Standalone/hooks/useWindowSize'
import { defaultSalesPerson, getSalesPerson } from 'services/instanda'
import { InfoOutlined } from '@material-ui/icons'
import { getBrandBySalesName } from 'utils/configBrands'
import { useHistory } from 'react-router-dom'
import GoodieNote from 'UI/GoodieNote/GoodieNote'

const commonButton = {
  width: '100%',
  height: '50px',
  border: 'none',
  marginTop: '25px',
  borderRadius: '12px'
}

const useStyles = makeStyles({
  stepperSideBar: {
    position: 'sticky',
    top: '50px'
  },
  sideImage: {
    textAlign: 'center'
  },
  sideTitle: {
    color: '#000000',
    fontSize: '27px',
    fontWeight: 500,
    textAlign: 'left'
  },
  backButton: {
    ...commonButton,
    color: '#000000',
    fontWeight: 'bold',
    backgroundColor: '#f7f7f7'
  },
  nextButton: {
    ...commonButton,
    fontWeight: 500,
    color: '#ffffff',
    backgroundColor: '#ff6c1a'
  },
  nextButtonDisabled: {
    ...commonButton,
    color: '#000000',
    fontWeight: 'bold',
    backgroundColor: 'lightgrey'
  },
  cardAgent: {
    margin: '2rem 0 0 0',
    padding: '1rem',
    backgroundColor: '#D8D8D8',
    color: 'gray'
  }
})

export default function StepperSidebar ({ sideMain, sideText, MVRError }: { sideMain: string, sideText: string , MVRError?: boolean}): ReactElement {
  const classes = useStyles()
  const formContext = useFormContext()
  const history = useHistory()  

  const [fallbackMain, setFallbackMain] = useState('')
  const [fallbackText, setFallbackText] = useState('')
  const [openToolTip, setOpenToolTip] = useState(false)

  const instandaThisPage = Number(localStorage.getItem('instandaThisPage'))

  const goBackSide = (value: boolean): void => {
    localStorage.setItem("isComingBack", JSON.stringify(value));
    //formContext.setIsComingBack(true)
    formContext.setSideText('') // clears sideText before changing the page
    // if([2].includes(formContext.thisPage)){
    //   formContext?.form?.handleSubmit()
    // }
    formContext.goBackPage()     
  }

  const goNextSide = (): void => {
    formContext.setSideText('') // clears sideText before changing the page    
    formContext?.form?.handleSubmit()
  }

  const copyToclipboard = async (value: any): Promise<void> => {
    await navigator.clipboard.writeText(value)
  }
  const tooltipOpen = (): void => {
    setOpenToolTip(true)
    setTimeout(() => {
      setOpenToolTip(false)      
    }, 3000);
  }


  useEffect(() => {
    setFallbackMain(sideMain)
    setFallbackText(sideText)
  }, [formContext.thisPage])

  const windowSize = useWindowSize()
  const { width } = windowSize

  const sidebarButtons = (
    <Grid container spacing={2}>
      <Grid item xs>
        <button className={classes.backButton} type='button' onClick={() => goBackSide(false)}>Previous</button>
      </Grid>
      <Grid item xs>
        <button className={instandaThisPage === 6 && MVRError ? classes.nextButtonDisabled : classes.nextButton } type='submit' onClick={goNextSide} disabled={instandaThisPage === 6 && MVRError}>{formContext.orangeBtnText ?? 'Next'}</button>
      </Grid>
    </Grid>
  )

  if (width > 540) {
    return (
      <section className={classes.stepperSideBar}>
        <Grid container spacing={1}>
          <Grid item xs={2} className={classes.sideImage}>
            <img src={spacing} alt='spacer' />
          </Grid>
          <Grid item xs={10}>
            <section>
              <div className={classes.sideTitle}>{formContext.sideMain === '' ? fallbackMain : formContext.sideMain}</div>
              <hr />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <img src={reading} alt='pouch-read' />
                </Grid>
                <Grid item xs={8}>
                  {formContext?.sideText === '' ? fallbackText : formContext?.sideText}
                </Grid>
              </Grid>
            </section>
            {sidebarButtons}
            {
              getBrandBySalesName(getSalesPerson())?.groupName ? 
              <Card className={classes.cardAgent} >
                <Box display='flex'>
                  <InfoOutlined/>
                  <Typography style={{margin:'0 0 0 .5rem'}}>
                    { getBrandBySalesName(getSalesPerson())?.groupName }
                  </Typography>
                </Box>
                <Box display='flex'>
                  <Typography style={{margin:'.5rem 0 0 0'}}>
                  Phone number: <u>{ getBrandBySalesName(getSalesPerson())?.phoneNumber }</u><br/>
                  </Typography>
                </Box>
              </Card>
              :
              <></>
            }
            {
              instandaThisPage === 5 ?
                <GoodieNote title='Note from Goodie'>
                  <p>
                    Once the driver license is added, and the next button is clicked, the System will
                    order a Vehicle History Score (VHS) for each vehicle and a Motor Vehicle Record
                    (MVR) for each driver. <span className='orange'>Rate quote may change.</span>
                  </p>
                </GoodieNote>
                : null
            }
            {
              instandaThisPage === 6 && MVRError ?
                <GoodieNote title='Note from Goodie' error>
                  <p>
                    Unfortunately one of your drivers is not eligible to quote with us. 
                    Please click here to <a href='javascript:void(0)' onClick={() => goBackSide(true)}>go back</a> and remove the driver. 
                    Contact {' '}
                    <Tooltip open={openToolTip} title='Phone Number Copied!' disableFocusListener disableHoverListener disableTouchListener>
                        <a 
                          href='javascript:void(0)' 
                          onClick={async () => { await copyToclipboard('+844-POUCH-IT'); tooltipOpen() }} >
                        +844-POUCH-IT
                        </a>
                    </Tooltip>
                    {' '}if you have any issues.
                  </p>
                </GoodieNote>
                : null
            }
          </Grid>
        </Grid>
      </section>
    )
  } else {
    return sidebarButtons
  }
}
