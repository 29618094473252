import { useState, useEffect, ReactNode, ReactElement } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Clear from '@mui/icons-material/Clear';
import { Box, IconButton } from '@material-ui/core';
import '../Collapse/Collapse.scss'
import { ICollapse } from 'interfaces/pouch-redux';

const Collapse = ({ index = 0, ignoreIndex = false, title, image, subtitle = '', className, children, hasError = false, delButton = () => null, isQuoteInfo }: ICollapse): ReactElement => {
    const [expanded, setExpanded] = useState(false)
    useEffect(() => setExpanded(true), [hasError])
    const removeButton = (): void => delButton(index)
    const toggleExpanded = (): void => setExpanded(!expanded)
    const formatedSubtitle = subtitle && subtitle.split('-')
    useEffect(() => {
        const isAdjustLS = String(localStorage.getItem('isAdjustPolicy'))
        const isAdjust = isAdjustLS === 'true' ? true : false
        isAdjust && !isQuoteInfo ? setExpanded(false) : setExpanded(true)
    }, [])
    return (
        <div className={`form-container ${className}`}>
            <Accordion className='form' expanded={hasError || expanded} onChange={hasError ? () => null : toggleExpanded}>
                <AccordionSummary                    
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className='summary'
                >
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <div className="driver">
                            <Typography className='vehicle-title'>
                                <strong>{`${title} ${index + 1}`}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {expanded ? '' : subtitle !== '' ?
                                    subtitle.indexOf('-') > 0 ?
                                        <>
                                            {formatedSubtitle[0]}&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;{formatedSubtitle[1]}
                                        </> : <>{formatedSubtitle[0]}</> : ''}
                            </Typography>
                        </div>
                        { index !== 0 || ignoreIndex ?
                            (
                                <div className='delete-btn'>
                                    <IconButton size='small' onClick={removeButton}>
                                        <Clear className='delete' />
                                    </IconButton>
                                </div>
                            )
                        : null}
                    </Box>
                </AccordionSummary>
                <Box>
                    
                </Box>
                <AccordionDetails>
                    <section className='children'>{children}</section>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default Collapse;