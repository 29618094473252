export const paymentStyles = {
    flexCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',        
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center'       
    },
    page: {
        width: '100%',
        height: '100vh'
    },
    card: {
        padding: '2rem',
        maxWidth: '600px'
    },
    inherit: {
        color: 'inherit',
        fontSize: 'inherit',
        fontFamily: 'inherit',
    },
    mx1:{
        margin: '1rem 0'
    }

}