import { makeStyles } from '@material-ui/core'
import { ReactElement } from 'react'
import Button from './Button'

const useStyles = makeStyles({
  card: {
    width: 291,
    height: 428,
    borderRadius: 22,
    padding: 20,
    border: 2,
    borderStyle: 'solid',
    borderColor: '#E2E2E2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 20
  },
  image: {
    width: 184,
    height: 175,
    marginTop: 10,
    marginBottom: 40
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Libre Franklin',
    fontSize: 30
  },
  cardButton: {
    color: '#FF6C1A',
    fontSize: 18,
    fontFamily: 'Libre Franklin',
    width: 213,
    height: 48
  }
})

export default function Card ({
  className = '',
  CardImage,
  title,
  buttonText,
  buttonClick
}: {
  className?: string
  CardImage: any
  title: string
  buttonText: string | null
  buttonClick?: (id?: number, cardImage?: any, title?: string, modalText?: string) => void
}): ReactElement {
  const classes = useStyles()
  return (
    <div className={className ?? classes.card}>
      <div className={classes.image}>
        <CardImage className={classes.image} />
      </div>
      <div className={classes.title}>
        {title}
      </div>
      {buttonText != null ? (
        <Button
          className={classes.cardButton}
          borderRounded
          onClick={buttonClick}
        >
          {buttonText}
        </Button>
      ) : null}

    </div>
  )
}
