import { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as GOODIE_P1 } from '../../../components/StepperContainer/assets/stepper-pouch.svg'
import { ReactComponent as ICOCAR } from '../assets/ico_car.svg'
import { ReactComponent as ICOGLBOP } from '../assets/ico_glbop.svg'
import { getSalesPerson, isMARKETING } from 'services/instanda'
import { getBrandBySalesName } from 'utils/configBrands'

interface Props {
  open: boolean
  onClose?: () => void
}

const useStyles = makeStyles({
  root: {
    padding: '1.8em'
  },
  radioGroup: {
    margin: '1em 0'
  },
  radioElement: {
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  radioItem: {
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    '&:checked': {
      color: 'red'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 15
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center'
  },
  continueBtn: {
    padding: '10px 30px',
    border: 'none',
    marginTop: '25px',
    borderRadius: '12px',
    fontWeight: 500,
    color: '#ffffff',
    backgroundColor: '#ff6c1a'
  },
  paragraph: {
    fontSize: '14px',
    textAlign: 'justify'
  },
  goodieModalIcon: {
    width: 130,
    height: 130,
    '& .st0': {
      // fill: '#4FBCBC'
      fill: '#ED6F29'
    },
    '& .st1': {
      fill: '#4FBCBC'
      // fill: '#ED6F29'
    }
  },
  brandModalIcon: {
    width: 230,
    height: 230
  },
  optionIcon: {
    height: 30,
    width: 30
  },
  Subtitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    width: 200
  },
  active: {
    color: '#000',
    '& svg': {
      '& .st0': {
        fill: '#000'
      }
    }
  },
  inactive: {
    opacity: 0.44,
    '& svg': {
      '& .st0': {
        opacity: 0.44
      }
    }
  }
})

const Modal = ({ open, onClose = () => {} }: Props): JSX.Element => {
  const [selectedPolicyType, setSelectedPolicyType] = useState('')
  const classes = useStyles()

  const handleSelectedOption = (option: string): void => {
    setSelectedPolicyType(option)
  }

  useEffect(() => {
    const quoteType = localStorage.getItem('policyType') ?? 'commercial'
    setSelectedPolicyType(quoteType)
  }, [])

  const handleClose = (): void => {
    localStorage.setItem('policyType', selectedPolicyType)
    onClose()
  }

  return (
    <Dialog {...{ open }}>
      <div className={classes.root}>
        <section className={classes.title}>
          <GOODIE_P1 className={classes.goodieModalIcon} /> 
          {
            getBrandBySalesName(getSalesPerson()) ?             
            <img src={ getBrandBySalesName(getSalesPerson())?.logo } className={classes.brandModalIcon} />    
            :
            <></>
          }
        </section>
        <section className={classes.radioGroup}>
          <div className={classes.radioElement}>
            <div
              className={`${classes.Subtitle} ${
                selectedPolicyType === 'commercial'
                  ? classes.active
                  : classes.inactive
              }`}
            >
              <ICOCAR className={classes.optionIcon} />
              <label>Commercial Auto</label>              
            </div>
            {/* <input
              name='insuranceType'
              type='radio'
              value='commercial'
              checked={selectedPolicyType === 'commercial'}
              className={classes.radioItem}
              onChange={() => handleSelectedOption('commercial')}
            /> */}
          </div>
          {/* <div className={classes.radioElement}>
            <div
              className={`${classes.Subtitle} ${
                selectedPolicyType === 'glbop'
                  ? classes.active
                  : classes.inactive
              }`}
            >
              <ICOGLBOP className={classes.optionIcon} />
              <label>GL/BOP & Auto</label>
            </div>
            <input
              name='insuranceType'
              type='radio'
              value='glbop'
              checked={selectedPolicyType === 'glbop'}
              className={classes.radioItem}
              onChange={() => handleSelectedOption('glbop')}
            />
          </div> */}
        </section>
        <section className={classes.paragraph}>
          <p>
            Click continue to go through our 90 second quote to get covered!
          </p>
          {/* <p>
            Through our partnership with {isMARKETING ? 'Insurance Partner' : 'Coterie Insurance'}, we now offer BOP and
            GL coverage with your commercial auto insurance coverages all in one
            place.
          </p> */}
        </section>
        <section className={classes.buttonRow}>
          <button
            className={classes.continueBtn}
            type='button'
            onClick={handleClose}
          >
            Continue
          </button>
        </section>
      </div>
    </Dialog>
  )
}

export default Modal
