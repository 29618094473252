import person1 from '../views/Landing/assets/person1.png'
import imgAvatar from '../views/Landing/assets/avatar.png'
import { ReactComponent as Geotab } from '../views/Landing/assets/Goodies/Geotag_icon.svg'
import { ReactComponent as Shield } from '../views/Landing/assets/Goodies/Shield_icon.svg'
import { ReactComponent as Dollar } from '../views/Landing/assets/Goodies/Coin_icon.svg'
import { ReactComponent as Liability } from '../views/Landing/assets/Goodies/Liability.svg'
import { ReactComponent as Physical } from '../views/Landing/assets/Goodies/Physical damage.svg'
import { ReactComponent as Uninsured } from '../views/Landing/assets/Goodies/Uninsured.svg'
import { ReactComponent as Medical } from '../views/Landing/assets/Goodies/Medical.svg'
import { ReactComponent as GOODIE_PLUMBER } from '../views/Landing/assets/Goodies/Goodie_Plumber.svg'
import { ReactComponent as GOODIE_MASONRY } from '../views/Landing/assets/Goodies/Goodie_Masonry_Contractor.svg'
import { ReactComponent as GOODIE_FLORIST } from '../views/Landing/assets/Goodies/Goodie_Florist.svg'
import { ReactComponent as GOODIE_JANITOR } from '../views/Landing/assets/Goodies/Goodie_Janitor.svg'
import { ReactComponent as GOODIE_CATERER } from '../views/Landing/assets/Goodies/Goodie_Caterer.svg'
import { ReactComponent as GOODIE_ELECTRICIAN } from '../views/Landing/assets/Goodies/Goodie_electrician.svg'
import { ReactComponent as GOODIE_LANDSCAPER } from '../views/Landing/assets/Goodies/Goodie_landscaper.svg'
import { ReactComponent as GOODIE_HVAC } from '../views/Landing/assets/Goodies/Goodie_HVAC.svg'
import { ReactComponent as GOODIE_MFS } from '../views/Landing/assets/Goodies/Goodie_Mobile_food_stand.svg'
import { ReactComponent as PerkTrailer } from '../views/Landing/assets/Goodies/PerkTrailer.svg'
import { ReactComponent as PerkTruck } from '../views/Landing/assets/Goodies/PerkTruck.svg'
import { ReactComponent as PerkCar } from '../views/Landing/assets/Goodies/PerkCar.svg'

export const Homeitems = [
  {
    name: 'Gloria Guntinas',
    personTitle: 'CEO of Pouch Insurance',
    image: imgAvatar,
    cardTitle: 'Safety for All Drivers',
    text: 'Vehicles are revenue generators for small businesses. They make deliveries. They transport tools and parts. They get craftsmen to job sites. By giving every customer free vehicle tracking software and rewarding good drivers, we help business owners improve safety and decrease risk, save money, and grow their businesses.'
  },
  {
    name: 'Steve McKay',
    personTitle: 'CEO of Pouch Insurance',
    image: imgAvatar,
    cardTitle: 'We Make it Affordable',
    text: 'Vehicles are revenue generators for small businesses. They make deliveries. They transport tools and parts. They get craftsmen to job sites. By giving every customer free vehicle tracking software and rewarding good drivers, we help buiness owners improve safety and decrease risk, save money, and grow their businesses.'
  },
  {
    name: 'Tom Libassi',
    personTitle: 'Co-Founder of Pouch',
    image: imgAvatar,
    cardTitle: "Our Team's Expertise",
    text: 'Pouch is led by a team rich with proven experience that expands the knowledge and expertise in our portfolio. It also opens up access to the direct-to-business market and telematics, which are highly complementary to what we do.'
  },
  {
    name: 'Highways Today',
    personTitle: 'One of Our Fans',
    cardTitle: 'Person title #4',
    text: 'Aimed at contractors, landscapers, florists, and other small businesses that depend on a fleet of vehicles, Pouch offers free tracking software for every vehicle insured, helping small business owners better manage and protect one of their most valuable assets while earning discounted rates and other goodies.'
  }
]

export const FreeVehicleTrackingHomeSection = [
  {
    image: Geotab,
    title: 'Track fleets'
  },
  {
    image: Shield,
    title: 'Keep Drivers Safe'
  },
  {
    image: Dollar,
    title: 'Save Money'
  }
]

export const PouchLabel = {
  title: 'As Easy as Ordering a Coffee!',
  subtitle: ''
}

export const VehicleWeSupportLabel = {
  subtitle: 'Vehicles we support',
  text: 'Ranging from all make and models in the artisan business class. We have the experience you are looking for.'
}

export const VehicleWeSupportCard = [
  {
    image: PerkTruck,
    title: 'Pickup Trucks'
  },
  {
    image: PerkTrailer,
    title: 'Trailer'
  },
  {
    image: PerkCar,
    title: 'Business Autos'
  }
]

export const CommercialAutoInsuranceHomeSection = [
  {
    image: Liability,
    title: 'Liability Coverages'
  },
  {
    image: Physical,
    title: 'Physical Damage'
  },
  {
    image: Uninsured,
    title: 'Uninsured Motorist'
  },
  {
    image: Medical,
    title: 'Medical Payments'
  }
]

export const WhatWeOfferTopSection = {
  title: 'What We Offer:',
  subtitle: 'Vehicle tracking',
  text: 'Your customers can protect their drivers and their assets with fleet tracking software. They can keep track of their vehicles and know what their drivers are up to, all while saving money.'
}

export const WhatWeOfferBottomSection = {
  title: 'What We Offer:',
  subtitle:
    'Auto insurance with Vehicle tracking',
  text: 'With Pouch’s commercial auto insurance, we offer liability and physical damage protection for cars, trucks, vans, and any other vehicle that is used for business.',
  secondText:
    'Commercial auto insurance isn’t just for industrial vehicles like semis or dump trucks. For example, businesses that use a car to run errands, carry tools or transport clients might also need a commercial auto policy.'
}

export const AboutItems = [
  {
    name: 'Mark White',
    image: person1,
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
  },
  {
    name: 'Person name #2',
    image: person1,
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
  },
  {
    name: 'Person name  #3',
    image: person1,
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
  },
  {
    name: 'Person name  #4',
    image: person1,
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
  }
]

export const PouchLabelCards = [
  {
    title: 'Plumber',
    value: 'Plumbing, Heating, Air-Conditioning',
    CardImage: GOODIE_PLUMBER,
    buttonText: 'Quote Now',
    modalText:
      "A plumber's vehicle is often both an office and a storage space. Protect your workspace today!"
  },
  {
    title: 'Masonry Contractor',
    value: 'Brick, Masonry & Other Stone Work',
    CardImage: GOODIE_MASONRY,
    buttonText: 'Quote Now',
    modalText:
      "Wheter you're using a pickup truck or trailer to get the job site, your fleet is an integral part to any size job. Protect your vehicles today!"
  },
  {
    title: 'Florist',
    value: 'Florist',
    CardImage: GOODIE_FLORIST,
    buttonText: 'Quote Now',
    modalText:
      'Delivering floral arragements and cut flowers requires the efficiency only your vehicles provide. Protect your vehicles today!'
  },
  {
    title: 'Janitor',
    value: 'Building Cleaning & Maintenance Services',
    CardImage: GOODIE_JANITOR,
    buttonText: 'Quote Now',
    modalText:
      'Whether your company cleans offices, houses, or carpets, your vehicles need to be able to support your business. Protect your vehicles today!'
  },
  {
    title: 'Caterer',
    value: 'Catering & Food Delivery (Including Pizza)',
    CardImage: GOODIE_CATERER,
    buttonText: 'Quote Now',
    modalText:
      'While providing a high-quality guest experience, your catering vehicles both delivers cusine efficiently and represents your business. Protect your vehicles today!'
  },
  {
    title: 'Landscaper',
    value: 'Landscaping, Lawn % Garden Services',
    CardImage: GOODIE_LANDSCAPER,
    buttonText: 'Quote Now',
    modalText:
      'A landscaping business handles a variety of jobs, form earth moving to basic lawn care. Keep your mode of transport safe with commercial auto insurance!'
  },
  {
    title: 'Electrician',
    value: 'Electrical Work, Cable, Security System Technician',
    CardImage: GOODIE_ELECTRICIAN,
    buttonText: 'Quote Now',
    modalText:
      "As an electrician, your vehicles don't only transport tools and supplies, but increase your productivity. Keep your vehicle safe with commercial auto insurance today!"
  },
  {
    title: 'House Painter',
    value: 'Painting and Paper Hanging',
    CardImage: GOODIE_MASONRY,
    buttonText: 'Quote Now',
    modalText:
      'As a painter, your vehicles carry the supplies you need to prepare and paint all jobs from start to finish. Protect your vehicle today with commercial auto insurance!'
  },
  {
    title: 'Mobile Food Stand',
    value: 'Food Establishments (Including Food Truck)',
    CardImage: GOODIE_MFS,
    buttonText: 'Quote Now',
    modalText:
      'Your kitcchen-equipped truck is the foundation of your food truck business. Protect your livelihood today with Pouch Commercial Auto Insurance!'
  },
  {
    title: 'HVAC',
    value: 'Plumbing, Heating, Air-Conditioning',
    CardImage: GOODIE_HVAC,
    buttonText: 'Quote Now',
    modalText:
      'When handling installations, maintenance, and repairs for HVAC systems, your vehicles haul the tools you need to get the job done. Keep your trucks and vans safe with Pouch Commercial Auto Insurance today!'
  }
]
