import React from 'react'
import { DialogBoxStyles } from '../styles/DialogBox.styles'

const DialogBox: React.FC<{ amount: any }> = ({ amount }) => {
  const classes = DialogBoxStyles()
  const { container, subContainer } = classes
  return (
    <div className={container}>
      <div className={subContainer}>
        <label htmlFor='amount'>Amount</label>
        <input disabled type='text' value={`$${amount}`} />
      </div>
      <div className={subContainer}>
        <label htmlFor='fee'>Fee</label>
        <input disabled type='text' value='$ 0.0' />
      </div>
      <div className={subContainer}>
        <label htmlFor='total'>Total</label>
        <input disabled type='text' name='total' value={`$${amount}`} />
      </div>
    </div>
  )
}

export default DialogBox
