import { filter, find, get, isEmpty } from 'lodash'
import * as Yup from 'yup'
import { isAdult, isGreaterThanToday, isSmaller, isSmallerThanUpThrityDaysInAdvance } from './calculations'
import { licenseValidation, supportedStates, usStatesFull } from 'services/instadaModel'
import ValidationError from 'yup/lib/ValidationError'
import { GetZip } from 'services/instanda'

export const errOB = {
  required: 'This field is required',
  tooLong: 'Value can not be that long',
  isAlpha: 'Value must contain only letters',
  isEmail: 'Invalid email',
  positive: 'Number can not be negative',
  yearCar: 'Type a valid year',
  vimTolong: 'The vehicle vin must have only 17 characters',
  vimShort: 'The vehicle vin must have 17 characters',
  isAlphaNumeric: 'Value must contain only letters and numbers',
  numericOnly: 'Only numbers allowed',
  graterThan1000: 'The value must be greater than or equal to $1,000',
  claimsLimit: 'Claims amount limit exceeded: The value must be less than or equal to $15000',
  violationsLimit: 'Due to too many violations this risk does not meet our underwriting guides',
  isNumeric: 'Value must contain only numbers',
  statedAmountLimit: 'The max amount for a trailer stated amount is $75,000'
}

export const errTrailer = {
  tooLong: 'Value can not be that long',
  vimShort: 'The trailer vin must have 17 characters'
}

const alphaRegex = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü ]+$/
const alphaNumericRegex = /^[a-zA-Z0-9_]*$/
export const numbersRegex = /^[0-9]+$/

let businessState = '' as string

const validZipCodeRange = (zipCode: string, state: string): boolean => {
  let validZipCode = true
  const zipCodeNum = Number(zipCode)
  switch (state) {
    case 'AZ':
      validZipCode = (zipCodeNum < 85001 || zipCodeNum > 86556) ? false : true
      break;

    case 'IL':
      validZipCode = (zipCodeNum < 60001 || zipCodeNum > 62999) ? false : true
      break;
    case 'TX':
      validZipCode = (zipCodeNum < 73301 || zipCodeNum > 88595) ? false : true
      break;
    case 'TN':
      validZipCode = (zipCodeNum < 37010 || zipCodeNum > 38589) ? false : true
      break;
    default:
      break;
  }
  console.log(zipCode, state, validZipCode)
  return validZipCode
}

export const ContactSchema = Yup.object().shape({
  ContactFirstName_TXT: Yup.string()
    .required(errOB.required)
    .max(50, errOB.tooLong)
    .matches(alphaRegex, errOB.isAlpha),
  ContactLastName_TXT: Yup.string()
    .required(errOB.required)
    .max(50, errOB.tooLong)
    .matches(alphaRegex, errOB.isAlpha),
  ContactEmail_TXT: Yup.string().required(errOB.required).email(errOB.isEmail),
  BusinessNameDBA_TXT: Yup.string().required(errOB.required),
  BusinessSubClass_CHOICE: Yup.string().required(errOB.required),
  ContactPhone_TXT: Yup.string()
    .required(errOB.required)
    .matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      'Type a valid phone number'
    ),
  PolicyEffective_DATE: Yup.string().required(errOB.required),
  EffectiveChangeDate: Yup.date().when('IsMTA', {
    is: true,
    then: Yup.date().required(errOB.required)
  }),
  BusinessAddressLine1_TXT: Yup.string().required(errOB.required),
  BusinessCity_TXT: Yup.string().required(errOB.required),
  BusinessState_TXT: Yup.string().required(errOB.required)
    .test('BusinessState_TXT', '', (value: string | undefined): boolean => {
      businessState = value || ''
      return true
    }),
  BusinessZip_TXT: Yup.string()
    // .required(errOB.required)
    // .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Type a valid zip code')
    .test('BusinessZip_TXT', 'Your zip code is not supported', async (value: string | undefined): Promise<boolean> => {
      if (value === undefined || value === '') return false
      if (value.length < 5 || value.length > 5) return false
      const manuallyLS = String(localStorage.getItem('manually'))
      const isAdjustLS = String(localStorage.getItem('isAdjustPolicy'))
      const manually = manuallyLS === 'true' ? true : false
      const isAdjust = isAdjustLS === 'true' ? true : false
      if (manually) {
        if (validZipCodeRange(value, businessState)) return true
        return false
      } else if (isAdjust) {
        return true
      } else {
        if (/^\d{5}(?:[-\s]\d{4})?$/.test(value)) {
          const zipCodeValidate = await GetZip(value ?? '')
          let validState = true as any
          let stateAbbr = '' as string          
          if (zipCodeValidate.data.result.results.length > 0) {            
            zipCodeValidate.data.result.results[0].address_components.forEach((val: any) => {
              const foundState = find(
                usStatesFull,
                (current) => current.name === val.long_name
              )
              const stateAbbreviation =
                foundState != null ? foundState.abbr : val.long_name
              val.types.forEach((typeName: any) => {
                if (typeName === 'administrative_area_level_1') {
                  stateAbbr = val.short_name
                  validState = find(
                    supportedStates,
                    (current) => current.value === stateAbbreviation
                  )
                }
              })
            })
            if (validState === undefined) return false
            return true
          }
          return false
        }
      }
      return false
    }),
  ContactTitle_TXT: Yup.string().required(errOB.required),
  VehicleVINOverride_TXT: Yup.string().length(17),
  policyType: Yup.string(),
  numEmployees: Yup
    .number()
    .when('policyType', {
      is: 'glbop',
      then: Yup.number().required(errOB.required).min(0, errOB.positive).typeError(errOB.numericOnly)
    })
})

export const PaymentOptionSchema = Yup.object().shape({
  PayPlan_CHOICE: Yup.string().required()
})

export const AddlInsuredSchema = Yup.object().shape({
  PolAddlInsuredName_TXT: Yup.string()
    .required(errOB.required)
    .max(50, errOB.tooLong)
    .matches(alphaRegex, errOB.isAlpha),
  PolAddlInsuredAddressLine1_TXT: Yup.string().required(errOB.required),
  PolAddlInsuredCity_TXT: Yup.string().required(errOB.required),
  PolAddlInsuredState_TXT: Yup.string().required(errOB.required),
  PolAddlInsuredZipCode_TXT: Yup.string()
    .required(errOB.required)
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Type a valid zip code')
    .test('PolAddlInsuredZipCode_TXT', 'Your zip code is not supported', async (value: string | undefined): Promise<boolean> => {
      if (value === undefined || value === '') return false
      if (value.length < 5 || value.length > 5) return false
      if (/^\d{5}(?:[-\s]\d{4})?$/.test(value)) {
        const zipCodeValidate = await GetZip(value ?? '')
        let validState = true as any
        if (zipCodeValidate.data.result.results.length > 0) {
          zipCodeValidate.data.result.results[0].address_components.forEach((val: any) => {
            const foundState = find(
              usStatesFull,
              (current) => current.name === val.long_name
            )
            const stateAbbreviation =
              foundState != null ? foundState.abbr : val.long_name

            val.types.forEach((typeName: any) => {
              if (typeName === 'administrative_area_level_1') {
                validState = find(
                  supportedStates,
                  (current) => current.value === stateAbbreviation
                )
              }
            })
          })
          if (validState === undefined) return false
          return true
        }
        return false
      }
      return false
    }),
})

declare module 'yup' {
  // tslint:disable-next-line
  interface ArraySchema<T> {
    unique: (
      message: string,
      field: string
    ) => ArraySchema<T>
  }
}

Yup.addMethod(Yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array) {
    const errors: ValidationError[] = []

    let firstFields: string[] = []

    array?.forEach((row, index) => {
      const propertyValue = get(row, field)
      if (!firstFields.includes(propertyValue)) {
        firstFields = [...firstFields, propertyValue]
        return
      }
      if (propertyValue && filter(array, [field, propertyValue]).length > 1) {
        errors.push(
          this.createError({
            path: `[${index}].${field}`,
            message
          })
        )
      }
    })

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors)
    }

    return true
  })
})

export const VehicleSchema = Yup.array().of(
  Yup.object().shape({
    findByVIN: Yup.bool(),
    VehicleYear_NUM: Yup.string()
      .required(errOB.required)
      .matches(/^\d{4}$/, errOB.yearCar),
    VehicleMake_TXT: Yup.string().required(errOB.required),
    VehicleModel_TXT: Yup.string().required(errOB.required),
    VehicleBody_TXT: Yup.string().required(errOB.required),
    // To discomment when MTA is release
    // VehicleOverrideDeductCOLL_CHOICE: Yup.string().required(errOB.required),
    // VehicleOverrideDeductCOMP_CHOICE: Yup.string().required(errOB.required),
    // VehicleOverrideLimitUMPD_IL_CHOICE: Yup.string().required(errOB.required),
    VehicleVINOverride_TXT: Yup.string()
      .max(17, errOB.tooLong)
      .min(17, errOB.vimShort),
    Lienholder_YNP: Yup.string(),
    LienholderName_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderAddressLine1_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderCity_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderState_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderZipCode_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string()
          .required(errOB.required)
          .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Type a valid zip code')
      }),
    VehicleAdditionalInsured_YN: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string()
          .required(errOB.required)
      })
  })
).unique('VehicleVINOverride_TXT', 'The VIN entered is already in use')

export const VehicleSchemaMTA = Yup.array().of(
  Yup.object().shape({
    findByVIN: Yup.bool(),
    VehicleYear_NUM: Yup.string()
      .required(errOB.required)
      .matches(/^\d{4}$/, errOB.yearCar),
    VehicleMake_TXT: Yup.string().required(errOB.required),
    VehicleModel_TXT: Yup.string().required(errOB.required),
    VehicleBody_TXT: Yup.string().required(errOB.required),
    // To discomment when MTA is release
    // VehicleOverrideDeductCOLL_CHOICE: Yup.string().required(errOB.required),
    // VehicleOverrideDeductCOMP_CHOICE: Yup.string().required(errOB.required),
    // VehicleOverrideLimitUMPD_IL_CHOICE: Yup.string().required(errOB.required),
    VehicleVINOverride_TXT: Yup.string()
      .required(errOB.required)
      .max(17, errOB.tooLong)
      .min(17, errOB.vimShort),
    Lienholder_YNP: Yup.string(),
    LienholderName_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderAddressLine1_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderCity_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderState_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string().required(errOB.required)
      }),
    LienholderZipCode_TXT: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string()
          .required(errOB.required)
          .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Type a valid zip code')
      }),
    VehicleAdditionalInsured_YN: Yup
      .string()
      .when('Lienholder_YNP', {
        is: 'Yes',
        then: Yup.string()
          .required(errOB.required)
      })
  })
).unique('VehicleVINOverride_TXT', 'The VIN entered is already in use')

export const TrailerSchema = Yup.array().of(
  Yup.object().shape({
    TrailerVIN_TXT: Yup.string()
      .min(1, errTrailer.vimShort),
    TrailerYear_TXT: Yup.string().required(errOB.required),
    TrailerMake_TXT: Yup.string().required(errOB.required),
    TrailerStatedAmount_NUM: Yup.number().min(0, errOB.positive).max(75000, errOB.statedAmountLimit)
  })
)

export const TrailerSchemaMTA = Yup.array().of(
  Yup.object().shape({
    TrailerVIN_TXT: Yup.string()
      .max(17, errTrailer.tooLong)
      .min(17, errTrailer.vimShort),
    TrailerYear_TXT: Yup.string().required(errOB.required),
    TrailerMake_TXT: Yup.string().required(errOB.required),
    TrailerCollisionDeductible_CHOICE: Yup.string().required(errOB.required),
    TrailerComprehensiveDeductible_CHOICE: Yup.string().required(errOB.required),
    TrailerStatedAmount_CHOICE: Yup.string().required(errOB.required)
  })
)

export const DriverSchema = Yup.array().of(
  Yup.object().shape({
    DriverFirstName_TXT: Yup.string()
      .required(errOB.required)
      .max(50, errOB.tooLong)
      .matches(alphaRegex, errOB.isAlpha),
    DriverLastName_TXT: Yup.string()
      .required(errOB.required)
      .max(50, errOB.tooLong)
      .matches(alphaRegex, errOB.isAlpha),
    DriverDOB_DATE: Yup.date()
      .required(errOB.required)
      .test('DriverDOB_DATE', 'Driver must have legal age', isAdult),
    DriverIncidentsThreeYears_YNP: Yup.string(),
    DriverIncidentsViolations_NUM: Yup.number()
      .when('DriverIncidentsThreeYears_YNP', {
        is: 'Yes',
        then: Yup.number().required(errOB.required).min(0, errOB.positive)
      }),
    DriverIncidentsTickets_NUM: Yup.number()
      .when('DriverIncidentsThreeYears_YNP', {
        is: 'Yes',
        then: Yup.number().required(errOB.required).min(0, errOB.positive)
      }),
    DriverIncidentsAccidents_NUM: Yup.number()
      .when('DriverIncidentsThreeYears_YNP', {
        is: 'Yes',
        then: Yup.number().required(errOB.required).min(0, errOB.positive)
      })
  }).test('manyAccidents', 'Due to too many violations this risk does not meet our underwriting guides', (value) => {
    const tickets = (value.DriverIncidentsTickets_NUM ?? 0) * 1
    const accidents = (value.DriverIncidentsAccidents_NUM ?? 0) * 4
    const violations = (value.DriverIncidentsViolations_NUM ?? 0) * 4
    if ((tickets + accidents + violations) > 5) {
      return false
    } else {
      return true
    }
  })
)
export const DriverSchemaMTA = Yup.array().of(
  Yup.object().shape({
    DriverFirstName_TXT: Yup.string()
      .required(errOB.required)
      .max(50, errOB.tooLong)
      .matches(alphaRegex, errOB.isAlpha),
    DriverLastName_TXT: Yup.string()
      .required(errOB.required)
      .max(50, errOB.tooLong)
      .matches(alphaRegex, errOB.isAlpha),
    DriverDOB_DATE: Yup.date()
      .required(errOB.required)
      .test('DriverDOB_DATE', 'Driver must have legal age', isAdult),
    DriverIncidentsThreeYears_YNP: Yup.string(),    
    DriverIncidentsAccidents_NUM: Yup.number()
      .when('DriverIncidentsThreeYears_YNP', {
        is: 'Yes',
        then: Yup.number().required(errOB.required).min(0, errOB.positive).max(1, errOB.violationsLimit)
      })
  })
)

export const ContactBussinesSchema = Yup.object().shape({
  ContactTitle_TXT: Yup.string()
    .required(errOB.required)
    .matches(alphaRegex, errOB.isAlpha),
  PolicyEffective_DATE: Yup.date()
    .required(errOB.required)
    .test('PolicyEffective_DATE', "Effective policy date can't be greater than thirty days of today's date", isSmallerThanUpThrityDaysInAdvance),
  Vehicles: Yup.array().of(
    Yup.object().shape({
      VehicleVINOverride_TXT: Yup.string()
        .required(errOB.required)
        .max(17, errOB.vimTolong)
        .min(17, errOB.vimShort)
        .matches(alphaNumericRegex, errOB.isAlphaNumeric)
    })
  ),
  Drivers: Yup.array().of(
    Yup.object().shape({
      DriverFullName_TXT: Yup.string()
        .required(errOB.required)
        .max(50, errOB.tooLong)
        .matches(alphaRegex, errOB.isAlpha),
      DriverLicenseStateOverride_CHOICE: Yup.string()
        .required(errOB.required)
        .matches(alphaRegex, errOB.isAlpha),
      DriverLicenseOverride_TXT: Yup.string()
        .required(errOB.required)
        .when('DriverLicenseStateOverride_CHOICE', (licenseState, schema) => {
          if (licenseState === '' || licenseState == null || licenseState === 'na') {
            return schema
          } else {
            const state = typeof licenseState === 'string' ? licenseState : ''
            const currentState: any = get(licenseValidation, `[${state}]`)
            if (Array.isArray(currentState.regex) && typeof currentState.description === 'string') {
              return schema.test('regex-validation', currentState?.description ?? 'Error showing regex description', (value: any) => {
                let isValid = false
                for (let i = 0; i < currentState.regex.length; i++) {
                  if (currentState.regex[i].test(value)) {
                    isValid = true
                    break
                  }
                }
                return isValid
              })
            } else {
              console.log('You have an error with the validation for', licenseState)
              return schema
            }
          }
        })
    })
  )
})

export const glSchema = Yup.object().shape({
  // POLICY_END: Yup.date().min(Yup.ref('POLICY_START'), 'End date can\'t be before start date'),
  GLLIMIT: Yup.number().required(errOB.required).positive().min(0, errOB.positive).typeError(errOB.numericOnly),
  PROPERTYDAMAGELIABILITYDEDUCTIBLE: Yup.number().required(errOB.required).positive().min(0, errOB.positive).typeError(errOB.numericOnly),
  // GLAGG: Yup.number().required(errOB.required).positive().min(0, errOB.positive).typeError(errOB.numericOnly),
  // GLAGGPCO: Yup.number().required(errOB.required).positive().min(0, errOB.positive).typeError(errOB.numericOnly),
  START_DATE: Yup.date()
    .required(errOB.required)
    .test('START_DATE', "Business start date can't be greater than todays date", isSmaller),
  // MIB: Yup.number().positive().required(errOB.required).min(0, errOB.positive).typeError(errOB.numericOnly),
  // EMPS: Yup.number().positive().required(errOB.required).min(0, errOB.positive).typeError(errOB.numericOnly),
  CLAIMSINTHEPASTYEARS: Yup.string(),
  CLAIMDESC: Yup
    .string()
    .when('CLAIMSINTHEPASTYEARS', {
      is: 'Yes',
      then: Yup.string().required(errOB.required).matches(alphaRegex, errOB.isAlpha)
    }),
  CLAIMAMT: Yup
    .number()
    .when('CLAIMSINTHEPASTYEARS', {
      is: 'Yes',
      then: Yup
        .number()
        .required(errOB.required)
        .min(0, errOB.positive)
        .max(15000, errOB.claimsLimit)
        .typeError(errOB.numericOnly)
    }),
  ANNUAL_PAYROLL: Yup.number().required(errOB.required).min(1000, errOB.graterThan1000).typeError(errOB.numericOnly),
  ANNUAL_SALES: Yup.number().required(errOB.required).min(1000, errOB.graterThan1000).typeError(errOB.numericOnly)
  // ENDRSMTCOVTYPE: Yup.string().required(errOB.required),
  // INSADDR: Yup.string().required(errOB.required),
  // INSCITY: Yup.string().required(errOB.required).matches(alphaRegex, errOB.isAlpha),
  // INSSTATE: Yup.string().required(errOB.required).matches(alphaRegex, errOB.isAlpha),
  // INSZIP: Yup.string().required(errOB.required).matches(numbersRegex, errOB.numericOnly)
})

export const bopSchema = glSchema.shape({
  INSBPPLIMIT: Yup.number().required(errOB.required).min(0, errOB.positive).typeError(errOB.numericOnly),
  INSBUILDINGLIMIT: Yup.number().required(errOB.required).min(0, errOB.positive).typeError(errOB.numericOnly),
  BUILDINGDEDUCTIBLE: Yup.number().required(errOB.required).min(1, errOB.positive).typeError(errOB.numericOnly),
  BPPDEDUCTIBLE: Yup.number().required(errOB.required).min(1, errOB.positive).typeError(errOB.numericOnly)
})


export const ChargebeeInitialValues = {
  number: "",
  expiry_month: "",
  expiry_year: "",
  cvv: "",
}

export const chargebeeSchema = Yup.object().shape({
  number: Yup.string()
    .required(errOB.required),
  expiry_month: Yup.number()
    .required(errOB.required)
    .min(1, 'Invalid Month')
    .max(12, 'Invalid Month'),
  expiry_year: Yup.number()
    .required(errOB.required)
    .min(22, 'Invalid Year')
    .max(99, 'Invalid Year'),
  cvv: Yup.string()
    .required(errOB.required)
    .matches(numbersRegex, errOB.isNumeric).max(3, 'invalid cvv').min(3, 'invalid cvv')
})

export const validateError = (error: any): boolean => {
  if (error) {
    return true
  } else {
    return false
  }
}