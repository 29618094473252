import { useState, ReactElement, useEffect, useRef } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { GetLocalQuoteData, getPolicyDataForAdjustment } from 'services/instanda'
import { model } from 'services/instadaModel'

// More
import FormContext from 'context/FormContext'
import ErrorModal from './components/ErrorModal'
import useSideButtons from 'hooks/useSideButtons'
import StepperContainer from 'components/StepperContainer'
import { Bind as BindTexts, Join as JoinTexts } from '../../components/StepperContainer/index'

// Pages
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
// import Coterie from './Coterie'
import Page4 from './Page4'
import Page5 from './Page5'
import Page6 from './Page6'
import Page7 from './Page7'
import Page8 from './Page8'
import Page9 from './Page9'
import AdjustPolicyPage from './AdjustPolicyPage'
import { get } from 'lodash'
import Page6A from './Page6A'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
)

export default function Join (): ReactElement {
  const classes = useStyles()
  const [form, setForm] = useState(0)
  const [MVRError, setMVRError] = useState(false)
  const { goNextPage, goBackPage, thisPage, displayGlBopView, setDisplayGlBopView, changePage } = useSideButtons()
  const indication = useRef({
    sideText: '',
    page: 1
  })

  // Navbar progress percentage
  const [progress, setProgress] = useState(0)

  // Active loading backdrop
  const [loading, setLoading] = useState(false)

  // Sidebar title and text
  const [sideMain, setSideMain] = useState('')
  const [sideText, setSideText] = useState('')

  // Error modal
  const [errorModal, setErrorModal] = useState(false)

  // To change side text dinamically
  const whichType = thisPage <= 4 ? JoinTexts : BindTexts
  const qtyReduce = thisPage <= 4 ? 1 : JoinTexts.length + 1

  const changeIndication = (e: any): void => {
    const fieldIndications = whichType[context.thisPage - qtyReduce].fieldIndications
    indication.current = indication.current.page !== thisPage
      ? {
          sideText: whichType[context.thisPage - qtyReduce]?.sideText !== undefined ? whichType[context.thisPage - qtyReduce]?.sideText : '',
          page: context.thisPage
        }
      : indication.current
    setSideText(e.type === 'mouseenter' ? String(get(fieldIndications, e.target.name)) : indication.current.sideText)
  }

  // Orange
  const [orangeBtnText, setOrangeBtnText] = useState<string | null>(null)

  // Previously on AppContext

  const [policyNumber, setPolicyNumber] = useState(null) as any
  const [quote, setQuote] = useState(null) as any
  const [coterieValues, setCoterieValues] = useState(null) as any

  // adjust policy
  const [adjustPolicy, setAdjustPolicy] = useState(false)

  useEffect(() => {
    const getAdjustPolicy = localStorage.getItem('isAdjustPolicy')
    if (getAdjustPolicy === '' || getAdjustPolicy === null) {
      return
    }
    setAdjustPolicy(true)
  }, [])

  useEffect(() => {
    setLoading(true)
    const joinStorage = JSON.parse(localStorage.getItem('instandaFullQuote') ?? '{}')
    GetLocalQuoteData()
      .then((res: any) => {
        setLoading(false)
        setQuote({ ...res.data, ...joinStorage })
      })
      .catch((resErr: any) => {
        setLoading(false)
        setQuote({ ...model, ...joinStorage })
        console.error({ resErr })
      })
  }, [])

  useEffect(() => {
    if (quote != null) {
      localStorage.setItem('instandaFullQuote', JSON.stringify(quote))
    }
  }, [quote])

  useEffect(() => {
    const coterieStorage = JSON.parse(localStorage.getItem('coterieValues') ?? '{}')
    setCoterieValues(coterieStorage)
  }, [])

  useEffect(() => {
    if (coterieValues != null) {
      localStorage.setItem('coterieValues', JSON.stringify(coterieValues))
    }
  }, [coterieValues])

  /**
    * if there is a policyNumber in localStorage retrieves the related info from the backend
   */
  useEffect(() => {
    if (getPolicyNumber() === '') return
    getPolicyData(getPolicyNumber()).finally(() => {
      setLoading(false)
    })
  }, [])

  /**
   * gets policyNumber form localStorage and returns the PolicyNumber:string without last 3 chars if it hast the version of Policy number
   * @returns PolicyNumber: string
   */
  const getPolicyNumber = (): string => {
    const splitPolicy = localStorage.getItem('policyNumber') as string
    if (splitPolicy === null) return ''
    return splitPolicy.length > 12 ? splitPolicy.slice(0, -3) : splitPolicy
  }

  /**
   * Calls getPolicyDataForAdjustment endpoint and sets the policy data on Quote state
   * @param PolicyNumber - PolicyNumber to search on instanda
   */
  const getPolicyData = async (PolicyNumber: string): Promise<void> => {
    console.warn(PolicyNumber)
    setLoading(true)
    try {
      const result = await getPolicyDataForAdjustment(PolicyNumber)
      setQuote(result?.data)
    } catch (error) {
      console.error(error)
    }
  }

  const updateLocalQuoteData = (): void => {
    GetLocalQuoteData()
      .then((res: any) => {
        const quoteRef = localStorage.getItem('instandaQuoteRef')
        if (quoteRef != null) {
          setQuote(res.data)
        } else {
          setQuote({ ...model })
        }
        localStorage.setItem('instandaFullQuote', JSON.stringify(quote))
        setLoading(false)
      })
      .catch((resErr: any) => {
        setQuote(model)
        setLoading(false)
        console.error({ resErr })
      })
  }

  const savePolicyNumber = (policyNumber: string): void => {
    localStorage.setItem('policyNumber', policyNumber)
    setPolicyNumber(policyNumber)
  }

  const policyType = localStorage.getItem('policyType') as string

  const context = {
    form,
    setForm,
    // LocalStorage pagination
    goNextPage,
    goBackPage,
    changePage,
    // Navbar progress percentage
    progress,
    setProgress,
    // Active loading backdrop
    setLoading,
    // Current show form page
    thisPage,
    // Sidebar title and text
    sideMain,
    setSideMain,
    sideText,
    setSideText,
    loading,
    changeIndication,
    // Error modal
    errorModal,
    setErrorModal,
    // Orange
    orangeBtnText: orangeBtnText,
    setOrangeBtnText: setOrangeBtnText,
    // to hide and show glbopView
    displayGlBopView,
    setDisplayGlBopView,
    // Previously on AppContext
    quote,
    setQuote,
    policyNumber,
    savePolicyNumber,
    updateLocalQuoteData,
    coterieValues,
    setCoterieValues,
    adjustPolicy
  }
  return (
    <FormContext.Provider value={context}>
      <ErrorModal open={errorModal} toggle={setErrorModal} />
      {loading && (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color='secondary' />
        </Backdrop>
      )}
      {!adjustPolicy
        ? <StepperContainer
            showSidebar={
            thisPage === 1 ||
            thisPage === 2 ||
            thisPage === 3 ||
            (thisPage === 4 && policyType === 'glbop' && displayGlBopView) || // shows the left tooltip when glbop view is shown in the page 4
            thisPage === 5 ||
            thisPage === 6
          }
            halfGray={
            thisPage === 4 && policyType !== 'glbop'
          }
            typeSidebar={
            thisPage <= 4 ? 'Join' : 'Bind'
          }
          MVRError={MVRError}
          >

          <Switch>
            <Route
              exact
              path='/join/about-you'
              render={() => {
                if (thisPage >= 1) return <Page1 />
                else return <div>Nothing here 1</div>
              }}
            />
            <Route
              exact
              path='/join/vehicles'
              render={() => {
                if (thisPage >= 2) return <Page2 />
                else return <div>Nothing here 2</div>
              }}
            />
            <Route
              exact
              path='/join/drivers'
              render={() => {
                if (thisPage >= 3) return <Page3/>
                else return <div>Nothing here 3</div>
              }}
            />
            <Route
              exact
              path='/join/quote'
              render={() => {
                if (thisPage >= 4) return <Page4 />
                else return <div>Nothing here 4</div>
              }}
            />
            <Route
              exact
              path='/join/additional-info'
              render={() => {
                if (thisPage >= 5) return <Page5 />
                else return <div>Nothing here 5</div>
              }}
            />
            <Route
              exact
              path='/join/final-quote'
              render={() => {
                if (thisPage >= 6) return <Page6A setMVRError={setMVRError} />
                else return <div>Nothing here 6 A</div>
              }}
            />
            <Route
              exact
              path='/join/final-quote-2'
              render={() => {
                if (thisPage >= 7) return <Page6 />
                else return <div>Nothing here 6</div>
              }}
            />
            <Route
              exact
              path='/join/documents'
              render={() => {
                if (thisPage >= 8) return <Page7 />
                else return <div>Nothing here 7</div>
              }}
            />
            <Route
              exact
              path='/join/payment'
              render={() => {
                if (thisPage >= 9) return <Page8 />
                else return <div>Nothing here 8</div>
              }}
            />
            <Route
              exact
              path='/join/congrats'
              render={() => {
                return <Page9 />
              }}
            />
            {/* <Route
            exact
            path='/join/glbop'
            render={() => {
              if (thisPage >= 4 && policyType === 'glbop') {
                return <Coterie />
              } else return <Coterie />
            }}
          /> */}
            {/* <Route exact path='/join'>
            <Redirect to='/join/about-you' />
          </Route> */}
          </Switch>
          </StepperContainer>
        : <Route
            render={() => {
              return <AdjustPolicyPage />
            }}
          />}
    </FormContext.Provider>
  )
}
