import { useState } from 'react';

interface Toggle {
  paymentMethodChoice: boolean
  selectPaymentMethod: () => void
}

export const useToggle = (): Toggle => {
  const [paymentMethodChoice, setPaymentMethodChoice] = useState(true)

  const selectPaymentMethod = (): void => {
    setPaymentMethodChoice(!paymentMethodChoice)
  };

  return {
    paymentMethodChoice,
    selectPaymentMethod
  }
};
