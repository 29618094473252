import { makeStyles } from '@material-ui/core';
import City from '../../assets/city.png';

export const startQuoteSectionStyles = makeStyles({
  partnerTitle: {
    fontSize: '45px',
    textAlign: 'center',
    paddingTop: '50px'
  },
  carouselKoalaItem: {
    '@media (max-width: 320px)': {
      '& .pouch-carousel-container': {
        width: '105%'
      }
    }
  },
  partnerSection: {
    backgroundColor: '#FFF',
    paddingTop: 40,
    paddingBottom: 40
  },
  partnerLogo: {
    width: 280,
    height: 56,
    marginLeft: 30,
    marginRigth: 100,
    '@media (max-width:780px)': {
      marginLeft: 0,
      marginRigth: 0,
      width: 130,
      height: 46
    },
    '@media (max-width:740px)': {
      marginLeft: 0,
      marginRigth: 0,
      width: 150,
      height: 36
    }
  },
  btn: {
    backgroundColor: 'transparent',
    border: 3,
    borderStyle: 'solid',
    borderColor: '#FF6C1A',
    borderRadius: 22,
    color: '#FF6C1A',
    fontFamily: 'Libre Franklin',
    fontSize: 18,
    width: '50%',
    height: 83,
    margin: 0,
    marginBottom: 30,
    '@media (max-width:780px)': {
      fontSize: 20,
      width: '87%'
    },
    '&:hover': {
      backgroundColor: '#f8f8f8'
    },
    '&:active': {
      backgroundColor: '#f3f3f3'
    }
  },
  startQuoteImgContainer: {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${City})`,
    height: 650,
    '@media (max-width:780px)': {
      height: 280
    },
    '@media (max-width:420px)': {
      height: 235
    },
    '@media (max-width:380px)': {
      height: 235
    }
  },
  startQuoteBtnContainer: {
    width: '100%',
    margin: 'auto',
    padding: '10px',
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'middle'
  }
})
