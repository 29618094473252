import { useContext, createContext } from 'react'

interface IFormContext {
  progress: number
  sideMain: string
  sideText: string
  setProgress: React.Dispatch<React.SetStateAction<number>>
  setSideMain: React.Dispatch<React.SetStateAction<string>>
  setSideText: React.Dispatch<React.SetStateAction<string>>
  thisPage: number
  // To change side text dinamically
  changeIndication?: (e: any) => void
  // LocalStorage pagination
  goBackPage: () => void
  goNextPage: () => void
  changePage: (newNumber: number) => void
  // Form
  form: any
  setForm: any
  errorModal: boolean
  setErrorModal: React.Dispatch<React.SetStateAction<boolean>>
  orangeBtnText: string | null
  setOrangeBtnText: React.Dispatch<React.SetStateAction<string | null>>
  // to show and hide glbop view
  displayGlBopView: boolean
  setDisplayGlBopView: React.Dispatch<React.SetStateAction<boolean>>
  // loading
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  // Previously on AppContext
  quote: any
  setQuote: any
  policyNumber: any
  savePolicyNumber: any
  updateLocalQuoteData: any
  coterieValues: any
  setCoterieValues: any
  adjustPolicy: boolean
}

const FormContext = createContext<IFormContext | undefined>(undefined)

export const useFormContext = (): IFormContext => {
  const context: IFormContext | undefined = useContext(FormContext)
  if (context === undefined) throw Error('Form Context is not right')
  return context
}

export default FormContext
