import { ReactElement, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as SvgMenu } from './assets/Menu.svg'
import styled from 'styled-components'
import { Bind, Join } from './index'
import { useFormContext } from 'context/FormContext'

const useStyles = makeStyles({
  closeButton: {
    backgroundColor: '#fff',
    color: '#fff',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'row-reverse',
    '& button': {
      backgroundColor: '#fff',
      border: 'none'
    }
  },
  menuOptions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  menuTitle: {
    fontWeight: 501
  }
})

interface menuType {
  open: boolean
}

const StyledMenu = styled.div<menuType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 136%;
  background-color: #fff;
  position: absolute !important;
  z-index: 9999;
  padding-right: 20px;
  padding-left: 20px;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
    width: 100%;
    height: 160%;
  }
`

interface optionType {
  disabled: boolean
}

const StyledOption = styled.div<optionType>`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  opacity: ${({ disabled }) => disabled ? '0.25' : '1'};

  div {
    width: 85%;
    display: flex;
    align-items: center;
    margin-top: 20px;

    div {
      margin-top: 0px;
      height: 70px;
      max-width: 120px;
      padding: 10px;
      img {
        height: 55px;
      }
    }

    span {
      font-size: 17px;
      font-weight: 501;
      display: inline-block;
      width: 220px;
    }
  }
`

interface hamburgerProps {
  showHamburgerMenu: boolean
  setShowHamburgerMenu: React.Dispatch<React.SetStateAction<boolean>>
  typeSidebar: string
}

interface menuProps {
  title: string
  image: string
  onClick: (event: any) => void
  disabled: boolean
}

export default function SteperHamburgerMenu ({ showHamburgerMenu, setShowHamburgerMenu, typeSidebar }: hamburgerProps): ReactElement {
  const classes = useStyles()
  const { changePage, thisPage, progress } = useFormContext()
  const optionsCopy = [...Join, ...Bind]
  optionsCopy[optionsCopy?.length - 1].text1 = 'Your Policy'
  const [options, setoptions] = useState(optionsCopy)
  const progressRef = useRef({ Joinprogress: 0, Bindprogress: 0, typeSidebar: 'Join' })

  const validateMenuOption = (): void => {
    progressRef.current = {
      Joinprogress: (typeSidebar === 'Join' && progressRef.current.Joinprogress < progress) ? progress : progressRef.current.Joinprogress,
      Bindprogress: (typeSidebar === 'Bind' && progressRef.current.Bindprogress < progress) ? progress : progressRef.current.Bindprogress,
      typeSidebar: progressRef.current.typeSidebar === 'Bind' ? 'Bind' : typeSidebar
    }
    const copyOptJoin = Join.map((option) => {
      const isDisabled = (progressRef.current.Joinprogress < option.min)
      return {...option, disabled: typeSidebar === 'Bind' ? false : isDisabled}
    })
    const copyOptBind = Bind.map((option) => {
      const isDisabled = (progressRef.current.Bindprogress < option.min)
      return { ...option, disabled: progressRef.current.typeSidebar === 'Join' ? true : isDisabled }
    })
    setoptions([...copyOptJoin, ...copyOptBind])
  }

  useEffect(() => {
    validateMenuOption()
  }, [progress])

  const handleChangePage = (page: number): void => {
    if (thisPage === 10) {
      return
    }
    setShowHamburgerMenu((open) => !open)
    changePage(page)
  }

  const MenuOption = ({ title, image, onClick, disabled }: menuProps): any => (
    <StyledOption onClick={(e: any) => { if (!disabled) onClick(e) }} disabled={disabled}>
      <div>
        <div>
          <img src={image} alt='not-found' />
        </div>
        <span>{title}</span>
      </div>
    </StyledOption>
  )

  return (
    <StyledMenu open={showHamburgerMenu}>
      <div className={classes.closeButton}>
        <button onClick={() => { setShowHamburgerMenu((open) => !open) }}>
          <SvgMenu />
        </button>
      </div>
      <div className={classes.menuOptions}>
        <h4 className={classes.menuTitle}>Steps to your quote</h4>
        {
          options.map((option: any) => (
            <MenuOption
              key={option.page}
              title={`${option.text1} ${option.text2}`.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
              image={option.image}
              onClick={(event: any) => handleChangePage(option.page)}
              disabled={option.disabled}
            />
          ))
        }
      </div>
    </StyledMenu>
  )
}
