import { brand } from "interfaces/brand";
import stepperMagnum from '../components/StepperContainer/assets/stepper-magnum.svg'
import stepperCustom from '../components/StepperContainer/assets/stepper-custom.png'

export const getSalesNamesofBrands = (  ): string []  => {
    return configBrands().map((brand) => {
        return brand.salesName; 
    });
}

export const getBrandBySalesName = ( salesName:string ): brand | null => {
    return configBrands().find((brand) => brand.salesName == salesName) ?? null
}

export const getBrandByAgentGroup = ( group:string ): brand | null => {
    return configBrands().find((brand) => brand.groupName == group) ?? null
}

export const getBrandByHostName = (): brand | null =>{
    return configBrands().find(( brand ) => window.location.hostname.includes(brand.subdomain)) ?? null
}

export const configBrands = () : brand [] => {
    return [
        {
            name: "Magnum",
            salesName: "MagnumMaster",
            groupName: "Magnum Insurance Agency",
            subdomain: "magnum",
            logo: stepperMagnum,
            url: "https://magnuminsurance.com",
            phoneNumber: "224-348-2400"
        },
        {
            name: "Custom Brand",
            salesName: "PouchDemoGroup",
            groupName: "Test Agent Group A",
            subdomain: "pouchforagent-demo",
            logo: stepperCustom,
            url: "https://pouchinsurance.com",
            phoneNumber: "123-456-7890"
        }
    ]
}