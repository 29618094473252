import { makeStyles } from '@material-ui/core'

// https://v4.mui.com/customization/breakpoints/#breakpoints

export const useUpperFormStyles = makeStyles(theme => ({
  paymentSection: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    '& label': {
      color: '#818892',
      paddingTop: '5px',
      fontWeight: 'bold'
    },
    '& input': {
      width: '100%',
      height: '34px',
      borderRadius: '5px',
      backgroundColor: '#f8fbfa',
      border: '1px solid #818892'
    },
    '& select': {
      width: '100%',
      height: '34px',
      borderRadius: '5px',
      backgroundColor: '#f8fbfa',
      border: '1px solid #818892'
    }
  },
  customHr: {
    width: '100%',
    margin: '3px 0px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  textRight: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  searchButton: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '10px'
    },
    '& button': {
      color: '#fff',
      width: '100px',
      border: 'none',
      height: '38px',
      marginTop: '-2px',
      fontWeight: 'bold',
      borderRadius: '10px',
      backgroundColor: '#37c3b8'
    }
  },
  submitButton: {
    textAlign: 'center',
    paddingBottom: '15px',
    '& button': {
      border: 0,
      color: 'white',
      borderRadius: '9px',
      padding: '10px 40px',
      backgroundColor: '#FE5730'
    }
  },
  notesArea: {
    paddingTop: '40px',
    '& textarea': {
      width: '100%',
      height: '20vh'
    }
  }
}))
