import { Box } from '@material-ui/core'
import Title from 'components/Title'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'

const AdjustPolicyPage = (): JSX.Element => {
  return (
    <Box maxWidth={860} margin='0 auto' padding={5}>
      <Title>&nbsp;&nbsp;Policy Adjusment</Title>
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
    </Box>
  )
}

export default AdjustPolicyPage
