import { ReactElement } from 'react'
import PouchCard from './PouchCard'
import CoterieCard from './CoterieCard'
import { IGenericCard } from 'interfaces'
import { resposiveStyles } from 'styles/page6.styles'
import {
  Grid
} from '@material-ui/core'

function GenericCard(cardProps: IGenericCard): ReactElement {
  const { policyType } = cardProps
  const classes = resposiveStyles()
  
  // To uncomment later

  // return policyType ? (
  //   <Grid container justifyContent='space-between' className={classes.cardContainer}>
  //     <Grid item lg={5} className={classes.cards}>
  //       <PouchCard {...cardProps} />
  //     </Grid>
  //     <Grid item lg={5} className={classes.cards}>
  //       <CoterieCard {...cardProps} />
  //     </Grid>
  //   </Grid>
  // ) : 
  return (
    <Grid container justifyContent='center'>
      <Grid item lg={6}>
        <PouchCard {...cardProps} />
      </Grid>
    </Grid>
  )
}

export default GenericCard
