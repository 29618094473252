import React, { useEffect, useState } from 'react'
import icon1 from '../../Join/assets/quote-icon-1.svg'
import icon2 from '../../Join/assets/quote-icon-2.svg'
import icon3 from '../../Join/assets/quote-icon-3.svg'
import icon4 from '../../Join/assets/quote-icon-4.svg'
import { LimitMEDPAY, DeductCOLL, DeductCOMP, UMPDIL, CardPropsIL, CardPropsAZ, LimitBIPDIL, LimitUMUIMIL, LimitBIPDAZ, LimitUMBIAZ, LimitUIMBIAZ, payPlanOptions, DeductTCOLL, DeductTCOMP, CardPropsTX, LimitBIPDTX, LimitMEDPAYTX, LimitEmployers, LimitUMUIMTX, limitPIPTX, LimitHired, CardPropsGA, LimitBIPDGA, LimitUIMBIGA, UMTypeGA, LimitMEDPAYGA, LimitUMCOGA, LimitUIMBITX, LimitUMCOTX, DeductTSA, LimitBIPDTN, LimitUMUIMTN, LimitMEDPAYTN, CardPropsTN } from 'utils/joinQuoteSelect'
import QuoteSelect from "../components/QuoteSelect/QuoteSelect"
import { Box, Input } from '@material-ui/core'
import Collapse from '../components/Collapse/Collapse';
import './QuoteInfo.scss'
import { GetLocalQuoteData, UpdateQuoteDataNoRate } from 'services/instanda'
import joinQuoteFiller from 'utils/joinQuoteFiller'
import { useSnackbar } from 'notistack'
import errorHandler from 'utils/errorHandler'
import TypedInput from 'components/TypedInput'
import { generateId } from 'utils/functions'
import { useAppSelector } from 'hooks/useAppSelector'
import { dispatchApp } from 'redux/store'
import { updateQuotePouch } from 'redux/slices/quote'

export const QuoteInfo = ({ verifyErrors, setVerifyErrors, isSending, isDisabled }: any) => {
  const [id, setId] = useState('');
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch);
  const { enqueueSnackbar } = useSnackbar();
  const [policyValues, setPolicyValues] = useState<any>({});
  const [cardProps, setcardProps] = useState<any>([]);
  const [isHired, setIsHired] = useState(false);
  const [isEmployerNOL, setIsEmployerNOL] = useState(false);
  const [{ hasError, errorMessage }, setumbiUimbiError] = useState<any>({ hasError: false, errorMessage: '' })

  useEffect(() => {
    setcardProps(quote?.BusinessState_TXT === 'AZ' ? CardPropsAZ : quote?.BusinessState_TXT === 'TX' ? CardPropsTX : quote?.BusinessState_TXT === 'GA' ? CardPropsGA : quote?.BusinessState_TXT === 'TN' ? CardPropsTN : CardPropsIL) // set cardProps to corresponding usstate
    try {
      setTimeout(() => {
        if (quote) {
          setPolicyValues(quote) // Forcing added fields to policy
          console.log("setPolicyValues", quote)
          // priceChanges(quote) // Forcing price changes
          // end same quote filler
        } else {
          // addEmptyVehicle()
        }
      }, 100);
    } catch (error) {
      errorHandler(error, enqueueSnackbar)
    }
  }, []);

  const setValues = async (property: string, value: string) => {
    const {
      // SelectedVehicleOverrideLimitUIMBI_TXT,
      // VehicleOverrideLimitBIPD_IL_CHOICE,
      // VehicleOverrideLimitUMUIM_IL_CHOICE,
      // VehicleOverrideLimitMEDPAY_CHOICE,
      // HiredAuto_YNP,
      // VehicleOverrideLimitBIPD_AZ_CHOICE,
      // VehicleOverrideLimitUMBI_CHOICE,
      // VehicleOverrideLimitUIMBI_CHOICE,      
      // VehicleOverrideLimitBIPD_GA_CHOICE,
      // VehicleOverrideLimitUMBIPD_GA_CHOICE,
      // UninsuredMotoristCoverageOption_GA_CHOICE,
      // VehicleOverrideLimitMEDPAY_GA_CHOICE,
      // EmployersNonOwnershipLiability_YN,      
      // AnnualCostOfHire,
      // VehicleOverrideLimitBIPD_TX_CHOICE,
      // VehicleOverrideLimitUMUIM_TX_CHOICE,
      // VehicleOverrideLimitMEDPAY_TX_CHOICE,
      // VehicleOverrideLimitPIP_CHOICE,
      ...quoteWithout
    } = quote ?? {}
    const insert: any = {}
    insert[`${property}`] = value
    console.log("insert", insert)
    dispatchApp(updateQuotePouch({
      ...quoteWithout,
      ...insert

      //  SelectedVehicleOverrideLimitUIMBI_TXT: policyValues?.SelectedVehicleOverrideLimitUIMBI_TXT || '',
      //  BIPD_IL_CHOICE: policyValues?.BIPD_IL_CHOICE || '',
      // VehicleOverrideLimitUMUIM_IL_CHOICE: policyValues?.VehicleOverrideLimitUMUIM_IL_CHOICE || '',
      // VehicleOverrideLimitMEDPAY_CHOICE: policyValues?.VehicleOverrideLimitMEDPAY_CHOICE || '',
      // HiredAuto_YNP: policyValues?.HiredAuto_YNP || '',
      // VehicleOverrideLimitBIPD_AZ_CHOICE: policyValues?.VehicleOverrideLimitBIPD_AZ_CHOICE || '',
      // VehicleOverrideLimitUMBI_CHOICE: policyValues?.VehicleOverrideLimitUMBI_CHOICE || '',
      // VehicleOverrideLimitUIMBI_CHOICE: policyValues?.VehicleOverrideLimitUIMBI_CHOICE || '',      
      // VehicleOverrideLimitBIPD_GA_CHOICE: policyValues?.VehicleOverrideLimitBIPD_GA_CHOICE || '',
      // VehicleOverrideLimitUMBIPD_GA_CHOICE: policyValues?.VehicleOverrideLimitUMBIPD_GA_CHOICE || '',
      // UninsuredMotoristCoverageOption_GA_CHOICE: policyValues?.UninsuredMotoristCoverageOption_GA_CHOICE || '',
      // VehicleOverrideLimitMEDPAY_GA_CHOICE: policyValues?.VehicleOverrideLimitMEDPAY_GA_CHOICE || '',
      // EmployersNonOwnershipLiability_YN: policyValues?.EmployersNonOwnershipLiability_YN || '',      
      // AnnualCostOfHire: policyValues?.AnnualCostOfHire || '',
      // VehicleOverrideLimitBIPD_TX_CHOICE: policyValues?.VehicleOverrideLimitBIPD_TX_CHOICE || '',
      // VehicleOverrideLimitUMUIM_TX_CHOICE: policyValues?.VehicleOverrideLimitUMUIM_TX_CHOICE || '',
      // VehicleOverrideLimitMEDPAY_TX_CHOICE: policyValues?.VehicleOverrideLimitMEDPAY_TX_CHOICE || '',
      // VehicleOverrideLimitPIP_CHOICE: policyValues?.VehicleOverrideLimitPIP_CHOICE || '',
    }))
  }


  const validateUmbiAndUimbi = (): boolean => {
    if (policyValues[cardProps.umbi] !== policyValues[cardProps.uimbi]) {
      setumbiUimbiError({
        hasError: true,
        errorMessage: 'Uninsured Motorist and Underinsured Motorist must be the same'
      })
      return false
    } else {
      setumbiUimbiError({
        hasError: false,
        errorMessage: ''
      })
      return true
    }
  }

  useEffect(() => {
    setId(generateId() + 'QuoteInfo');
    //setSubmitHandler( () => formikFormVehicles.handleSubmit ) 
  }, []);

  useEffect(() => {
    if (id !== '') {
      verifyErrors[id] = errorMessage !== '' ? true : false;
      setVerifyErrors(verifyErrors);
    }
  }, [errorMessage])

  return (
    <Box maxWidth={860} margin='0 auto' padding={2} className='premium-info'>
      <Collapse
        title='Policy Coverages'
        ignoreIndex
        isQuoteInfo
        className='custom-colapse'>
        {
          policyValues.BusinessState_TXT === 'IL'
            ? (
              <>
                <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} isAdjust image={icon1} property={cardProps.bipd} options={LimitBIPDIL} title='Bodily Injury (BI) Property Damage (PD) Limits' />
                <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} isAdjust image={icon2} property={cardProps.umui} options={LimitUMUIMIL} title='Uninsured / Underinsured Motorist (UIMBI) Limits' />
                <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} isAdjust image={icon3} property={cardProps.medp} options={LimitMEDPAY} title='Medical Payments Limit' />
                <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.hired} options={LimitHired} title='Hired auto' isHired={isHired} setIsHired={setIsHired} />                
                <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.enol} options={LimitEmployers} title={`Employer's Non-Ownership Liability`} isEmployerNOL={isEmployerNOL} setIsEmployerNOL={setIsEmployerNOL} />
              </>
            )
            : policyValues.BusinessState_TXT === 'TX'
              ? (
                <>
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon1} property={cardProps.bipd} options={LimitBIPDTX} title='Bodily Injury (BI) Property Damage (PD) Limits' />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon2} property={cardProps.umui} options={LimitUIMBITX} title='Uninsured / Underinsured Motorist (UIMBI) Limits' />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon3} property={cardProps.medp} options={LimitMEDPAYTX} title='Medical Payments Limit' />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon1} property={cardProps.pip} options={limitPIPTX} title='Personal Injury Protection Coverage Limits (PIP)' />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.hired} options={LimitHired} title='Hired auto' isHired={isHired} setIsHired={setIsHired} />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.enol} options={LimitEmployers} title={`Employer's Non-Ownership Liability`} isEmployerNOL={isEmployerNOL} setIsEmployerNOL={setIsEmployerNOL} />
                </>
              )
            : policyValues.BusinessState_TXT === 'TN'
              ? (
                <>
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon1} property={cardProps.bipd} options={LimitBIPDTN} title='Bodily Injury (BI) Property Damage (PD) Limits' />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon2} property={cardProps.umui} options={LimitUMUIMTN} title='Uninsured / Underinsured Motorist (UIMBI) Limits' />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon3} property={cardProps.medp} options={LimitMEDPAYTN} title='Medical Payments Limit' />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.enol} options={LimitEmployers} title={`Employer's Non-Ownership Liability`} isEmployerNOL={isEmployerNOL} setIsEmployerNOL={setIsEmployerNOL} />
                  <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.hired} options={LimitHired} title='Hired auto' isHired={isHired} setIsHired={setIsHired} />
                </>
              )
              : policyValues.BusinessState_TXT === 'GA'
                ? (
                  <>
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon1} property={cardProps.bipd} options={LimitBIPDGA} title='Bodily Injury (BI) Property Damage (PD) Limits' />
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon2} property={cardProps.umui} options={LimitUIMBIGA} title='Uninsured / Underinsured Motorist (UIMBI) Limits' />
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon2} property={cardProps.umco} options={LimitUMCOGA} title='Uninsured Motorist Coverage Option' />
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon3} property={cardProps.medp} options={LimitMEDPAYGA} title='Medical Payments Limit' />
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.hired} options={LimitHired} title='Hired auto' isHired={isHired} setIsHired={setIsHired} />
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.enol} options={LimitEmployers} title={`Employer's Non-Ownership Liability`} isEmployerNOL={isEmployerNOL} setIsEmployerNOL={setIsEmployerNOL} />
                  </>
                )
                : policyValues.BusinessState_TXT === 'AZ'
                  ? (
                    <>
                      <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon1} property={cardProps.bipd} options={LimitBIPDGA} title='Bodily Injury (BI) Property Damage (PD) Limits' />
                      <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon2} property={cardProps.umbi} options={LimitUMBIAZ} title='Uninsured Motorist (UMBI) Limits' validateField={validateUmbiAndUimbi} />
                      <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon2} property={cardProps.uimbi} options={LimitUIMBIAZ} title='Underinsured Motorist (UIMBI) Limits' validateField={validateUmbiAndUimbi} showError={hasError} errorMessage={errorMessage} />
                      <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon3} property={cardProps.medp} options={LimitMEDPAY} title='Medical Payments Limit' />
                      <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.hired} options={LimitHired} title='Hired auto' isHired={isHired} setIsHired={setIsHired} />
                      <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.enol} options={LimitEmployers} title={`Employer's Non-Ownership Liability`} isEmployerNOL={isEmployerNOL} setIsEmployerNOL={setIsEmployerNOL} />
                    </>) :
                  <>
                    <QuoteSelect isDisabled={isViewMode} policyValues={policyValues} setPolicyValues={setPolicyValues} image={icon1} property={cardProps.bipd} options={LimitBIPDGA} title='Bodily Injury (BI) Property Damage (PD) Limits' />
                    <QuoteSelect isDisabled={isViewMode} policyValues={policyValues} setPolicyValues={setPolicyValues} image={icon2} property={cardProps.umbi} options={LimitUMBIAZ} title='Uninsured Motorist (UMBI) Limits' validateField={validateUmbiAndUimbi} />
                    <QuoteSelect isDisabled={isViewMode} policyValues={policyValues} setPolicyValues={setPolicyValues} image={icon2} property={cardProps.uimbi} options={LimitUIMBIAZ} title='Underinsured Motorist (UIMBI) Limits' validateField={validateUmbiAndUimbi} showError={hasError} errorMessage={errorMessage} />
                    <QuoteSelect isDisabled={isViewMode} policyValues={policyValues} setPolicyValues={setPolicyValues} image={icon3} property={cardProps.medp} options={LimitMEDPAY} title='Medical Payments Limit' />
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.hired} options={LimitHired} title='Hired auto' isHired={isHired} setIsHired={setIsHired} />
                    <QuoteSelect isDisabled={isViewMode} policyValues={quote} setPolicyValues={setValues} image={icon4} property={cardProps.enol} options={LimitEmployers} title={`Employer's Non-Ownership Liability`} isEmployerNOL={isEmployerNOL} setIsEmployerNOL={setIsEmployerNOL} />
                  </>
        }
      </Collapse>

    </Box>
  )
}
