import JoinAbout from './assets/join-about.svg'
import JoinQuote from './assets/join-quote.svg'
import JoinDrivers from './assets/join-drivers.svg'
import JoinVehicles from './assets/join-vehicles.svg'
import AdditionalInfo from './assets/Additional info.svg'
import Documents from './assets/Documents.svg'
import Payment from './assets/Payment.svg'
import Congrats from './assets/Congrats.svg'

const instandaThisPage = Number(localStorage.getItem('instandaThisPage'))
const aditionalInfo = () => {
  if (instandaThisPage === 5) {
    return 5
  } else if (instandaThisPage === 6) {
    return 6
  }
}

export const Join = [
  {
    image: JoinAbout,
    text1: 'About your',
    text2: 'Business',
    min: 0,
    max: 33,
    sideMain: 'About your Business',
    sideText: 'To start a quote, complete the required information about the business and click next to continue.',
    fieldIndications: {
      BusinessSubClass_CHOICE: 'Select the business class that best describes your business from the options down below.'
    },
    page: 1
  }, {
    image: JoinVehicles,
    text1: 'Vehicles',
    text2: '',
    min: 33,
    max: 66,
    sideMain: 'Vehicles',
    sideText: 'To continue your Quote, add all the vehicles and trailers that you want to include in the policy, then click next to continue',
    fieldIndications: {
      VehicleVINOverride_TXT: 'Every vehicle has a 17 alphanumeric character field known as the Vehicle Identification Number, and can be found at the front of the dashboard, on the driver\'s side of the vehicle, this will help identify your vehicle as every VIN is unique.',
      TrailerVIN_TXT: 'Every vehicle has a 17 alphanumeric character field known as the Vehicle Identification Number, and can be found at the front of the dashboard, on the driver\'s side of the vehicle, this will help identify your vehicle as every VIN is unique.'
    },
    page: 2
  }, {
    image: JoinDrivers,
    text1: 'Drivers',
    text2: '',
    min: 66,
    max: 100,
    sideMain: 'Drivers',
    sideText: 'To continue your Quote, add all the drivers you want to include in the policy, don\'t forget to add accidents or violations then click next to continue.',
    fieldIndications: {
      DriverIncidentsViolations_NUM: 'If you had any traffic violation, or any accident involving your vehicles, please add the number down below.'
    },
    page: 3
  }, {
    image: JoinQuote,
    text1: 'Your',
    text2: 'Quote',
    min: 100,
    max: 100,
    sideMain: 'Your Quote',
    sideText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    rightTooltip: {
      GL: 'General Liability (GL) Insurance protects you and your business from being held financially responsible for damage caused to another person’s property, body, or reputation. General Liability is the most common form of insurance for small businesses, as every single professional is required to have some form of general liability coverage.',
      BOP: ' A Business Owners Policy (BOP) is more versatile and protects both a small business owner’s business and property – all in one package (typically combining General Liability and Property Insurance).'
    },
    page: 4
  }
]

export const Bind = [
  {
    image: AdditionalInfo,
    text1: 'Additional Info',
    text2: '',
    min: 0,
    max: 1,
    sideMain: 'Additional Info',
    sideText: 'One last step, please add a couple more details to create the final quote.',
    fieldIndications: {
      VehicleVINOverride_TXT: 'Every vehicle has a 17 alphanumeric character field known as the Vehicle Identification Number, and can be found at the front of the dashboard, on the driver\'s side of the vehicle, this will help identify your vehicle as every VIN is unique.'
    },
    rightTooltip: {
      GL: '',
      BOP: ''
    },
    page: 5
  }, {
    image: JoinDrivers,
    text1: 'MVR Report',
    text2: '',
    min: 1,
    max: 33,
    sideMain: 'MVR Report',
    sideText: 'One last step, please add a couple more details to create the final quote.',    
    rightTooltip: {
      GL: '',
      BOP: ''
    },
    page: 6
  }, {
    image: Documents,
    text1: 'Documents',
    text2: '',
    min: 33,
    max: 66,
    sideMain: 'Documents',
    sideText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    page: 8
  }, {
    image: Payment,
    text1: 'Payment',
    text2: '',
    min: 66,
    max: 100,
    sideMain: 'Payment',
    sideText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    page: 9
  }, {
    image: Congrats,
    text1: 'Congrats',
    text2: '',
    min: 100,
    max: 100,
    sideMain: 'Congrats',
    sideText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    page: 10
  }
]

export { default } from './StepperContainer'
