import { Grid, useMediaQuery } from '@material-ui/core'
import { useState, useEffect, ReactElement } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
// Context
import { useFormContext } from 'context/FormContext'

const StepperPercentage = (min: number, max: number): number => {
  const { progress } = useFormContext()
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    if (progress < min) {
      setPercentage(0)
    } else if (progress > max) {
      setPercentage(100)
    } else {
      const pc = (progress - min) / (max - min) * 100
      setPercentage(pc)
    }
  }, [progress, min, max])

  return percentage
}

interface IStepperImage {
  min: number
  max: number
  image: string
  text1: string
  text2: string
}

const useStyles = makeStyles(theme => ({
  imageContainer: {
    paddingTop: '2px',
    paddingBottom: '2px',
    backgroundColor: '#f7f7f7',
    '& img': {
      paddingBottom: '2px',
      // Responsive
      height: '30px',
      [theme.breakpoints.up('sm')]: {
        height: '40px'
      }
    },
    '& div': {
      lineHeight: 1.3,
      color: '#2a2a2a',
      fontWeight: 'bold',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      // Responsive
      fontSize: '6px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '8px'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '10px'
      }
    }
  },
  sliderWrapper: {
    position: 'relative'
  },
  percentNumber: {
    lineHeight: 1.2,
    color: '#2a2a2a',
    textAlign: 'left',
    '& span': {
      lineHeight: 0.85,
      position: 'relative'
    },
    '& span:first-child': {
      top: '2px'
    },
    // Responsive
    fontSize: '7px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '11px'
    }
  },
  fillingLine: {
    height: '3px',
    borderRadius: '2px'
  }
}))

export default function StepperImage ({ min, max, image, text1, text2 }: IStepperImage): ReactElement {
  const [currentPage, setCurrentPage] = useState(false)
  const theme = useTheme()
  const classes = useStyles()
  const { progress, thisPage, changePage } = useFormContext()
  const fillPercent = StepperPercentage(min, max)
  const isSM = useMediaQuery(theme.breakpoints.up('sm'))
  const isMD = useMediaQuery(theme.breakpoints.up('md'))

  const isHere = (progress >= min && progress < max)
  const actSize = isMD ? '10px' : (isSM ? '5px' : '10px')
  const disSize = isMD ? '35px' : (isSM ? '30px' : '26px')

  useEffect(() => {
    hoverOnIcon(text1)
  }, [thisPage])

  const pages = ['', 'About your', 'Vehicles', 'Drivers', 'Your', 'Additional Info', 'MVR Report', '', 'Documents', 'Payment', 'Your Policy']

  const hoverOnIcon = (text: string): void => {
    const idxOfPage = pages.indexOf(text)
    thisPage > idxOfPage ? setCurrentPage(true) : setCurrentPage(false)
    thisPage === 9 && setCurrentPage(false)
  }

  const navigateTo = (text: string): void => {
    const idxOfPage = pages.indexOf(text)
    changePage(idxOfPage)
  }

  return (
    <>
      <Grid item xs style={{ opacity: (progress >= min ? 1 : 0.25), cursor: 'pointer', pointerEvents: currentPage ? 'all' : 'none' }} onClick={() => navigateTo(text1)} onMouseEnter={() => hoverOnIcon(text1)}>
        <div className={classes.imageContainer}>
          <img src={image} alt='not-found' />
          <div>{text1}</div>
          <div>{text2}</div>
        </div>
      </Grid>
      {
        min !== 100 && (
          <Grid item xs>
            <div className={classes.sliderWrapper} style={{ top: isHere ? actSize : disSize }}>
              {
                isHere ? (
                  <div className={classes.percentNumber} style={{ paddingLeft: `calc(${fillPercent}% - 10px)` }}>
                    <span>{progress}%</span>
                    <br />
                    <span style={{ left: `${fillPercent / 3}%` }}>▾</span>
                  </div>
                ) : null
              }
              <div
                className={classes.fillingLine} style={{
                  background: `linear-gradient(to right, black 0% ${fillPercent}%, #c6c6c6 ${fillPercent}% 100%)`
                }}
              />
            </div>
          </Grid>
        )
      }
    </>
  )
}
