import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { generateId } from 'utils/functions'
import { useAppSelector } from 'hooks/useAppSelector'
import './PaymentOption.scss'
import Title from 'components/Title'
import { payPlanOptions, payPlanOptionsGA } from 'services/instadaModel'
import { useFormik } from 'formik'
import { PaymentOptionSchema, validateError } from 'utils/validations'
import TypedInput from '../components/TypedInput/TypedInput'
import { IPaymentOption } from 'interfaces/pouch-redux'
import { dispatchApp } from 'redux/store'
import { updateQuotePouch } from 'redux/slices/quote';

const PaymentOption = ({ verifyErrors, setVerifyErrors, isSending, isDisabled }: any) => {
  const [id, setId] = useState('')
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch)
  const [{ hasError, errorMessage }, setumbiUimbiError] = useState<any>({ hasError: false, errorMessage: '' })

  const initialValues: IPaymentOption = {
    PayPlan_CHOICE: ''
  }

  const formikFormPaymentOption = useFormik({
    initialValues: initialValues as IPaymentOption,
    validationSchema: PaymentOptionSchema,
    onSubmit: () => setValues()
  })

  const setValues = async () => {
    const {
      PayPlan_CHOICE,
      ...quoteWithout
    } = quote ?? {};
    console.log(quoteWithout)
    dispatchApp(updateQuotePouch({
      ...quoteWithout,
      PayPlan_CHOICE: formikFormPaymentOption.values.PayPlan_CHOICE
    }));
  }

  useEffect(() => {
    setId(generateId()+'PaymentOption');
    formikFormPaymentOption.setFieldValue('PayPlan_CHOICE', quote?.PayPlan_CHOICE ?? previousQuote?.PayPlan_CHOICE)
  }, []);

  useEffect(()=> {
    if(id !== ''){
      verifyErrors[id] = errorMessage !== '' ? true : false;
      setVerifyErrors(verifyErrors);
    }
  }, [ formikFormPaymentOption.errors ])

  useEffect(()=>{
    setValues()
  }, [ formikFormPaymentOption.values ])

  return (
    <form
      onSubmit={formikFormPaymentOption.handleSubmit}
    >
      <Box maxWidth={860} margin='0 auto' padding={5} className='premium-info'>
        <div className='row'>
          <div className='col-6'>
            <Title>Payment Option</Title>
            <TypedInput
              inputName='PayPlan_CHOICE'
              inputPlaceholder='How do you want to pay'
              inputType='select'
              inputOptions={quote?.BusinessState_TXT === 'GA' ? payPlanOptionsGA : payPlanOptions}
              formikForm={formikFormPaymentOption}
              hasErrors={validateError(formikFormPaymentOption.errors.PayPlan_CHOICE)}
              inputValue='PayPlan_CHOICE'
              disabled={isViewMode}
            />
          </div>
        </div>
      </Box>
    </form>
  )
}

export default PaymentOption
