import { get } from 'lodash'
import { FormikProps } from 'formik'
import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    padding: '10px 0'
  },
  borderMain: {
    outline: 'none',
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: '#fff',
    border: '2px solid #dddddd'
  },
  inputClass: {
    appearance: 'textfield',
    backgroundColor: '#FFF',
    width: '100%',
    border: 'none',
    padding: '10px'
  },
  labelTitle: {
    left: '5px',
    top: '-10px',
    border: 'none',
    padding: '0 5px',
    fontSize: '0.7em',
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundColor: '#fff'
  },
  labelError: {
    color: 'red',
    fontSize: '0.7em'
  },
  inputDate: {
    backgroundColor: '#FFF',
    textTransform: 'uppercase'
  }
})

interface ITypedInput {
  formikForm?: FormikProps<any> | any
  inputType?: string
  inputTitle: string
  inputValue?: string
  inputOptions?: Array<{ value: string, label: string }>
  inputPlaceholder?: string
  inputName?: string
  onMouseEnter?: Function
  onMouseLeave?: Function
  noEmptyDropdown?: boolean
  minDate?: string
  maxDate?: string
  property?: string,
  disabled?: boolean
}

const getAsString = (mainObject: FormikProps<any> | any, mainString: string): string => {
  const newValue = get(mainObject, mainString)
  return typeof newValue === 'string' ? newValue : ''
}

export default function TypedInput ({ formikForm, inputType = 'text', inputTitle, inputValue, inputOptions, inputPlaceholder = '', inputName, onMouseEnter, onMouseLeave, noEmptyDropdown = false, minDate = '', maxDate = '', disabled=false, property }: ITypedInput): ReactElement {
  const classes = useStyles()

  const value = getAsString(formikForm, `values.${inputValue}`)
  const valueString = value !== 'not provided' ? value : ''
  const errorString = getAsString(formikForm, `errors.${inputValue}`)
  const touchString = get(formikForm, `touched.${inputValue}`)

  // console.log({ values: formikForm.values, valueString })

  const sharedProps = {
    value: inputType === 'date' ? valueString.split('T')[0] : valueString,
    placeholder: inputPlaceholder,
    className: `${classes.inputClass} ${inputType === 'date' ? classes.inputDate : ''}`,
    name: inputName,
    onChange: (event: any) => formikForm?.setFieldValue(inputValue, event.target.value),
    onMouseEnter: (e: any) => { return onMouseEnter !== undefined ? onMouseEnter(e) : null },
    onMouseLeave: (e: any) => { return onMouseLeave !== undefined ? onMouseLeave(e) : null }
  }

  const showErrors = errorString !== '' && touchString === true

  return (
    <div className={classes.container}>
      <section className={classes.borderMain} style={{ borderColor: showErrors ? '#ff0000' : '#dddddd' }}>
        <label className={classes.labelTitle}>{inputTitle}</label>
        {
          inputOptions === undefined
            ? <input {...sharedProps} type={inputType} min={minDate} max={maxDate} style={{ color: valueString.length === 0 || disabled ? 'gray' : 'black' }} disabled={disabled} />
            : (
              <select {...sharedProps}>
                {
                 inputType === 'business' ? <option value='Not provided'>Not provided</option> : noEmptyDropdown ? null : <option value=''>Select an option...</option>
                }
                {
                  inputOptions.map((optionsValue, optionsIndex) => (
                    <option key={optionsIndex} value={optionsValue.value}>{optionsValue.label}</option>
                  ))
                }
              </select>
              )
        }
      </section>
      {
        showErrors && <label className={classes.labelError}>{errorString}</label>
      }
    </div>
  )
}
