import { Box, CircularProgress, Dialog, Typography } from '@material-ui/core'
import { useAppSelector } from 'hooks/useAppSelector'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateQuotePouch, updating } from 'redux/slices/quote'
import { dispatchApp } from 'redux/store'
import { UpdateQuoteData, UpdateQuoteDataNoRate } from 'services/instanda'
import { CustomerInfo } from 'views/MTA/CustomerInfo'
import { DriverInfo } from 'views/MTA/DriverInfo'
import { QuoteInfo } from 'views/MTA/QuoteInfo'
import { TrailerInfo } from 'views/MTA/TrailerInfo'
import { VehicleInfo } from 'views/MTA/VehicleInfo'
import Loader from '../Loader/Loader'
import AdditionalInsuredInfo from '../../AdditionalInsuredInfo/AdditionalInsuredInfo'
import './MTAForm.scss'
import { dateOfToday } from 'utils/calculations'
import PaymentOption from 'views/MTA/PaymentOption/PaymentOption'
import { useSnackbar } from 'notistack'

export const MTAForm = () => {

    // const isAdjust = JSON.parse(String(localStorage.getItem('isAdjustPolicy'))); // Temporary
    const { loading, QuoteRefURL, quote, previousQuote, multiItems, isViewMode, thisPage, setThisPage } = useAppSelector(state => state.pouch)
    const [semaphore, setSemaphore] = useState(false);
    const [verifyErrors, setVerifyErrors] = useState({});
    const [hasErrors, setHasErrors] = useState(false);    
    const [isSending, setIsSending] = useState(false)
    const { enqueueSnackbar } = useSnackbar();   
    const history = useHistory()

    const startDate = new Date(dateOfToday()).getTime()
    const endDate = new Date(String(quote?.PolicyExpiration_DATE)).getTime()
    const diff = (endDate - startDate) / (1000 * 60 * 60 * 24)

    useEffect(() => {
        console.log(quote)
        complete()
        if (QuoteRefURL === quote?.QuoteRef) {
            localStorage.setItem('instandaQuoteRef', quote?.QuoteRef || '{}')
            if (!loading && !semaphore) {
                if (quote?.IsMTA) {
                    if (quote?.FinalPremiumAnnualInclTaxes_NUM === previousQuote?.FinalPremiumAnnualInclTaxes_NUM) {
                        setSemaphore(true);                        
                    }                    
                }
            }
        }
    }, [])

    const complete = async () => {
        const {

            ...quoteWithout
        } = quote ?? {};
        console.log(quoteWithout)
        dispatchApp(updateQuotePouch({
            ...quoteWithout,
            thisPage: 1
        }));
    }

    const validateErrors = () => {
        const errors = Object.entries(verifyErrors).flatMap(error => error[1]).includes(true) || Object.entries(verifyErrors).length === 0
        console.log("Validate error: ", errors)
        setHasErrors(errors)
    }

    useEffect(() => {
        console.log("exec", Object.values(verifyErrors))
        validateErrors()
    }, [ Object.values(verifyErrors) ])
    console.log(verifyErrors)  

    const sendForm = async () => {
        try{
            dispatchApp(updating({
                page: 'updating',
                loading: true,
            }));
                        
            await UpdateQuoteData(quote, quote?.QuoteRef)
            history.push('/mta/mvr')
            
            dispatchApp(updating({
                page: 'updating',
                loading: false,
            }));        
        }catch(error: any){            
            enqueueSnackbar( ( error?.response?.data["Error Messages"] ||  error?.response?.data )?? 'Try again'  , { variant: 'error' })            
            dispatchApp(updating({
                page: 'updating',
                loading: false,
            })); 
        }
    }
    
    return (
        <>
            {
                loading ?
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Dialog
                            PaperProps={{
                                className: 'custom-dialog'
                            }}
                            open={true}
                        >
                            <Loader />
                        </Dialog>
                    </Box>
                    :
                    <>
                        <CustomerInfo
                            isAdjust                            
                            setVerifyErrors={setVerifyErrors}
                            verifyErrors={verifyErrors}
                        />
                        <VehicleInfo
                            isAdjust                            
                            setVerifyErrors={setVerifyErrors}
                            verifyErrors={verifyErrors}
                        />
                        <TrailerInfo
                            isAdjust                            
                            setVerifyErrors={setVerifyErrors}
                            verifyErrors={verifyErrors}                    
                        />
                        <DriverInfo
                            isAdjust                            
                            setVerifyErrors={setVerifyErrors}
                            verifyErrors={verifyErrors}
                        />
                        <AdditionalInsuredInfo
                            isAdjust                            
                            setVerifyErrors={setVerifyErrors}
                            verifyErrors={verifyErrors}                            
                        />
                        <PaymentOption
                            isAdjust
                            setVerifyErrors={setVerifyErrors}
                            verifyErrors={verifyErrors}                            
                        />
                        <QuoteInfo
                            isAdjust
                            setVerifyErrors={setVerifyErrors}
                            verifyErrors={verifyErrors}                            
                        />
                        <Box maxWidth={860} margin='0 auto' padding={5}>
                            {
                                !isViewMode && diff > 7 ?
                                    <button type='button' className={`next-btn ${hasErrors ? 'not-active' : ''}`}
                                        onClick={sendForm}
                                        disabled={hasErrors}
                                    >Update Quote</button>
                                    :
                                    <>
                                        <Typography className='text-error text-center mb-10'>{diff <= 7 && 'You can not rate an MTA that expires in 7 days or less'}</Typography>
                                    </>
                            }
                        </Box>
                    </>
            }
        </>

    )
}
