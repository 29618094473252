import { agentDashboardURL, getSalesPerson } from "services/instanda"
import { format, add } from 'date-fns'
import { getBrandBySalesName } from "./configBrands"

const goToDashboard = () => {
    window.open(`${agentDashboardURL}`, '_self')
}

const generateId = () => {
    const random = Math.random().toString(36).substr(2)
    const date = Date.now().toString(36)
    return random + date
}

const tomorrowDate = (): string => {
    return `${format(add(new Date(), { days: 1, hours: 1 }), 'yyyy-MM-dd')}`
}

const formatDate = (date: any, format?: boolean) => {
    if (date.includes('T')) {
        date = String(date).substring(0, String(date).indexOf('T'))
    }
    const newdate = date.split('/').reverse().join('/')
    return format ? newdate : date
}

const compareDates = (date: any, oldDate: any) => {
    const date2 = new Date(oldDate)
    return date.getTime() > date2.getTime()
}

const envs = ['localhost', '127.0.0.1', 'uat', 'dev']
const isDEV = envs.some(keyword => window.location.href.includes(keyword))
const envUrl = isDEV ? '-uat' : ''

export {
    goToDashboard,
    generateId,
    tomorrowDate,
    formatDate,
    compareDates,
    envUrl
}