import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { isAGENT, isPAYMENT } from './services/instanda'
import AppContext from 'context/AppContext'
import { SnackbarProvider } from 'notistack'
import { render } from 'react-dom'
import './index.css'

// Pages
import Join from './views/Join'
import Home from 'views/Landing'
import Partner from 'views/Landing/Partner'
import { AboutUs } from 'views/Landing/AboutUs'
import Standalone from 'views/Standalone/Standalone'
import ChargeBeeTokenize from './views/ChargeBeeTokenize'
import deleteLocalStorage from 'utils/deleteLocalStorage'
import { StandaloneCaptchaTest } from 'views/StandaloneCaptcha/StandaloneCaptchaTest'
import AdjustPolicy from 'views/Join/AdjustPolicy'
import { Payment } from 'views/Payment'


const setLocalStorageKey = (key: string, value: string | null): void => {
  if (value !== null) localStorage.setItem(key, value) // Adding value to localstorage only if is sent via url params
}

// Redux
import { Provider } from 'react-redux'
import store, { dispatchApp, persistor } from './redux/store'
import { CustomerInfo } from 'views/MTA/CustomerInfo'
import { VehicleInfo } from 'views/MTA/VehicleInfo'
import { TrailerInfo } from 'views/MTA/TrailerInfo'
import { DriverInfo } from 'views/MTA/DriverInfo'
import { QuoteInfo } from 'views/MTA/QuoteInfo'
import { PremiumInfo } from 'views/MTA/PremiumInfo'
import { ThankYou } from 'views/MTA/ThankYou'
import Navbar from 'UI/Navbar/Navbar'
import { getInfoQuote, setQuoteRefURL } from 'redux/slices/quote'
import { PersistGate } from 'redux-persist/integration/react'
import { CircularProgress } from '@material-ui/core'
import { MTAForm } from 'views/MTA/components/MTAForm/MTAForm'
import { configBrands } from 'utils/configBrands'
import Page6A from 'views/Join/Page6A'
import MVRInfo from 'views/MTA/MVRInfo/MVRInfo'





const App = () => {
  const brands = configBrands();
  console.log(brands);

  return (
    <AppContext>
      <SnackbarProvider maxSnack={5}>
        <BrowserRouter>
          <Provider store={store}>
            <PersistGate loading={<CircularProgress />} persistor={persistor}>
              <Switch>
                <Route
                  exact
                  path='/'
                  render={() => {
                    if (isPAYMENT) {
                      return <Standalone />
                    } else if (isAGENT()) {
                      deleteLocalStorage()
                      return <Redirect to='/join' />
                    } else {
                      return <Home />
                    }
                  }}
                />
                <Route path='/home'>
                  <Home />
                </Route>
                <Route path='/partners'>
                  <Partner />
                </Route>
                <Route path='/about-us'>
                  <AboutUs />
                </Route>
                <Route path='/join'>
                  <Join />
                </Route>
                {
                  brands.map((brand, i) => {
                    return <Route key={i} path={`/join-${brand.subdomain}`}
                      render={() => {
                        deleteLocalStorage()
                        localStorage.setItem('AgentGroupName', brand.groupName)
                        localStorage.setItem('agentPerson', brand.salesName)
                        return <Redirect to='/join' />
                      }}
                    />
                  })
                }
                <Route path='/chargebeetokenize'>
                  <ChargeBeeTokenize />
                </Route>
                <Route path='/standalone-payment'>
                  <Standalone />
                </Route>
                <Route path='/captchatest'>
                  <StandaloneCaptchaTest />
                </Route>
                <Route
                  path='/mta-instanda'
                  render={() => {
                    deleteLocalStorage()
                    const paramsURL = new URLSearchParams(window.location.search);
                    localStorage.setItem('agentPerson', paramsURL.get('SalespersonEmail') || '')
                    const isViewMode = paramsURL.get('isViewMode')?.toLowerCase() === 'true' ?? false;
                    dispatchApp(setQuoteRefURL({
                      QuoteRefURL: paramsURL.get('QuoteRef') ?? '',
                      page: 'mta-instanda',
                      isViewMode,
                      errorMessage: '',
                      quote: {}
                    }))
                    persistor.purge().then(() => {
                      dispatchApp(getInfoQuote(paramsURL.get('PolicyNumber'), 'mta-form'));
                    });
                    return <Redirect to={`${isViewMode ? '/viewPolicy' : '/mta'}`} />
                  }}
                />
                <Route path='/viewPolicy'>
                  <Navbar />
                  <MTAForm />
                </Route>
                <Route path='/mta'>
                  <Navbar />
                  <Switch>
                    <Route exact path='/mta'>
                      <MTAForm />
                    </Route>
                    <Route exact path='/mta/mvr'>
                      <MVRInfo />
                    </Route>
                  </Switch>
                </Route>
                <Route
                  path='/agent'
                  render={() => {
                    deleteLocalStorage()
                    const paramsURL = new URLSearchParams(window.location.search)
                    setLocalStorageKey('instandaQuoteRef', paramsURL.get('quoteRef'))
                    setLocalStorageKey('agentPerson', paramsURL.get('agentName'))
                    setLocalStorageKey('AgentGroupName', paramsURL.get('AgentGroupName'))
                    setLocalStorageKey('quoteRefCopy', paramsURL.get('quoteRefCopy'))
                    setLocalStorageKey('agentFinish', paramsURL.get('finished'))
                    return <Redirect to='/join' />
                  }}
                />
                <Route
                  path='/premium-info'
                  render={() => {
                    return <PremiumInfo navbar={true} />
                  }}
                />
                <Route
                  path='/payment'
                  render={() => {
                    return <PremiumInfo navbar={true} />
                  }}
                />
                <Route
                  path='/thank-you'
                  render={() => {
                    return <ThankYou />
                  }}
                />
                <Route
                  path='/payment-instanda'
                  render={() => {
                    const paramsURL = new URLSearchParams(window.location.search);
                    dispatchApp(setQuoteRefURL({
                      QuoteRefURL: paramsURL.get('QuoteRef') ?? '',
                      page: 'payment-instanda',
                      errorMessage: '',
                      quote: {}
                    }))
                    persistor.purge().then(() => {
                      dispatchApp(getInfoQuote(paramsURL.get('QuoteRef'), 'premium-info'));
                    });
                    return <Redirect to='/payment' />
                  }}
                />
                <Route
                  render={() => {
                    console.info('Redirect to home')
                    return <Redirect to='/' />
                  }}
                />
              </Switch>
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </SnackbarProvider>
    </AppContext>
  )
}


render(
  <App />,
  document.getElementById('root')
)
