import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

interface containerType {
    error?: boolean
    className?: string
}

const InputContainer = styled.div<containerType>`
    /* position: relative; */
    border-radius: 5px;
    border: 2px solid #dddddd;
    background-color: #fff;
    position: relative;
    margin-bottom: 12px;
    padding: 0;

`
const Label = styled.label`
    font-size: 0.9rem;
    border: none;
    /* position: absolute; */
    top: -10px;
    background-color: #fff;
    left: 5px;
    white-space: nowrap;
    padding: 0 5px;
`

const SelectImput = styled.select`
    border: none;
    padding: 10px;
    width: 100%;
`

const Input = styled.input`
    border: none;
    padding: 10px;
    width: 100%;
`

const ToggleContainer = styled.div`
  position: relative;
  background-color: #E5E5E5;
  border-radius: 8;
  display: flex;
  height: 35px;
`

const Form = styled.form`
    display: block;
    padding-bottom: 25px;
`

const FlexMain = styled.div`
    display: flex;
    padding-bottom: 25px;
`
const FlexImage = styled.div`
    flex: 0 0 80px;
    position: relative;
    img: {
        width: '100%'
    }
`

const Button = styled.button`
    border: 2px solid #eeedef;
    border-radius: 5px;
    width: 50%;
    height: 3rem;
`

const ErrorMessage = styled.div`
    color: red;
    margin: 0 0 10px 0;
    padding: 5px;
    font-size: 12px;
`
interface optionType {
    disabled: boolean
}

const StyledOption = styled.div<optionType>`
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    opacity: ${({ disabled }) => disabled ? '0.25' : '1'};
  
    div {
      width: 85%;
      display: flex;
      align-items: center;
      margin-top: 20px;
  
      div {
        margin-top: 0px;
        height: 70px;
        max-width: 120px;
        padding: 10px;
        img {
          height: 55px;
        }
      }
  
      span {
        font-size: 17px;
        font-weight: 501;
        display: inline-block;
        width: 220px;
      }
    }
  `
interface menuType {
    open: boolean
}

const StyledMenu = styled.div<menuType>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 136%;
    background-color: #fff;
    position: absolute !important;
    z-index: 9999;
    padding-right: 20px;
    padding-left: 20px;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease-in-out;
  
    @media (max-width: 576px) {
      width: 100%;
      height: 160%;
    }
  `

export {
    InputContainer,
    Label,
    Input,
    SelectImput,
    ToggleContainer,
    Form,
    FlexMain,
    FlexImage,
    Button,
    ErrorMessage,
    StyledOption,
    StyledMenu
}