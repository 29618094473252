import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/styles'

const labelStyles = makeStyles({
  labelPerks: {
    width: '100%',
    height: '70px',
    display: 'flex',
    margin: '25px 0px',
    borderRadius: '20px',
    backgroundColor: '#f1f1f1'
  },
  imgContainer: {
    margin: 'auto',
    flex: '0 0 75px',
    textAlign: 'center'
  },
  txtContainer: {
    flex: 1,
    fontWeight: 700,
    fontSize: '18px',
    paddingTop: '3px',
    margin: 'auto 0px',
    textAlign: 'center'
  }
})

const LabelPerks = ({ Img, title }: { Img: React.FunctionComponent, title: string }): ReactElement => {
  const classes = labelStyles()
  return (
    <section className={classes.labelPerks}>
      <div className={classes.imgContainer}>
        <Img />
      </div>
      <div className={classes.txtContainer}>
        {title}
      </div>
    </section>
  )
}

export default LabelPerks
