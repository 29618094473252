import { MouseEventHandler, ReactElement } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  btnDefault: {
    backgroundColor: '#39C7C2',
    border: 2,
    borderStyle: 'solid',
    borderColor: '#21A29D',
    borderRadius: 22,
    color: '#fff',
    minWidth: 100,
    minHeight: 30,
    width: 150,
    height: 45,
    margin: 15
  }
})

export default function BtnPrimary ({ children, onClick }: { children:string, onClick?:MouseEventHandler<HTMLElement>}): ReactElement {
  const classes = useStyles();

  return (
    <button
      className={ classes.btnDefault }
      onClick={ onClick }
    >
      { children }
    </button>
  )
}
