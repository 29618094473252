import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  btn: {
    backgroundColor: '#ffff',
    border: 3,
    borderStyle: 'solid',
    borderColor: '#FF6C1A',
    borderRadius: 22,
    color: '#FF6C1A',
    fontFamily: 'Libre Franklin',
    width: '275px',
    height: '65px',
    margin: '30px auto',
    fontSize: '18px'
  }
})

export default function Button ({
  className = '',
  children,
  onClick,
  borderRounded = false,
  disabled = false
}: {
  className?: string
  children: any
  onClick?: () => void
  borderRounded?: boolean
  disabled?: boolean
}): ReactElement {
  const classes = useStyles()
  const { btn } = classes

  return (
    <button
      className={btn ?? className}
      onClick={onClick}
      disabled={disabled}
      style={{ borderRadius: borderRounded ? '14%/55%' : '' }}
    >
      {children}
    </button>
  )
}
