import axios from 'axios'
import { quotePouch } from 'interfaces/pouch-redux'
import { configBrands, getBrandByHostName, getBrandBySalesName, getSalesNamesofBrands } from 'utils/configBrands'

export const defaultSalesPerson = 'PouchMasterAgent'
export const defaultAgentGroup = 'Pouch Insurance Agency'

export const salesPersonLocalStorage = (): string | null => {  
  return getBrandByHostName()?.salesName ?? localStorage.getItem('agentPerson')
}

const AgentGroupNameLocalStorage = (): string | null => {
  return getBrandByHostName()?.groupName ?? localStorage.getItem('AgentGroupName')  
}

export const isAGENT = (): boolean => {  
  return ((window.location.hostname.includes('agent') && !window.location.hostname.includes('pouchforagent-demo') ) || localStorage.getItem('agentPerson') != null) && !getSalesNamesofBrands().includes(getSalesPerson()) 
}

export const isPAYMENT = window.location.hostname.includes('payment')

export const isLOCAL = ['localhost', '127.0.0.1'].includes(window.location.hostname)
export const isDEV = window.location.hostname.includes('-dev')
const isUAT = window.location.hostname.includes('-uat')
const isPRD = !isLOCAL && !isDEV && !isUAT

const devBackendURL = 'https://apiquote.pouchinsurance.com/staging'

export const helloSignClientId =
  (isLOCAL || isDEV)
    ? 'f4eb0529d6d9b497df15d5ab0e742d10'
    : isUAT
      ? '2e924448dc0aef8b61fe777077236126'
      : 'e3fbc6656850e6752484b939d5205314'

export const getSalesPerson = (): string => {
  return (salesPersonLocalStorage() || null)?? defaultSalesPerson
}

export const getAgentGroupName = (): string => {
  return (AgentGroupNameLocalStorage() || null) ?? defaultAgentGroup
}

export const agentDashboardURL = getBrandBySalesName(getSalesPerson())?.url ?
  getBrandBySalesName(getSalesPerson())?.url : isPRD ? 
  'https://agent.pouchinsurance.com/Public/AgentDashboard'
  :'https://agent-pouchinsurance.instanda.us/Public/AgentDashboard'

export const isMARKETING = window.location.hostname.includes('marketing')

export const RecaptchaSiteKey = '6LfwH2kdAAAAAGB4HbOHSnrbSYl1sKDiAEX-Uw3S'

export const API = axios.create({
  baseURL: isLOCAL
    ? 'http://localhost:3100/master'
    : isDEV
      ? devBackendURL
      : isUAT
        ? 'https://apiquote.pouchinsurance.com/staging'
        : 'https://apiquote.pouchinsurance.com/production',
  responseType: 'json'
})

export const devAPI = axios.create({ // This is the backend we should call when we require to whitelist an IP address // Polk requires a static IP address, so we can't use the plain ALB that ECS uses // That means that we are always calling DEV backend for certain things
  baseURL: devBackendURL,
  responseType: 'json'
})

export const quoteRef = (): string => {
  const quoteLS = localStorage.getItem('instandaQuoteRef') ?? ''
  if (quoteLS === '') console.log('QuoteRef was not in localStorage')
  return quoteLS
}

export async function StartQuote (values: any): Promise<any> {
  values.AgentGroupId = getAgentGroupName()
  return await API.post(`instanda/startQuote/${getSalesPerson()}`, values)
}

export async function GetLocalQuoteData (otherQuoteRef?: string): Promise<any> {
  return API.get(`instanda/getQuote/${otherQuoteRef ?? quoteRef()}`)
}

export async function GenerateMTAData (otherQuoteRef?: string): Promise<any> {
  return API.get(`instanda/rateadjustment/${otherQuoteRef ?? quoteRef()}/${getSalesPerson()}`)
}

export async function UpdateQuoteData (data: any, otherQuoteRef?: string): Promise<any> {
  return await API.post(`instanda/updateQuote/${otherQuoteRef ?? quoteRef()}/${getSalesPerson()}`, data)
}

export async function updateQuoteChargerBee (data: any, QuoteRef?: string): Promise<any> {
  return await API.post(`instanda/updateQuoteChargerBee/${QuoteRef ?? quoteRef()}/${getSalesPerson()}`, data)
}

export async function UpdateQuoteDataNoRate (data: any, qRef: string): Promise<any> {
  return await API.post(`instanda/updateQuoteNoRate/${qRef}`, data)
}

export async function BindExistingQuote (data: any, otherQuoteRef?: string): Promise<any> {
  return await API.post(`instanda/bindExistingQuote/${otherQuoteRef ?? quoteRef()}/${getSalesPerson()}`, data)
}

export async function GetBussinesClass (): Promise<any> {
  return await API.get('upload/getAllClasses')
}

export async function GetAllYears (): Promise<any> {
  return await API.get('upload/getByYears')
}

export async function GetMakeByYear (year: string): Promise<any> {
  return await API.get(`upload/getByMake?year=${year}`)
}
export async function GetModelByYearAndMake (year: string, make: string): Promise<any> {
  return await API.get(`upload/getByModel?year=${year}&make=${make}`)
}

export async function GetTrim (year: string, make: string, model: string): Promise<any> {
  return await API.get(`upload/getTrim?year=${year}&make=${make}&model=${model}`)
}

export async function GetTrailerAllYears (): Promise<any> {
  return await API.get('trailer/getAllYears')
}

export async function GetTrailerMakeByYear (value: string): Promise<any> {
  return await API.get(`trailer/getByYear?value=${value}`)
}

export async function GetMapList (search: string): Promise<any> {
  return await API.get(`/instanda/getAllPlaces/${search}`)
}

export async function getLocationDetails (search: string): Promise<any> {
  return await API.get(`/instanda/getLocationDetails/${search}`)
}

export async function getSignUrl (): Promise<any> {
  return await API.post(`/hellosign/${quoteRef()}`)
}

export async function sendSignEmail (): Promise<any> {
  return await API.put(`/hellomail/${quoteRef()}`)
}

export async function getPolicyData (policyNumber: string): Promise<any> {
  return await API.get(`/instanda/getPolicyData/${policyNumber}`)
}

export async function downloadPolicyService (otherQuoteRef?: string, downloadableName?:string): Promise<any> {
  return await API.get(`/instanda/getBase64Document/${otherQuoteRef ?? quoteRef()}/${downloadableName ?? 'PCAxxAPP_1122'}`,{responseType: 'blob'})
}

export async function GetZip (data: string) {
  return await API.get(`/instanda/getPlaceGeocode/address=${data}&sensor=true`)
}
/**
 * searches the given policy Number in instanda and our database and
 * return the info merged form the both sources
 * @param policyNumber - Policy number to search on instanda
 * @returns - the object with the merged data from instanda and our databse
 */
export async function getPolicyDataForAdjustment (policyNumber: string): Promise<any> {
  return await API.get(`/instanda/getPolicyDataForAdjustment/${policyNumber}`)
}

export async function getQuoteMultiItems (quoteRef: string): Promise<any> {
  return await API.get(`/instanda/getQuoteMultiItems/${quoteRef}`)
}
