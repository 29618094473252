import { ReactElement, useState } from 'react'
import Carousel, { RenderArrowProps } from 'react-elastic-carousel'
import { makeStyles, Modal } from '@material-ui/core'
import Card from './Card'
import '../styles/PouchCarousel.css'
import { ReactComponent as SVG_LEFTARROWICON } from '../../assets/ArrowIconLeft.svg'
import { ReactComponent as SVG_RIGHTARROWICON } from '../../assets/ArrowIconRight.svg'
import Button from './Button'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  btnArrows: {
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: '50%',
    height: 40,
    width: 40,
    transition: 'all 0.3s ease',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: '#f8f8f8'
    },
    '&:disabled': {
      visibility: 'hidden'
    }
  },
  btnIndicatorsContainer: {
    position: 'absolute',
    bottom: '14%'
  },
  itemOne: {
    width: '20%',
    marginLeft: '20%',
    '@media (max-width: 1024px)': {
      marginLeft: '7%',
      width: '25%',
      '& .pouch-label-title': {
        fontSize: 45
      }
    },
    '@media (max-width: 768px)': {
      width: '100%',
      marginLeft: '0%'
    },
    '@media (max-width: 414px)': {
      width: '60%'
    }
  },
  itemTwo: {
    width: '60%',
    display: 'flex',
    '@media (max-width: 1024px)': {
      marginLeft: '3%'
    },
    '@media (max-width: 414px)': {
      flexDirection: 'column',
      marginLeft: '25%'
    },
    '@media (max-width: 375px)': {
      marginLeft: '15%'
    }
  },
  modalContainer: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '300px',
    backgroundColor: '#ffff',
    borderRadius: '20px',
    display: 'flex',
    '@media (max-width: 768px)': {
      left: '20%'
    },
    '@media (max-width: 414px)': {
      left: '5%',
      width: '350px'
    }
  },
  modalLeftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  modalRightSide: {
    width: '50%',
    paddingLeft: '5%',
    paddingTop: '12%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  modalTitle: {
    fontSize: 22,
    marginTop: '10%'
  },
  modalSentence: {
    fontSize: 14,
    marginTop: '2%',
    marginLeft: '10%',
    marginRight: '10%'
  },
  popUpImage: {
    width: 165,
    height: 145,
    textAlign: 'center'
  }
})

const breackPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 5 }
]

interface ModalData {
  cardImage: any
  title: string
  value: string
  modalText: string
}

const initialState = {
  cardImage: () => null,
  title: '',
  value: '',
  modalText: ''
}

export default function PouchCarousel (
  {
    items
  }: {
    items: Array<{ title: string, value: string, CardImage?: any, buttonText: string, modalText: string }>,
  }
): ReactElement {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const [tempData, setTempData] = useState<ModalData>(initialState)
  const history = useHistory()

  const goToQuote = (bussinessClass: string): void => {
    localStorage.setItem('businessNamePick', bussinessClass)
    history.push('/join/about-you')
  }

  const openCloseModal = (cardImage: any, title: string, value: string, modalText: string): void => {
    const tempData = { cardImage, title, value, modalText }
    setTempData(tempData)
    // Uncomment to open modal
    // setOpen(!open)
    // Uncomment open join quote inmediately
    console.log('openCloseModalClicked')
    goToQuote(value)
  }

  const { cardImage: CardImage, title, value, modalText } = tempData

  // To render arrow buttons of the carousel
  const renderArrows = ({ type, onClick, isEdge }: RenderArrowProps) => {
    const SVG_ICON = (type === 'NEXT') ? SVG_RIGHTARROWICON : SVG_LEFTARROWICON
    return (
      <button onClick={onClick} disabled={isEdge} className={classes.btnArrows}>
        <SVG_ICON style={{ height: 28, width: 20 }} />
      </button>
    )
  }

  return (
    <div className='pouch-carousel-container'>
      <Carousel
        isRTL={false}
        breakPoints={breackPoints}
        renderArrow={renderArrows}
        pagination={false}
      >
        {
          items.map(({ title, value, CardImage, buttonText, modalText }, idx) => {
            return (
              <Card
                key={idx}
                title={title}
                CardImage={CardImage}
                buttonText={buttonText}
                className='PouchCard'
                buttonClick={() => openCloseModal(CardImage, title, value, modalText)}
              />
            )
          })
        }
      </Carousel>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.modalLeftSide}>
            <div className={classes.modalTitle}>{title}</div>
            <div className={classes.modalSentence}>{modalText}</div>
            <Button borderRounded onClick={() => goToQuote(value)}>Go to Quote</Button>
          </div>
          <div className={classes.modalRightSide}>
            <CardImage className={classes.popUpImage} />
          </div>
        </div>
      </Modal>
    </div>
  )
}
