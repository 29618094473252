import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'

interface ComponentProps{
  isAdjust?: boolean
}

const useStyles = makeStyles<ComponentProps>(({ isAdjust }) => ({
  title: {
    fontWeight: 700,
    margin: '0 0 20px 0',
    fontSize: `${isAdjust ? '2.5em' : '1.5em'}`,
    fontStyle: 'normal'
  }
}))

export default function Title ({ children, isAdjust }: { children: string, isAdjust?: boolean }): ReactElement {
  const classes = useStyles({ isAdjust })
  return (
    <h1 className={classes.title}>{children}</h1>
  )
}
