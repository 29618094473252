const defaultFunction = (): void => {
  console.warn('Removing quote stuff from local storage')
  localStorage.removeItem('instandaFullQuote')
  localStorage.removeItem('coterieValues')
  localStorage.removeItem('instandaQuoteRef')
  localStorage.removeItem('agentPerson')
  localStorage.removeItem('agentFinish')
  localStorage.removeItem('instandaThisPage')
  localStorage.removeItem('policyType')
  localStorage.removeItem('policyNumber')
  localStorage.removeItem('businessNamePick')
  localStorage.removeItem('paymentVia')
  localStorage.removeItem('quoteRefCopy')
  localStorage.removeItem('manually')
  localStorage.removeItem('isAdjustPolicy')
}

export default defaultFunction
