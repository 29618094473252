import { Box, CircularProgress } from '@material-ui/core'
import Title from 'components/Title'
import { useAppSelector } from 'hooks/useAppSelector';
import { IPremiumInfo } from 'interfaces';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { updating } from 'redux/slices/quote';
import { dispatchApp } from 'redux/store';
import { findTerm, PayMTAWithChargebee,findGWPremiumMTA } from 'services/chargeBee';
import { BindExistingQuote, updateQuoteChargerBee } from 'services/instanda';
import { Button } from 'UI/CustomElement';
import Navbar from 'UI/Navbar/Navbar';
import { Payment } from 'views/Payment';
import PremiumCard from '../components/PremiumCard/PremiumCard';
import { updateQuotePouch } from '../../../redux/slices/quote';
import './PremiumInfo.scss'
import { merge } from 'lodash';
import errorHandler from 'utils/errorHandler';
import Card from '../components/Card/Card';

const PremiumInfo = ({navbar = true} : IPremiumInfo) => {
  const { loading, QuoteRefURL , quote, previousQuote } = useAppSelector(state=> state.pouch);  
  const { enqueueSnackbar } = useSnackbar(); 
  const [semaphore, setSemaphore] = useState(false);
  const history = useHistory();
  
  const callBindExistingQuoteService = async (): Promise<void> => {
    try{
      dispatchApp(updating({
        page:'updating',
        loading:true,        
      }));

      await  BindExistingQuote({},quote?.QuoteRef);

    }catch(err:any){
      enqueueSnackbar(err.message, { variant: 'error' })
    }        
  }  

  const GWPremiumMTA = async (): Promise<any> => {
    try {
      const resp = await findGWPremiumMTA(quote?.QuoteRef)
      return resp?.data?.response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    if(QuoteRefURL === quote?.QuoteRef){
      if(!loading && !semaphore){
        if(quote?.IsMTA){        
          if(quote?.FinalPremiumAnnualInclTaxes_NUM === previousQuote?.FinalPremiumAnnualInclTaxes_NUM){
            setSemaphore(true);            
            callBindExistingQuoteService().then(()=>{
              return toThankYou();
            })
          }else{
            GWPremiumMTA()
            .then(res => {
              const dueToday = res.MTA
              dispatchApp( updateQuotePouch(merge({dueToday}, quote)))              
            });
          }       
        }
      }
    }
  },[quote?.QuoteRef,quote?.IsMTA,quote?.FinalPremiumAnnualInclTaxes_NUM,previousQuote?.FinalPremiumAnnualInclTaxes_NUM,loading])

  const updatePolicy = async () =>{
    try{
      dispatchApp(updating({
        page:'updating',
        loading:true,
      }));

      const pay = await PayMTAWithChargebee({
        OriginalPolicyStartDate: quote?.OriginalPolicyStartDate,
        SubscriptionID_TXT: quote?.ChargeBeeID_TXT,
        customer_id: quote?.ChargeBeeInvoice_TXT,
        invoice_id: quote?.ChargerBeeDownPaymentID,
        payPlan: quote?.PayPlan_CHOICE,
        policyNumber: quote?.PouchPolicyNumber_TXT,
        FinalPremiumAnnualInclTaxes_NUM: quote?.FinalPremiumAnnualInclTaxes_NUM,
        FinalPremiumMonthlyInclTaxes_NUM: quote?.FinalPremiumMonthlyInclTaxes_NUM,
        dueToday: quote?.dueToday,
        RootQuoteRef: quote?.RootQuoteRef,
        QuoteRef: quote?.QuoteRef
      })

      let DownPaymentID = "";
      if(quote){
        if(quote?.dueToday?.MTADueTodayAmount && quote?.dueToday?.MTADueTodayAmount < 0){
          DownPaymentID = pay.data.creditNote   
        }else{  
          DownPaymentID = pay.data.invoice
        }
      }

      await updateQuoteChargerBee({ 
        ChargeBeeID_TXT:  pay.data.subscription,
        ChargeBeeInvoice_TXT: pay.data.customer,      
        ChargerBeeDownPaymentID: pay.data.invoice,
        MTADueTodayAmount: pay.data.MTADueTodayAmount,
        MTAChangetotal: pay.data.MTAChangetotal,
        MTADueTodayMonthlyAmount: pay.data.MTADueTodayMonthlyAmount
      }, quote?.QuoteRef)

      await callBindExistingQuoteService()      

      const { ChargerBeeDownPaymentID, ...quoteWithout} = quote??{};      
      dispatchApp( updateQuotePouch({
        ...quoteWithout,
        ChargerBeeDownPaymentID: DownPaymentID
      }));      

      return toThankYou();
     
    }catch(err:any){
      dispatchApp(updating({
        page:'updating',
        loading:false,
      }));
      enqueueSnackbar(err?.response?.data ?? 'Try again', { variant: 'error' })      
    }        
  };

  const toThankYou = () => {
    history.push('thank-you')
  }

  const goBack = () => {
    quote?.IsMTA ? history.push('/mta/mvr') : history.goBack()
  }

  return (    
    <div>
      {
        navbar ? <Navbar /> : null
      }
      <Box maxWidth={860} margin='0 auto' padding={5}>
        <div className='col-12 mt-10 mx-4 text-center'>
          <Title>{quote?.IsMTA ? "Premium Change" : "Payment"}</Title>
        </div>
        {
          loading ?
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <CircularProgress/>
          </Box>
          :
          <>
            {
            quote?.IsMTA ?
              <>
                <div className='row'>
                  <div className='col-6'>
                    <PremiumCard
                      isCurrent
                      title='Current Premium'
                      PremiumAmount={previousQuote?.FinalPremiumAnnualInclTaxes_NUM?.toString()}
                      PremiumMonthlyAmount={previousQuote?.FinalPremiumMonthlyInclTaxes_NUM?.toString()}
                      />
                  </div>
                  <div className='col-6'>
                    <PremiumCard
                      title='New Premium'
                      PremiumAmount={quote?.FinalPremiumAnnualInclTaxes_NUM?.toString()}
                      PremiumMonthlyAmount={quote?.FinalPremiumMonthlyInclTaxes_NUM?.toString()}
                      FinalPremiumAmount={quote?.dueToday?.MTADueTodayAmount?.toString()} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-10 info">
                    <span>It is not possible to change your payment plan during an adjustment.</span>
                  </div>
                </div>
              </> :
              <div className='row'>
                <div className='col-5'>
                  <PremiumCard
                    className='extraLarge'
                    isCurrent
                    title='Premium'
                    PremiumAmount={quote?.FinalPremiumAnnualInclTaxes_NUM?.toString()}
                    PremiumMonthlyAmount={quote?.FinalPremiumMonthlyInclTaxes_NUM?.toString()} />
                </div>          
                <div className='col-7'>
                  <Payment />
                </div>
                {
                  (quote?.CreatedFrom === 'NewBusiness' && quote?.PayPlanSelected_TXT?.includes('Monthly')) ?
                  (
                    <div className='col-12'>
                      <Card 
                        PremiumAmount={quote?.FinalPremiumMonthlyInclTaxes_NUM?.toString()}
                        downPayment={quote?.cb_downpayment_num?.toString()}
                        PolicyEffective_DATE={quote?.PolicyEffective_DATE}
                        PeriodLengthMonthly_NUM={quote?.PeriodLengthMonthly_NUM}
                        PolicyTerm_CHOICE={quote?.PolicyTerm_CHOICE} 
                      />
                    </div>
                  ): null
                }
              </div>
            }
          </>          
        }
        
        
      </Box>
      {
        quote?.IsMTA && !loading && (          
          quote?.FinalPremiumAnnualInclTaxes_NUM != previousQuote?.FinalPremiumAnnualInclTaxes_NUM
        ) ?
        <Box maxWidth={860} margin='0 auto'display='flex' className='btn-container'>
          <Button className='back-btn' onClick={() => goBack()}>Back</Button>
          <Button className='update-btn' onClick={()=>{updatePolicy()}}>Update Policy</Button>
        </Box>:
        <></>
      }

    </div>
  )
}

export default PremiumInfo