import { ReactElement } from 'react';

import TextField from './TextField';

const CardNumberFormater = ( InputProps: any): ReactElement => {

  const {  type, form, name, ...inputProps } = InputProps

  const onChange = (event: any): void => {    
    const formattedNumber = formatCardNumber(event);
    form.setFieldValue(name, formattedNumber);
  }

  const formatCardNumber = (value: string): string | undefined => {
    if (value === '') return ''
    const cardNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = cardNumber.length

    if (phoneNumberLength < 4) return cardNumber

    return `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 8)} ${cardNumber.slice(8, 12)} ${cardNumber.slice(12, 16)}`.trim()
  }


  const props = {...inputProps, type, form, name, onChange}

  return (
    <>
      <TextField
        { ...props }
      />
    </>
  )
}

export default CardNumberFormater
