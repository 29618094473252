import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PayloadQuoteAction } from "interfaces/pouch-redux";

export const quoteSlice = createSlice({
    name: 'test',
    initialState: {
        page: 'in-page',
        previousQuote:{},
        quote: {},
        loading: true,
        errorMessage: ''
    } as PayloadQuoteAction,
    reducers: {
      inPage: (state, action: PayloadAction<PayloadQuoteAction>) => {
        state.page = action.payload.page;        
        state.quote = action.payload.quote;
        state.errorMessage = action.payload.errorMessage;
        state.loading= false;
      },      
      updating: (state, action: PayloadAction<PayloadQuoteAction>) => {
        state.page = 'updating';
        state.loading= action.payload.loading;
      },
      setQuoteRefURL: (state, action: PayloadAction<PayloadQuoteAction>) => {
        state.QuoteRefURL = action.payload.QuoteRefURL;
        state.isViewMode = action.payload.isViewMode;
        state.loading = true;
      },
      getQuote: (state, action: PayloadAction<PayloadQuoteAction>) => {
        state.page = 'get-quote';
        state.previousQuote = action.payload.previousQuote;
        state.quote = action.payload.quote;
        state.multiItems = action.payload.multiItems;
        state.errorMessage = action.payload.errorMessage;
        state.loading= false;
      },
    },
})

// Action creators are generated for each case reducer function
export const { inPage, updating, getQuote, setQuoteRefURL } = quoteSlice.actions;