import { ReactElement, useEffect, useState } from 'react'
import './SelectTruck.scss'
import { ErrorMessage, InputContainer, Label, SelectImput } from '../../../../UI/CustomElement'
import TypedInput from '../TypedInput/TypedInput'
import { ISelectTruck } from 'interfaces/pouch-redux'
import { GetTrailerAllYears, GetTrailerMakeByYear } from 'services/instanda'
import { DeductTCOLL, DeductTCOMP, DeductTSA } from 'utils/joinQuoteSelect'
import { validateError } from 'utils/validations'

const SelectTruck = ({
  inputIndex = 1,
  inputLabel = '',
  placeholder = '',
  isAdjust,
  isDisabled,
  formikForm,
  state,
  hasErrors,
  setVerifyErrors
}: ISelectTruck): ReactElement => {

  const enableStatedAmount = state === 'GA' || state === 'TX' || state === 'TN' || state === 'IL' || state === 'AZ' ? true : false;
  const [yearList, setYearList] = useState([])
  const [makeList, setMakeList] = useState([])
  const [errors, setErrors] = useState(false)

  const setYearFunction = (year: string): void => {
    GetTrailerMakeByYear(year)
      .then((resYear) => setMakeList(resYear.data.result))
      .catch((errYear) => console.log({ errYear }))
  }

  useEffect(() => {
    async function getAllYears(): Promise<void> {
      const responseYears = await GetTrailerAllYears()
      setYearList(responseYears.data.result.sort().reverse());
      if (formikForm.values[inputIndex].TrailerMake_TXT != null) { // If make is already filled get make list based on year
        setYearFunction(formikForm.values[inputIndex].TrailerYear_TXT)
      }
    }
    getAllYears().catch((initialError) => console.log({ initialError }))
  }, [])

  const setYear = (year: string): void => {
    formikForm.setFieldValue(`[${inputIndex}].TrailerYear_TXT`, year)
    formikForm.setFieldValue(`[${inputIndex}].TrailerMake_TXT`, '') // Make needs to be cleared because current may not be valid for new year
    setYearFunction(year)
  }

  const setMake = (make: string): void => {
    formikForm.setFieldValue(`[${inputIndex}].TrailerMake_TXT`, make)
  }

  const setTrailerVin = (vin: string): void => {
    formikForm.setFieldValue(`[${inputIndex}].TrailerVIN_TXT`, vin)
  }

  const setStatedAmount = (value: string): void => {
    formikForm.setFieldValue(`[${inputIndex}].TrailerStatedAmount_CHOICE`, value)
  }
  const currentErrors: any = formikForm.errors[inputIndex]

  useEffect(() => {
    if (currentErrors?.TrailerVIN_TXT !== '') {
      setErrors(true)
    }
  }, [currentErrors])

  return (
    <>
      <div className='row'>
        <div className={`col-12`} style={{ position: 'relative' }}>          
          <Label>VIN</Label>
          <TypedInput
            className={`search-input ${formikForm.errors[inputIndex] ? 'border-red-vin': ''}`}
            inputName='TrailerVIN_TXT'
            inputPlaceholder='Vin (optional)'
            setVerifyErrors={setVerifyErrors}
            inputValue={`[${inputIndex}].TrailerVIN_TXT`}
            hasErrors={inputIndex && formikForm.errors[inputIndex] && formikForm.errors[inputIndex].TrailerVIN_TXT ? validateError(formikForm.errors[inputIndex].TrailerVIN_TXT) : false}
            formikForm={formikForm}
            disabled={isDisabled}
          />
          {formikForm.errors[inputIndex] && formikForm.errors[inputIndex].TrailerVIN_TXT && validateError(formikForm.errors[inputIndex].TrailerVIN_TXT) && (
              <ErrorMessage>{formikForm.errors[inputIndex].TrailerVIN_TXT}</ErrorMessage>
            )}
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <Label>Year</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.TrailerYear_TXT ? 'border-red' : ''}
          >
            <SelectImput
              name='TrailerYear_TXT'
              onChange={(event) => setYear(event.target.value)}
              disabled={isDisabled}
              value={formikForm.values[inputIndex].TrailerYear_TXT}
            >
              <option value=''>Select an option...</option>
              {yearList.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}

            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-6'>
          <Label>Make</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.TrailerMake_TXT ? 'border-red' : ''}
          >
            <SelectImput
              name='TrailerMake_TXT'
              onChange={(event) => setMake(event.target.value)}
              disabled={isDisabled}
              value={formikForm.values[inputIndex].TrailerMake_TXT}
            >
              <option value=''>Select an option...</option>
              {makeList.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-6'>
          <Label>Stated Amount</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.TrailerStatedAmount_CHOICE ? 'border-red' : ''}
          >
            <SelectImput
              name='TrailerStatedAmount_CHOICE'
              onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].TrailerStatedAmount_CHOICE`, evt.target.value) }}
              disabled={isDisabled}
              value={formikForm.values[inputIndex].TrailerStatedAmount_CHOICE}
            >
              <option value=''>Select an option...</option>
              {DeductTSA.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-6'>
          <Label>Collision Deductible</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.TrailerCollisionDeductible_CHOICE ? 'border-red' : ''}
          >
            <SelectImput
              name='TrailerCollisionDeductible_CHOICE'
              onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].TrailerCollisionDeductible_CHOICE`, evt.target.value) }}
              disabled={isDisabled}
              value={formikForm.values[inputIndex].TrailerCollisionDeductible_CHOICE}
            >
              <option value=''>Select an option...</option>
              {DeductTCOLL.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-6'>
          <Label>Comprehensive Deductible</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.TrailerComprehensiveDeductible_CHOICE ? 'border-red' : ''}
          >
            <SelectImput
              name='TrailerComprehensiveDeductible_CHOICE'
              onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].TrailerComprehensiveDeductible_CHOICE`, evt.target.value) }}
              disabled={isDisabled}
              value={formikForm.values[inputIndex].TrailerComprehensiveDeductible_CHOICE}
            >
              <option value=''>Select an option...</option>
              {DeductTCOMP.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        {enableStatedAmount ? (
          <div className='col-6'>
            {currentErrors ? (
              <label className='labelError'>
                {currentErrors.TrailerStatedAmount_CHOICE}
              </label>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default SelectTruck