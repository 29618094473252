interface quoteValuesChargerBee {
    startDate: string
    daysInPolicy: number
    first_name: string
    last_name: string
    email: string
    line1: string
    city: string
    state: string
    zip: string
    country: string
    PolicyEffective_DATE: string,    
    PayPlan_CHOICE: string,
    price: number,
    policy_number: string,
    AgentGroupName: string,
    SalesPersonEmail: string,
    phone: string,
    QuoteRef: string,
    parentId: string
}

export const getQuoteValuesChargerBee = (quote: any): quoteValuesChargerBee => ({
    QuoteRef: quote?.QuoteRef,
    startDate: quote?.PolicyEffective_DATE,
    daysInPolicy: quote?.DaysInPolicyYear_NUM,
    first_name: quote?.ContactFirstName_TXT,
    last_name: quote?.ContactLastName_TXT,
    email: quote?.ContactEmail_TXT,
    line1: quote?.BusinessAddressLine1_TXT,
    city: quote?.BusinessCity_TXT,
    state: quote?.BusinessState_TXT,
    zip: quote?.BusinessZip5_TXT,
    country: quote?.BusinessCountry_TXT,
    PolicyEffective_DATE: quote?.PolicyEffective_DATE,    
    price: quote?.FinalPremiumAnnualInclTaxes_NUM,
    PayPlan_CHOICE: quote?.PayPlan_CHOICE ? quote?.PayPlan_CHOICE : quote?.PayPlan_CHOICE_Default,
    policy_number: quote?.PouchPolicyNumber_TXT,    
    AgentGroupName: quote?.AgentGroupName,
    SalesPersonEmail: quote?.External_AgentGroupEmail_Default,
    phone: quote?.ContactPhone_TXT,
    parentId: quote?.AgentGroupRefID
})

