import { useState, ReactElement, useEffect } from 'react'
import { FormControl, MenuItem, Select, FormHelperText } from '@material-ui/core'
import './QuoteSelect.scss'
import { get, set } from 'lodash'
import { IQuote } from 'interfaces/pouch-redux'
import { getInfoQuote, updateQuotePouch } from 'redux/slices/quote';
import { dispatchApp } from 'redux/store'
import { useAppSelector } from 'hooks/useAppSelector'

export default function QuoteSelect({
  property,
  options,
  image,
  title,
  disabled = false,
  showError = false,
  errorMessage,
  className,
  validateField,
  isAdjust,
  isDisabled,
  isHired,
  setIsHired,
  isEmployerNOL,
  setIsEmployerNOL,
  policyValues = {},
  setPolicyValues,
  isSending
}: IQuote): ReactElement {
  const [selectValue, setSelectValue] = useState('');

  const handleChange = (event: any): void => {
    console.log(policyValues)
    // Change policy with new value
    if (property === 'HiredAuto_YNP') {
      event.target.value === 'Yes' ? setIsHired?.(true) : setIsHired?.(false)
      console.log(property, get(policyValues, property))
      console.log(isHired)
    }
    if (property === 'EmployersNonOwnershipLiability_YN') {
      event.target.value === 'Yes' ? setIsEmployerNOL?.(true) : setIsEmployerNOL?.(false)
      console.log(property, get(policyValues, property))
      console.log(isEmployerNOL)
    }
    setSelectValue(event.target.value);
    setPolicyValues?.(property, event.target.value)
    // Show update button instead of buy now button
    // quoteContext.setNeedsUpdate(true)
  }
  
  useEffect( ()=>{    
    console.log(policyValues)
    console.log('QuoteSelectGet', policyValues[property] , property)
    setSelectValue(( policyValues[property] || undefined) ?? '');
  }, [ policyValues ])

  return (
    <div className='main'>
      <div className='flex1'>
        <img className='image' src={image} alt="" />
        <div className='title'>
          <div>{title}</div>
        </div>
        {/* <Tooltip title='Lorem ipsum dolor' className={classes.question}>
          <HelpIcon />
        </Tooltip> */}
      </div>
      <div className='flex2'>
        {/* <p>{selectValue} - {usableProp}</p> */}
        <FormControl variant='outlined' size='small' className='select' disabled={disabled} error={showError}>
          <Select
            className={className}
            disabled={isDisabled}
            value={ selectValue } 
            onChange={(event: any) => {
              handleChange(event)
              if (validateField != null) validateField()
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              getContentAnchorEl: null
            }}
          >
            {
              options?.map((value, index) => (
                <MenuItem key={index} value={value}>{value}</MenuItem>
              ))
            }
          </Select>
          <FormHelperText className='errorMessage'>
            {
              errorMessage && errorMessage
            }
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}
