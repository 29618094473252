import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import { getBrandByAgentGroup, getBrandBySalesName } from 'utils/configBrands'
import { goToDashboard } from 'utils/functions'
import stepperPouch from '../../components/StepperContainer/assets/stepper-pouch.svg'
import './Navbar.scss'
const Navbar = () => {
  const { quote } = useAppSelector(state=> state.pouch);  
  return (
    <nav>
        <img className='nav-img' src={stepperPouch} alt="pouch-logo" onClick={ () => goToDashboard() }></img>
        {
          
          quote?.AgentGroupName && getBrandByAgentGroup(quote?.AgentGroupName) ?
          <img className='nav-img' src={getBrandByAgentGroup(quote?.AgentGroupName)?.logo} alt="pouch-logo" onClick={ () => goToDashboard() }></img>
          :
          <></>
        }
    </nav>
  )
}

export default Navbar