import { useHistory } from 'react-router-dom'
import { FC, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core'

const useStyles = makeStyles({
  title: {
    textAlign: 'left',
    '& *': {
      fontSize: '25px'
    }
  },
  content: {
    textAlign: 'left',
    paddingBottom: '25px'
  },
  niceButton: {
    color: '#fff',
    backgroundColor: '#32CD32',
    '&:hover': {
      backgroundColor: '#32CD32'
    }
  },
  wrapperButton: {
    paddingTop: '30px',
    textAlign: 'center'
  }
})

export interface IOpenProps {
  title: string | null
  content: string | null
  isSuccess: boolean
}

interface IStandaloneModal {
  openStandalone: IOpenProps
  setOpenStandalone: Dispatch<SetStateAction<IOpenProps>>
  email?: string
  callBindExistingQuoteService?: () => void
  transactionNumber?: string
  hideFirstMessage?: boolean
}

const StandaloneModal: FC<IStandaloneModal> = ({ openStandalone, setOpenStandalone, email = '', callBindExistingQuoteService, transactionNumber = '', hideFirstMessage = false }) => {
  const classes = useStyles()
  const history = useHistory()

  const goClose = (): void => {
    setOpenStandalone({
      title: null,
      content: null,
      isSuccess: false
    })
  }

  const goIndex = (): void => {
    history.push('/home')
  }

  const goCongrats = (): void => {
    if (callBindExistingQuoteService != null) {
      callBindExistingQuoteService()
    }
  }

  const disableClose = (): void => {
    console.log('disable close standalone modal')
  }

  const isOpenned = openStandalone.title !== null

  const backgroundStyle = openStandalone.isSuccess ? {} : { backgroundColor: '#FFEDED', color: '#D41515' }

  return (
    <Dialog onClose={disableClose} open={isOpenned} maxWidth='xs' disableEscapeKeyDown>
      <DialogTitle className={classes.title} style={backgroundStyle}>
        {openStandalone.title}
      </DialogTitle>
      <DialogContent className={classes.content} style={backgroundStyle}>
        <div>
          {
            openStandalone.content ?? (
              <span>
                {hideFirstMessage ? null : <div>Thank you, your payment has been successful</div>}
                {email !== '' ? <div>A confirmation email has been sent to <b>{email}</b></div> : null}
                {transactionNumber !== '' ? <div><br />Your transaction number is <b>{transactionNumber}</b></div> : null}
              </span>
            )
          }
        </div>
        <div className={classes.wrapperButton}>
          {
            openStandalone.isSuccess
              ? callBindExistingQuoteService != null
                ? (
                  <Button className={classes.niceButton} variant='contained' onClick={goCongrats}>Close</Button>
                  )
                : (
                  <Button className={classes.niceButton} variant='contained' onClick={goIndex}>Go to home</Button>
                  )
              : (
                <Button variant='contained' color='secondary' onClick={goClose}>Close</Button>
                )
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default StandaloneModal
