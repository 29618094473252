import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { Grid } from '@material-ui/core'
import { useState, useEffect, ReactElement } from 'react'
// Components
import Title from 'components/Title'
import TypedInput from 'components/TypedInput'
// Utils
import errorHandler, { wasDeclined } from 'utils/errorHandler'
import { ContactBussinesSchema } from 'utils/validations'
// Context
import { useFormContext } from 'context/FormContext'
// Services
import { usStates, signOptions, payPlanOptions, payPlanOptionsGA } from 'services/instadaModel'
import { GetLocalQuoteData, UpdateQuoteData, sendSignEmail, isAGENT, getSalesPerson, API, getQuoteMultiItems } from 'services/instanda'
// Hooks
import useMixpanelTrack from 'hooks/useMixpanelTrack'
import { getSalesNamesofBrands } from 'utils/configBrands'
import { VerifyVIN } from './components/VerifyVIN'

export default function Page5 (): ReactElement {
  const [loading, setLoading] = useState(true)
  const [isFirstSubmit, setIsFirstSubmit] = useState(true)
  const [verifiedVINs, setVerifiedVIN] = useState([])
  const [policyHasTelematics, setPolicyHasTelematics] = useState(false)
  const [payPlan, setPayPlan] = useState('')
  const [vehicles, setVehicles] = useState<any>([])
  useMixpanelTrack('bind1-tracking')
  const formContext = useFormContext()
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => formContext.setForm(formikForm), [])

  const VehiclesInitialValues = {
    VehicleVINOverride_TXT: '',
    VehicleMake_TXT: '',
    VehicleModel_TXT: '',
    VehicleYear_NUM : '',
    VehiclePolicyParticipationTelematics_YN: 'No'
  }

  const DriversInitialValues = {
    DriverFullName_TXT: '',
    DriverLicenseStateOverride_CHOICE: '',
    DriverLicenseOverride_TXT: ''
  }

  useEffect(() => {
    const fullQuote = JSON.parse(localStorage.getItem('instandaFullQuote') as string)
    const telematics = fullQuote?.Vehicles?.map((vehicle: any) => vehicle.VehiclePolicyParticipationTelematics_YN)
    setPayPlan(fullQuote.PayPlan_CHOICE)
    const newPolicy = {
      PayPlan_CHOICE: fullQuote.PayPlan_CHOICE,
      PayPlanSelected_TXT: fullQuote.PayPlanSelected_TXT,
      PayPlan_CHOICE_Default: fullQuote.PayPlan_CHOICE_Defaul
    }
    UpdateQuoteData(newPolicy) // Updating PayPlan_CHOICE selected on page 4
      .then((res) => {
        console.log('PayPlan_CHOICE', fullQuote.PayPlan_CHOICE)
      })
      .catch(resErr => {
        errorHandler(resErr, enqueueSnackbar)
      })
    if (telematics.includes('Yes') === true) {
      setPolicyHasTelematics(true)
    }
  }, [])

  useEffect(() => {
    const fullQuote = JSON.parse(localStorage.getItem('instandaFullQuote') as string)
    setVehicles(fullQuote.Vehicles)
    formContext.setProgress(1)
    GetLocalQuoteData()
      .then((localRes) => {
        setLoading(false)
        const result = localRes?.data ?? {}
        const driversCount = localRes.data?.Drivers_Count;
        const vehiclesCount = localRes.data?.Vehicles_Count;
        formikForm
          .setValues({
            ...formikForm.values,
            ...result,
            PolicyEffective_DATE: result?.PolicyEffective_DATE,
            PayPlan_CHOICE: fullQuote.PayPlan_CHOICE,
            ChargeBeeSignOption_TXT: isAGENT() && result?.ChargeBeeSignOption_TXT === 'Sign now' ? 'Sign later' : result?.ChargeBeeSignOption_TXT,
            Drivers: result?.Drivers.slice(0,driversCount).map((current: any) => {
              const driverName = String(current.DriverFullName_TXT ?? '')
              const driverLicenseStateOverride_CHOICE = current.DriverLicenseStateOverride_CHOICE ?? current.DriverLicenseStateOverride_CHOICE_Default
              const driverLicenseOverride_TXT = current.DriverLicenseOverride_TXT ?? current.DriverLicenseOverride_TXT_Default
              return {
                ...DriversInitialValues,
                ...current,
                DriverFullName_TXT: driverName,
                DriverLicenseStateOverride_CHOICE: driverLicenseStateOverride_CHOICE,
                DriverLicenseOverride_TXT: driverLicenseOverride_TXT
              }
            }) ?? [DriversInitialValues],
            VehiclePolicyParticipationTelematics_YN: result?.VehiclePolicyParticipationTelematics_YN ?? 'No',
            Vehicles: result?.Vehicles.slice(0,vehiclesCount).map((current: any) => {
              const vehicleVIN = current.VehicleVINOverride_TXT ?? current.VehicleVIN_TXT_Default
              return {
                ...VehiclesInitialValues,
                ...current,
                ...vehicles,
                isFindByVIN: current.VehicleVIN_TXT_Default &&  current.VehicleVIN_TXT_Default != 'not provided'  ? true : false,
                VehicleVINOverride_TXT: vehicleVIN,
                VehicleOverrideDeductCOLL_CHOICE: current.VehicleOverrideDeductCOLL_CHOICE,
                VehicleOverrideDeductCOMP_CHOICE: current.VehicleOverrideDeductCOMP_CHOICE,
                VehicleOverrideLimitUMPD_IL_CHOICE: current.VehicleOverrideLimitUMPD_IL_CHOICE
              }
            }) ?? [VehiclesInitialValues]
          })
          .catch((formErrorCommon) => {
            console.log({ formErrorCommon })
          })
      })
      .catch((localErr) => {
        setLoading(false)
        console.log({ localErr })
      })
  }, [])

  const validateForm = async (): Promise<void> => {
    const validateForm = await formikForm.validateForm()
    if (isEmpty(validateForm)) {
      if (isAGENT()) {
        if (formikForm.values.PayPlan_CHOICE === 'Paid in Full.') {
          formikForm.values.PayPlan_CHOICE = 'Paid in Full'
        }
      }
      console.log(formikForm.values.PayPlan_CHOICE)
      goSubmitInformation(isFirstSubmit)
    } else {
      console.log('some form has error', formikForm.errors, formikForm.isValid, validateForm)
    }
  }

  const getDataToSubmit = (): any => {
    const vehiclesData = {
      ...formikForm.values.Vehicles,
      ...vehicles?.vehicles
    }
    console.log('VehicleData: ', vehiclesData)
    // setVehicles([...formikForm.values.Vehicles, vehicles])
    formikForm.setValues({...formikForm.values, ...vehicles})
    const Drivers = formikForm.values.Drivers.map((Driver) => {
      const DriversFullNameArr = Driver?.DriverFullName_TXT.split(' ')
      const firstName = DriversFullNameArr[0] !== undefined ? DriversFullNameArr[0] : ''
      const lastName = DriversFullNameArr[1] !== undefined ? DriversFullNameArr[1] : ''
      return {
        ...Driver,
        DriverFirstName_TXT: firstName,
        DriverLastName_TXT: lastName,
      }
    })
    console.log()
    const dataToSend = {
      ...formikForm.values,
      Drivers,
      VehiclePolicyParticipationTelematics_YN: policyHasTelematics ? 'Yes' : 'No',
      PayPlan_CHOICE: payPlan
     }
    console.log({ dataToSend, formikForm: formikForm.values, Drivers })
    console.log(payPlan)
    return dataToSend
  }

  const goSubmitInformation = async (firstSubmitBoolean: boolean): Promise<void> => {
    const areValidVINs = await validateVINs();
    if(areValidVINs){
      if (firstSubmitBoolean) {
        formContext.setLoading(true)
        const dataToSend = getDataToSubmit()
        UpdateQuoteData(dataToSend)
          .then((res) => {
            formContext.setLoading(false)
            if (wasDeclined(res?.data, formContext.setErrorModal)) return
            setIsFirstSubmit(false)
            goSubmitDifferent()
          })
          .catch((resErr) => {
            formContext.setLoading(false)
            errorHandler(resErr, enqueueSnackbar)
          })
      } else {
        goSubmitDifferent()
      }
    }
  }

  const goSubmitDifferent = (): void => {
    if (!formikForm.values.SignEmailSent && formikForm.values.ChargeBeeSignOption_TXT !== 'Sign now') {
      console.log('send email or reminder')
      formContext.setLoading(true)
      sendSignEmail()
        .then(() => {
          formContext.setLoading(false)
          formikForm.setFieldValue('SignEmailSent', true).catch((changeSignBoolean) => console.log({ changeSignBoolean }))
          if (formikForm.values.ChargeBeeSignOption_TXT === 'Sign later') {
            formContext.goNextPage()
          }
        })
        .catch((localErr) => console.log({ localErr }))
    } else if (formContext.orangeBtnText != null) {
      console.log('get quote to see if its updated')
      GetLocalQuoteData()
        .then((localRes) => {
          const result = localRes?.data ?? {}
          if (result?.SignEmailWasSigned === true) {
            formikForm
              .setValues({
                ...formikForm.values,
                ChargeBeeSignOption_TXT: isAGENT() && result?.ChargeBeeSignOption_TXT === 'Sign now' ? 'Sign later' : result?.ChargeBeeSignOption_TXT,
                SignDocs_CONF: 'Confirmed',
                ProceedNoSign_CONF: 'Confirmed',
                HelloSign_DocumentsSigned_YN: 'Yes'
              })
              .catch((formErrorCommon) => {
                console.log({ formErrorCommon })
                formContext.setLoading(false)
              })
          } else {
            console.log()
          }
        })
        .catch((localErr) => console.log({ localErr }))
    } else {
      formContext.goNextPage()
    }
  }

  const formikForm = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      SignEmailSent: false,
      ChargeBeeSignOption_TXT: isAGENT() ? 'Sign later' : 'Sign now',
      ContactTitle_TXT: 'Not provided',
      Drivers: [DriversInitialValues],
      Vehicles: [VehiclesInitialValues],
      // HelloSign
      SignDocs_CONF: 'Unconfirmed',
      ProceedNoSign_CONF: 'Unconfirmed',
      HelloSign_DocumentsSigned_YN: 'No',
      PayPlan_CHOICE: payPlan
    },
    validationSchema: ContactBussinesSchema,
    onSubmit: validateForm
  })

  useEffect(() => {
    if (formikForm.values.ChargeBeeSignOption_TXT === 'Send signature request' && formikForm.values.HelloSign_DocumentsSigned_YN === 'No') {
      formContext.setOrangeBtnText('Update')
    } else {
      formContext.setOrangeBtnText(null)
    }
  }, [formikForm.values.ChargeBeeSignOption_TXT, formikForm.values.HelloSign_DocumentsSigned_YN])

  const validateVINs = async () => {
    console.log(formikForm.values.Vehicles)
    const response = await getQuoteMultiItems(formContext.quote.QuoteRef)
    const result = await response.data.data.response
    const vehicleData = result.Vehicles
    const vins:any = []
    let index = 0
    for(const vehicle of formikForm.values.Vehicles){
      const isVinAlready = vehicleData.some((vehicleD: any) => vehicleD.VehicleVINOverride_TXT === vehicle.VehicleVINOverride_TXT )
      console.log(isVinAlready, vehicle.VehicleVINOverride_TXT)
      try{
        if (!isVinAlready) {
          const vehicleDecoder = await API.post('/polkServices/vinDecoderTen', { vin: vehicle.VehicleVINOverride_TXT }, { headers: { 'Content-Type': 'application/json' } })
          if( vehicleDecoder.data.year != 0
            && (vehicle.VehicleMake_TXT != vehicleDecoder.data.make
            || vehicle.VehicleModel_TXT != vehicleDecoder.data.model
            || vehicle.VehicleYear_NUM != vehicleDecoder.data.year)){
              const vehicleVIN = {
                index,
                VIN: vehicle.VehicleVINOverride_TXT,
                userData: {
                  make: vehicle.VehicleMake_TXT,
                  model: vehicle.VehicleModel_TXT,
                  year: vehicle.VehicleYear_NUM
                },
                realData: {
                  make: vehicleDecoder.data.make,
                  model: vehicleDecoder.data.model,
                  year: vehicleDecoder.data.year,
                }
              }
              vins.push(vehicleVIN)
            }
          console.log(index,vehicleDecoder)
        }
      }catch(error){
        console.log(error,vehicle)
      }
      index++
    }
    setVerifiedVIN(vins)
    console.log("error:",vins)
    return vins.length == 0;
  }

  const backModal = () => {
    setVerifiedVIN([])
  }

  const continueModal = () => {
    verifiedVINs.forEach((vehicle: any) => {
      const formVehicle = formikForm.values.Vehicles[vehicle.index];
      formVehicle.VehicleMake_TXT = vehicle.realData.make
      formVehicle.VehicleModel_TXT = vehicle.realData.model
      formVehicle.VehicleYear_NUM = vehicle.realData.year
    })
    console.log(formikForm.values.Vehicles)
    formikForm.handleSubmit()
  }

  return (
    <div className='container'>
      {
        !loading
          ? (
            <>
              <div className='row'>
                <div className='col-12 mt-10'>
                  <Title>A Couple More Details</Title>
                </div>
                <form onSubmit={formikForm.handleSubmit}>
                  <Grid container spacing={3}>
                    {
                    formikForm.values.Vehicles.map((item: any, index: number) => {
                      const value = `Vehicles[${index}].VehicleVINOverride_TXT`
                      const placeholder = `${String(item.VehicleMake_TXT ?? '')} ${String(item.VehicleModel_TXT ?? '')} ${String(item.VehicleYear_NUM ?? '')} VIN here`
                      return (
                        <Grid item xs={12} sm={12} md={index === 1 ? true : 6} key={index}>
                          <TypedInput
                            formikForm={formikForm}
                            inputTitle={`Vehicle ${index + 1} VIN`}
                            inputValue={value}
                            inputPlaceholder={placeholder}
                            inputName='VehicleVINOverride_TXT'
                            onMouseEnter={formContext.changeIndication}
                            onMouseLeave={formContext.changeIndication}
                            disabled={item.isFindByVIN && item.VehicleVINOverride_TXT != 'not provided'}
                          />
                        </Grid>
                      )
                    })
                  }
                  </Grid>
                  {
                  formikForm.values.Drivers.map((_: any, index: number) => {
                    return (
                      <div className='row' key={index}>
                        <div className='col-12 col-md-6'>
                          <TypedInput formikForm={formikForm} inputTitle="Driver's Name" inputValue={`Drivers[${index}].DriverFullName_TXT`} />
                        </div>
                        <div className='col-sm'>
                          <TypedInput formikForm={formikForm} inputTitle='DL State' inputValue={`Drivers[${index}].DriverLicenseStateOverride_CHOICE`} inputOptions={usStates.map(state => { return { label: state, value: state } })} />
                        </div>
                        <div className='col-sm'>
                          <TypedInput formikForm={formikForm} inputTitle='DL Number' inputValue={`Drivers[${index}].DriverLicenseOverride_TXT`} inputPlaceholder='DL Number' />
                        </div>
                      </div>
                    )
                  })
                }
                {
                  isAGENT() && (
                    <>
                      <div className='row'>
                        <div className='col-12 mt-10'>
                          <Title>Signature Options</Title>
                        </div>
                        <div className='col-12'>
                          <TypedInput formikForm={formikForm} inputTitle='How do you want to sign' inputValue='ChargeBeeSignOption_TXT' inputOptions={signOptions} noEmptyDropdown />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 mt-10'>
                          <Title>Payment Options</Title>
                        </div>
                        <div className='col-12'>
                          <TypedInput inputType='select' formikForm={formikForm} inputTitle='How do you want to pay' inputValue='PayPlan_CHOICE' inputOptions={formContext.quote?.BusinessState_TXT === 'GA' ? payPlanOptionsGA : payPlanOptions} noEmptyDropdown />
                        </div>
                      </div>
                    </>
                  )
                }
                </form>
              </div>
              <VerifyVIN verifiedVINs={verifiedVINs} open={verifiedVINs.length > 0}  back={backModal} next={continueModal}/>
            </>
            )
          : <div>loading...</div>
      }
    </div>
  )
}
