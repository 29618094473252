import './GoodieNote.scss'
import { InfoOutlined } from '@material-ui/icons';
import { IGoodieNote } from 'interfaces/pouch-redux';
const GoodieNote = ({error, title, children}: IGoodieNote) => {
  return (
      <div className='goddie-card'>
           <InfoOutlined style={{color: error ? '#FF6C1A': '#39C7C2', fontSize: '2rem'}} />
            <strong>{title}</strong>
            {children}
      </div>
  )
}

export default GoodieNote