import React from 'react'
import Logo from '../../../components/StepperContainer/assets/stepper-pouch.svg'
import '../styles/Header.css'

const Header: React.FC = () => {
  return (
    <div className='sapm-header'>
      <div>
        <img src={Logo} alt='header-logo' />
      </div>
      <div className='sapm-header-title'>
        <div>Make a Payment</div>
        <div>Please enter your payment information below</div>
      </div>
    </div>
  )
}

export default Header
