import { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './PremiumCard.scss'
import { IPremiumCard } from 'interfaces/pouch-redux';

const PremiumCard = ({ title, PremiumAmount, PremiumMonthlyAmount, FinalPremiumAmount, isCurrent = false, isRefund = false, className = '' }: IPremiumCard): ReactElement => {
    return (
        <Card sx={{ minWidth: 275 }} className={`custom-card ${className} ${isCurrent ? 'current' : ''}`}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color='text.secondary' className='text-center'>
                    {title}
                </Typography>
                <div className='container text-center'>
                    <div className='row mt-10'>
                        <div className='col-6 col-lg-12'>
                            <Typography variant='h5' component='div' className='amount'>
                                {'$'}{PremiumAmount}
                            </Typography>
                            <span>Paid in Full</span>
                        </div>
                        <div className='col-6 col-lg-12'>
                            <Typography variant='h5' component='div' className='amount'>
                                {'$'}{PremiumMonthlyAmount}
                            </Typography>
                            <span>Monthly</span>
                            <br />
                            <span>*$6 installment surcharge to be included for all payments after down payment.</span>
                        </div>
                        {!isCurrent ? (
                                <>
                                    <span>Effective from date of change</span>
                                    <Typography variant='h5' component='div' className='amount'>
                                        {'$'}{FinalPremiumAmount}
                                    </Typography>
                                    <span>{isRefund ? 'Refund': 'Due Today'}</span>
                                </>
                            ):
                            (
                                <></>
                            )
                        }
                    </div>
                </div>

            </CardContent>
        </Card>
    )
}

export default PremiumCard