export const model = {
  ContactEmail_TXT: '',
  ContactTitle_TXT: 'Mr',
  ContactFirstName_TXT: '',
  ContactLastName_TXT: '',
  ContactPhone_TXT: '',
  BusinessNameDBA_TXT: '',
  BusinessAddressLine1_TXT: '',
  BusinessAddressLine2_TXT: '',
  BusinessCity_TXT: '',
  BusinessState_TXT: '',
  BusinessZip_TXT: '',
  BusinessSubClass_CHOICE: '',
  policyType: 'commercial',
  numEmployees: '',
  VehiclePolicyParticipationTelematics_YN: 'No',
  VehicleOverrideLimitUMPDRatingInput_TXT: 'IL|No Coverage'
}

export const personTitle = [
  // { value: 'Not provided', label: 'Not provided' },
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Dr', label: 'Dr' }
]

export const signOptions = [

  { value: 'Sign later', label: 'Print and sign at agency' },
  { value: 'Send signature request', label: 'Email to user to sign electronically' }
]

export const payPlanOptions = [

  { value: 'Monthly - Recurring Credit Card', label: 'Monthly Recurring CC' },
  // { value: 'Monthly - Recurring EFT', label: 'Monthly Recurring ACH' },
  { value: 'Offline Monthly', label: 'Monthly Recurring Offline' },
  { value: 'Paid in Full', label: 'Paid in full CC' },
  // { value: 'Paid in Full.', label: 'Paid in full ACH' },
  { value: 'Offline Paid in Full', label: 'Paid in full Offline' }
]
export const payPlanOptionsGA = [
  { value: 'Paid in Full', label: 'Paid in full CC' },
  { value: 'Offline Paid in Full', label: 'Paid in full Offline' }
]

export const supportedStates = [
  { value: 'IL', label: 'Illinois' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'GA', label: 'Georgia' },
  { value: 'TX', label: 'Texas' },
  { value: 'TN', label: 'Tennessee' }
]

export const usStatesFull = [
  { abbr: 'AK', name: 'Alaska' },
  { abbr: 'AL', name: 'Alabama' },
  { abbr: 'AR', name: 'Arkansas' },
  { abbr: 'AS', name: 'American Samoa' },
  { abbr: 'AZ', name: 'Arizona' },
  { abbr: 'CA', name: 'California' },
  { abbr: 'CO', name: 'Colorado' },
  { abbr: 'CT', name: 'Connecticut' },
  { abbr: 'DC', name: 'District of Columbia' },
  { abbr: 'DE', name: 'Delaware' },
  { abbr: 'FL', name: 'Florida' },
  { abbr: 'GA', name: 'Georgia' },
  { abbr: 'GU', name: 'Guam' },
  { abbr: 'HI', name: 'Hawaii' },
  { abbr: 'IA', name: 'Iowa' },
  { abbr: 'ID', name: 'Idaho' },
  { abbr: 'IL', name: 'Illinois' },
  { abbr: 'IN', name: 'Indiana' },
  { abbr: 'KS', name: 'Kansas' },
  { abbr: 'KY', name: 'Kentucky' },
  { abbr: 'LA', name: 'Louisiana' },
  { abbr: 'MA', name: 'Massachusetts' },
  { abbr: 'MD', name: 'Maryland' },
  { abbr: 'ME', name: 'Maine' },
  { abbr: 'MI', name: 'Michigan' },
  { abbr: 'MN', name: 'Minnesota' },
  { abbr: 'MO', name: 'Missouri' },
  { abbr: 'MS', name: 'Mississippi' },
  { abbr: 'MT', name: 'Montana' },
  { abbr: 'NC', name: 'North Carolina' },
  { abbr: 'ND', name: 'North Dakota' },
  { abbr: 'NE', name: 'Nebraska' },
  { abbr: 'NH', name: 'New Hampshire' },
  { abbr: 'NJ', name: 'New Jersey' },
  { abbr: 'NM', name: 'New Mexico' },
  { abbr: 'NV', name: 'Nevada' },
  { abbr: 'NY', name: 'New York' },
  { abbr: 'OH', name: 'Ohio' },
  { abbr: 'OK', name: 'Oklahoma' },
  { abbr: 'OR', name: 'Oregon' },
  { abbr: 'PA', name: 'Pennsylvania' },
  { abbr: 'PR', name: 'Puerto Rico' },
  { abbr: 'RI', name: 'Rhode Island' },
  { abbr: 'SC', name: 'South Carolina' },
  { abbr: 'SD', name: 'South Dakota' },
  { abbr: 'TN', name: 'Tennessee' },
  { abbr: 'TX', name: 'Texas' },
  { abbr: 'UT', name: 'Utah' },
  { abbr: 'VA', name: 'Virginia' },
  { abbr: 'VI', name: 'Virgin Islands' },
  { abbr: 'VT', name: 'Vermont' },
  { abbr: 'WA', name: 'Washington' },
  { abbr: 'WI', name: 'Wisconsin' },
  { abbr: 'WV', name: 'West Virginia' },
  { abbr: 'WY', name: 'Wyoming' },
  { abbr: 'International', name: 'International' }
]

export const licenseValidation = {
  AL: {
    regex: [/^[0-9]{7}$/],
    description: "Alabama driver's license numbers are seven numbers. For example, 6996164."
  },
  AK: {
    regex: [/^[0-9]{7}$/],
    description: "Alaska driver's license numbers are seven numbers. For example, 6244114."
  },
  AZ: {
    regex: [/^[0-9]{9}$/, /^[A-Za-z]{1}[0-9]{8}$/],
    description: "Arizona driver's license numbers are a nine numbers. For example, 113654424. Arizona driver's license numbers may also consist of one letter and eight numbers. For example, B13654424."
  },
  AR: {
    regex: [/^[9]{1}[0-9]{8}$/],
    description: "Arkansas driver's license numbers are nine numbers that always begins with 9. For example, 999000680."
  },
  CA: {
    regex: [/^[A-Za-z]{1}[0-9]{7}$/],
    description: "California driver's license numbers consist of one letter and seven numbers. For example, A0002144."
  },
  CO: {
    regex: [/^[0-9]{2}[-][0-9]{3}[-][0-9]{4}$/],
    description: "Colorado driver's license numbers are nine numbers formatted as ##-###-####. For example, 94-334-0101."
  },
  CT: {
    regex: [/^[0-9]{9}$/],
    description: "Connecticut driver's license numbers are nine numbers. For example, 146825129."
  },
  DE: {
    regex: [/^[0-9]{7}$/],
    description: "Delaware driver's license numbers are a seven numbers. For example, 1232805."
  },
  DC: {
    regex: [/^[0-9]{7}$/],
    description: 'Washington, D.C. driver\'s license numbers are a seven numbers. For example, 9992616. '
  },
  FL: {
    regex: [/^[A-Za-z]{1}[0-9]{12}$/, /^[A-Za-z]{1}[0-9]{3}\W[0-9]{3}\W[0-9]{2}\W[0-9]{3}\W[0-9]{1}$/],
    description: 'Florida driver\'s license numbers consist of one letter and 12 numbers, unformatted. For example, G544061739250. Florida drivers license numbers may also consist of one letter and 12 numbers, using the format L##-###-##-###-#. For example, G544-061-73-925-0.'
  },
  GA: {
    regex: [/^[0-9]{9}$/],
    description: "Georgia driver's license numbers are nine numbers. For example, 09999999."
  },
  HI: {
    regex: [/^[A-Za-z]{1}[0-9]{8}$/],
    description: "Hawaii driver's license numbers consist of one letter and eight numbers. For example, H00000002."
  },
  ID: {
    regex: [/^[A-Za-z]{2}[0-9]{6}[A-Za-z]{1}$/],
    description: 'idaho driver\'s license numbers consist of two letters, six numbers, and one letter. for example, aa123456z. '
  },
  IL: {
    regex: [/^[A-Za-z]{0,1}[0-9]{3}[0-9]{4}[0-9]{4}$/],
    description: 'Illinois driver\'s license numbers consist of one letter and 11 numbers formatted as L###########. For example, D40078360001.'
  },
  IN: {
    regex: [/^[0-9]{4}[-][0-9]{2}[-][0-9]{4}$/],
    description: "Indiana driver's license numbers consist of 10 numbers formatted as ####-##-####. For example, 0299-11-6078."
  },
  IA: {
    regex: [/^[0-9]{9}$/, /^[0-9]{3}[A-Za-z]{2,6}[0-9]{4}$/],
    description: 'Iowa driver\'s license numbers are a nine numbers. For example, 123456789. Iowa driver\'s license numbers may also consist of three numbers, two letters, and four letters. For example, 123AB9755.'
  },
  KS: {
    regex: [/^[A-Za-z]{1}[0-9]{2}[-][0-9]{2}[-][0-9]{4}$/],
    description: 'Kansas driver\'s license numbers consist of one letter and eight numbers formatted as L##-##-####. For example, K00-09-7443. '
  },
  KY: {
    regex: [/^[A-Za-z]{1}[0-9]{2}[-][0-9]{3}[-][0-9]{3}$/],
    description: 'Kentucky driver\'s license numbers consist of  one letter and eight numbers formatted as L##-###-###. For example, V12-345-678.'
  },
  LA: {
    regex: [/^[0-9]{9}$/],
    description: 'Louisiana driver\'s license numbers are a nine numbers. For example, 007000100.'
  },
  ME: {
    regex: [/^[0-9]{7}$/],
    description: 'Maine driver\'s license numbers are seven numbers. For example, 0015000.'
  },
  MD: {
    regex: [/^[A-Za-z]{1}[0-9]{12}$/, /^[A-Za-z]{1}[-][0-9]{3}[-][0-9]{3}[-][0-9]{3}[-][0-9]{3}$/],
    description: 'Maryland driver\'s license numbers consist of one letter and 12 numbers, unformatted. For example, S514778616977. They may also consist of one letter and 12 numbers formatted as L-###-###-###-###. For example, S-514-778-616-977.'
  },
  MA: {
    regex: [/^[A-Z]{1}[0-9]{9}$/],
    description: 'Massachusetts driver\'s license numbers consist of one letter and nine numbers. For example, S999888801.'
  },
  MI: {
    regex: [/^[A-Z]{1}[0-9]{12}$/, /^[A-Za-z]{1}[ ][0-9]{3}[ ][0-9]{3}[ ][0-9]{3}[ ][0-9]{3}$/],
    description: 'Michigan driver\'s license numbers consist of one letter and 12 numbers, unformatted. For example, P800000224322. They may also be one letter and 12 numbers formatted as L ### ### ### ###. For example, P 800 000 224 312.'
  },
  MN: {
    regex: [/^[A-Za-z]{1}[0-9]{12}$/],
    description: 'Minnesota driver\'s license numbers consist of one letter and 12 numbers, unformatted. For example, P800000224322.'
  },
  MS: {
    regex: [/^[0-9]{3}[-][0-9]{2}[-][0-9]{4}$/],
    description: 'Mississippi driver\'s license numbers are nine numbers formatted like a Social Security number as ###-##-####. For example, 125-01-2050.'
  },
  MO: {
    regex: [/^[A-Za-z]{1}[0-9]{9}$/],
    description: ' Missouri driver\'s license numbers consist of one letter and nine numbers. For example, F050256006.'
  },
  MT: {
    regex: [/^([0-9]{6}|[0-9]{13})$/],
    description: 'Montana driver\'s license numbers are a nine numbers. For example, 626926253. They also may be 13 digits in length'
  },
  NE: {
    regex: [/^[a-zA-Z]{1}[0-9]{8}$/],
    description: 'Nebraska driver\'s license numbers consist of one letter and eight numbers. For example, A20600249.'
  },
  NV: {
    regex: [/^[0-9]{10}$/],
    description: 'Nevada driver\'s license numbers consist of 10 numbers. For example, 0002102201. '
  },
  NH: {
    regex: [/^[0-9]{10}$/],
    description: 'New Hampshire driver\'s license numbers are 10 numbers.'
  },
  NJ: {
    regex: [/^[a-zA-Z]{1}[0-9]{4}[-][0-9]{5}[-][0-9]{5}$/],
    description: 'New Jersey driver\'s license numbers consist of one letter and 14 numbers formatted as L####-#####-#####. Form example, L4000-2555-00001-10000'
  },
  NM: {
    regex: [/^[0-9]{9}$/],
    description: 'New Mexico driver\'s license numbers are nine numbers. For example, 013696424.'
  },
  NY: {
    regex: [/^[0-9]{3}[ ][0-9]{3}[ ][0-9]{3}$/],
    description: 'New York driver\'s license numbers are nine numbers formatted as ### ### ###. For example, 123 456 789. '
  },
  NC: {
    regex: [/^[0-9]{12}$/],
    description: 'North Carolina driver\'s license numbers are 12 numbers. For example, 8013303159. '
  },
  ND: {
    regex: [/^[a-zA-Z]{3}[-][0-9]{2}[-][0-9]{4}$/],
    description: 'North Dakota driver\'s license numbers are nine numbers: The first 3 letters of the licensee’s last name (if the last name is only 2 letters, the third character will be an “X”), Next 2-digit year of birth Finally 4 digits. Form example, ADX-84-0000.'
  },
  OH: {
    regex: [/^[a-zA-Z]{2}[0-9]{6}$/],
    description: 'Ohio driver\'s license numbers are two letters and six numbers. For example, TL545796.'
  },
  OK: {
    regex: [/^[A-Z]{1}[0-9]{9}$/],
    description: 'Oklahoma driver\'s license numbers consist of one letter and nine numbers. For example, B000062835'
  },
  OR: {
    regex: [/^[0-9]{7}$/],
    description: 'Oregon driver\'s license numbers are a seven numbers. For example, 6110033.'
  },
  PA: {
    regex: [/^[0-9]{2}[ ][0-9]{3}[ ][0-9]{3}$/],
    description: 'Pennsylvania driver\'s license numbers are eight numbers formatted as ## ### ###. For example, 99 900 104.'
  },
  RI: {
    regex: [/^[0-9]{7}$/],
    description: 'Rhode Island driver\'s license numbers are seven numbers. The first two digits are the year the license was issued (2008 is indicated by “28”). For example, 2858889. '
  },
  SC: {
    regex: [/^[0-9]{9}$/],
    description: 'South Carolina driver\'s license numbers are a nine numbers. For example, 100179226.'
  },
  SD: {
    regex: [/^[0-9]{8}$/],
    description: 'South Dakota driver\'s license numbers are eight numbers. For example, 00616775.'
  },
  TN: {
    regex: [/^([0-9]{8}|[0-9]{9})$/],
    description: 'Tennessee driver\'s license numbers can consist of eight or nine numbers. For example, 101915638.'
  },
  TX: {
    regex: [/^[0-9]{8}$/],
    description: 'Texas driver\'s license numbers are eight numbers. For example, 17600550.'
  },
  UT: {
    regex: [/^[0-9]{9}$/],
    description: 'Utah driver\'s license numbers are a nine numbers. For example, 400138831.'
  },
  VT: {
    regex: [/^[0-9]{8}$/, /^[0-9]{7}[a-zA-Z]$/],
    description: 'Vermont driver\'s license numbers are eight numbers. For example, 17600550. They may also consist of seven numbers and one letter. For example, 8205059A.'
  },
  VA: {
    regex: [/^[a-zA-Z]{1}[0-9]{8}$/, /^[a-zA-Z]{1}[0-9]{2}[-][0-9]{2}[-][0-9]{4}$/],
    description: 'Virginia driver\'s license numbers consist of one letter and eight numbers. For example, A20600249. Virginia driver\'s license numbers may also consist of one letter and eight numbers formatted as L##-##-####. For example, T16-70-0185.'
  },
  WA: {
    regex: [/^[a-zA-Z]{3}[*]{2}[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{1}[0-9]$/],
    description: 'Washington driver\'s license numbers use the format: three letters ** two letters, three numbers, one letter, and one number. For example, DOE**MJ501P1. '
  },
  WV: {
    regex: [/^[0-9]{7}$/, /^[a-zA-Z]{1}[0-9]{6}$/],
    description: 'West Virginia driver\'s license numbers are a seven numbers. For example, 1899168. They may also consist of one letter and six numbers. For example, R899168.'
  },
  WI: {
    regex: [/^[a-zA-Z]{1}[0-9]{3}[-][0-9]{4}[-][0-9]{4}[-][0-9]{2}$/],
    description: 'Wisconsin driver\'s license numbers are one letter and 13 numbers formatted as L###-####-####-##. For example, J525-4209-0465-05. '
  },
  WY: {
    regex: [/^[0-9]{6}[-][0-9]{3}$/],
    description: 'Wyoming driver\'s license numbers are nine numbers formatted as ######-###. For example, 050070-003. '
  },
  International: {

  }

}

export const usStates = Object.keys(licenseValidation)
