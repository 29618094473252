import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/styles'
import KoalaFaceIcon from '../../assets/Koala_Face.svg'

const useStyles = makeStyles({
  container: {
    color: '#808080',
    textAlign: 'center',
    paddingTop: '30px',
    paddingBottom: '10px',
    backgroundColor: '#fafafa'
  },
  pouch: {
    width: '80px',
    marginBottom: '20px'
  }
})

export default function Footer (): ReactElement {
  const classes = useStyles()

  return (
    <footer className={classes.container}>
      <img className={classes.pouch} src={KoalaFaceIcon} alt='footer-icon' />
      <p>Pouch Insurance</p>
      <p>7400 N Caldwell Ave, Niles, IL, 60714</p>
      <p>1-844-POUCHIT</p>
    </footer>
  )
}
