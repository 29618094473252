import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import Collapse from '../components/Collapse/Collapse'
import { LocalShipping as CarIcon, CheckCircle, RadioButtonUnchecked } from '@material-ui/icons'
import Title from 'components/Title'
import AddButton from 'views/Join/components/AddButton'
import { TrailerSchemaMTA } from 'utils/validations'
import { Form } from '../../../UI/CustomElement'
import SelectTruck from '../components/SelectTruck/SelectTruck'
import { ISelectTruckProps } from 'interfaces/pouch-redux'
import { generateId } from 'utils/functions'
import { GetLocalQuoteData } from 'services/instanda'
import { useAppSelector } from 'hooks/useAppSelector'
import { useSnackbar } from 'notistack'
import errorHandler from 'utils/errorHandler'
import { dispatchApp } from 'redux/store'
import { getInfoQuote, updateQuotePouch } from 'redux/slices/quote';
import './TrailerInfo.scss'

const trailerGenerator = (addOffset: number): any => {
    return {
        TrailerVIN_TXT: '',
        TrailerYear_TXT: '',
        TrailerMake_TXT: '',
        TrailerUpdate_DATE: Date.now() + addOffset,
        TrailerStatedAmount_CHOICE: '$4,000'
    }
}

const TrailerInfo = ({ isAdjust, verifyErrors, setVerifyErrors, isSending }: any) => {

    const [id, setId] = useState('');
    const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch);
    const { enqueueSnackbar } = useSnackbar();
    const [businessState_TXT, setBusinessState_TXT] = useState('')

    const onSubmit = () => {

    }

    const formikFormTrailers = useFormik<ISelectTruckProps[]>({
        initialValues: [],
        enableReinitialize: true,
        validationSchema: TrailerSchemaMTA,
        onSubmit: onSubmit
    })

    const setValues = async () => {
        const trailerValues = { Trailers: formikFormTrailers.values }
        dispatchApp(updateQuotePouch({
            ...quote,
            Trailers: trailerValues.Trailers
        }))
    }

    const addButtonTrailer = async (): Promise<void> => {
        await formikFormTrailers.setValues([
            ...formikFormTrailers.values,
            trailerGenerator(formikFormTrailers.values.length)
        ])
    }

    const delButtonTrailer = async (index: number): Promise<void> => {
        const modArray = [...formikFormTrailers.values]
        modArray.splice(index, 1)
        await formikFormTrailers.setValues(modArray)
    }

    useEffect(() => {
        setId(generateId() + 'TrailerInfo');
        try {
            setTimeout(() => {
                if (quote?.Trailers) {
                    // quote?.Trailers = previousQuote?.Trailers || []
                    const newTrailers = quote?.Trailers?.map((trailer: any, index: number) => {
                        const originalValues = trailerGenerator(index)
                        const trailerValues = {
                            ...trailer,
                            TrailerVIN_TXT: trailer.TrailerVIN_TXT || '',
                            // TrailerVINOverride_TXT: trailer.TrailerVINOverride_TXT || '',
                            TrailerYear_TXT: trailer.TrailerYear_TXT || '',
                            TrailerMake_TXT: trailer.TrailerMake_TXT || '',
                            TrailerStatedAmount_CHOICE: trailer.TrailerStatedAmount_CHOICE ?? ((previousQuote?.Trailers && previousQuote?.Trailers[index] && previousQuote?.Trailers[index].TrailerStatedAmount_CHOICE)?? ''),
                            TrailerCollisionDeductible_CHOICE: trailer.TrailerCollisionDeductible_CHOICE ?? ((previousQuote?.Trailers && previousQuote?.Trailers[index] && previousQuote?.Trailers[index].TrailerCollisionDeductible_CHOICE)?? ''),
                            TrailerComprehensiveDeductible_CHOICE: trailer.TrailerComprehensiveDeductible_CHOICE ?? ((previousQuote?.Trailers && previousQuote?.Trailers[index] && previousQuote?.Trailers[index].TrailerComprehensiveDeductible_CHOICE)?? '')
                        }
                        setBusinessState_TXT(trailer.BusinessState_TXT)
                        return { ...originalValues, ...trailerValues }
                    })
                    formikFormTrailers
                        .setValues(newTrailers)
                        .catch((formError: any) => console.log({ formError }))
                } else if (previousQuote?.Trailers) {
                    const newTrailers = previousQuote?.Trailers?.map((trailer: any, index: number) => {
                        const originalValues = trailerGenerator(index)
                        const trailerValues = {
                            ...trailer,
                            TrailerVIN_TXT: trailer.TrailerVIN_TXT || '',
                            // TrailerVINOverride_TXT: trailer.TrailerVINOverride_TXT || '',
                            TrailerYear_TXT: trailer.TrailerYear_TXT || '',
                            TrailerMake_TXT: trailer.TrailerMake_TXT || '',

                        }
                        setBusinessState_TXT(trailer.BusinessState_TXT)
                        return { ...originalValues, ...trailerValues }
                    })
                    formikFormTrailers
                        .setValues(newTrailers)
                        .catch((formError: any) => console.log({ formError }))
                } else {
                    // addEmptyVehicle()
                }
            }, 100);
        } catch (error) {
            errorHandler(error, enqueueSnackbar)
        }

    }, []);

    useEffect(() => {
        if (id !== '') {
            verifyErrors[id] = Object.keys(formikFormTrailers.errors).length > 0 ? true : false;
            console.log('TrailerInfo', id, verifyErrors[id], formikFormTrailers.errors)
            setVerifyErrors({
                ...verifyErrors
            });
        }
    }, [formikFormTrailers.errors]);

    useEffect(() => {
        setValues();
    }, [formikFormTrailers.values]);

    return (
        <>
            <Form
                onSubmit={formikFormTrailers.handleSubmit}
            >
                <Box maxWidth={860} margin='0 auto' padding={5} className='trailer-card'>
                    {!isAdjust && <Title>Trailer information</Title>}
                    {formikFormTrailers.values?.map((_: any, index: number) => {
                        const key = formikFormTrailers?.values[index]?.TrailerUpdate_DATE

                        const trailerVin = formikFormTrailers.values[index].TrailerVIN_TXT !== '' ?
                            `${formikFormTrailers.values[index].TrailerMake_TXT} - ${formikFormTrailers.values[index].TrailerVIN_TXT}` :
                            formikFormTrailers.values[index].TrailerMake_TXT

                        const subtitle = isAdjust ? `
                        ${formikFormTrailers.values[index].TrailerYear_TXT}
                        ${trailerVin}
                        ` :
                            formikFormTrailers?.values[index]?.TrailerMake_TXT
                        const hasError =
                            formikFormTrailers?.submitCount !== 0 &&
                            formikFormTrailers?.errors[index] != null

                        return (
                            <Collapse
                                key={key}
                                index={index}
                                title='Trailer'
                                image={<CarIcon />}
                                subtitle={subtitle}
                                hasError={hasError}
                                delButton={delButtonTrailer}
                                className='multi-item-card'
                                ignoreIndex
                            >
                                {/* <SelectTruck inputIndex={index} form={formikFormTrailers} state={formContext.quote.BusinessState_TXT} /> */}
                                <SelectTruck
                                    isAdjust={isAdjust}
                                    inputIndex={index}
                                    formikForm={formikFormTrailers}
                                    isDisabled={isViewMode}
                                    state={businessState_TXT}
                                />
                            </Collapse>
                        )
                    })}
                    <AddButton
                        text='Add a Trailer'
                        action={addButtonTrailer}
                        disable={!(formikFormTrailers.values.length < 50) || isViewMode}
                    />
                </Box>
            </Form>
        </>
    )
}

export default TrailerInfo