import HelloSign from 'hellosign-embedded'
import { useEffect, useState, ReactElement } from 'react'
import { getSignUrl, GetLocalQuoteData, UpdateQuoteData, helloSignClientId, BindExistingQuote, getSalesPerson, isAGENT, quoteRef } from 'services/instanda'
// Hooks
import useFormPercent from 'hooks/useFormPercent'
// Context
import { useFormContext } from 'context/FormContext'
import { useHistory } from 'react-router-dom'
import { getSalesNamesofBrands } from 'utils/configBrands'

function HelloSignView ({ noNeedToPay }: { noNeedToPay: boolean }): ReactElement {
  const formContext = useFormContext()
  const history = useHistory()
  const client = new HelloSign({
    allowCancel: false,
    clientId: helloSignClientId,
    skipDomainVerification: true
  })

  useFormPercent(35, 0, formContext)

  useEffect(() => { // Get documents that the user will sign
    formContext.setLoading(true)
    getSignUrl()
      .then((resGetURL) => {
        client.open(resGetURL.data.signUrl)
        formContext.setLoading(false)
      })
      .catch((errGetURL) => {
        console.log({ errGetURL })
        formContext.setLoading(false)
      })
  }, [])

  useEffect(() => {
    return () => {
      console.log('Closing HelloSign because you unmounted the component')
      client.close()
    }
  }, [])

  client.on('finish', () => { // Don't use "close" because it will be triggered when you do a pop state and will do a bind or update
    formContext.setLoading(true)
    console.log('Congratulations! You have signed the document with HelloSign')
    const signedObject = { SignDocs_CONF: 'Confirmed', ProceedNoSign_CONF: 'Confirmed', HelloSign_DocumentsSigned_YN: 'Yes' }
    if (noNeedToPay) {
      BindExistingQuote(signedObject)
        .then((resBind) => {
          formContext.setLoading(false)
          formContext.savePolicyNumber(resBind.data.policyNumber)
          formContext.setProgress(100)
          formContext.goNextPage()
        })
        .catch((errBind) => {
          formContext.setLoading(false)
          console.error({ errBind })
        })
    } else {
      UpdateQuoteData(signedObject)
        .then((resUpdate) => {
          console.log({ resUpdate })
          const quoteRef = localStorage.getItem('instandaQuoteRef')
          formContext.setLoading(false)
          history.push(`/payment-instanda?QuoteRef=${quoteRef}`);
          //formContext.goNextPage()
        })
        .catch((errUpdate) => {
          formContext.setLoading(false)
          console.error({ errUpdate })
        })
    }
  })

  return (
    <div style={{ textAlign: 'center' }}>Loading PDF</div>
  )
}

export default function HelloSignWrapper (): any {
  const formContext = useFormContext()
  const [doNotShowPayment, setDoNotShowPayment] = useState(false) // Don't show payment if user will pay offline
  const [helloSignLoading, setHelloSignLoading] = useState(false) // Only make it true when the HelloSign should be loaded
  const history = useHistory()

  useEffect(() => {
    formContext.setLoading(true)
    if (isAGENT()) {
      history.push(`/payment-instanda?QuoteRef=${quoteRef()}`) 
    }
    GetLocalQuoteData()
      .then((resDocs) => {
        console.log('soYouWill', resDocs?.data?.ChargeBeeSignOption_TXT)
        if (resDocs?.data?.PayPlan_CHOICE === 'Offline Monthly' || resDocs?.data?.PayPlan_CHOICE === 'Offline Paid in Full') {
          setDoNotShowPayment(true)
        }
        if (resDocs?.data?.ChargeBeeSignOption_TXT === 'Sign now' || getSalesNamesofBrands().includes(getSalesPerson())) {
          setHelloSignLoading(true)
        } else {
          formContext.setLoading(false)
          const quoteRef = localStorage.getItem('instandaQuoteRef')
          history.push(`/payment-instanda?QuoteRef=${quoteRef}`)        
        }
      })
      .catch((errDocs) => {
        formContext.setLoading(false)
        console.log({ errDocs })
      })
  }, [])

  return (
    helloSignLoading && <HelloSignView noNeedToPay={doNotShowPayment} />
  )
}
