import { Dispatch, SetStateAction } from 'react'
import { ProviderContext } from 'notistack'

const errorHandler = (
  replyError: any,
  displayErr: ProviderContext['enqueueSnackbar']
): void => {
  try {
    const errorMessages = replyError?.response?.data['Error Messages']
    if (errorMessages == null) throw new Error('Error Messages not found')
    const errorArray = Array.isArray(errorMessages) ? errorMessages : [errorMessages]
    errorArray.forEach((error: string) => {
      displayErr(error, { variant: 'error' })
    })
  } catch (catchError) {
    if (typeof replyError === 'string') {
      displayErr(replyError, { variant: 'error' })
    } else {
      displayErr('There was an error', { variant: 'error' })
    }
    console.log('Can not display error message', { catchError, replyError })
  }
}

export const wasDeclined = (
  replyMessage: any,
  modalToggle: Dispatch<SetStateAction<boolean>>
): boolean => {
  try {
    if (replyMessage?.WasDeclined === false) return false
    if (replyMessage?.Declines == null) {
      console.log('Quote was declined but Instanda provided no reason, so we can continue with the quote')
      return false // Sometimes instanda does that because they do what they want, but if it can be declined and properly done, so it will continue
    } else {
      if (Array.isArray(replyMessage?.Declines) && replyMessage?.Declines?.length > 0) {
        modalToggle(true)
        return true
      }
      return false
    }
  } catch (catchError) {
    console.log('Error showing decline message but quote will proceed')
    console.log('Error message', { catchError, replyMessage })
    return false
  }
}

export const simpleErrorHandler = (
  replyError: any,
  displayErr: ProviderContext['enqueueSnackbar'],
  setLoading?: Dispatch<SetStateAction<boolean>>
): void => {
  if (setLoading != null) setLoading(false)
  try {
    if (replyError == null) throw new Error('Error Messages not found')
    displayErr(replyError, { variant: 'error' })
  } catch (catchError) {
    displayErr('There was an error', { variant: 'error' })
    console.log('Can not display error message', { catchError, replyError })
  }
}

export default errorHandler
