import { get, isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { Person as PersonIcon } from '@material-ui/icons'
import { useEffect, ReactElement, useState } from 'react'
// Components
import Title from 'components/Title'
import Collapse from './components/Collapse'
import TextField from 'components/TextField'
import TypedInput from 'components/TypedInput'
import AddButton from './components/AddButton'
// Context
import { useFormContext } from 'context/FormContext'
// Services
import { GetLocalQuoteData, getQuoteMultiItems, quoteRef, UpdateQuoteData, UpdateQuoteDataNoRate } from 'services/instanda'
// Utils
import { AddlInsuredSchema, DriverSchema } from 'utils/validations'
import errorHandler, { wasDeclined } from 'utils/errorHandler'
import useMixpanelTrack from 'hooks/useMixpanelTrack'
import { dateOf16YearsInThePast, dateOf100YearsInThePast } from 'utils/calculations'
// Hooks
import useFormPercent from 'hooks/useFormPercent'
import { InputLabel, Button, makeStyles } from '@material-ui/core'
import { AddlInsuredInfo } from 'views/MTA/AddlInsuredInfo'
import { IAddlInsured } from 'interfaces/pouch-redux'

interface IPageValues {
  DriverFirstName_TXT: string
  DriverLastName_TXT: string
  DriverDOB_DATE: string
  DriverIncidentsThreeYears_YNP: 'Yes' | 'No'
  DriverIncidentsTickets_NUM?: number
  DriverIncidentsViolations_NUM?: number
  DriverIncidentsAccidents_NUM?: number
  DriverUpdate_DATE?: number
}

const pageValues: IPageValues = {
  DriverFirstName_TXT: '',
  DriverLastName_TXT: '',
  DriverDOB_DATE: '',
  DriverIncidentsThreeYears_YNP: 'No',
  DriverIncidentsTickets_NUM: 0,
  DriverIncidentsViolations_NUM: 0,
  DriverIncidentsAccidents_NUM: 0,
  DriverUpdate_DATE: Date.now()
}
const AddlInsuredValues: IAddlInsured = {
  PolAddlInsuredName_TXT: '',
  PolAddlInsuredAddressLine1_TXT: '',
  PolAddlInsuredAddressLine2_TXT: '',
  PolAddlInsuredCity_TXT: '',
  PolAddlInsuredState_TXT: '',
  PolAddlInsuredZipCode_TXT: '',
  PolAdditionalInsuredCountry_TXT: ''
}
const pageValuesFx = (addOffset: number): IPageValues => {
  const newValue = { ...pageValues, DriverUpdate_DATE: (Date.now() / addOffset) }
  return newValue
}

const useStyles = makeStyles({
  yesBtn: {
    border: '1px solid #E5E5E5',
    padding: '5px 30px',
    textTransform: 'capitalize',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor: '#EEEEEE',
      fontWeight: 'bold',
      color: '#000',
      '@media (max-width:740px)': {
        color: '#ffffff',
        backgroundColor: '#FF6C1A',
        borderRadius: 8
      }
    }
  },
  noBtn: {
    border: '1px solid #E5E5E5',
    padding: '5px 30px',
    textTransform: 'capitalize',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&:hover': {
      backgroundColor: '#EEEEEE',
      fontWeight: 'bold',
      color: '#000',
      '@media (max-width:740px)': {
        color: '#ffffff',
        backgroundColor: '#FF6C1A',
        borderRadius: 8
      }
    }
  },
  active: {
    color: '#ffffff',
    backgroundColor: '#ff6c1a',
    fontWeight: 'bold',
    borderRadius: 8
  },
  notActive: {
    backgroundColor: '#E5E5E5',
    fontWeight: 500,
    padding: '5px 25px'
  },
  toggleContainer: {
    position: 'relative',
    backgroundColor: '#E5E5E5',
    borderRadius: 8,
    display: 'flex'
  },
  accidentsText: {
    color: 'red'
  }
})

export default function Page3 (): ReactElement {
  useMixpanelTrack('quote3-tracking')

  const classes = useStyles()
  const formContext = useFormContext()  
  const { enqueueSnackbar } = useSnackbar()
  const PolicyEffective_DATE =  JSON.parse(localStorage.getItem('PolicyEffective_DATE') as string)

  const formikForm = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: [pageValues],
    validationSchema: DriverSchema,    
    onSubmit: (values) => {
      const driversObject: { Drivers: IPageValues[] } = { Drivers: values }
      values.forEach((_: IPageValues, index: number) => {
        driversObject.Drivers[index].DriverIncidentsThreeYears_YNP = ((driversObject.Drivers[index].DriverIncidentsViolations_NUM ?? 0) > 0) || ((driversObject.Drivers[index].DriverIncidentsAccidents_NUM ?? 0) > 0) || ((driversObject.Drivers[index].DriverIncidentsTickets_NUM ?? 0) > 0) ? 'Yes' : 'No'
      })
      const quoteValues = { ...formContext.quote, Drivers: driversObject.Drivers }
      formContext.setLoading(true)
      console.log(quoteValues);
      const driverPayLoad = {
        PolicyEffective_DATE,
        ...driversObject
      }
      UpdateQuoteDataNoRate(driverPayLoad, quoteRef())
        .then((res) => {
          formContext.setLoading(false)
          formContext.setQuote(quoteValues) 
          formikFormAddInsured.submitForm().
            then(() => console.log(quoteValues))
            .catch(resErr => {
            formContext.setLoading(false)
              errorHandler(resErr, enqueueSnackbar)
            })
          const isComingBack = localStorage.getItem("isComingBack") ? localStorage.getItem("isComingBack") === 'true': false;
          if(!isComingBack){
            formContext.goNextPage()
          }else{
            localStorage.setItem("isComingBack",'false');
          }          
        })
        .catch(resErr => {
          formContext.setLoading(false)
          errorHandler(resErr, enqueueSnackbar)
        })
    }
  })

  const formikFormAddInsured = useFormik<IAddlInsured[]>({
    initialValues: [],
    enableReinitialize: true,
    validationSchema: AddlInsuredSchema,
    onSubmit: (values) => {
      const addInsuredObject: { AdditionalInsured_MI: IAddlInsured[] } = { AdditionalInsured_MI: values }
      const quoteValues = { ...formContext.quote, Drivers: formikForm.values, AdditionalInsured_MI: addInsuredObject.AdditionalInsured_MI }
      formContext.setLoading(true)
      console.log(quoteValues);
      const addInsuredPayLoad = {
        PolicyEffective_DATE,
        ...addInsuredObject
      }
      UpdateQuoteDataNoRate(addInsuredPayLoad, quoteRef())
        .then((res) => {
          formContext.setLoading(false)
          formContext.setQuote(quoteValues)   
          const isComingBack = localStorage.getItem("isComingBack") ? localStorage.getItem("isComingBack") === 'true': false;
          if(!isComingBack){
            formContext.goNextPage()
          }else{
            localStorage.setItem("isComingBack",'false');
          }          
        })
        .catch(resErr => {
          formContext.setLoading(false)
          errorHandler(resErr, enqueueSnackbar)
        })
    }
  })

  useFormPercent(66, formikForm, formContext)

  const getAddInsuredValues = async (quoteRef: string) => {
    const response = await getQuoteMultiItems(quoteRef)
    const result = await response.data.data.response
    const additionalInsured = result.AdditionalInsured_MI
    additionalInsured.slice(0,formContext?.quote?.AdditionalInsured_MI_Count).map((addlInsured: any, index: number) => {
        formikFormAddInsured.setFieldValue(`[${index}].PolAddlInsuredName_TXT`, addlInsured.PolAddlInsuredName_TXT)
        formikFormAddInsured.setFieldValue(`[${index}].PolAddlInsuredAddressLine1_TXT`, addlInsured.PolAddlInsuredAddressLine1_TXT)
        formikFormAddInsured.setFieldValue(`[${index}].PolAddlInsuredAddressLine2_TXT`, addlInsured.PolAddlInsuredAddressLine2_TXT)
        formikFormAddInsured.setFieldValue(`[${index}].PolAddlInsuredCity_TXT`, addlInsured.PolAddlInsuredCity_TXT)
        formikFormAddInsured.setFieldValue(`[${index}].PolAddlInsuredState_TXT`, addlInsured.PolAddlInsuredState_TXT)
        formikFormAddInsured.setFieldValue(`[${index}].PolAddlInsuredZipCode_TXT`, addlInsured.PolAddlInsuredZipCode_TXT)
    })
  }
  const getDriverAccidentsValues = async (quoteRef: string) => {
    const response = await getQuoteMultiItems(quoteRef)
    const result = await response.data.data.response
    const drivers = result.Drivers
    drivers.slice(0,formContext?.quote?.Drivers_Count).map((driver: any, index: number) => {
        formikForm.setFieldValue(`[${index}].DriverIncidentsThreeYears_YNP`, driver.DriverIncidentsThreeYears_YNP)
        formikForm.setFieldValue(`[${index}].DriverIncidentsAccidents_NUM`, driver.DriverIncidentsAccidents_NUM)
    })
  }

  useEffect(() => {
    try {
      formContext.setLoading(true)
      GetLocalQuoteData()
        .then((res) => {
          if (res.data?.Drivers?.length > 0) {
            const newValues: IPageValues[] = []
            const driversCount = res.data?.Drivers_Count;
            formContext.setQuote(res.data);
            res.data?.Drivers?.slice(0,driversCount).forEach((driver: any, index: number) => {
              const baseValues = pageValuesFx(index + 1) // Offset should be possitive
              newValues[index] = { ...baseValues, ...driver }
            })
            formikForm.setValues(newValues).catch(formError => console.log({ formError })).finally(() => {
              if (!isEmpty(formContext.quote)) formContext.setLoading(false)
            })
            getDriverAccidentsValues(res.data?.QuoteRef)
          } else {
            const newValues: IPageValues[] = []
            const baseValues = pageValuesFx(1) // Offset should be possitive
            baseValues.DriverFirstName_TXT = res.data?.ContactFirstName_TXT
            baseValues.DriverLastName_TXT = res.data?.ContactLastName_TXT
            newValues[0] = baseValues
            formikForm.setValues(newValues).catch(formError => console.log({ formError })).finally(() => {
              if (!isEmpty(formContext.quote)) formContext.setLoading(false)
            })
          }
        })
        .catch((_) => {
          formContext.setLoading(false)
          console.log('Can not get quote data')
        })
    } catch (tryErr) {
      console.error({ tryErr })
    }    
    getAddInsuredValues(formContext.quote?.QuoteRef || quoteRef)
  }, [])

  const addButton = async (): Promise<void> => {
    await formikForm.setValues([...formikForm.values, pageValuesFx(formikForm.values.length - 1)])
  }

  const delButton = async (index: number): Promise<void> => {
    const modArray = [...formikForm.values]
    modArray.splice(index, 1)
    await formikForm.setValues(modArray)
  }

  useEffect(() => formContext.setForm(formikForm), []) // ImportantSetForm

  const changeIncidentsYNP = (change: string, index: number): void => {
    formikForm.setFieldValue(`[${index}].DriverIncidentsThreeYears_YNP`, change)
    if (change === 'No') {
      formikForm.setFieldValue(`[${index}].DriverIncidentsTickets_NUM`, 0)
      formikForm.setFieldValue(`[${index}].DriverIncidentsViolations_NUM`, 0)
      formikForm.setFieldValue(`[${index}].DriverIncidentsAccidents_NUM`, 0)
    }
  }

  return (
    <form onSubmit={formikForm.handleSubmit}>
      <Title isAdjust>Driver information</Title>      
      {
        formikForm.values.map((_, index) => {
          const mainAccidents = get(formikForm, `errors.${index}`)
          const key = formikForm.values[index].DriverUpdate_DATE ?? index
          const subtitle = formikForm.values[index].DriverFirstName_TXT
          const hasError = formikForm.submitCount !== 0 && formikForm?.errors[index] != null
          return (
            <Collapse key={key} index={index} title='Driver' image={<PersonIcon />} subtitle={subtitle} hasError={hasError} delButton={delButton}>
              <TypedInput
                inputTitle='First Name'
                formikForm={formikForm}
                inputValue={`[${index}].DriverFirstName_TXT`}
                inputPlaceholder='First Name'
              />
              <TypedInput
                inputTitle='Last Name'
                formikForm={formikForm}
                inputValue={`[${index}].DriverLastName_TXT`}
                inputPlaceholder='Last Name'
              />
              <TypedInput
                inputTitle='Date of Birth'
                formikForm={formikForm}
                inputValue={`[${index}].DriverDOB_DATE`}
                inputType='date'
                minDate={dateOf100YearsInThePast()}
                maxDate={dateOf16YearsInThePast()}
              />
              <div className='d-flex align-items-center'>
                <InputLabel style={{ color: '#000' }}>
                  Any At-Fault Accidents in the last 3 years? &nbsp;
                </InputLabel>
                <div className={classes.toggleContainer}>
                  <Button
                    classes={{ root: classes.yesBtn }}
                    onClick={() => { changeIncidentsYNP('Yes', index) }}
                    className={formikForm.values[index].DriverIncidentsThreeYears_YNP === 'Yes' ? classes.active : classes.notActive}
                  >
                    Yes
                  </Button>
                  <Button
                    classes={{ root: classes.noBtn }}
                    onClick={() => { changeIncidentsYNP('No', index) }}
                    className={formikForm.values[index].DriverIncidentsThreeYears_YNP === 'Yes' ? classes.notActive : classes.active}
                  >
                    No
                  </Button>
                </div>
              </div>
              {
                (formikForm.values[index].DriverIncidentsThreeYears_YNP === 'Yes') ? (
                  <>
                    <TextField
                      name='DriverIncidentsAccidents_NUM'
                      type='number'
                      inputIndex={index}
                      inputLabel='At-Fault Accidents'
                      placeholder='At-Fault Accidents'
                      max={20}
                      form={formikForm}
                      onChange={(e: any) => {
                        if (/[^0-9]/.test(e)) { // to only let enter positive numbers on this field
                          const value = `${e}`.split('').filter((c: any) => !/[^0-9]/.test(c)).join('')
                          formikForm.setFieldValue(`[${index}].DriverIncidentsAccidents_NUM`, Number(value)).catch((error) => console.log(error))
                        }
                      }}
                    />
                    {
                      typeof mainAccidents === 'string' && <div className={classes.accidentsText}>{mainAccidents}</div>
                    }
                  </>
                ) : null
              }
            </Collapse>
          )
        })
      }
      <AddButton text='Add a Driver' action={addButton} disable={!(formikForm.values.length < 50)} />
      <div className='div-no-padding'>
        <AddlInsuredInfo formikFormAddInsured={formikFormAddInsured} />
      </div>
    </form>
  )
}
