import { find } from 'lodash'
import styled from 'styled-components'
import React, { useState, ReactElement, ChangeEvent } from 'react'
import { useSnackbar } from 'notistack'
import { usStatesFull, supportedStates } from 'services/instadaModel'
import { makeStyles } from '@material-ui/core/styles'
import { simpleErrorHandler } from 'utils/errorHandler'
import { GetMapList, getLocationDetails } from '../services/instanda'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { Box, Slider, Typography } from '@material-ui/core'
import sliderThumb from './StepperContainer/assets/slider-thumb.svg'

const InputContainer = styled.div<{ error: string, message?: string }>`
  position: relative;
  border-radius: 5px;
  border: 2px solid ${(props) => (props.error ? 'red' : (props.message != null) ? '#dddddd' : '#dddddd')};
  background-color: #fff;
  position: relative;
`

const ToggleSelect = styled.div<{ error: string }>`
  position: relative;
  border-radius: 25px;
  heigth: 25px;
  border: 2px solid ${(props) => (props.error ? 'red' : '#dddddd')};
  background-color: #fff;
  position: relative;
`

const CustomSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-rail': {
    height: 7,
    opacity: 2,
    backgroundColor: '#d9d9d9',
    borderRadius: 4
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#39c7c2',
    backgroundImage: `url(${sliderThumb})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#d9d9d9',
    height: 25,
    width: 2,
    top: 4,
    '@media (max-width:920px)': {
      top: 10
    },
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: '#39c7c2'
    }
  },
  '& .MuiSlider-markLabel': {
    top: 33,
    color: '#9b9b9b',
    fontSize: 15,
    paddingTop: 2,
    paddingbutton: 2,
    paddingLeft: 15,
    paddingRight: 15
  },
  '& .MuiSlider-markLabelActive': {
    border: '1px solid #d9d9d9',
    borderRadius: 12,
    backgroundColor: '#fff',
    color: '#000'
  }
}))

const useStyles = makeStyles({
  container: {
    padding: '10px 0'
  },
  listItem: {
    cursor: 'pointer',
    padding: '5px 0px',
    borderBottom: '1px solid #dddddd'
  },
  date: {
    WebkitAppearance: 'textfield',
    MozAppearance: 'textfield',
    backgroundColor: '#FFF',
    textTransform: 'uppercase',
    minHeight: '1.2em'
  },
  dateEmpty: {
    WebkitAppearance: 'textfield',
    MozAppearance: 'textfield',
    backgroundColor: '#FFF',
    color: 'gray',
    textTransform: 'uppercase',
    minHeight: '1.2em'
  },
  select: {
    Webkitapearence: 'textfield',
    MozAppearance: 'textfield',
    backgroundColor: '#FFF',
    width: '100%',
    border: 'none',
    padding: '10px'
  },
  input: {
    width: '100%',
    border: 'none',
    padding: '10px'
  },
  resultList: {
    zIndex: 9999,
    width: '100%',
    position: 'absolute',
    backgroundColor: '#fff',
    border: '2px solid #dddddd'
  },
  label: {
    left: '5px',
    top: '-10px',
    border: 'none',
    padding: '0 5px',
    fontSize: '0.7em',
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundColor: '#fff'
  },
  labelError: {
    color: 'red',
    fontSize: '0.7em'
  },
  labelMessage: {
    color: '#3800E7',
    fontSize: '0.7em'
  },
  toggleSelect: {
    '& .MuiToggleButtonGroup-root': {
      width: '100%',
      heigth: '25px',
      '& .MuiToggleButton-root': {
        width: '100%',
        border: '3px solid #fff',
        borderRith: '1px solid #dddd',
        borderLeft: '1px solid #dddd',
        heigth: '20px',
        padding: '4px'
      },
      '& .MuiToggleButton-root:first-child': {
        borderRadius: '25px 0 0 25px'
      },
      '& .MuiToggleButton-root:last-child': {
        borderRadius: '0 25px 25px 0'
      },
      '& .Mui-selected': {
        borderRadius: '25px !important',
        backgroundColor: '#fff',
        border: '3px solid #39c7c2 !important'
      }
    }
  },
  yourSelection: {
    color: '#9b9b9b',
    fontSize: 12
  },
  selectedPrice: {
    color: '#39c7c2',
    fontSize: 17
  },
  SliderTitle: {
    maxWidth: '70%'
  }
})

export default function TextField ({
  placePicker = false,
  form,
  type, // Input type, defaults to "text"
  inputIndex, // Gets index in case form is array
  inputLabel, // In case input label is different from placeholder use this
  placeholder, // Input label and placeholder are the same
  minDate,
  maxDate,
  name,
  id,
  value,
  options,
  select,
  onChange,
  disabled,
  onMouseEnter,
  onMouseLeave,
  isLienholder = false,
  toggleSelect = false,
  sliderSelect = false,
  message,
  max,
  onKeyUp,
  className
}: {
  placePicker?: boolean
  form?: any
  type?: string | undefined
  inputIndex?: number | undefined
  inputLabel?: string | undefined
  placeholder?: string | undefined
  minDate?: string | undefined
  maxDate?: string | undefined
  name: string
  id?: any
  value?: string
  options?: any
  select?: boolean
  toggleSelect?: boolean
  sliderSelect?: boolean
  onChange?: Function
  onMouseEnter?: Function
  onMouseLeave?: Function
  onKeyUp?: Function
  disabled?: boolean
  isLienholder?: boolean
  message?: string
  max?: number
  className?: string
}): ReactElement {
  const classes = useStyles()
  const [placeResult, setPlaceResult] = useState<number[]>([])
  const { errors, touched } = form
  const { enqueueSnackbar } = useSnackbar()

  const searchPlace = async (value: string): Promise<void> => {
    form.setFieldValue(inputIndex != null ? `[${inputIndex}].${name}` : name, value)
    if (value.length >= 4) {
      GetMapList(value)
        .then((res) => setPlaceResult(res.data.result))
        .catch((error) => console.log(error))
    }
  }

  const cleanValues = (): void => {
    form.setFieldValue('BusinessCity_TXT', '')
    form.setFieldValue('BusinessState_TXT', '')
    form.setFieldValue('BusinessZip_TXT', '')
  }

  const setPlaceValue = (value: string): void => {
    form.setFieldValue(name, value)
    cleanValues()
    let validState = true as any
    getLocationDetails(value)
      .then((res) => {
        res.data.result[0].address_components.forEach((val: any) => {
          const foundState = find(
            usStatesFull,
            (current) => current.name === val.long_name
          )
          const stateAbbreviation =
            foundState != null ? foundState.abbr : val.long_name

          val.types.forEach((typeName: any) => {
            if (typeName === 'locality') {
              form.setFieldValue('BusinessCity_TXT', val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'administrative_area_level_1') {
              validState = find(
                supportedStates,
                (current) => current.value === stateAbbreviation
              )
              form.setFieldValue('BusinessState_TXT', stateAbbreviation)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'postal_code') {
              form.setFieldValue('BusinessZip_TXT', val.long_name)
            }
          })
        })

        if (validState === undefined) {
          simpleErrorHandler('Your state is not supported', enqueueSnackbar)
          cleanValues()
        }
      })
      .catch((err) => console.log(err))
    setPlaceResult([])
  }

  const cleanLienholderValues = (): void => {
    form.setFieldValue('LienholderCountry_TXT', '')
    form.setFieldValue('LienholderCity_TXT', '')
    form.setFieldValue('LienholderZipCode_TXT', '')
  }

  const setLienholderPlaceValue = (value: string): void => {
    form.setFieldValue(inputIndex != null ? `[${inputIndex}].${name}` : name, value)
    cleanLienholderValues()
    getLocationDetails(value)
      .then((res) => {
        res.data.result[0].address_components.forEach((val: any) => {
          console.log('do something', val)
          val.types.forEach((typeName: any) => {
            if (typeName === 'country') {
              form.setFieldValue(`[${inputIndex}].LienholderCountry_TXT`, val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'locality') {
              form.setFieldValue(`[${inputIndex}].LienholderCity_TXT`, val.long_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'administrative_area_level_1') {
              form.setFieldValue(`[${inputIndex}].LienholderState_TXT`, val.short_name)
            }
          })

          val.types.forEach((typeName: any) => {
            if (typeName === 'postal_code') {
              form.setFieldValue(`[${inputIndex}].LienholderZipCode_TXT`, val.long_name)
            }
          })
        })
      })
      .catch((err) => console.log(err))
    setPlaceResult([])
  }

  const inputValue = (inputIndex != null && form?.values[inputIndex] != null
    ? form.values[inputIndex][name]
    : form.values[name]) ?? ''
  const errorsName = inputIndex != null && errors[inputIndex] != null
    ? errors[inputIndex][name]
    : errors[name]
  const touchedName = inputIndex != null && touched[inputIndex] != null
    ? touched[inputIndex][name]
    : touched[name]

  const showErrors = errorsName && touchedName
  const showMessage: any = message !== undefined

  const SliderSelect = ({
    marks,
    form,
    value,
    label,
    inputIndex,
    name
  }: {
    marks: any
    form: any
    value: number
    label: string
    inputIndex?: number | undefined
    name: string
  }): any => {
    const scaledMarks = marks.map((mark: any, index: number) => ({ value: index + 1, label: mark.label }))
    const selectedLabel = marks.find((mark: any) => mark.value === value)?.label ?? ''
    const selectedValue = scaledMarks.find((mark: any) => mark.label === selectedLabel)?.value ?? 0

    return (
      <Box sx={{ width: '100%', mb: 5 }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography gutterBottom className={classes.SliderTitle}>{label}</Typography>
          {
            selectedValue !== 0 &&
              <Typography gutterBottom className={classes.yourSelection}> your selection &nbsp; <span className={classes.selectedPrice}>{selectedLabel}</span> </Typography>
          }
        </Box>
        <CustomSlider
          value={selectedValue}
          marks={scaledMarks}
          track={false}
          aria-labelledby='track-inverted-slider'
          valueLabelDisplay='off'
          step={null}
          onChange={(event: ChangeEvent<{}>, value: number | number[]) => {
            const selectedLabel = scaledMarks.find((mark: any) => mark.value === value)?.label ?? ''
            const newValue = marks.find((mark: any) => mark.label === selectedLabel)?.value ?? 0
            form.setFieldValue(
              inputIndex != null ? `[${inputIndex}].${name}` : name,
              newValue
            )
          }}
          min={0}
          max={marks.length + 1}
        />
        {showErrors && (
          <label className={classes.labelError}>{errorsName}</label>
        )}
      </Box>
    )
  }

  const ToggleButtonSelect = () => (
    <div className={classes.container}>
      <span style={{ fontWeight: '501' }}>
        {inputLabel ?? placeholder ?? ''}
      </span>
      <br />
      <br />
      <ToggleSelect error={showErrors} className={classes.toggleSelect}>
        <ToggleButtonGroup
          value={Number(value ?? inputValue)}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, value) => {
            form.setFieldValue(
              inputIndex != null ? `[${inputIndex}].${name}` : name,
              value
            )
          }}
        >
          {
            options.map((val: any, index: number) => (
              <ToggleButton
                value={val.value}
                key={index}
              >
                {val.label}
              </ToggleButton>
            ))
          }
        </ToggleButtonGroup>
      </ToggleSelect>

      {showErrors && (
        <label className={classes.labelError}>{errorsName}</label>
      )}
    </div>
  )

  if (select === true) {
    return (
      <div className={classes.container}>
        <InputContainer error={showErrors} message={showMessage}>
          <label className={classes.label}>
            {inputLabel ?? placeholder ?? ''}
          </label>
          <select
            className={classes.select}
            onChange={(evt) => {
              if (onChange !== undefined) {
                form.setFieldValue(
                  inputIndex != null ? `[${inputIndex}].${name}` : name,
                  evt.target.value
                )
                onChange(evt.target.value)
              } else {
                form.setFieldValue(
                  inputIndex != null ? `[${inputIndex}].${name}` : name,
                  evt.target.value
                )
              }
            }}
            value={inputValue}
            name={name}
            onMouseEnter={(e: any) => { onMouseEnter !== undefined && onMouseEnter(e) }}
            onMouseLeave={(e: any) => { onMouseLeave !== undefined && onMouseLeave(e) }}
          >
            <option value=''>Select an option...</option>
            {options.map((val: any, index: number) => (
              <option key={index} value={val.value}>
                {val.label}
              </option>
            ))}
          </select>
        </InputContainer>
        {showMessage && (
          <label className={classes.labelMessage}>{message}</label>
        )}
      </div>
    )
  } else if (toggleSelect) {
    return (
      <ToggleButtonSelect />
    )
  } else if (sliderSelect) {
    return (
      <SliderSelect
        {...{
          marks: options,
          form,
          value: Number(inputValue),
          label: inputLabel ?? placeholder ?? '',
          inputIndex,
          name
        }}
      />
    )
  } else {
    return (
      <div className={`${classes.container} ${className}`}>
        <InputContainer error={showErrors}>
          <label className={classes.label}>
            {inputLabel ?? placeholder ?? ''}
          </label>
          <input
            autoComplete='off'
            className={`${classes.input} ${
              (type === 'date') ? (inputValue === '') ? classes.dateEmpty : classes.date : ''
            }`}
            onChange={(event) => {
              if (onChange !== undefined) {
                placePicker
                  ? searchPlace(event.target.value)
                  : form.setFieldValue(
                    inputIndex != null ? `[${inputIndex}].${name}` : name,
                    event.target.value
                  )
                onChange(event.target.value)
              } else {
                placePicker
                  ? searchPlace(event.target.value)
                  : form.setFieldValue(
                    inputIndex != null ? `[${inputIndex}].${name}` : name,
                    event.target.value
                  )
              }
            }}
            disabled={disabled}
            id={id}
            name={name}
            value={value ? value : !isLienholder ? inputValue :  inputIndex ? form.values[inputIndex].inputValue : inputValue}
            type={type ?? 'text'}
            placeholder={placeholder ?? ''} // Its needed to make controlled components making a defined "value"
            min={type === 'date' ? minDate : undefined}
            max={type === 'date' ? maxDate : max}
            onMouseEnter={(e: any) => { onMouseEnter !== undefined && onMouseEnter(e) }}
            onMouseLeave={(e: any) => { onMouseLeave !== undefined && onMouseLeave(e) }}
            onKeyUp={(e: any) => {
              if (onKeyUp !== undefined) {
                onKeyUp(e)
              }
            }}
          />

          {placePicker && placeResult.length !== 0 && (
            <div className={classes.resultList}>
              {placeResult.map((place: any, index: number) => (
                <div
                  className={classes.listItem}
                  key={index}
                  onClick={() => (!isLienholder) ? setPlaceValue(place.description) : setLienholderPlaceValue(place.description)}
                >
                  {place.description}
                </div>
              ))}
            </div>
          )}
        </InputContainer>

        {showErrors && (
          <label className={classes.labelError}>{errorsName}</label>
        )}
      </div>
    )
  }
}
