import { Dialog, makeStyles } from "@material-ui/core"

interface Props {
    verifiedVINs: any []
    open: boolean,
    back?: () => void,
    next?: () => void,
}

const useStyles = makeStyles({
    root: {
      padding: '2rem 4rem',
      fontSize: '14px'
    },
    marginExtra: {
        margin: "2rem 0"
    },
    textVehicle: {
        display: "flex",
        justifyContent: "space-between"
    },
    marginLeft: {
        marginLeft: "1rem"
    },
    withoutMargin:{
        margin: 0
    },
    textalignCenter:{
        textAlign:"center"
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'center'
    },
    continueBtn: {
        padding: '10px 30px',
        border: 'none',
        margin: '0rem 1rem',
        borderRadius: '12px',
        fontWeight: 500,
        color: '#ffffff',
        backgroundColor: '#ff6c1a'
    },
    backBtn: {
        padding: '10px 30px',
        border: 'none',
        margin: '0rem 1rem',
        borderRadius: '12px',
        fontWeight: 500,
        color: 'black',
        backgroundColor: '#f5f5f5'
    },
})

export const VerifyVIN = ({ verifiedVINs = [], open, back = () => {}, next = () => {} }: Props): JSX.Element => {
    const classes = useStyles()
    
    return (
        <Dialog open={open}>
            <div className={classes.root}>
                <p><strong>Message from Goodie</strong></p>
                <p className={classes.marginExtra}>The VIN that was added does not match the vehicle</p>
                {
                    verifiedVINs.map((vehicle, i) =>{
                        return (
                            <div key={i}>
                                <p>
                                    <strong>Vehicle {vehicle.index + 1}</strong>
                                    - { vehicle.userData.year + ' ' + vehicle.userData.make + ' ' + vehicle.userData.model }
                                </p>
                                <div>
                                    <p className={classes.withoutMargin}><strong>Did you mean?</strong></p>
                                    <div className={classes.textVehicle}>
                                        <p>{ vehicle.realData.year + ' ' + vehicle.realData.make + ' ' + vehicle.realData.model }</p>
                                        <p className={classes.marginLeft}>{ vehicle.VIN }</p>
                                    </div>
                                </div>  
                            </div>
                        )
                    })
                }
                <p className={classes.textalignCenter}>To change the VIN, click back.</p>
                <section className={classes.buttonRow}>
                    <button
                        className={classes.backBtn}
                        type='button'
                        onClick={back}
                    >
                        Back
                    </button>
                    <button
                        className={classes.continueBtn}
                        type='button'
                        onClick={next}
                    >
                        Update
                    </button>
                </section>
            </div>
        </Dialog>
    )
}