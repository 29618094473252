import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paragraph: {
    padding: '25px 5px',
    display: 'flex',
    flexDirection: 'column'
  },
  titles: {
    fontWeight: 500,
    fontSize: '20px'
  },
  subtitles: {
    fontWeight: 500,
    fontSize: '30px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '45px'
    }
  },
  texts: {
    color: 'gray',
    marginTop: '2%',
    fontSize: '22px',
    textAlign: 'justify'
  }
}))

const WhatWeOfferSection = ({ title, subtitle, text }: { title?: string, subtitle?: string, text?: string }): ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.paragraph}>
      {
        title != null && <div className={classes.titles}>{title}</div>
      }
      {
        subtitle != null && <div className={classes.subtitles}>{subtitle}</div>
      }
      {
        text != null && <p className={classes.texts}>{text}</p>
      }
    </div>
  )
}

export default WhatWeOfferSection
