import React from 'react'

const Alert: React.FC = ({ children }) => {
  return (
    <div style={{ color: 'red', textAlign: 'center' }}>
      {children}
    </div>
  )
}

export default Alert
