
import { Box, Card, Grid, Tooltip, Typography } from '@material-ui/core'
import { useState, useEffect, ReactElement } from 'react'
// Assets
import spacing from '../../components/StepperContainer/assets/stepper-spacing.svg'
import reading from '../../components/StepperContainer/assets/stepper-reading.svg'
// hooks
import { useWindowSize } from 'views/Standalone/hooks/useWindowSize'
import { defaultSalesPerson, getSalesPerson } from 'services/instanda'
import { InfoOutlined } from '@material-ui/icons'
import { getBrandBySalesName } from 'utils/configBrands'
import GoodieNote from 'UI/GoodieNote/GoodieNote'
import { useHistory } from 'react-router-dom'
// CSS
import './StepperSidebar.scss'
import { useAppSelector } from 'hooks/useAppSelector'

export default function StepperSidebar ({ sideMain, sideText, MVRError }: { sideMain: string, sideText: string , MVRError?: boolean}): ReactElement {
  const history = useHistory()  
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode, thisPage, displayGlBopView, orangeBtnText} = useAppSelector(state => state.pouch)

  const [fallbackMain, setFallbackMain] = useState('')
  const [fallbackText, setFallbackText] = useState('')
  const [openToolTip, setOpenToolTip] = useState(false)  

  const goBackSide = (): void => { 
    quote?.IsMTA ? history.push('/mta') : history.goBack()
  }
  
  const goNextSide = (): void => {
    history.push(`/payment-instanda?QuoteRef=${quote?.QuoteRef}`)
  }

  const copyToclipboard = async (value: any): Promise<void> => {
    await navigator.clipboard.writeText(value)
  }

  const tooltipOpen = (): void => {
    setOpenToolTip(true)
    setTimeout(() => {
      setOpenToolTip(false)      
    }, 3000);
  }


  useEffect(() => {
    setFallbackMain(sideMain)
    setFallbackText(sideText)
  }, [thisPage])

  const windowSize = useWindowSize()
  const { width } = windowSize

  const sidebarButtons = (
    <Grid container spacing={2}>
      <Grid item xs>
        <button className='backButton' type='button' onClick={goBackSide}>Previous</button>
      </Grid>
      <Grid item xs>
        <button className={MVRError ? 'nextButtonDisabled' : 'nextButton' } type='submit' onClick={goNextSide} disabled={MVRError}>{orangeBtnText ?? 'Next'}</button>
      </Grid>
    </Grid>
  )

  if (width > 540) {
    return (
      <section className='stepperSideBar'>
        <Grid container spacing={1}>
          <Grid item xs={2} className='sideImage'>
            <img src={spacing} alt='spacer' />
          </Grid>
          <Grid item xs={10}>
            <section>
              <div className='sideTitle'>{sideMain === '' ? fallbackMain : sideMain}</div>
              <hr />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <img src={reading} alt='pouch-read' />
                </Grid>
                <Grid item xs={8}>
                  {sideText === '' ? fallbackText : sideText}
                </Grid>
              </Grid>
            </section>
            {sidebarButtons}
            {
              getBrandBySalesName(getSalesPerson())?.groupName ? 
              <Card className='cardAgent' >
                <Box display='flex'>
                  <InfoOutlined/>
                  <Typography style={{margin:'0 0 0 .5rem'}}>
                    { getBrandBySalesName(getSalesPerson())?.groupName }
                  </Typography>
                </Box>
                <Box display='flex'>
                  <Typography style={{margin:'.5rem 0 0 0'}}>
                  Phone number: <u>{ getBrandBySalesName(getSalesPerson())?.phoneNumber }</u><br/>
                  </Typography>
                </Box>
              </Card>
              :
              <></>
            }            
            {
              MVRError ?
                <GoodieNote title='Note from Goodie' error>
                  <p>
                    Unfortunately one of your drivers is not eligible to quote with us. 
                    Please click here to <a href='javascript:void(0)' onClick={() => goBackSide()}>go back</a> and remove the driver. 
                    Contact {' '}
                    <Tooltip open={openToolTip} title='Phone Number Copied!' disableFocusListener disableHoverListener disableTouchListener>
                        <a 
                          href='javascript:void(0)' 
                          onClick={async () => { await copyToclipboard('+844-POUCH-IT'); tooltipOpen() }} >
                        +844-POUCH-IT
                        </a>
                    </Tooltip>
                    {' '}if you have any issues.
                  </p>
                </GoodieNote>
                : null
            }
          </Grid>
        </Grid>
      </section>
    )
  } else {
    return sidebarButtons
  }
}
