import { Redirect } from 'react-router'
import ReCAPTCHA from 'react-google-recaptcha'
import { ReactElement, useRef, useState } from 'react'
import { API, RecaptchaSiteKey } from 'services/instanda'
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  errorMessage: {
    fontSize: '0.57rem',
    color: 'red'
  }
})

export const StandaloneCaptchaTest = (): ReactElement => {
  const classes = useStyles()
  const recaptchaRef = useRef<any>(null)
  const [isChecked, setIsChecked] = useState(true)

  const handleChange = (e: any): void => {
    if (e !== null) setIsChecked(true)
    else setIsChecked(false)
  }

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault()
    const captchaToken = await recaptchaRef?.current?.getValue() // Provides the recapcha token
    if (captchaToken?.length !== 0) {
      const res = await API.post('/testReCaptcha', { captchaToken })
      console.log('is human?', res.data.success)
    } else {
      setIsChecked(false)
    }
  }

  if (
    ![
      'localhost',
      'pouch-site-dev.s3-website-us-east-1.amazonaws.com'
    ].includes(window.location.hostname) // To only show it in dev env
  ) {
    return <Redirect to='/' />
  }

  return (
    <div className='container'>
      <div className='mt-5 mb-5'>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={RecaptchaSiteKey} // It's necessary to add the recaptcha site key
              hl='en'
              onChange={handleChange}
            />
            {!isChecked && (
              <FormHelperText className={classes.errorMessage}>
                Please check the box to proceed
              </FormHelperText>
            )}
          </FormControl>

          <br />
        </form>
      </div>
    </div>
  )
}
