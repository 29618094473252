import React, { useState } from 'react'
import DialogBox from './components/DialogBox'
import Header from './components/Header'
import PaymentMethod from './components/PaymentMethod'
import UpperForm from './components/UpperForm'
import { useToggle } from './hooks/useToggle'
import { useWindowSize } from './hooks/useWindowSize'
import BankAccountForm from './components/BankAccountForm'
import CreditCardForm from './components/CreditCardForm'
import { PolicyDataTypes } from './interfaces'

const Standalone: React.FC = () => {
  const [dataPolicy, setDataPolicy] = useState<PolicyDataTypes | null>(null)
  const { paymentMethodChoice, selectPaymentMethod } = useToggle()
  const size = useWindowSize()
  const { width } = size

  const newAmount = dataPolicy?.amount ?? '0.0'

  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <UpperForm dataPolicy={dataPolicy} setDataPolicy={setDataPolicy} />
      </div>
      <div>
        <PaymentMethod
          paymentMethodChoice={paymentMethodChoice}
          selectPaymentMethod={selectPaymentMethod}
          amount={newAmount}
        />
      </div>
      {width < 600 ? <DialogBox amount={newAmount} /> : ''}
      <div>
        {paymentMethodChoice
          ? (
            <CreditCardForm
              dataPolicy={dataPolicy}
              paymentMethodChoice={paymentMethodChoice}
            />
            )
          : (
            <BankAccountForm
              dataPolicy={dataPolicy}
              paymentMethodChoice={paymentMethodChoice}
            />
            )}
      </div>
    </div>
  )
}

export default Standalone
