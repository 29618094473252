import { CardComponent, CardNumber, CardExpiry, CardCVV } from '@chargebee/chargebee-js-react-wrapper'
import { useState, useEffect, createRef, ReactElement, Dispatch, SetStateAction, MouseEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  token: {
    padding: '10px'
  },
  input: {
    padding: '10px',
    borderRadius: '5px',
    position: 'relative',
    marginBottom: '20px',
    backgroundColor: '#fff',
    border: '1px solid #dddddd'
  },
  label: {
    left: '5px',
    top: '-10px',
    border: 'none',
    padding: '0 5px',
    fontSize: '0.7em',
    position: 'absolute',
    whiteSpace: 'nowrap',
    backgroundColor: '#fff'
  },
  error: {
    color: 'red',
    fontSize: '20px',
    textAlign: 'center',
    paddingBottom: '10px'
  }
})

interface IChargeBeeTokenize {
  hideButton?: boolean
  triggerSubmit?: string | boolean
  setTriggerSubmit?: Dispatch<SetStateAction<string | boolean>>
}

function ChargeBeeTokenize ({
  hideButton = false,
  triggerSubmit = false,
  setTriggerSubmit = (thing: any) => console.log('NoFunctionDefined', thing)
}: IChargeBeeTokenize): ReactElement {
  const classes = useStyles()
  const cardRef = createRef<any>()
  const [errors] = useState<any>({})
  const [incompleteCardMessage, setIncompleteCardMessage] = useState<string | null>(null)
  const [invalidCardMessage, setInvalidCardMessage] = useState<string | null>(null)
  const [incompleteExpirationMessage, setIncompleteExpirationMessage] = useState<string | null>(null)
  const [invalidCardExpirationMessage, setInvalidCardExpirationMessage] = useState<string | null>(null)
  const [incompleteCVV, setIncompleteCVV] = useState<string | null>(null)

  const submitLogic = (): void => {
    cardRef?.current?.tokenize()
      .then((beeRes: any) => {
        console.log('ChargeBeeToken', beeRes.token)
        setTriggerSubmit(beeRes.token)
      })
      .catch((beeErr: any) => {
        console.log('ChargeBeeError', beeErr)
        // setHasError(beeErr.message ?? 'Error while paying with ChargeBee')
      })
  }

  const onSubmit = (e: MouseEvent<HTMLButtonElement>): void => {
    if (e != null) e.preventDefault()
    submitLogic()
  }

  useEffect(() => {
    if (triggerSubmit === true) submitLogic()
    setTriggerSubmit(false)
  }, [triggerSubmit])

  const handleBlur = (status: any): any => {
    const error = {
      ...errors,
      [status.field]: status
    }
    const fieldError = Object.values(error)

    const incompleteCardNumber = fieldError.some((value: any) => value.field === 'number' && value.complete === false)

    const invalidCardNumber = fieldError.some((value: any) => value.field === 'number' && value.complete === true && value.error !== undefined)

    const incompleteExpiration = fieldError.some((value: any) => value.field === 'expiry' && value.complete === false)

    const invalidExpiration = fieldError.some((value: any) => value.field === 'expiry' && value.complete === true && value.error !== undefined)

    const incompleteCVV = fieldError.some((value: any) => value.field === 'cvv' && value.complete === false)

    incompleteCardNumber ? setIncompleteCardMessage('You need to fill in all credit card numbers') : setIncompleteCardMessage('')

    invalidCardNumber ? setInvalidCardMessage('Invalid Card Number') : setInvalidCardMessage('')

    incompleteExpiration ? setIncompleteExpirationMessage('You need to fill in expiration numbers') : setIncompleteExpirationMessage('')

    invalidExpiration ? setInvalidCardExpirationMessage('Expired card') : setInvalidCardExpirationMessage('')

    incompleteCVV ? setIncompleteCVV('You need to fill in all CVV digits') : setIncompleteCVV('')
  }

  return (
    <div className={classes.token}>
      <form>
        <CardComponent ref={cardRef} >
          <div className={classes.input}>
            <label className={classes.label}>Card number</label>
            <CardNumber placeholder='4111 1111 1111 1111' onBlur={handleBlur} />
          </div>
          {incompleteCardMessage !== null ? <div className={classes.error}>{incompleteCardMessage}</div> : null}
          {invalidCardMessage !== null ? <div className={classes.error}>{invalidCardMessage}</div> : null}
          <div className={classes.input}>
            <label className={classes.label}>Card Expiracy</label>
            <CardExpiry placeholder='MM / YY' onBlur={handleBlur} />
          </div>
          {incompleteExpirationMessage !== null ? <div className={classes.error}>{incompleteExpirationMessage}</div> : null}
          {invalidCardExpirationMessage !== null ? <div className={classes.error}>{invalidCardExpirationMessage}</div> : null}
          <div className={classes.input}>
            <label className={classes.label}>Card CVV</label>
            <CardCVV placeholder='CVV' onBlur={handleBlur} />
          </div>
          {incompleteCVV !== null ? <div className={classes.error}>{incompleteCVV}</div> : null}
        </CardComponent>
      </form>
      {
        !hideButton && <button onClick={onSubmit}>Submit</button>
      }
    </div>
  )
}

export default ChargeBeeTokenize
