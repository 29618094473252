import { ReactElement, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Header from './components/global/Header'
import Drawer from './components/global/Drawer'
import Money from './components/sections/Money'
import PouchLabel from './components/sections/PouchLabel'
import FreeVehicleSection from './components/sections/FreeVehicleSection'
import { ReactComponent as SvgFm } from './assets/forceByMojio.svg'
import { ReactComponent as SvgApp } from './assets/appulate.svg'
import coterieLogo from './assets/coterie_logo.png'
import genericLogo from './assets/genericLogo.png'

import {
  VehicleWeSupportCard,
  VehicleWeSupportLabel,
  FreeVehicleTrackingHomeSection,
  WhatWeOfferTopSection,
  WhatWeOfferBottomSection,
  CommercialAutoInsuranceHomeSection,
  PouchLabel as labelItems,
  PouchLabelCards
} from 'utils/carouselData'
import { startQuoteSectionStyles } from './components/styles/StartQuoteSection.styles'
import Footer from './components/global/Footer'
import PouchCarousel from './components/generalities/PouchCarousel'
import deleteLocalStorage from 'utils/deleteLocalStorage'
import { isMARKETING } from 'services/instanda'
import { envUrl } from 'utils/functions'

export default function Home (): ReactElement {
  const sqclasses = startQuoteSectionStyles()
  const [openMenu, setOpenMenu] = useState(false)


  const goToQuote = (): void => {
    window.location.href = `https://quote${envUrl}-v3.pouchinsurance.com`
  }

  useEffect(() => {
    deleteLocalStorage()
    location.href = 'https://www.pouchinsurance.com/'
  }, [])

  return (
    <>
      <Header setOpenMenu={setOpenMenu} />
      <Drawer isOpen={openMenu} setOpenMenu={setOpenMenu} />
      <Money />
      <PouchLabel labelItems={labelItems} />
      <Grid container direction='column'>
        <Grid item className={sqclasses.carouselKoalaItem}>
          <PouchCarousel items={PouchLabelCards} />
        </Grid>
      </Grid>
      <Grid container direction='column'>
        <FreeVehicleSection
          leftItems={FreeVehicleTrackingHomeSection}
          rightItems={WhatWeOfferTopSection}
        />
        <FreeVehicleSection
          leftItems={VehicleWeSupportCard}
          rightItems={VehicleWeSupportLabel}
          pickOtherSide
          renderAsBoxes
          changeSectionSize={5}
        />
        <FreeVehicleSection
          leftItems={CommercialAutoInsuranceHomeSection}
          rightItems={WhatWeOfferBottomSection}
        />
        <div className={sqclasses.partnerTitle}>Our Partners</div>
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          className={sqclasses.partnerSection}
          spacing={2}
        >
          <Grid item>
            <a href='https://appulate.com/' target='_blank' rel='noreferrer'>
              <SvgApp className={sqclasses.partnerLogo} />
            </a>
          </Grid>
          <Grid item>
            <a
              href='https://forcebymojio.com/'
              target='_blank'
              rel='noreferrer'
            >
              <SvgFm className={sqclasses.partnerLogo} />
            </a>
          </Grid>
          <Grid item>
            <a
              href={isMARKETING ? '/' : 'https://coterieinsurance.com/'}
              target='_blank'
              rel='noreferrer'
              onClick={(e: any) => isMARKETING && e.preventDefault()}
            >
              <img
                alt='coterie_logo'
                src={isMARKETING ? genericLogo : coterieLogo}
                className={sqclasses.partnerLogo}
              />
            </a>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12} md={7} className={sqclasses.startQuoteImgContainer} />
            <Grid item xs={12} md={5} className={sqclasses.startQuoteBtnContainer}>
              <button className={sqclasses.btn} onClick={goToQuote}>
                GET A QUOTE TODAY
              </button>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Footer />
    </>
  )
}
