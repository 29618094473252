import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { ReactElement, useEffect, useContext, useState } from 'react'
import { format, add } from 'date-fns'
// Components
import Title from 'components/Title'
import TextField from 'components/TextField'
import Modal from '../Join/components/AutoAndGlbopModal'
// Utils
import errorHandler from 'utils/errorHandler'
import { ContactSchema } from 'utils/validations'
// Services
import { supportedStates } from 'services/instadaModel'
import { StartQuote, GetLocalQuoteData, UpdateQuoteDataNoRate, GetBussinesClass, quoteRef, isAGENT, getSalesPerson } from 'services/instanda'
// Context
import { useFormContext } from 'context/FormContext'
import { ContactFormContext } from 'context/ContactFormContext'
// Hooks
import useFormPercent from 'hooks/useFormPercent'
import useMixpanelTrack from 'hooks/useMixpanelTrack'
import { getCoterieQuestions } from 'services/coterie'
import TypedInput from 'components/TypedInput'
import { dateAddDays, dateOfToday } from 'utils/calculations'
import { getSalesNamesofBrands } from 'utils/configBrands'
import { FormControlLabel } from '@material-ui/core'
import PouchSwitch from 'views/MTA/components/PouchSwitch/PouchSwitch'

interface ContactFormType {
  ContactEmail_TXT: string
  ContactTitle_TXT: string
  ContactFirstName_TXT: string
  ContactLastName_TXT: string
  ContactPhone_TXT: string
  BusinessNameDBA_TXT: string
  BusinessAddressLine1_TXT: string
  BusinessAddressLine2_TXT: string
  BusinessCity_TXT: string
  BusinessState_TXT: string
  BusinessZip_TXT: string
  BusinessSubClass_CHOICE: string
  numEmployees: string
  policyType: string
  PolicyEffective_DATE?: string
  manually: boolean
}

export default function Page1 (): ReactElement {
  const manuallyLS = String(localStorage.getItem('manually'))
  let manuallyBool = manuallyLS === 'true' ? true : false
  const agentFinish = String(localStorage.getItem('agentFinish'))
  const isCompleteQuote = agentFinish === 'true' ? true : false
  const comingBack = String(localStorage.getItem('comingBack'))
  const isComingBack = comingBack === 'true' ? true : false
  useMixpanelTrack('quote1-tracking')
  const formContext = useFormContext()
  const { enqueueSnackbar } = useSnackbar()
  const [classes, setClasses] = useState([])
  const [openDialog, setOpenDialog] = useState(true)
  const contactFormContext = useContext(ContactFormContext)
  const [coterieData, setCoterieData] = useState(null) as any
  const [supportedClass, setsupportedClass] = useState(true)
  const [formatPhoneNum, setFormatPhoneNum] = useState<string>('')
  const [manually, setManually] = useState(manuallyBool)
  const [touched, setTouched] = useState(false)

  // console.log('fomr', formContext)

  useEffect(() => {
    formContext.setForm(formikForm) // Set current form
    if (formContext.quote != null) {
      formikForm.setValues(formContext.quote)
        .then(() => {
          const homeBusiness = localStorage.getItem('businessNamePick')
          if (homeBusiness !== null) {
            formikForm.setFieldValue('BusinessSubClass_CHOICE', homeBusiness)
              .then(() => {
                localStorage.removeItem('businessNamePick')
              })
              .catch((changeBusinessError) => {
                console.log({ changeBusinessError })
              })
          }
        })
        .catch((formSetError) => {
          console.log({ formSetError })
        })
    }
  }, [formContext.quote])

  useEffect(() => {
    async function getAllClasses (): Promise<any> {
      try {
        let values = [] as any
        const result = await GetBussinesClass()
        result.data.result.map((item: any) =>
          values.push({ value: item.className, label: item.className })
        )

        values = values.sort(SortArray)
        setClasses(values)
      } catch (error) {
        console.log(error)
      }
    }
    getAllClasses().catch((error) => console.log({ error }))
  }, [])

  useEffect(() => {
    console.log('Dirty', formContext.form.dirty)
    localStorage.setItem('manually', JSON.stringify(manually))
    if (touched) {
      // formikForm.setFieldValue('manually', manually)
      formikForm.setFieldValue('BusinessAddressLine1_TXT', '')
      formikForm.setFieldValue('BusinessAddressLine2_TXT', '')
      formikForm.setFieldValue('BusinessCity_TXT', '')
      formikForm.setFieldValue('BusinessState_TXT', '')
      formikForm.setFieldValue('BusinessZip_TXT', '')
    }

  }, [manually])

  function SortArray (x: any, y: any) {
    if (x.label < y.label) { return -1 }
    if (x.label > y.label) { return 1 }
    return 0
  }

  const nextPage = (): void => {
    formContext.setLoading(false)
    formContext.goNextPage()
  }

  const handleOpenGlBopModal = (): void => {
    setOpenDialog((isOpen) => !isOpen)
    formikForm.setFieldValue('policyType', localStorage.getItem('policyType'))
    if (localStorage.getItem('policyType') === 'glbop') {
      if (coterieData?.BUSINESSCLASSES[formikForm.values.BusinessSubClass_CHOICE] === undefined && formikForm.values.BusinessSubClass_CHOICE !== '') {
        if (formContext.changeIndication !== undefined) {
          formContext.setSideText('If your business doesn\'t have a GL/BOP offer, you can still continue with your Commercial Auto Quote.')
          setsupportedClass(false)
        }
      }
    }
  }

  const getQuote = async (): Promise<void> => {
    if (quoteRef()) {
      const response = await GetLocalQuoteData(quoteRef()) // Get from generated quote
      const result = await response?.data
      return result
    }
  }

  const formikForm = useFormik({
    enableReinitialize: true,
    initialValues: contactFormContext.values as ContactFormType,
    validationSchema: ContactSchema,
    onSubmit: (quoteValues) => {
      console.log("Find problem")
      const quoteValuesCopy = {
        ...quoteValues,
        policyType: coterieData?.BUSINESSCLASSES[quoteValues.BusinessSubClass_CHOICE] === undefined ? 'commercial' : quoteValues.policyType
      }
      formContext.setQuote(quoteValuesCopy) // Setting formContext.quote values
      if (coterieData?.BUSINESSCLASSES[quoteValues.BusinessSubClass_CHOICE] === undefined) {
        localStorage.setItem('policyType', 'commercial')
      }
      const { numEmployees, policyType, ...quoteCopy } = quoteValues
      if (quoteRef() !== '') {
        UpdateQuoteDataNoRate(formikForm.values, quoteRef())
          .then((data: any) => {
            localStorage.setItem('PolicyEffective_DATE', JSON.stringify(data.PolicyEffective_DATE || todayDate()))
            console.log(formContext.quote, data)
          })
          .catch(error => {
            console.log(error)
            formContext.setLoading(false)
          })
          .finally(() => formContext.goNextPage())
      } else {
        formContext.setLoading(true)
        const newObject = quoteCopy
        // StartQuote(newObject)
        //   .then((result) => {
        //     formContext.setLoading(false)
        //     localStorage.setItem('instandaQuoteRef', result?.data?.quoteRef)
        //     nextPage()
        //   })
        //   .catch((resErr) => {
        //     formContext.setLoading(false)
        //     errorHandler(resErr, enqueueSnackbar)
        //   })
        //   .finally(() => {
        //     formContext.setQuote({ ...newObject, numEmployees })
        //   })
      }
    }
  })

  useEffect(() => { // Handle phone number format
    const phoneNumberinQuote = formContext?.quote?.ContactPhone_TXT
    if (phoneNumberinQuote !== null) {
      setFormatPhoneNum(phoneNumberinQuote)
    }
    manuallyBool = manuallyLS === 'true' ? true : false
    console.log(manuallyBool)
    setManually(manuallyBool)
  }, [])

  useEffect(() => { // Handle quote copy function
    async function copyQuote (): Promise<any> {
      formContext.setLoading(true)
      const quoteRefCopy = localStorage.getItem('quoteRefCopy')
      if (quoteRefCopy != null) {
        // GetQuote
        const resultGet = await GetLocalQuoteData(quoteRefCopy)
        const resultGetFinal = resultGet?.data
        console.log({ resultGetFinal })
        formContext.setLoading(true)
        // StartQuote
        const resultStart = await StartQuote(resultGetFinal)
        const resultStartFinal = resultStart?.data
        console.log({ resultStartFinal })
        formContext.setLoading(true)
        // SetQuoteRef
        const finalQuoteRef = resultStartFinal?.quoteRef
        localStorage.setItem('instandaQuoteRef', finalQuoteRef)
        console.log({ finalQuoteRef })
        formContext.setLoading(true)
        // UpdateQuote
        const resultsUpdate = await UpdateQuoteDataNoRate(resultGetFinal, finalQuoteRef)
        const resultsUpdateFinal = resultsUpdate?.data
        console.log({ resultsUpdateFinal })
        // SetQuoteLocal
        const resultGetAfterUpdate = await GetLocalQuoteData(finalQuoteRef) // Get from generated quote
        const resultGetAfterUpdateFinal = resultGetAfterUpdate?.data
        const instandaFullQuote =  JSON.parse(localStorage.getItem('instandaFullQuote') as string)
        const fullQuoteLS = {
          ...instandaFullQuote,
          ...resultGetAfterUpdateFinal,
          PolicyEffective_DATE: formikForm.values.PolicyEffective_DATE
        }
        localStorage.setItem('instandaFullQuote', JSON.stringify(fullQuoteLS))
        formContext.setQuote(fullQuoteLS)
      }
    }

    copyQuote()
      .catch((copyQuoteError) => {
        console.log({ copyQuoteError })
        errorHandler('There was an error copying the quote', enqueueSnackbar)
      })
      .finally(() => {
        localStorage.removeItem('quoteRefCopy')
        formContext.setLoading(false)
      })
  }, [])

  const handlePhoneInput = (value: string): void => {
    const phoneNumberValue = value.slice(0, 13)
    const formattedNumber = formatPhoneNumber(phoneNumberValue)
    setFormatPhoneNum(formattedNumber as string)
    formikForm.setFieldValue('ContactPhone_TXT', formattedNumber)
  }

  const formatPhoneNumber = (value: string): string | undefined => {
    if (value === '') return ''
    const phoneNumber = value.replace(/[^\d]/g, '')
    const phoneNumberLength = phoneNumber.length

    if (phoneNumberLength < 4) return phoneNumber

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const todayDate = (): string => {
    return `${format(add(new Date(), { days: 0, hours: 0 }), 'yyyy-MM-dd')}`
  }

  useFormPercent(0, formikForm, formContext)

  useEffect(() => {
    formContext.setForm(formikForm)
  }, []) // ImportantSetForm

  const getQuestions = async (): Promise<void> => {
    try {
      const questions = await getCoterieQuestions()
      setCoterieData(questions)
    } catch (error) {
      enqueueSnackbar('There was an error retrieving coterie question ', { variant: 'error' })
    }
  }

  useEffect(() => {
    getQuestions()
  }, [])

  useEffect(() => {
    !isAGENT() && formikForm.setFieldValue('PolicyEffective_DATE', todayDate())
  }, [openDialog])

  useEffect(() => {
    const instandaFullQuote =  JSON.parse(localStorage.getItem('instandaFullQuote') as string)
    const fullQuoteLS = {
      ...instandaFullQuote,
      PolicyEffective_DATE: formikForm.values.PolicyEffective_DATE
    }
    localStorage.setItem('instandaFullQuote', JSON.stringify(fullQuoteLS))
    localStorage.setItem('PolicyEffective_DATE', JSON.stringify(fullQuoteLS.PolicyEffective_DATE))
  }, [formikForm.values.PolicyEffective_DATE])

  const isAdjust = formContext.adjustPolicy

  const handleChangeClass = (e: any): void => {
    const value = e.target?.value ?? e
    if (coterieData?.BUSINESSCLASSES[value] === undefined && value !== '') {
      if (formContext.changeIndication !== undefined) {
        formContext.setSideText('If your business doesn\'t have a GL/BOP offer, you can still continue with your Commercial Auto Quote.')
        setsupportedClass(false)
      }
    } else {
      formContext.setSideText('To start a quote, complete the required information about the business and click next to continue.')
      setsupportedClass(true)
    }
  }

  useEffect(() => {
    if (isCompleteQuote) {
      formContext.setLoading(true)
      getQuote()
      .then((data: any) => {
        localStorage.setItem('instandaFullQuote', JSON.stringify(data))
        localStorage.setItem('PolicyEffective_DATE', JSON.stringify(data.PolicyEffective_DATE || todayDate()))
          console.log(formContext.quote, data)
        })
        .catch(error => {
          console.log(error)
          formContext.setLoading(false)
        })
        .finally(() => formContext.goNextPage())
    }
  }, [])

  if (formContext.loading) {
    return <div>loading...</div>
  } else {
    return (
      <div className='container'>
        <div className='row '>
          <form onSubmit={formikForm.handleSubmit}>
            <div className='col-12 mt-10'>
              {!isAdjust ? <Title>Customer Name</Title> : <Title isAdjust>Personal & Business Information</Title>}
            </div>
            <div className='row'>
              <div className={!isAdjust ? 'col-6' : 'col-3'}>
                <TextField
                  name='ContactFirstName_TXT'
                  placeholder='First Name'
                  form={formikForm}
                />
              </div>
              <div className={!isAdjust ? 'col-6' : 'col-3'}>
                <TextField
                  name='ContactLastName_TXT'
                  placeholder='Last Name'
                  form={formikForm}
                />
              </div>
              {isAdjust &&
                <>
                  <div className='col-3'>
                    <TextField
                      name='ContactEmail_TXT'
                      placeholder='Email'
                      type='email'
                      form={formikForm}
                    />
                  </div>
                  <div className='col-3'>
                    <TextField
                      name='BusinessSubClass_CHOICE'
                      placeholder='Business Class'
                      form={formikForm}
                      options={classes}
                      select
                      onMouseEnter={formContext.changeIndication}
                      onMouseLeave={formikForm.values.policyType === 'glbop' ? handleChangeClass : formContext.changeIndication}
                      onChange={formikForm.values.policyType === 'glbop' ? handleChangeClass : undefined}
                      message={!supportedClass ? 'The business you selected doesn\'t have a GL/BOP offer at the moment.' : undefined}
                    />
                  </div>
                </>}
            </div>
            {!isAdjust && <div className='col-12'>
              <TextField
                name='ContactEmail_TXT'
                placeholder='Email'
                type='email'
                form={formikForm}
              />
            </div>}

            {isAGENT()
              ? (
                <>
                  <div className='col-12 mt-10'>
                    <Title>About Your Quote</Title>
                  </div>
                  <div className='col-12'>
                    <TypedInput
                      formikForm={formikForm}
                      inputType='date'
                      inputTitle='Effective Policy Date'
                      inputValue='PolicyEffective_DATE'
                      minDate={dateOfToday()}
                      maxDate={dateAddDays(dateOfToday(), 30)}
                    />
                  </div>
                </>
                )
              : null}
            <div className='col-12 mt-10'>
              {!isAdjust && <Title>Business Information</Title>}
            </div>
            {!isAdjust && <div className='col-12'>
              <TextField
                name='BusinessNameDBA_TXT'
                placeholder='Business Name'
                form={formikForm}
              />
                          </div>}
            {!isAdjust && <div className='col-12'>
              <TextField
                name='BusinessSubClass_CHOICE'
                placeholder='Business Class'
                form={formikForm}
                options={classes}
                select
                onMouseEnter={formContext.changeIndication}
                onMouseLeave={formikForm.values.policyType === 'glbop' ? handleChangeClass : formContext.changeIndication}
                onChange={formikForm.values.policyType === 'glbop' ? handleChangeClass : undefined}
                message={!supportedClass ? 'The business you selected doesn\'t have a GL/BOP offer at the moment.' : undefined}
              />
                          </div>}
            {isAdjust &&
              <div className='row'>
                <div className='col-6'>
                  <TextField
                    name='BusinessNameDBA_TXT'
                    placeholder='Business Name'
                    form={formikForm}
                  />
                </div>
                <div className='col-6'>
                  <TextField
                    name='ContactPhone_TXT'
                    placeholder='Business Phone'
                    form={formikForm}
                    onChange={handlePhoneInput}
                    value={formatPhoneNum}
                  />
                </div>
              </div>}
            {!isAdjust &&
              <div className='col-12'>
                <TextField
                  name='ContactPhone_TXT'
                  placeholder='Business Phone'
                  form={formikForm}
                  onChange={handlePhoneInput}
                  value={formatPhoneNum}
                />
              </div>}
            {!isAdjust && <div className='col-12'>
              <TextField
                name='BusinessAddressLine1_TXT'
                placeholder='Business Address'
                placePicker={!manually}
                form={formikForm}
              />
                          </div>}
            {!isAdjust && <div className='col-12'>
              <TextField
                name='BusinessAddressLine2_TXT'
                inputLabel='Business Address Line 2 (Optional)'
                placeholder='Address Line'
                form={formikForm}
              />
              {
                manually &&
                <>
                  <TextField
                    name='BusinessCity_TXT'
                    placeholder='City'
                    form={formikForm}
                  />
                  <TextField
                    name='BusinessState_TXT'
                    placeholder='State'
                    form={formikForm}
                    select
                    options={supportedStates}
                  />
                </>
              }
                          </div>}
            {
              (formikForm.values.policyType === 'glbop') &&
                <div className='col-12'>
                  <TextField
                    name='numEmployees'
                    placeholder='Number of employees'
                    type='text'
                    form={formikForm}
                    onChange={(e: any) => {
                      if (/[^0-9]/.test(e)) { // to only let enter numbers on this field
                        const value = `${e}`.split('').filter((c: any) => !/[^0-9]/.test(c)).join('')
                        formikForm.setFieldValue('numEmployees', value).catch((error) => console.log(error))
                      }
                    }}
                  />
                </div>
            }
            {isAdjust &&
              <div className='row'>
                <div className='col-6'>
                  <TextField
                    name='BusinessAddressLine1_TXT'
                    placeholder='Business Address'
                    placePicker
                    form={formikForm}
                  />
                </div>
                <div className='col-6'>
                  <TextField
                    name='BusinessZip_TXT'
                    placeholder='ZIP'
                    form={formikForm}
                  />
                </div>
              </div>}

            {!isAdjust && <div className='col-12'>
              <TextField
                name='BusinessZip_TXT'
                placeholder='ZIP'
                form={formikForm}
              />
                          </div>}
            <div className='col-12'>
              <FormControlLabel
                control={<PouchSwitch manually={manually} setManually={setManually} setTouched={setTouched} />}
                label=''
              />
            </div>
          </form>
        </div>
        {
          isAGENT()?
            null: <Modal open={openDialog} onClose={handleOpenGlBopModal} />
        }
      </div>
    )
  }
}
