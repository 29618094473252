import React from 'react'
import { PaymentMethodChoice } from '../interfaces'
import creditCardIcon from '../assets/credit-card-icon.png'
import eCheckIcon from '../assets/echeck-icon.png'
import '../styles/PaymentMethod.css'

const PaymentMethod: React.FC<PaymentMethodChoice> = (props) => {
  const { paymentMethodChoice, selectPaymentMethod, hiddenClassName } = props

  const prop = {
    titleOne: 'Credit Card',
    titleTwo: 'ACH',
    amountTitle: 'Amount',
    amountMoney: props.amount,
    fee: 'Fee',
    feeMoney: '0.0',
    total: 'Total',
    totalMoney: props.amount
  }

  const {
    titleOne,
    titleTwo,
    amountTitle,
    fee,
    total,
    amountMoney,
    feeMoney,
    totalMoney
  } = prop

  return (
    <div
      className={
        hiddenClassName === null ||
        hiddenClassName === undefined ||
        !hiddenClassName
          ? 'sapm'
          : 'pm'
      }
      onClick={selectPaymentMethod}
    >
      <div
        className={
          hiddenClassName === null ||
          hiddenClassName === undefined ||
          !hiddenClassName
            ? paymentMethodChoice
              ? 'sapm-container-on'
              : 'sapm-container-off'
            : paymentMethodChoice
              ? 'pm-container-on'
              : 'pm-container-off'
        }
      >
        <div
          className={
            hiddenClassName === null ||
            hiddenClassName === undefined ||
            !hiddenClassName
              ? 'sapm-container-top-line'
              : 'pm-container-top-line'
          }
        />
        <div className='sapm-container-header'>
          <div>
            <img src={creditCardIcon} alt='credit-card-icon' />
          </div>
          <div>
            <div>{titleOne}</div>
          </div>
        </div>
        <hr />
        <div
          className={
            hiddenClassName === null ||
            hiddenClassName === undefined ||
            !hiddenClassName
              ? 'sapm-container-payer-info'
              : 'pm-container-payer-info'
          }
        >
          <div>
            <label htmlFor='amount'>{amountTitle}</label>
            <input disabled type='text' value={`$ ${amountMoney}`} />
          </div>
          <div>
            <label htmlFor='fee'>{fee}</label>
            <input disabled type='text' value={`$ ${feeMoney}`} />
          </div>
          <div>
            <label htmlFor='total'>{total}</label>
            <input
              disabled
              type='text'
              name='total'
              value={`$ ${totalMoney}`}
            />
          </div>
        </div>
        <div
          className={
            hiddenClassName === null ||
            hiddenClassName === undefined ||
            !hiddenClassName
              ? 'sapm-container-circle'
              : 'pm-container-circle'
          }
        >
          <div
            className={
              hiddenClassName === null ||
              hiddenClassName === undefined ||
              !hiddenClassName
                ? paymentMethodChoice
                  ? 'sapm-container-circle-selector-on'
                  : 'sapm-container-circle-selector-off'
                : paymentMethodChoice
                  ? 'pm-container-circle-selector-on'
                  : 'pm-container-circle-selector-off'
            }
          />
        </div>
      </div>
      {/* <div
        className={
          hiddenClassName === null ||
          hiddenClassName === undefined ||
          !hiddenClassName
            ? paymentMethodChoice
              ? 'sapm-container-off'
              : 'sapm-container-on'
            : paymentMethodChoice
              ? 'pm-container-off'
              : 'pm-container-on'
        }
        onClick={selectPaymentMethod}
      >
        <div
          className={
            hiddenClassName === null ||
            hiddenClassName === undefined ||
            !hiddenClassName
              ? 'sapm-container-top-line'
              : 'pm-container-top-line'
          }
        />
        <div className='sapm-container-header'>
          <div>
            <img src={eCheckIcon} alt='echeck-icon' />
          </div>
          <div>
            <div>{titleTwo}</div>
          </div>
        </div>
        <hr />
        <div
          className={
            hiddenClassName === null ||
            hiddenClassName === undefined ||
            !hiddenClassName
              ? 'sapm-container-payer-info'
              : 'pm-container-payer-info'
          }
        >
          <div>
            <label htmlFor='amount'>{amountTitle}</label>
            <input disabled type='text' value={`$ ${amountMoney}`} />
          </div>
          <div>
            <label htmlFor='fee'>{fee}</label>
            <input disabled type='text' value={`$ ${feeMoney}`} />
          </div>
          <div>
            <label htmlFor='total'>{total}</label>
            <input
              disabled
              type='text'
              name='total'
              value={`$ ${totalMoney}`}
            />
          </div>
        </div>
        <div
          className={
            hiddenClassName === null ||
            hiddenClassName === undefined ||
            !hiddenClassName
              ? 'sapm-container-circle'
              : 'pm-container-circle'
          }
        >
          <div
            className={
              hiddenClassName === null ||
              hiddenClassName === undefined ||
              !hiddenClassName
                ? paymentMethodChoice
                  ? 'sapm-container-circle-selector-off'
                  : 'sapm-container-circle-selector-on'
                : paymentMethodChoice
                  ? 'pm-container-circle-selector-off'
                  : 'pm-container-circle-selector-on'
            }
          />
        </div>
      </div> */}
    </div>
  )
}

export default PaymentMethod
