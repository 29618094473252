import { ReactElement } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import { Link } from 'react-router-dom'
import { Grid, AppBar, Toolbar, IconButton, makeStyles } from '@material-ui/core'

import logo from '../../assets/logo.svg'
import BtnPrimary from '../generalities/BtnPrimary'
import { envUrl } from 'utils/functions'

const useStyles = makeStyles({
  appBar: {
    marginTop: '0px',
    backgroundColor: 'white',
    boxShadow: 'none',
    marginButtom: '40px',
    top: 0
  },
  navBar: {
    backgroundColor: 'white',
    color: '#716262'
  },
  menuIcon: {
    marginLeft: 'auto',
    '@media (min-width:999px)': {
      display: 'none'
    },
    '@media (max-width:998px)': {
      display: 'block'
    }
  },
  specialMenuItem: {
    color: '#39C7C2',
    textDecoration: 'none',
    '&:hover': {
      color: '#37bfbb'
    }
  },
  menuItem: {
    marginRight: '40px',
    textDecoration: 'none',
    color: '#6b6b6b',
    '&:hover': {
      color: '#525252'
    }
  },
  menuContainer: {
    marginLeft: 'auto',
    '@media (min-width:999px)': {
      display: 'block'
    },
    '@media (max-width:998px)': {
      display: 'none'
    }
  },
  menuLogo: {
    width: '150px'
  }
})

export default function Header ({
  setOpenMenu
}: {
  setOpenMenu: (status: boolean) => void
}): ReactElement {
  const classes = useStyles()


  const goToQuote = (): void => {
    window.location.href = `https://quote${envUrl}-v3.pouchinsurance.com`
  }

  return (
    // This react component has all the sections of the landing page
    <AppBar position='sticky' className={classes.appBar}>
      <Toolbar className={classes.navBar}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <a className={classes.menuItem} href='https://www.pouchinsurance.com'>
              <img className={classes.menuLogo} alt='brand-pouch' src={logo} />
            </a>
            {/* <Link className={classes.menuItem} to='/'>
              <img className={classes.menuLogo} alt='brand-pouch' src={logo} />
            </Link> */}
          </Grid>
          <Grid item>
            <div className={classes.menuContainer}>
              {/* <Link className={classes.menuItem} to='/'>
                <span>OUR SERVICES</span>
              </Link>
              <Link className={classes.menuItem} to='/about-us'>
                <span>ABOUT US</span>
              </Link>
              <Link className={classes.menuItem} to='/partners'>
                <span>PARTNERS</span>
              </Link>
              <Link className={classes.menuItem} to='/'>
                <span>FAQ</span>
              </Link>
              <Link className={classes.menuItem} to='/'>
                <span>FOR AGENTS</span>
              </Link> */}
            </div>
          </Grid>
          <Grid item>
            <div className={classes.menuContainer}>
              <Link className={classes.menuItem} to='/about-us'>
                <span>ABOUT US</span>
              </Link>
              <Link className={classes.menuItem} to='/partners'>
                <span>PARTNERS</span>
              </Link>
              {/* <Link className={classes.specialMenuItem} to="/">
                <span>SIGN IN</span>
              </Link> */}
              <BtnPrimary onClick={goToQuote}>GET A QUOTE</BtnPrimary>
            </div>
          </Grid>
        </Grid>
        <IconButton className={classes.menuIcon} edge='start' color='inherit' aria-label='menu'>
          <MenuIcon onClick={() => { setOpenMenu(true) }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
