import { useEffect } from 'react'

const appendScript = (scriptToAppend: string): void => {
  const script = document.createElement('script')
  script.src = scriptToAppend
  script.async = true
  document.body.appendChild(script)
}

const removeScript = (scriptToremove: string): void => {
  const allsuspects = document.getElementsByTagName('script')
  for (let i = allsuspects.length; i >= 0; i--) {
    if (allsuspects[i] && allsuspects[i].getAttribute('src') !== null && allsuspects[i].getAttribute('src')!.includes(`${scriptToremove}`)) {
      allsuspects[i].parentNode!.removeChild(allsuspects[i])
    }
  }
}

export default function useMixpanelTrack (fileName: string): void {
  useEffect(() => {
    appendScript(`/${fileName}.js`)
  }, [])
  useEffect(() => () => {
    removeScript(`/${fileName}.js`)
  }, [])
}
