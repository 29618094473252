import { ReactNode, ReactElement, useState } from 'react'
import { Grid, Container, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import { Link } from 'react-router-dom'
// Components
import StepperImage from './StepperImage'
import StepperSidebar from './StepperSidebar'
// Images
import stepperPouch from './assets/stepper-pouch.svg'
// Utils
import { Bind, Join } from './index'
import { isAGENT, agentDashboardURL, getSalesPerson, defaultSalesPerson } from 'services/instanda'
// Context
import { useFormContext } from 'context/FormContext'
import SteperHamburgerMenu from './SteperHamburgerMenu'
// hooks
import { useWindowSize } from 'views/Standalone/hooks/useWindowSize'
import { getBrandBySalesName, getSalesNamesofBrands } from 'utils/configBrands'

const sidePercent = 49

const useStyles = makeStyles(theme => ({
  pageContainer: {
    paddingTop: '30px',
    paddingBottom: '20px'
  },
  pageHalfGray: {
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.up('md')]: {
      background: `linear-gradient(to right, #F7F7F7 0% ${sidePercent}%, white ${sidePercent}% 100%)`
    }
  },
  stepperMain: {
    padding: '10px',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: '#f7f7f7'
  },
  stepperImage: {
    '& img': {
      height: '65px'
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      alignItems: 'left'
    }
  },
  stepperElement: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '6px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }
  },
  menuIcon: {
    marginRight: 50,
    padding: 0,
    '&:hover': {
      borderRadius: 0,
      backgroundColor: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      height: '50px',
      width: '50px',
      '@media (max-width: 576px)': {
        height: '1em',
        width: '1em'
      }
    }
  }
}))

interface IStepperContainer {
  showSidebar?: boolean
  halfGray?: boolean
  typeSidebar: 'Bind' | 'Join'
  MVRError?: boolean
  children: ReactNode
}

export default function StepperContainer ({ showSidebar = true, halfGray = false, typeSidebar, MVRError, children }: IStepperContainer): ReactElement {
  const classes = useStyles()
  const formContext = useFormContext()
  const [open, setopen] = useState(false)
  const coterieValues = JSON.parse(localStorage.getItem('coterieValues') ?? '{}')
  const windowSize = useWindowSize()

  const { width } = windowSize

  // Join is first part of the form and Bind is the second part
  const whichType = typeSidebar === 'Bind' ? Bind : Join
  const qtyReduce = typeSidebar === 'Bind' ? Join.length + 1 : 1
  const sideText = formContext.thisPage === 4 && formContext.displayGlBopView
    ? (coterieValues.applicationTypes?.[0] === 'BOP'
      ? whichType[formContext.thisPage - qtyReduce]?.rightTooltip?.BOP
      : whichType[formContext.thisPage - qtyReduce]?.rightTooltip?.GL)
    : whichType[formContext.thisPage - qtyReduce]?.sideText

  const getLogoBrand = () => {
    if(getSalesPerson() != defaultSalesPerson && !isAGENT()){
     return <a href={getBrandBySalesName(getSalesPerson())?.url}>
        <img src={getBrandBySalesName(getSalesPerson())?.logo} alt='agent-logo' />
      </a>
    } 
  }
  return (
    <section className={halfGray ? classes.pageHalfGray : ''}>
      <div className={classes.stepperMain}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3} md={3} lg={4} className={classes.stepperImage}>
            {
              isAGENT() ? (
                <>
                  <a href={agentDashboardURL}>
                    <img src={stepperPouch} alt='agent-logo' />
                  </a>
                  {
                    getLogoBrand()
                  }
                </>
              ) : (
                <>
                  <a href='https://pouchinsurance.com/'>
                    <img src={stepperPouch} alt='pouch-logo' />
                  </a>
                  {
                    getLogoBrand()
                  }
                </>                
              )
            }
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={4}>
            <section className={classes.stepperElement}>
              <Grid container spacing={1}>
                {
                  (width < 540)
                    ? (
                      <IconButton className={classes.menuIcon} onClick={() => { setopen(!open) }} edge='start' color='inherit' aria-label='menu'>
                        <MenuIcon fontSize='large' />
                      </IconButton>
                    )
                    : (
                      ''
                    )
                }
                {
                  (width > 540)
                    ? (
                      whichType.map((iST, iIN) => (
                        <StepperImage key={iIN} image={iST.image} text1={iST.text1} text2={iST.text2} min={iST.min} max={iST.max} />
                      ))
                    )
                    : ('')
                }
              </Grid>
            </section>
          </Grid>
        </Grid>
      </div>
      <SteperHamburgerMenu showHamburgerMenu={open} setShowHamburgerMenu={setopen} typeSidebar={typeSidebar}/>
      <Container className={classes.pageContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm md>
            {children}
          </Grid>
          {showSidebar ? (
            <Grid item xs={12} sm={5} md={4}>
              <StepperSidebar sideMain={whichType[formContext.thisPage - qtyReduce].sideMain} MVRError={MVRError} sideText={`${sideText ?? ''}`} />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </section>
  )
}
