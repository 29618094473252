import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { LocalShipping as CarIcon } from '@material-ui/icons'
import Collapse from '../components/Collapse/Collapse'
import Select from '../components/Select/Select'
import { Box } from '@material-ui/core'
import AddButton from 'views/Join/components/AddButton'
import { VehicleSchemaMTA } from 'utils/validations'
import { isEmpty } from 'lodash'
import { generateId } from 'utils/functions'
import { devAPI, GetAllYears, GetLocalQuoteData, GetMakeByYear, GetModelByYearAndMake } from 'services/instanda'
import { useAppSelector } from 'hooks/useAppSelector'
import errorHandler from 'utils/errorHandler'
import { useSnackbar } from 'notistack'
import { dispatchApp } from 'redux/store'
import { getInfoQuote, updateQuotePouch } from 'redux/slices/quote';
import Title from 'components/Title'




export const vehicleGenerator = (addOffset: number): any => {
  return {
    VehicleMake_TXT: '',
    VehicleModel_TXT: '',
    VehicleYear_NUM: '',
    VehicleBody_TXT: '',
    VehicleIncluded_YN: 'Yes',
    VehicleVINOverride_TXT: '',
    VehicleUpdate_DATE: Date.now() + addOffset,
    Lienholder_YNP: 'No',
    LienholderName_TXT: '',
    LienholderAddressLine1_TXT: '',
    LienholderCountry_TXT: 'United States',
    LienholderCity_TXT: '',
    LienholderState_TXT: '',
    LienholderZipCode_TXT: '',
    LienholderLoanNumber_TXT: '',
    VehicleAdditionalInsured_YN: 'No',
    VehiclePolicyParticipationTelematics_YN: 'No',
    VehicleOverrideDeductCOLL_CHOICE: '',
    VehicleOverrideDeductCOMP_CHOICE: '',
    VehicleOverrideLimitUMPD_IL_CHOICE: ''
  }
}

const VehicleInfo = ({ isAdjust, verifyErrors, setVerifyErrors, isSending }: any) => {
  const [id, setId] = useState('');
  const [telematics, setTelematics] = useState('No');
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch);
  const { enqueueSnackbar } = useSnackbar();
  let VehiclePolicyParticipationTelematics_YN = 'No'

  const formikFormVehicles = useFormik({
    initialValues: [
      {
        VehicleMake_TXT: '',
        VehicleModel_TXT: '',
        VehicleYear_NUM: 0,
        VehicleBody_TXT: '',
        VehicleIncluded_YN: 'Yes',
        VehicleVINOverride_TXT: '',
        VehicleUpdate_DATE: 0,
        Lienholder_YNP: 'No',
        LienholderName_TXT: '',
        LienholderAddressLine1_TXT: '',
        LienholderCountry_TXT: 'United States',
        LienholderCity_TXT: '',
        LienholderState_TXT: '',
        LienholderZipCode_TXT: '',
        LienholderLoanNumber_TXT: '',
        VehicleAdditionalInsured_YN: 'No',
        // VehiclePolicyParticipationTelematics_YN: 'No',
        VehicleOverrideDeductCOLL_CHOICE: '',
        VehicleOverrideDeductCOMP_CHOICE: '',
        VehicleOverrideLimitUMPD_IL_CHOICE: ''
      }
    ],
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: VehicleSchemaMTA,
    onSubmit: onSubmit
  })

  const setValues = async () => {
    const vehicleValues = { Vehicles: formikFormVehicles.values }
    dispatchApp(updateQuotePouch({
      ...quote,
      Vehicles: vehicleValues.Vehicles,
      VehiclePolicyParticipationTelematics_YN: telematics
    }))
  }
  // const formContext = useFormContext()
  async function onSubmit(): Promise<void> {

  }

  const addButton = async (): Promise<void> => {
    await formikFormVehicles.setValues([
      ...formikFormVehicles.values,
      vehicleGenerator(formikFormVehicles.values.length)
    ])
  }

  const delButton = async (index: number): Promise<void> => {
    const modArray = [...formikFormVehicles.values]
    modArray.splice(index, 1)
    await formikFormVehicles.setValues(modArray)
  }

  const searchVehicleByVIN = async (vin: string, InputIndex: number) => {
    if (vin.length < 10) {
      await clearVehicleFields(InputIndex)
      // TODO: add same validation for Trailers
      await formikFormVehicles.setFieldTouched(`[${InputIndex}].VehicleVINOverride_TXT`)
      formikFormVehicles.setFieldError(`[${InputIndex}].VehicleVINOverride_TXT`, 'a VIN prefix with at least 10 characters is required')
      return
    }
    devAPI.post('/polkServices/vinDecoderTen', { vin }, { headers: { 'Content-Type': 'application/json' } })
      .then(async (vinVehicle) => {
        const vehicleData = vinVehicle.data
        const polkYear = `${vehicleData?.year as number}`
        const polkMake = `${vehicleData?.make?.toUpperCase() as string}`
        const polkModel = `${vehicleData?.model?.toUpperCase() as string}`
        const validYearArray = await GetAllYears()
        const validMakeArray = await GetMakeByYear(polkYear)
        const validModelArray = await GetModelByYearAndMake(polkYear, polkMake)
        const validYear = validYearArray?.data?.result
        const validMake = validMakeArray?.data?.result
        const validModel = validModelArray?.data?.result
        console.log(vehicleData)
        if (validYear?.includes(polkYear) === true && validMake?.includes(polkMake) === true && validModel?.includes(polkModel) === true) {
          console.log('Is valid')
          formikFormVehicles.setValues((vehicles) => {
            const newVehicles = vehicles.map((vehicle: any, index: number) => {
              const newVehicle = { ...vehicle, VehicleMake_TXT: polkMake, VehicleModel_TXT: polkModel, VehicleYear_NUM: polkYear }
              return InputIndex === index ? newVehicle : vehicle
            })
            return newVehicles
          })
        } else {
          await clearVehicleFields(InputIndex)
          await formikFormVehicles.setFieldTouched(`[${InputIndex}].VehicleVINOverride_TXT`)
          formikFormVehicles.setFieldError(`[${InputIndex}].VehicleVINOverride_TXT`, 'We couldn’t find your VIN, try by adding your vehicle manually')
        }
      })
      .catch(async (error) => {
        await clearVehicleFields(InputIndex)
        await formikFormVehicles.setFieldTouched(`[${InputIndex}].VehicleVINOverride_TXT`)
        formikFormVehicles.setFieldError(`[${InputIndex}].VehicleVINOverride_TXT`, 'We couldn’t find your VIN, try by adding your vehicle manually')
        console.log('Error getting information from polk vinDecoder', error)
      })
  }

  const clearVehicleFields = async (inputIndex: number) => {
    await formikFormVehicles.setValues((vehicles) => {
      const newVehicles = vehicles.map((vehicle: any, index: number) => {
        const newVehicle = { ...vehicle, VehicleMake_TXT: '', VehicleModel_TXT: '', VehicleYear_NUM: '' }
        return inputIndex === index ? newVehicle : vehicle
      })
      return newVehicles
    })
  }

  useEffect(() => {
    setId(generateId() + 'VehicleInfo');
    try {
      setTimeout(() => {
        if (quote?.Vehicles) {
          const newVehicles = quote?.Vehicles?.map((vehicle: any, index: number) => {
            const originalValues = vehicleGenerator(index)
            const vehicleValues = {
              ...vehicle,
              Lienholder_YNP: vehicle.Lienholder_YNP || 'No',
              LienholderAddressLine1_TXT: vehicle.LienholderAddressLine1_TXT || '',
              LienholderCity_TXT: vehicle.LienholderCity_TXT || '',
              LienholderCountry_TXT: vehicle.LienholderCountry_TXT || 'United States',
              LienholderLoanNumber_TXT: vehicle.LienholderLoanNumber_TXT || '',
              LienholderName_TXT: vehicle.LienholderName_TXT || '',
              LienholderState_TXT: vehicle.LienholderState_TXT || '',
              LienholderZipCode_TXT: vehicle.LienholderZipCode_TXT || '',
              VehicleAdditionalInsured_YN: vehicle.VehicleAdditionalInsured_YN || 'No',
              VehicleVINOverride_TXT: vehicle.VehicleVIN_TXT_Default != 'not provided' ? vehicle.VehicleVIN_TXT_Default : (vehicle.VehicleVIN_TXT || ''),
              VehicleOverrideDeductCOLL_CHOICE: vehicle.VehicleOverrideDeductCOLL_CHOICE ?? 'No Coverage',
              VehicleOverrideDeductCOMP_CHOICE: vehicle.VehicleOverrideDeductCOMP_CHOICE ?? 'No Coverage',
              VehicleOverrideLimitUMPD_IL_CHOICE: vehicle.VehicleOverrideLimitUMPD_IL_CHOICE ?? 'No Coverage',
              VehiclePolicyParticipationTelematics_YN: vehicle.VehiclePolicyParticipationTelematics_YN ?? 'No'
            }
            VehiclePolicyParticipationTelematics_YN = vehicle.VehiclePolicyParticipationTelematics_YN;
            return { ...originalValues, ...vehicleValues, VehiclePolicyParticipationTelematics_YN }
          })
          formikFormVehicles
            .setValues(newVehicles)
            .catch((formError: any) => console.log({ formError }))
        } else if (previousQuote?.Vehicles) {
          const newVehicles = previousQuote?.Vehicles?.map((vehicle: any, index: number) => {
            const originalValues = vehicleGenerator(index)
            const vehicleValues = {
              ...vehicle,
              Lienholder_YNP: vehicle.Lienholder_YNP || 'No',
              LienholderAddressLine1_TXT: vehicle.LienholderAddressLine1_TXT || '',
              LienholderCity_TXT: vehicle.LienholderCity_TXT || '',
              LienholderCountry_TXT: vehicle.LienholderCountry_TXT || 'United States',
              LienholderLoanNumber_TXT: vehicle.LienholderLoanNumber_TXT || '',
              LienholderName_TXT: vehicle.LienholderName_TXT || '',
              LienholderState_TXT: vehicle.LienholderState_TXT || '',
              LienholderZipCode_TXT: vehicle.LienholderZipCode_TXT || '',
              VehicleAdditionalInsured_YN: vehicle.VehicleAdditionalInsured_YN || 'No',
              VehicleVINOverride_TXT: vehicle.VehicleVIN_TXT_Default || '',
              VehicleOverrideDeductCOLL_CHOICE: vehicle.VehicleOverrideDeductCOLL_CHOICE ?? 'No Coverage',
              VehicleOverrideDeductCOMP_CHOICE: vehicle.VehicleOverrideDeductCOMP_CHOICE ?? 'No Coverage',
              VehicleOverrideLimitUMPD_IL_CHOICE: vehicle.VehicleOverrideLimitUMPD_IL_CHOICE ?? 'No Coverage',
              VehiclePolicyParticipationTelematics_YN: vehicle.VehiclePolicyParticipationTelematics_YN ?? 'No'
            }
            VehiclePolicyParticipationTelematics_YN = vehicle.VehiclePolicyParticipationTelematics_YN;
            return { ...originalValues, ...vehicleValues, VehiclePolicyParticipationTelematics_YN }
          })
          formikFormVehicles
            .setValues(newVehicles)
            .catch((formError: any) => console.log({ formError }))
        }
      }, 100);
    } catch (error) {
      errorHandler(error, enqueueSnackbar)
    }
  }, []);

  useEffect(() => {
    if (id !== '') {
      verifyErrors[id] = Object.keys(formikFormVehicles.errors).length > 0 ? true : false;
      console.log('VehicleInfo', id, verifyErrors[id], formikFormVehicles.errors)
      setVerifyErrors({
        ...verifyErrors
      });
    }
  }, [formikFormVehicles.errors]);

  useEffect(() => {
    setValues();
  }, [formikFormVehicles.values])

  return (
    <>
      <form
        onSubmit={formikFormVehicles.handleSubmit}
      >
        <Box maxWidth={860} margin='0 auto' padding={5}>
          <div className='col-12 mt-10'>
            <Title>Vehicles and Trailers</Title>
          </div>
          {
            formikFormVehicles.values?.map((_: any, index: number) => {
              const key = formikFormVehicles.values[index].VehicleUpdate_DATE
              const subtitle = isAdjust ? `
                ${formikFormVehicles.values[index].VehicleYear_NUM}
                ${formikFormVehicles.values[index].VehicleMake_TXT}
                ${formikFormVehicles.values[index].VehicleModel_TXT} - ${formikFormVehicles.values[index].VehicleVINOverride_TXT}
                ` :
                formikFormVehicles.values[index].VehicleModel_TXT
              const hasError =
                formikFormVehicles.submitCount !== 0 &&
                formikFormVehicles?.errors[index] != null
              return (
                <Collapse
                  key={key}
                  index={index}
                  title='Vehicle'
                  image={<CarIcon />}
                  subtitle={subtitle}
                  hasError={hasError}
                  delButton={delButton}
                  className='multi-item-card'
                >
                  <Select
                    isAdjust={isAdjust}
                    inputIndex={index}
                    formikForm={formikFormVehicles}
                    searchVehicleByVIN={searchVehicleByVIN}
                    isDisabled={isViewMode}
                    telematics={telematics}
                    setTelematics={setTelematics}
                  />
                </Collapse>
              )
            })
          }
          <div>

          </div>
          <AddButton
            text='Add a Vehicle'
            action={addButton}
            disable={!(formikFormVehicles?.values?.length < 50) || isViewMode}
          />
        </Box>
      </form>
    </>
  )
}
export default VehicleInfo