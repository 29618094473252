import { useEffect, useState } from 'react';

interface SizeWindow {
  width: number
  height: number
}

export const useWindowSize = (): SizeWindow => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0
  })
  useEffect(() => {
    const handleResize = (): any => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    };

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
};
