import { InputLabel, Grid } from '@material-ui/core'
import { ReactElement, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
// Context
import { useFormContext } from 'context/FormContext'
// More
import GenericCard from './components/GenericCard'
import ForceImage from './assets/ForceImage.png'
import useMixpanelTrack from 'hooks/useMixpanelTrack'
import { GetLocalQuoteData, UpdateQuoteData, BindExistingQuote, isAGENT, getSalesPerson } from 'services/instanda'
// utils
import errorHandler, { wasDeclined } from 'utils/errorHandler'
import { payPlanOptions } from 'utils/joinQuoteSelect'
import { formatDate } from 'utils/formatDate'
import { addComma } from 'utils/addComma'
// styles
import { useStyles } from 'styles/page6.styles'
// interfaces
import { IStyleProps } from 'interfaces'
import QuoteCard from './components/QuoteCard'
import { useHistory } from 'react-router-dom'

export default function Page6 (): ReactElement {
  useMixpanelTrack('bind2-tracking')

  const formContext = useFormContext()
  const [policyType, policyTypeSet] = useState(false)
  const [paymentType, paymentTypeSet] = useState<string>('')
  const [coteriePaymentType, coteriePaymentTypeSet] = useState<string>('')
  const [isMonthly, isMonthlySet] = useState<string>('Per month')
  const [isMonthlyCoterie, isMonthlyCoterieSet] = useState<string>('Per month')
  const [glBop, glBopSet] = useState<string>('')
  const [paidInFull, setPaidInFull] = useState(false)
  const [coterieValues, coterieValuesSet] = useState<any>({})
  const [instandata, setInstandata] = useState<any>({})
  const [payPlan_CHOICE, setpayPlan_CHOICE] = useState('Monthly - Recurring Credit Card')
  const [policyHasTelematics, setPolicyHasTelematics] = useState(false)
  const history = useHistory()
  // Probably duplicated information
  const [selectedOption, setSelectedOption] = useState('Monthly')

  useEffect(() => {
    formContext.setProgress(18)
    formContext.setLoading(true)
    GetLocalQuoteData() // Get formContext.quote information
      .then((res) => {
        const result = res.data
        const option = result.PayPlan_CHOICE === 'Monthly - Recurring Credit Card' ? 'Monthly' : result.PayPlan_CHOICE
        setpayPlan_CHOICE(result.PayPlan_CHOICE)
        setSelectedOption(option)
        formContext.setLoading(false)
        setInstandata({ ...result })
        if (
          result.PayPlan_CHOICE === 'Paid in Full' ||
          result.PayPlan_CHOICE === 'Offline Paid in Full'
        ) {
          setPaidInFull(true)
          isMonthlySet('Per 6 months')
          isMonthlyCoterieSet('Per 12 months')
        }
      })
      .catch((resErr) => {
        formContext.setLoading(false)
        console.error({ resErr })
      })
  }, [])

  useEffect(() => {
    formContext.updateLocalQuoteData()
  }, [])

  useEffect(() => {
    const policyType = localStorage.getItem('policyType')
    const coterieValues = JSON.parse(localStorage.getItem('coterieInfo') as string)
    const isGlBop = JSON.parse(localStorage.getItem('coterieValues') as string)
    if (policyType !== 'commercial') {
      policyTypeSet(true)
      coterieValuesSet(coterieValues)
      if (isGlBop.applicationTypes?.[0] === 'BOP') {
        glBopSet('BOP')
      } else if (isGlBop.applicationTypes?.[0] === 'GL') {
        glBopSet('GL')
      }
    }
  }, [])

  const Drivers = formContext.quote?.Drivers ?? [{ DriverFullName_TXT: '' }]

  const policyPayment = (): void => {
    setPaidInFull((currentValue) => !currentValue)
  }

  const styleProps: IStyleProps = {
    sideSize: 55,
    isActive: true,
    isYearly: paidInFull,
    policyType
  }

  const classes = useStyles(styleProps)

  const toggleMM = (): void => {
    setPaidInFull(false)
    paymentTypeSet(repetitiveProps.costMont)
    coteriePaymentTypeSet(coterieValues?.monthly ?? '')
    isMonthlySet('Per month')
    isMonthlyCoterieSet('Per month')
  }

  const toggleYY = (): void => {
    setPaidInFull(true)
    paymentTypeSet(repetitiveProps.costYear)
    coteriePaymentTypeSet(addComma(coterieValues?.premium ?? ''))
    isMonthlySet('Per 6 months')
    isMonthlyCoterieSet('Per 12 months')
  }

  const { enqueueSnackbar } = useSnackbar()

  const callBindExistingQuoteService = async (): Promise<void> => {
    const responseObject = await BindExistingQuote({ PayPlan_CHOICE: payPlan_CHOICE })
    formContext.savePolicyNumber(responseObject.data.policyNumber)
    formContext.setProgress(100)
    formContext.goNextPage()
  }

  const buyNowQT = (): void => {
    formContext.setLoading(true)
    UpdateQuoteData({ PayPlan_CHOICE: payPlan_CHOICE })
      .then(async (res: any) => {        
        if (wasDeclined(res?.data, formContext.setErrorModal)) return
        formContext.updateLocalQuoteData()        
        formContext.setProgress(33)
        formContext.setLoading(false) // Hide loading indicator
        formContext.goNextPage()        
      })
      .catch((resErr: any) => {
        errorHandler(resErr, enqueueSnackbar)
        formContext.setLoading(false) // Hide loading indicator
      })
  }

  const updateQT = (): void => {
    console.log('This type of card should not be updated')
  }

  const changeSelectedOption = ({ target }: any): void => {
    setSelectedOption(target.value)
    switch (target.value) {
      case 'Paid in Full':
        if (!paidInFull) policyPayment()
        setpayPlan_CHOICE('Paid in Full')
        break
      case 'Monthly':
        if (paidInFull) policyPayment()
        setpayPlan_CHOICE('Monthly - Recurring Credit Card')
        break
      case 'Offline Paid in Full':
        if (!paidInFull) policyPayment()
        setpayPlan_CHOICE('Offline Paid in Full')
        break
      case 'Offline Monthly':
        if (paidInFull) policyPayment()
        setpayPlan_CHOICE('Offline Monthly')
        break
    }
  }

  const repetitiveProps = {
    toggleMM: toggleMM, // Toggle monthly
    toggleYY: toggleYY, // Toggle yearly
    buyNowQT: buyNowQT, // Buy now button
    updateQT: updateQT, // Update button
    isYearly: paidInFull, // Is yearly boolean
    costYear: instandata?.FinalPremiumAnnualInclTaxes_NUM, // Cost when paid yearly
    costMont: instandata?.FinalPremiumMonthlyInclTaxes_NUM, // Cost when paid monthly
    costDown: instandata?.cb_downpayment_num, // Upfront cost
    needsUpdate: false, // Needs update boolean
    isAgentSite: isAGENT(),
    payPlanOptions,
    selectedOption,
    changeSelectedOption,
    isArizona: instandata?.BusinessState_TXT === 'AZ'
  }

  const cardProps = {
    glBop,
    isMonthly,
    isMonthlyCoterie,
    policyType,
    paymentType,
    coteriePaymentType,
    downPayment: repetitiveProps.costDown,
    policyDate: formatDate(formContext.quote?.PolicyEffective_DATE ?? ''),
    isYearly: paidInFull,
    isArizona: repetitiveProps.isArizona,
    policyHasTelematics
  }

  useEffect(() => {
    paymentTypeSet(paidInFull ? repetitiveProps.costYear : repetitiveProps.costMont)
    coteriePaymentTypeSet(paidInFull ? (coterieValues?.premium ?? '') : coterieValues?.monthly ?? '')
  }, [repetitiveProps.costMont, paidInFull])

  useEffect(() => {
    const fullQuote = JSON.parse(localStorage.getItem('instandaFullQuote') as string)
    const telematics = fullQuote?.VehiclePolicyParticipationTelematics_YN
    if (telematics === 'Yes') {
      setPolicyHasTelematics(true)
    }
  }, [])

  return (
    <>
      <Grid container className={classes.container} direction='column'>
        <Grid item container justifyContent='space-around'>
          <Grid item lg={6}>
            <div style={{ marginBottom: 50 }}>
              <h1 className={classes.title}>{!policyType ? 'Here is your final quote!' : 'Here are your final quotes!'}</h1>
            </div>
          </Grid>
        </Grid>
        <Grid container justifyContent='space-around'>
          <Grid
            item
            container
            direction='column'
            justifyContent='center'
            xs={12}
            md={7}
            style={{ height: '100%' }}
          >
            <Grid item container lg justifyContent='space-around'>
              <Grid item xs={4} md={2}>
                <div style={{ marginBottom: '65px' }}>
                  <InputLabel style={{ fontSize: '14px' }} htmlFor='dl-number'>
                    Business name
                  </InputLabel>
                  <p className={classes.paragraph}>{formContext.quote?.BusinessNameDBA_TXT}</p>
                </div>
              </Grid>
              <Grid item xs={4} md={2}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div style={{ marginBottom: '65px', display: 'inline-block' }}>
                    <InputLabel style={{ fontSize: '14px' }} htmlFor='dl-number'>
                      Driver name 1
                    </InputLabel>
                    <p className={classes.paragraph}>
                      {Drivers[0]?.DriverFullName_TXT}
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item container>
              <GenericCard {...cardProps} />
            </Grid>
            <Grid item className={classes.btnLayOut}>
              <button className={classes.buyNowBtn} onClick={buyNowQT}>
                BUY NOW
              </button>
            </Grid>
            <Grid container direction='column' lg>
              {policyHasTelematics && <Grid item>
                <p className={classes.paragraph}>
                  Force™ by Mojio is a GPS vehicle tracking solution built to
                  help business owners track their ﬂeet in real-time, encourage
                  safe driving behavior, monitor health and maintenance, and
                  improve customer service – all while reducing the total cost
                  of operating their vehicles.
                </p>
                <p className={classes.paragraph}>
                  When business vehicles are insured with Pouch and sign up for Force
                  by Mojio you will receive a 15% discount on your policy!
                  Learn more about the benefits of GPS vehicle tracking on the Force by Mojio &nbsp;
                  <a
                    href='https://forcebymojio.com/why-force-by-mojio/'
                    className={classes.forceByMojioLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://forcebymojio.com/why-force-by-mojio/.
                  </a>
                </p>
                                      </Grid>}
              <Grid item>
                <img
                  src={ForceImage}
                  alt='elements'
                  style={{ width: '100%', marginTop: 70, marginBottom: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
