import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toggleWrapper: {
    height: '34px',
    display: 'flex',
    '& button': {
      flex: 'auto',
      border: 'none',
      borderRadius: '8px'
    },
    '& button:hover': {
      cursor: 'pointer',
      fontWeight: 'bold'
    },
    '& button:first-child': {
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px'
    },
    '& button:nth-child(2)': {
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px'
    }
  },
  active: {
    color: '#ffffff',
    fontWeight: 'bold',
    backgroundColor: '#37c3b8'
  }
})

interface IUniversalToggle {
  isTrue: boolean
  action1: () => void
  action2: () => void
}

export default function UniversalToggle ({ isTrue, action1, action2 }: IUniversalToggle): ReactElement {
  const classes = useStyles()
  return (
    <section>
      <div className={classes.toggleWrapper}>
        <button
          type='button'
          onClick={action1}
          className={isTrue ? classes.active : ''}
        >
          Monthly
        </button>
        <button
          type='button'
          onClick={action2}
          className={isTrue ? '' : classes.active}
        >
          Paid in Full
        </button>
      </div>
    </section>
  )
}
