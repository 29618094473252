import styled from 'styled-components'
import { useEffect, ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  GetAllYears,
  GetMakeByYear,
  GetModelByYearAndMake,
  GetTrim
} from '../services/instanda'
import { useFormContext } from 'context/FormContext'
import TextField from './TextField'
import { Button, IconButton, InputLabel } from '@material-ui/core'
import { Search } from '@material-ui/icons'

interface containerType {
  error: string
}

const InputContainer = styled.div<containerType>`
  position: relative;
  border-radius: 5px;
  border: 2px solid ${(props) => (props.error ? 'red' : '#dddddd')};
  background-color: #fff;
  position: relative;
  margin-bottom: 12px;
`
const Label = styled.label`
  font-size: 0.7em;
  border: none;
  position: absolute;
  top: -10px;
  background-color: #fff;
  left: 5px;
  white-space: nowrap;
  padding: 0 5px;
`

const Select = styled.select`
  border: none;
  padding: 10px;
  width: 100%;
`

interface ComponentProps{
  isAdjust?: boolean
}

const useStyles = makeStyles<ComponentProps>(({ isAdjust }) => ({
  container: {
    padding: '10px 0'
  },
  yesBtn: {
    border: '1px solid #E5E5E5',
    padding: '5px 30px',
    textTransform: 'capitalize',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    fontSize: '11px',
    color: '#000',
    '&:hover': {
      fontSize: '11px',
      backgroundColor: '#EEEEEE',
      fontWeight: 'bold',
      '@media (max-width:740px)': {
        color: '#ffffff',
        backgroundColor: '#FF6C1A',
        borderRadius: 8
      }
    }
  },
  yesTelematicsBtn: {
    width: '125px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      fontWeight: 'bold',
      '@media (max-width:740px)': {
        color: '#ffffff',
        backgroundColor: '#FF6C1A',
        borderRadius: 8
      }
    }
  },
  noBtn: {
    border: '1px solid #E5E5E5',
    padding: '5px 30px',
    textTransform: 'capitalize',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    fontSize: '11px',
    color: '#000',
    '&:hover': {
      fontSize: '11px',
      backgroundColor: '#EEEEEE',
      fontWeight: 'bold',
      '@media (max-width:740px)': {
        color: '#ffffff',
        backgroundColor: '#FF6C1A',
        borderRadius: 8
      }
    }
  },
  noTelematicsBtn: {
    width: '135px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      fontWeight: 'bold',
      '@media (max-width:740px)': {
        color: '#ffffff',
        backgroundColor: '#FF6C1A',
        borderRadius: 8
      }
    }
  },
  active: {
    color: '#ffffff',
    fontWeight: 'bold',
    backgroundColor: '#FF6C1A',
    borderRadius: 8
  },
  notActive: {
    backgroundColor: '#E5E5E5',
    fontWeight: 500,
    padding: '5px 25px'
  },
  toggleContainer: (props: ComponentProps) => ({
    position: 'relative',
    backgroundColor: '#E5E5E5',
    borderRadius: 8,
    display: 'flex',
    height: `${props.isAdjust ? '35px' : 'none'}`
  }),
  search: {
    position: 'absolute',
    zIndex: 9,
    right: 5,
    top: 12,
    cursor: 'pointer',
    color: '#FF6C1A',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      color: '#000',
      backgroundColor: 'transparent'
    }
  },
  vinTitle: {
    padding: '5px',
    color: '#000',
    fontSize: '14px'
  },
  vinField: {
    width: '91%'
  },
  searchVinBtn: {
    border: 'none',
    backgroundColor: '#ffff',
    fontSize: '10px',
    textDecoration: 'underline'
  }
}))

export default function SelectedField ({
  form,
  inputIndex, // Gets index in case form is array
  inputLabel, // In case input label is different from placeholder use this
  placeholder, // Input label and placeholder are the same
  searchVehicleByVIN,
  isAdjust
}: {
  form?: any
  inputIndex: number
  inputLabel?: string | undefined
  placeholder?: string | undefined
  searchVehicleByVIN: (vin: string, index: number) => Promise<void>
  isAdjust?: boolean
}): ReactElement {
  const formContext = useFormContext()
  const [yearList, setYearList] = useState([])
  const [selectedYear, setSelectedYear] = useState('')
  const [makeList, setMakeList] = useState([])
  const [selectedMake, setSelectedMake] = useState('')
  const [modelList, setModelList] = useState([])
  const [bodyList, setBodyList] = useState<string[]>([])
  const [showVinInput, setShowVinInput] = useState<boolean>(false)
  const classes = useStyles({ isAdjust })
  const { errors } = form

  useEffect(() => {
    formContext.setLoading(true)
    getAllYears()
  }, [
    form.values[inputIndex].VehicleYear_NUM,
    form.values[inputIndex].VehicleMake_TXT,
    form.values[inputIndex].VehicleModel_TXT
  ])

  useEffect(()=>{
    if(form.values[inputIndex].VehicleVINOverride_TXT && form.values[inputIndex].VehicleVINOverride_TXT.length === 17 && form.values[inputIndex].VehicleYear_NUM === ''){
      searchVehicleByVIN(form.values[inputIndex].VehicleVINOverride_TXT, inputIndex).then(() => console.log('Searching'))
    }
    if (form.values[inputIndex].VehicleVINOverride_TXT && form.values[inputIndex].VehicleVINOverride_TXT.length > 0) {
          form.setFieldValue(`[${inputIndex}].VehicleVIN_TXT`, form.values[inputIndex].VehicleVINOverride_TXT)
          setShowVinInput(true)
        }
  },[
    form.values[inputIndex].VehicleVINOverride_TXT
  ])

  async function getAllYears (): Promise<any> {
    const result = await GetAllYears()
    setYearList(result.data.result)
    if (form.values[inputIndex].VehicleYear_NUM) {
      setSelectedYear(form.values[inputIndex].VehicleYear_NUM)
      await getAllMakes(form.values[inputIndex].VehicleYear_NUM)
    }
    if (form.values[inputIndex].VehicleMake_TXT) {
      setSelectedMake(form.values[inputIndex].VehicleMake_TXT)
      await getAllModels(
        form.values[inputIndex].VehicleMake_TXT,
        form.values[inputIndex].VehicleYear_NUM
      )
    }

    if (form.values[inputIndex].VehicleModel_TXT) {
      await getTrimValueStart(
        form.values[inputIndex].VehicleYear_NUM,
        form.values[inputIndex].VehicleMake_TXT,
        form.values[inputIndex].VehicleModel_TXT
      )
    }

    setTimeout(() => {
      formContext.setLoading(false)
    }, 2000)
  }

  const getAllMakes = async (
    year: string,
    selected = false
  ): Promise<void> => {
    const result = await GetMakeByYear(year)
    setMakeList(result.data.result)
    setSelectedYear(year)

    if (selected) {
      form.setFieldValue(`[${inputIndex}].VehicleYear_NUM`, year)
      form.setFieldValue(`[${inputIndex}].VehicleMake_TXT`, '')
      form.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
      form.setFieldValue(`[${inputIndex}].VehicleBody_TXT`, '')
    }
  }

  const getAllModels = async (
    make: string,
    year?: string,
    selected = false
  ): Promise<void> => {
    const result = await GetModelByYearAndMake(year || selectedYear, make)
    setModelList(result.data.result)
    setSelectedMake(make)
    if (selected) {
      form.setFieldValue(`[${inputIndex}].VehicleMake_TXT`, make)
      form.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
      form.setFieldValue(`[${inputIndex}].VehicleBody_TXT`, '')
    }
  }

  const getTrimValue = async (model: string): Promise<void> => {
    form.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, model)
    const result = await GetTrim(selectedYear, selectedMake, model)
    const trimsList = result.data.result?.map((vehicle: any) => vehicle.trim)
    setBodyList(trimsList)
    form.setFieldValue(
      `[${inputIndex}].VehicleBody_TXT`,
      trimsList[0] || ''
    )
  }

  const getTrimValueStart = async (
    year: string,
    make: string,
    model: string
  ): Promise<void> => {
    try {
      form.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, model)
      const result = await GetTrim(year, make, model)
      const trimsList = result.data.result?.map((vehicle: any) => vehicle.trim)
      setBodyList(trimsList)
      form.setFieldValue(
        `[${inputIndex}].VehicleBody_TXT`,
        trimsList[0] || ''
      )
    } catch (error) {
      console.log(error)
    }
  }

  const changeLienHolderYNP = (change: string): void => {
    form.setFieldValue(
      `[${inputIndex}].Lienholder_YNP`,
      change
    )
    if (change === 'No') {
      form.setFieldValue(`[${inputIndex}].LienholderName_TXT`, '')
      form.setFieldValue(`[${inputIndex}].LienholderAddressLine1_TXT`, '')
      form.setFieldValue(`[${inputIndex}].LienholderLoanNumber_TXT`, '')
      form.setFieldValue(`[${inputIndex}].LienholderCity_TXT`, '')
      form.setFieldValue(`[${inputIndex}].LienholderState_TXT`, '')
      form.setFieldValue(`[${inputIndex}].LienholderZipCode_TXT`, '')
      form.setFieldValue(`[${inputIndex}].VehicleAdditionalInsured_YN`, 'No')
    }
  }
  const changeVehicleAdditionalInsured_YN = (change: string): void => {
    form.setFieldValue(
      `[${inputIndex}].VehicleAdditionalInsured_YN`,
      change
    )
  }

  const wantTelematics = (option: string): void => {
    form.setFieldValue(
      `[${inputIndex}].VehiclePolicyParticipationTelematics_YN`,
      option
    )
  }

  const showVin = (): void => {
    setShowVinInput(!showVinInput)
  }

  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className={classes.vinTitle}>VIN</div>
          <div><button type='button' className={classes.searchVinBtn} onClick={showVin}>{!showVinInput ? 'Search by Vin' : 'Search by year, make & model'}</button></div>
        </div>
        {!showVinInput
          ? null
          : <div className='col-12' style={{ position: 'relative', display: 'flex' }}>
            <TextField
              name='VehicleVINOverride_TXT'
              inputIndex={inputIndex}
              inputLabel='Vin'
              placeholder='Vin (optional)'
              className={classes.vinField}
              form={form}
              value={form.values[inputIndex].VehicleVINOverride_TXT}
              onChange = {()=>{
                form.values[inputIndex].findByVIN = false              
              }}
              onMouseEnter={formContext.changeIndication}
              onMouseLeave={formContext.changeIndication}
              onKeyUp={async (e: any) => {
                if (e.keyCode === 13) {
                 await searchVehicleByVIN(form.values[inputIndex].VehicleVINOverride_TXT, inputIndex)
                }
              }}
            />
            <IconButton className={classes.search} onClick={() => { searchVehicleByVIN(form.values[inputIndex].VehicleVINOverride_TXT, inputIndex) }}>
              <span style={{fontSize: '16px'}}>Search</span>
            </IconButton>
            </div>}
      </div>
      <div className='row'>
        <div className='col-4'>
          <div className={classes.container}>
            <InputContainer
              error={
                  errors[inputIndex] ? errors[inputIndex].VehicleYear_NUM : ''
                }
            >
              <Label>{inputLabel ?? placeholder ?? ''}</Label>
              <Select
                onChange={async (evt) => {
                  form.setFieldValue(`[${inputIndex}].VehicleMake_TXT`, '')
                  form.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
                  await getAllMakes(evt.target.value, true)
                }}
                value={form.values[inputIndex].VehicleYear_NUM}
                disabled={form.values[inputIndex].VehicleYear_NUM != 0 && form.values[inputIndex].findByVIN}
              >
                <option value=''>Select an option...</option>
                {yearList.sort((a, b) => b - a).map((val: string) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </div>
        </div>
        <div className='col-4'>
          <div className={classes.container}>
            <InputContainer
              error={
                  errors[inputIndex] ? errors[inputIndex].VehicleMake_TXT : ''
                }
            >
              <Label>{inputLabel ?? placeholder ?? ''}</Label>
              <Select
                onChange={async (evt) => {
                  form.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
                  await getAllModels(
                    evt.target.value,
                    form.values[inputIndex].VehicleYear_NUM,
                    true
                  )
                }}
                value={form.values[inputIndex].VehicleMake_TXT}
                disabled={form.values[inputIndex].VehicleMake_TXT != "" && form.values[inputIndex].findByVIN}
              >
                <option value=''>Select an option...</option>
                {makeList.map((val: string) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </div>
        </div>
        <div className='col-4'>
          <div className={classes.container}>
            <InputContainer
              error={
                  errors[inputIndex] ? errors[inputIndex].VehicleModel_TXT : ''
                }
            >
              <Label>{inputLabel ?? placeholder ?? ''}</Label>
              <Select
                onChange={async (evt) => await getTrimValue(evt.target.value)}
                value={form.values[inputIndex].VehicleModel_TXT}
                disabled={form.values[inputIndex].VehicleModel_TXT != "" && form.values[inputIndex].findByVIN}
              >
                <option value=''>Select an option...</option>
                {modelList.map((val: string) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <InputContainer
            error={
                errors[inputIndex] ? errors[inputIndex].VehicleBody_TXT : ''
              }
          >
            <Label>Trim</Label>
            <Select
              onChange={(evt) => { form.setFieldValue(`[${inputIndex}].VehicleBody_TXT`, evt.target.value) }}
              value={form.values[inputIndex].VehicleBody_TXT}
              disabled={form.values[inputIndex].VehicleBody_TXT != "" && form.values[inputIndex].findByVIN}
            >
              <option value=''>Select an option...</option>
              {bodyList.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Select>
          </InputContainer>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 mt-2 mb-3 d-flex align-items-center '>
          <InputLabel>
            {!isAdjust ? 'Do you have a Lienholder?' : 'Have a car loan or leased vehicle?'}&nbsp; &nbsp;
          </InputLabel>
          <div className={classes.toggleContainer}>
            <Button
              classes={{ root: classes.yesBtn }}
              onClick={() => { changeLienHolderYNP('Yes') }}
              className={form.values[inputIndex].Lienholder_YNP === 'Yes' ? classes.active : classes.notActive}
            >
              Yes
            </Button>
            <Button
              classes={{ root: classes.noBtn }}
              onClick={() => { changeLienHolderYNP('No') }}
              className={form.values[inputIndex].Lienholder_YNP === 'Yes' ? classes.notActive : classes.active}
            >
              No
            </Button>
          </div>
        </div>
        {
          inputIndex === 0 &&

            <div className='col-lg-6 mt-2 mb-3 d-flex align-items-center telematics'>
              <InputLabel>
                {!isAdjust ? 'Would you like <strong>Telematics</strong> on all vehicles ?' : 'Telematics'} &nbsp;
              </InputLabel>
              <div className={classes.toggleContainer}>
                <Button
                  classes={{ root: `${classes.yesBtn} ${classes.yesTelematicsBtn}` }}
                  onClick={() => { wantTelematics('Yes') }}
                  onMouseEnter={e => formContext.setSideText('When the business vehicles are insured with Pouch and sign up for Force by Mojio you will receive a 15% discount on the policy!')}
                  onMouseLeave={e => formContext.setSideText('')}
                  className={form.values[inputIndex].VehiclePolicyParticipationTelematics_YN === 'Yes' ? classes.active : classes.notActive}
                >
                  Yes for all
                </Button>
                <Button
                  classes={{ root: `${classes.noBtn} ${classes.noTelematicsBtn}` }}
                  onClick={() => { wantTelematics('No') }}
                  className={form.values[inputIndex].VehiclePolicyParticipationTelematics_YN === 'Yes' ? classes.notActive : classes.active}
                >
                  No for all
                </Button>
              </div>
            </div>
        }

      </div>
      {
        (form.values[inputIndex].Lienholder_YNP === 'Yes') &&
          <>
            <div className='row'>
              <div className='col-12'>
                <TextField
                  name='LienholderName_TXT'
                  inputIndex={inputIndex}
                  inputLabel='Full name of Lienholder/Loss Payee'
                  placeholder='Name'
                  form={form}
                  value={form.values[inputIndex].LienholderName_TXT}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <TextField
                  name='LienholderAddressLine1_TXT'
                  inputIndex={inputIndex}
                  inputLabel='Lienholder/Loss Payee Address'
                  placeholder='Address'
                  form={form}
                  value={form.values[inputIndex].LienholderAddressLine1_TXT}
                  placePicker
                  isLienholder
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <TextField
                  name='LienholderCity_TXT'
                  inputIndex={inputIndex}
                  inputLabel='Lienholder/Loss Payee City'
                  placeholder='City'
                  form={form}
                  value={form.values[inputIndex].LienholderCity_TXT}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <TextField
                  name='LienholderState_TXT'
                  inputIndex={inputIndex}
                  inputLabel='Lienholder/Loss Payee State'
                  placeholder='State'
                  form={form}
                  value={form.values[inputIndex].LienholderState_TXT}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <TextField
                  name='LienholderZipCode_TXT'
                  inputIndex={inputIndex}
                  inputLabel='Lienholder/Loss Payee ZIP'
                  placeholder='ZIP Code'
                  form={form}
                  value={form.values[inputIndex].LienholderZipCode_TXT}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <TextField
                  name='LienholderLoanNumber_TXT'
                  inputIndex={inputIndex}
                  inputLabel='Lienholder/Loss Payee Loan Number'
                  placeholder='Loan (optional)'
                  form={form}
                  value={form.values[inputIndex].LienholderLoanNumber_TXT}
                />
              </div>
            </div>
            <div className="row">
            <div className='col-8'>
              <InputLabel>
                Need Additional Insured (Leased Vehicle) ? &nbsp;
              </InputLabel>
            </div>
            <div className='col-4'>
              <div className={classes.toggleContainer}>
                <Button
                  classes={{ root: `${classes.yesBtn} ${classes.yesTelematicsBtn}` }}
                  onClick={() => { changeVehicleAdditionalInsured_YN('Yes') }}
                  // onMouseEnter={e => formContext.setSideText('When the business vehicles are insured with Pouch and sign up for Force by Mojio you will receive a 15% discount on the policy!')}
                  // onMouseLeave={e => formContext.setSideText('')}
                  className={form.values[inputIndex].VehicleAdditionalInsured_YN === 'Yes' ? classes.active : classes.notActive}
                >
                  Yes
                </Button>
                <Button
                  classes={{ root: `${classes.noBtn} ${classes.noTelematicsBtn}` }}
                  onClick={() => { changeVehicleAdditionalInsured_YN('No') }}
                  className={form.values[inputIndex].VehicleAdditionalInsured_YN === 'Yes' ? classes.notActive : classes.active}
                >
                  No
                </Button>
              </div>
            </div>
            </div>
          </>
      }
    </>
  )
}
