import { format, compareAsc } from 'date-fns'
import { formatDate } from './formatDate'

const getTodayDateAsMMHyphenDD = (): string => {
  const today = new Date()

  const day = today.getDate()
  const month = today.getMonth() + 1

  const customDay = day < 10 ? `0${day}` : day
  const customMonth = month < 10 ? `0${month}` : month

  return `${customMonth}-${customDay}`
}

export const dateOf16YearsInThePast = (): string => {
  return `${new Date().getFullYear() - 16}-${getTodayDateAsMMHyphenDD()}`
}

export const dateOf100YearsInThePast = (): string => {
  return `${new Date().getFullYear() - 100}-${getTodayDateAsMMHyphenDD()}`
}

export const isAdult = (value: Date | undefined): boolean => {
  const max = new Date(dateOf16YearsInThePast())
  const dob = new Date(value instanceof Date ? value.setHours(value.getHours() - 12) : max)
  return dob.getTime() <= max.getTime()
}

export const dateOfToday = (): string => {
  return `${new Date().getFullYear()}-${getTodayDateAsMMHyphenDD()}`
}
export const previousECD = (date: string): string => {
  return `${new Date(date).getFullYear()}-${getTodayDateAsMMHyphenDD()}`
}

export const getEffectiveChangeDate = (date: any) => {
  const Today = new Date(dateOfToday()).getTime()
  const effectiveChangeDate = new Date(date).getTime()
  const diff = (effectiveChangeDate < Today)
  return diff ? dateOfToday() : date
}

export const isGreaterThanToday = (value: Date | undefined): boolean => {
  const today = new Date()
  const other = new Date(value instanceof Date ? value : today)
  return compareAsc(other, today) === 1
}

export const isSmallerThanUpThrityDaysInAdvance = (value: Date | undefined): boolean => {
  const today = new Date()
  const todayThirtyDaysMore = new Date(today.setDate(today.getDate() + 29))
  const other = new Date(value instanceof Date ? value : today)
  return compareAsc(todayThirtyDaysMore, other) === 1
}

export const isSmaller = (value: Date | undefined): boolean => {
  const today = new Date()
  today.setDate(today.getDate())
  today.setHours(value instanceof Date ? value.getHours() : today.getHours())
  today.setMinutes(value instanceof Date ? value.getMinutes() : today.getMinutes())
  today.setSeconds(value instanceof Date ? value.getSeconds() : today.getSeconds())
  today.setMilliseconds(value instanceof Date ? value.getMilliseconds() : today.getMilliseconds())
  const ed = new Date(value instanceof Date ? value.setHours(value.getHours()) : today)
  return ed.getTime() <= today.getTime()
}

export const dateOf1YearsInTheFuture = (): string => {
  return `${new Date().getFullYear() + 1}-${getTodayDateAsMMHyphenDD()}`
}

export const dateAddYear = (date: string, months = 0): string => {
  const currentDate = new Date(date)
  const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + months))
  const day = currentDate.getDate() + 1
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear() + 1

  return months === 0 ? `${year}-${month}-${day}` : format(newDate, 'yyyy-MM-dd')
}

export const dateAddDays = (date: string, days = 0): string => {
  const currentDate = new Date(date)
  const newDate = new Date(currentDate.setDate(currentDate.getDate() + days))
  const day = currentDate.getDate()
  const month = currentDate.getMonth()
  const year = currentDate.getFullYear()

  return days === 0 ? `${year}-${month}-${day}` : format(newDate, 'yyyy-MM-dd')
}

export const monthDiff = (dateFrom: string): number => {
  const dF = new Date(`${dateFrom}:00:00:00`)
  const todayDate = new Date()
  const daysDiff = todayDate.getDate() - dF.getDate()
  return todayDate.getMonth() - dF.getMonth() + (12 * (todayDate.getFullYear() - dF.getFullYear())) + (daysDiff < 0 ? -1 : 0)
}
