import { monthDiff } from "./calculations"

export interface CoteriePayload {
  GLLIMIT: string
  GLAGG: string
  GLAGGPCO: string
  POLICY_START: string
  POLICY_END: string
  START_DATE: string // Business start date
  MIB: string
  EMPS: string
  CLAIMDESC: string
  CLAIMAMT: string
  ANNUAL_PAYROLL: string
  ANNUAL_SALES: string
  ENDRSMTCOVTYPE: string
  INSADDR: string
  INSCITY: string
  INSSTATE: string
  INSZIP: number
  INSLOCATIONTYPE: string
  INSBPPLIMIT: string
  INSBUILDINGLIMIT: string
  INSSPRINKLER: boolean
  BUILDINGDEDUCTIBLE: string
  BPPDEDUCTIBLE: string
  PROPERTYDAMAGELIABILITYDEDUCTIBLE: string
}

export const coterieInitialState = {
  GLLIMIT: '1000000',
  GLAGG: '',
  GLAGGPCO: '',
  POLICY_START: '',
  POLICY_END: '',
  START_DATE: '',
  MIB: '',
  // EMPS: '',
  CLAIMDESC: '',
  CLAIMAMT: '',
  ANNUAL_PAYROLL: '',
  ANNUAL_SALES: '',
  ENDRSMTCOVTYPE: 'Project',
  INSLOCATIONTYPE: 'Other',
  INSBPPLIMIT: '',
  INSBUILDINGLIMIT: 0,
  INSSPRINKLER: false,
  BUILDINGDEDUCTIBLE: '1000',
  BPPDEDUCTIBLE: '1000',
  PROPERTYDAMAGELIABILITYDEDUCTIBLE: '500',
  CLAIMSINTHEPASTYEARS: 'No'
}
export const getCoteriePayload = (applicationType: string, values: CoteriePayload): any => {
  const locations = [
    {
      street: values.INSADDR,
      city: values.INSCITY,
      state: values.INSSTATE,
      zip: values.INSZIP,
      locationType: values.INSLOCATIONTYPE,
      buildingLimit: parseInt(values.INSBUILDINGLIMIT ?? '0'),
      hasSprinklers: values.INSSPRINKLER
    }
  ]
  const glPayload = {
    glLimit: parseInt(values.GLLIMIT),
    glAggregateLimit: parseInt(values.GLAGG === '' ? '0' : values.GLAGG ),
    glAggregatePcoLimit: parseInt(values.GLAGGPCO === '' ? '0' : values.GLAGGPCO),
    policyStartDate: values.POLICY_START,
    businessStartDate: values.START_DATE,
    businessAgeInMonths: monthDiff(values.START_DATE),
    numEmployees: parseInt(values.EMPS),
    previousLosses: values.CLAIMAMT !== '' ? [{
      amount: parseInt(values.CLAIMAMT),
      description: values.CLAIMDESC
    }] : [],
    annualPayroll: parseInt(values.ANNUAL_PAYROLL),
    grossAnnualSales: parseInt(values.ANNUAL_SALES),
    endorsements: {
      coverage: values.ENDRSMTCOVTYPE
    },
    locations,
    PropertyDamageLiabilityDeductible: values.PROPERTYDAMAGELIABILITYDEDUCTIBLE
  }

  const bopLocations = locations.map(location => {
    return { ...location, bppLimit: parseInt(values.INSBPPLIMIT), buildingLimit: parseInt(values.INSBUILDINGLIMIT) }
  })

  const bopPayload = {
    ...glPayload,
    buildingDeductible: parseInt(values.BUILDINGDEDUCTIBLE),
    bppDeductible: parseInt(values.BPPDEDUCTIBLE),
    locations: bopLocations
  }

  return applicationType === 'GL' ? glPayload : bopPayload
}

export const questionsHidden = ['POLICY_START', 'POLICY_END', 'GLAGG', 'GLAGGPCO', 'EMPS', 'ENDRSMTCOVTYPE', 'INSADDR', 'INSCITY', 'INSSTATE', 'INSZIP', 'INSLOCATIONTYPE', 'CLAIMAMT', 'CLAIMDESC', 'MIB']

export const setCoterieState = (coterieValues: any): any => ({
  GLLIMIT: coterieValues.glLimit ?? coterieInitialState.GLLIMIT,
  GLAGG: coterieValues.glAggregateLimit,
  GLAGGPCO: coterieValues.glAggregatePcoLimit,
  POLICY_START: coterieValues.policyStartDate,
  START_DATE: coterieValues.businessStartDate,
  EMPS: coterieValues.numEmployees,
  CLAIMDESC: coterieValues.previousLosses[0]?.description ?? '',
  CLAIMAMT: coterieValues.previousLosses[0]?.amount ?? '',
  ANNUAL_PAYROLL: coterieValues.annualPayroll,
  ANNUAL_SALES: coterieValues.grossAnnualSales,
  INSBPPLIMIT: coterieValues.locations[0]?.bppLimit ?? '',
  INSBUILDINGLIMIT: coterieValues.locations[0]?.buildingLimit ?? '',
  INSSPRINKLER: coterieValues.locations[0]?.hasSprinklers,
  BUILDINGDEDUCTIBLE: coterieValues.buildingDeductible ?? coterieInitialState.BUILDINGDEDUCTIBLE,
  BPPDEDUCTIBLE: coterieValues.bppDeductible ?? coterieInitialState.BPPDEDUCTIBLE,
  PROPERTYDAMAGELIABILITYDEDUCTIBLE: coterieValues.PropertyDamageLiabilityDeductible ?? coterieInitialState.PROPERTYDAMAGELIABILITYDEDUCTIBLE,
  CLAIMSINTHEPASTYEARS: coterieValues.previousLosses.length > 0 ? 'Yes':'No'
})
