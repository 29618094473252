import React from 'react'
import { DataPolicy } from '../../Standalone/interfaces/index'
import { MenuItem, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const stylesFn = makeStyles({
  label: {
    top: '-12px',
    backgroundColor: 'white',
    padding: '10px'
  }
})

const BankAccountForm: React.FC<DataPolicy> = ({
  dataPolicy,
  paymentMethodChoice,
  onChange,
  values,
  touched,
  errors
}) => {
  const classes = stylesFn()

  const styling = {
    margin: '20px'
  }

  return (
    <div>
      <form style={{ marginTop: '-1.2rem' }}>
        <div style={styling}>
          <TextField
            label='Bank Account Holder'
            variant='outlined'
            type='text'
            name='bankAccountHolder'
            placeholder='John Smith'
            fullWidth
            size='small'
            onChange={onChange}
            value={values.bankAccountHolder}
            error={
              touched.bankAccountHolder !== null &&
              Boolean(errors.bankAccountHolder)
            }
            helperText={
              touched.bankAccountHolder !== null && errors.bankAccountHolder
            }
            InputLabelProps={{
              shrink: true,
              classes: {
                formControl: classes.label
              }
            }}
          />
        </div>
        <div style={styling}>
          <TextField
            label='Routing Number'
            placeholder='322079353'
            variant='outlined'
            type='text'
            name='routingNumber'
            fullWidth
            size='small'
            onChange={onChange}
            value={values.routingNumber}
            error={
              touched.routingNumber !== null && Boolean(errors.routingNumber)
            }
            helperText={touched.routingNumber !== null && errors.routingNumber}
            InputLabelProps={{
              shrink: true,
              classes: {
                formControl: classes.label
              }
            }}
          />
        </div>
        <div style={styling}>
          <TextField
            label='Account Number'
            placeholder='11000000333456781'
            variant='outlined'
            type='text'
            size='small'
            name='accountNumber'
            fullWidth
            onChange={onChange}
            value={values.accountNumber}
            error={
              touched.accountNumber !== null && Boolean(errors.accountNumber)
            }
            helperText={touched.accountNumber !== null && errors.accountNumber}
            InputLabelProps={{
              shrink: true,
              classes: {
                formControl: classes.label
              }
            }}
          />
        </div>
        <div style={styling}>
          <TextField
            label='Confirm Account Number'
            placeholder='11000000333456781'
            variant='outlined'
            type='text'
            size='small'
            name='confirmAccountNumber'
            fullWidth
            onChange={onChange}
            value={values.confirmAccountNumber}
            error={
              touched.confirmAccountNumber !== null &&
              Boolean(errors.confirmAccountNumber)
            }
            helperText={
              touched.confirmAccountNumber !== null &&
              errors.confirmAccountNumber
            }
            InputLabelProps={{
              shrink: true,
              classes: {
                formControl: classes.label
              }
            }}
          />
        </div>
        <div style={styling}>
          <TextField
            label='Please Select an Account Type'
            select
            size='small'
            variant='outlined'
            type='text'
            name='accountType'
            fullWidth
            onChange={onChange}
            value={values.accountType}
            InputLabelProps={{
              shrink: true,
              classes: {
                formControl: classes.label
              }
            }}
            error={touched.accountType !== null && Boolean(errors.accountType)}
            helperText={touched.accountType !== null && errors.accountType}
          >
            <MenuItem value='PERSONAL_CHECKING'>Personal Checking</MenuItem>
            <MenuItem value='PERSONAL_SAVINGS'>Personal Savings</MenuItem>
            <MenuItem value='BUSINESS_CHECKING'>Business Checking</MenuItem>
            <MenuItem value='PERSONAL_SAVINGS'>Business Savings</MenuItem>
          </TextField>
        </div>
      </form>
    </div>
  )
}

export default BankAccountForm
