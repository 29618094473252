import { useContext, createContext } from 'react'

interface IQuoteContext {
  // Actual card number (1 Custom, 2 Good, 3 Better, 4 Best)
  actualCard: number
  setActualCard: React.Dispatch<React.SetStateAction<number>>
  // Quote default + get policy values
  policyValues: any
  setPolicyValues: React.Dispatch<React.SetStateAction<any>>
  // Quote has beed edited and need re-calculate price
  needsUpdate: boolean
  setNeedsUpdate: React.Dispatch<React.SetStateAction<boolean>>
  // If card is monthly or yearly
  isYearly: boolean
  setIsYearly: React.Dispatch<React.SetStateAction<boolean>>
}

const QuoteContext = createContext<IQuoteContext | undefined>(undefined)

export const useQuoteContext = (): IQuoteContext => {
  const context: IQuoteContext | undefined = useContext(QuoteContext)
  if (context === undefined) throw Error('Quote context is not right')
  return context
}

export default QuoteContext
