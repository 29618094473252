import { makeStyles } from "@material-ui/core";
import bgImage from '../../assets/hero-image-about-us.jpg'

export const useAboutUsStyles = makeStyles({
  aboutUs__main: {
    backgroundColor: '#F8F8F8',
    fontFamily: 'Libre Franklin'
  },
  aboutUs__container: {
    marginRight: 75,
    marginLeft: 75,
    '@media (max-width:780px)': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  hero__image: {
    background: `linear-gradient(270deg, #F8F8F8 40%, rgba(243, 243, 243, .59) 100%), url(${bgImage})`,
    backgroundSize: 'auto 100%',
    backgroundRepeat: "no-repeat",
    minHeight: 620,
    '@media (max-width:780px)': {
      background: `linear-gradient(270deg, #F8F8F8 40%, rgba(243, 243, 243, .59) 100%), url(${bgImage})`,
      backgroundSize: 'auto 100%',
      minHeight: 380,
      border: 'none'
    },
  },
  grid__container: {
    height: 620,
    '@media (max-width:780px)': {
      height: 380,
    }
  },
  title: {
    fontSize: 62,
    '@media (max-width:780px)': {
      marginRight: 4,
      marginLeft: 4,
      fontSize: 25
    },
    '@media (max-width:740px)': {
      marginRight: 4,
      marginLeft: 4,
      fontSize: 25
    },
  },
  subtitle: {
    fontSize: 48,
    color: '#716262',
    textAlign: 'center',
    marginBottom: 30,
    '@media (max-width:780px)': {
      fontSize: 36,
    },
  },
  paragraph: {
    fontSize: 25,
    color: '#6b6b6b',
    textAlign: 'justify',
    '@media (max-width:780px)': {
      marginRight: 4,
      marginLeft: 4,
      fontSize: 16
    },
    '@media (max-width:740px)': {
      marginRight: 4,
      marginLeft: 4,
      fontSize: 14
    },
  },
  paragraph2: {
    fontSize: 25,
    color: '#6b6b6b',
    textAlign: 'center',
    '@media (max-width:780px)': {
      marginRight: 4,
      marginLeft: 4,
      fontSize: 16
    },
    '@media (max-width:740px)': {
      marginRight: 4,
      marginLeft: 4,
      fontSize: 16
    },
  },
  Goodie: {
    marginTop: 125,
    marginBottom: 40,
    '@media (max-width:780px)': {
      marginTop: 105,
      marginBottom: 20,
    },
    '@media (max-width:740px)': {
      marginTop: 85,
      marginBottom: 10,
    },
  },
  Goodie_icon: {
    height: 130,
    widht: 130,
    '@media (max-width:780px)': {
      height: 120,
      widht: 120,
    },
    '@media (max-width:740px)': {
      height: 100,
      widht: 100,
    },
  },
  aboutUs__socialIcons: {
    height: 80,
    widht: 80,
    marginLeft: 60,
    marginRight: 60,
    marginTop: 80,
    marginBottom: 120,
    '@media (max-width:780px)': {
      height: 70,
      widht: 70,
      marginLeft: 20,
      marginRight: 20,
    },
    '@media (max-width:740px)': {
      height: 60,
      widht: 60,
      marginLeft: 10,
      marginRight: 10,
    },
  },
  aboutUs__teamImage: {
    height: 286,
    width: 286,
    '@media (max-width:780px)': {
      height: 276,
      width: 276,
      fontSize: 38,
    },
    '@media (max-width:740px)': {
      height: 266,
      width: 266,
      fontSize: 36,
    },
  },
  aboutUs__teamName: {
    fontSize: 48.70,
    color: '#39C7C2',
    textAlign: 'center',
    marginTop: 25,
    '@media (max-width:780px)': {
      fontSize: 38,
    },
    '@media (max-width:740px)': {
      fontSize: 36,
    },
  },
  aboutUs__teamTitle: {
    fontSize: 39,
    color: '#716262',
    textAlign: 'center',
    '@media (max-width:780px)': {
      fontSize: 30,
    },
    '@media (max-width:740px)': {
      fontSize: 28,
    },
  },
  Goddie__image: {
    width: '100%',
    maxWidth: 621,
    height: 517,
    marginTop: 70,
    '@media (max-width:780px)': {
      fontSize: 30,
      maxWidth: 360,
      height: 330,
    },
    '@media (max-width:740px)': {
      fontSize: 28,
      maxWidth: 330,
      height: 300,
    },
  },
  Goodie__name: {
    fontSize: 48.70,
    color: '#39C7C2',
    textAlign: 'center',
    marginTop: 85,
    '@media (max-width:780px)': {
      marginTop: 0,
    },
  },
  Goodie__subTitle: {
    fontSize: 34,
    color: '#716262',
    textAlign: 'center',
    '@media (max-width:780px)': {
      fontSize:28
    },
  },
  goodie__section: {
    marginBottom: 80
  },
  Goodie_paragraph: {
    color: '#b4adad',
    fontSize: 25,
    textAlign: 'justify',
    margin: 5,
    '@media (max-width:780px)': {
      fontSize: 16
    },
  },
  carousel_section: {
    backgroundColor: '#f3f3f3'
  },
})
