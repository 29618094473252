import React, { ReactElement, useEffect, useState } from 'react'
import { ClickAwayListener, makeStyles, Tooltip } from '@material-ui/core'
import { useSnackbar } from 'notistack'
// import Congrats from '../../components/StepperContainer/assets/Congrats.svg'
import Congrats from '../../components/StepperContainer/assets/congrats'
import download_image from './assets/download-quote-pdf.png'
import { DownloadPDF } from '../../services/chargeBee'
import { useFormContext } from '../../context/FormContext'
import useMixpanelTrack from 'hooks/useMixpanelTrack'
import errorHandler from 'utils/errorHandler'
import { isAGENT, agentDashboardURL, isMARKETING, downloadPolicyService } from 'services/instanda'
import contentCopy from './assets/content_copy.svg'

// green color #37c3b8

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    width: '60%',
    textAlign: 'center',
    '@media (max-width: 425px)': {
      width: '95%'
    }
  },
  icon: {
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    height: 50,
    width: 120,
    margin: '0 auto',
    '@media (max-width: 414px)': {
      width: '85px'
    }
  },
  paragraph: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 16.5,
    fontWeight: 'bold',
    color: 'gray',
    '@media (max-width:740px)': {
      fontSize: 10
    }
  },
  policyNumber: {
    fontSize: 'clamp(1rem, 4vw, 40px)',
    fontWeight: 'bold',
    backgroundColor: '#eeedef',
    borderRadius: '50px',
    margin: '0.25em 0'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 'clamp(0.75rem, 3vw, 40px)',
    margin: '1em 0'
  },
  yourPolicy: {
    border: 'none',
    width: '18em',
    textAlign: 'center',
    fontSize: '2.2rem',
    '@media (max-width: 1200px)': {
      width: '15em',
      fontSize: '1.8rem'
    },
    '@media (max-width: 820px)': {
      width: '12em',
      fontSize: '1.2rem'
    }
  },
  contentCopy: {
    width: '35px',
    height: '40px',
    '@media (max-width: 820px)': {
      width: '30px',
      height: '30px'
    },
    '@media (max-width: 540px)': {
      width: '20px',
      height: '25px'
    },
    '@media (max-width: 310px)': {
      display: 'none'
    }
  },
  btnContentCopy: {
    border: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  btnpdf: {
    border: '2px solid #7f7f7f',
    alignItems: 'center',
    borderRadius: 15,
    backgroundColor: '#eeedef',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 50,
    paddingRight: 50,
    '@media (max-width:740px)': {
      fontSize: 12
    },
    width: '100%'
  },
  btnCoteriePolicy: {
    border: 5,
    borderColor: '#7f7f7f',
    borderRadius: 15,
    backgroundColor: '#37c3b8',
    color: '#ffff',
    marginTop: 20,
    paddingTop: 12,
    paddingLeft: 50,
    paddingRight: 50,
    '@media (max-width:740px)': {
      fontSize: 12
    },
    width: '100%'
  },
  btnInsurance: {
    border: '2px solid #7f7f7f',
    borderRadius: 15,
    backgroundColor: '#eeedef',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '3rem',
    marginTop: 20,
    paddingTop: 12,
    paddingLeft: 50,
    paddingRight: 50,
    '@media (max-width:740px)': {
      fontSize: 12
    },
    width: '100%'
  },
  btnKoala: {
    width: 40,
    height: 40,
    '@media (max-width: 500px)': {
      display: 'none'
    }
  },
  imgdownload: {
    height: 35,
    width: 35,
    '@media (max-width: 820px)': {
      height: 30,
      width: 30
    },
    '@media (max-width: 500px)': {
      display: 'none'
    }
  },
  btnCoterie: {
    border: '2px solid #7f7f7f',
    borderRadius: 30,
    backgroundColor: '#eeedef',
    marginTop: 20,
    paddingTop: 13.5,
    paddingBottom: 13.5,
    paddingLeft: 73,
    paddingRight: 73,
    '@media (max-width:740px)': {
      fontSize: 12
    }
  },
  mount: {
    fontSize: 60,
    color: '#ff6c1a',
    fontWeight: 'bold',
    '@media (max-width:740px)': {
      fontSize: 32
    }
  },
  goToAgent: {
    border: '2px solid #7f7f7f',
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '3rem',
    marginTop: 20,
    paddingTop: 12,
    paddingLeft: 50,
    paddingRight: 50,
    '@media (max-width:740px)': {
      fontSize: 12
    },
    '& a': {
      color: '#7f7f7f',
      fontWeight: 'bold',
      textDecoration: 'none'
    },
    width: '100%'
  },
  hr: {
    border: '1.25px solid black',
    margin: '2rem 0',
    width: '100%'
  },
  text: {
    margin: '1.75rem 0'
  },
  text2: {
    fontSize: '0.75rem',
    textAlign: 'right',
    margin: '0.25rem 0',
    color: '#545454'
  },
  passwordInput: {
    border: '2px solid #eeedef',
    borderRadius: '10px',
    padding: '0.5rem'
  },
  input1: {
    border: 'none',
    width: '80%'
  },
  input2: {
    border: '2px solid #545454',
    borderRadius: '30px',
    color: '#545454',
    width: '20%'
  },
  text3: {
    fontSize: '0.75rem',
    margin: '0.5rem 0',
    color: '#545454'
  }
})

export default function Page9 (): ReactElement {
  useMixpanelTrack('bind8-tracking')

  const quote = JSON.parse(localStorage.getItem('instandaFullQuote') as string)
  const [quoteCopy] = useState({ ...quote })
  const [openToolTip, setOpenToolTip] = React.useState(false)
  const classes = useStyles()
  const [downloadLink, setDownloadLink] = React.useState('')
  const [policyLink, setPolicyLink] = React.useState('')
  const formContext = useFormContext()
  const { enqueueSnackbar } = useSnackbar()
  const coterieUrl = localStorage.getItem('coterieUrl')
  const policyType = localStorage.getItem('policyType')
  const yourPolicy = React.useRef<any>(null)
  const paymentVia = localStorage.getItem('paymentVia')

  const download = (): void => {
    const newWindow = window.open(downloadLink, '_blank', 'noopener,noreferrer')
    if (newWindow != null) newWindow.opener = null
  }

  const downloadPolicy = (): void => {
    const newWindow = window.open(policyLink, '_blank', 'noopener,noreferrer')
    if (newWindow != null) newWindow.opener = null
  }
  const handlebtnCoterieClcik = (): void => {
    if (coterieUrl !== null) {
      window.open(coterieUrl)
    }
  }

  const copyToclipboard = async (): Promise<void> => {
    await navigator.clipboard.writeText(yourPolicy.current.value)
  }

  useEffect(() => {
    formContext.setProgress(100)
    formContext.setLoading(true)
    formContext.updateLocalQuoteData()
    async function dwpdf (): Promise<any> {
      try {
        const res = await DownloadPDF()
        setDownloadLink(res.data.result.download.download_url)
        formContext.setLoading(false)
        console.log({ res })
      } catch (err) {
        console.log({ err })
        errorHandler(err, enqueueSnackbar)
        formContext.setLoading(false)
      }
    }
    if (quote.PayPlan_CHOICE !== 'Offline Monthly' && quote.PayPlan_CHOICE !== 'Offline Paid in Full' && paymentVia === 'Chargebee') {
      dwpdf().catch((error) => {
        console.log({ error })
        errorHandler(error, enqueueSnackbar)
      })
    } else {
      formContext.setLoading(false)
    }
  }, [])

  useEffect(() => {
    formContext.setLoading(true)
    formContext.updateLocalQuoteData()
    async function dwpdf (): Promise<any> {
      try {
        const resp = await downloadPolicyService()
        setPolicyLink(resp.data.url)
        formContext.setLoading(false)
      } catch (err) {
        console.log({ err })
        errorHandler(err, enqueueSnackbar)
        formContext.setLoading(false)
      }
    }
    dwpdf().catch((error) => {
      console.log({ error })
      errorHandler(error, enqueueSnackbar)
    })
    formContext.setLoading(false)
  }, [])

  const tooltipOpen = (): void => {
    setOpenToolTip(true)
  }

  const tooltipClose = (): void => {
    setOpenToolTip(false)
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {/* TODO: change koala icon  */}
        {/* <img src={Congrats} alt='hint_icon' className={classes.image} /> */}
        <div className={classes.image}><Congrats fill='#37c3b8' /></div>
        <div>
          <div className={classes.title}>
            Thank You for Choosing Pouch!
          </div>
          <div style={{ fontWeight: 'bold' }}>YOUR POLICY NUMBER</div>
          <ClickAwayListener onClickAway={tooltipClose}>
            <div className={classes.policyNumber}>
              <input disabled className={classes.yourPolicy} ref={yourPolicy} value={quoteCopy?.PouchPolicyNumber_TXT ?? ''} />
              <Tooltip open={openToolTip} title='Policy Number Copied!' onClose={tooltipClose} disableFocusListener disableHoverListener disableTouchListener>
                <button onClick={async () => { await copyToclipboard(); tooltipOpen() }} className={classes.btnContentCopy}><img src={contentCopy} alt='content-copy' className={classes.contentCopy} />
                </button>
              </Tooltip>
            </div>
          </ClickAwayListener>
          {/* <div>
            <p className={classes.paragraph}>
              Your documents are now complete! Our partner is now processing and shipping your order. You can expect to recieve it in less than 10 business days.
              {
                policyType === 'glbop' && coterieUrl !== null && (
                  ` You also have been forwarded to the ${isMARKETING ? 'Insurance Partner' : 'Coterie'} site to finish your quote`
                )
              }
            </p>
          </div> */}
          <div>
            <div>You paid</div>
            <h3 className={classes.mount}>
              {quoteCopy &&
                `$${
                  (quoteCopy?.PayPlan_CHOICE === 'Paid in Full' || quoteCopy?.PayPlan_CHOICE === 'Offline Paid in Full')
                    ? quoteCopy.FinalPremiumAnnualInclTaxes_NUM?.toFixed(2) as number
                    : quoteCopy.cb_downpayment_num?.toFixed(2) as number
                }`}
              {!quoteCopy && ', click to donwload your invoice.'}
            </h3>
            {
              policyType === 'glbop' && coterieUrl !== null &&
                <>
                  <br />
                  <button className={classes.btnCoteriePolicy} onClick={() => !isMARKETING && handlebtnCoterieClcik()}>
                    <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Complete {isMARKETING ? 'Insurance Partner' : 'Coterie'} Policy</div>
                  </button>
                </>
            }
            <div>
              <button disabled={policyLink === ''} className={classes.btnpdf} onClick={() => downloadPolicy()}>
                <div><img className={classes.imgdownload} src={download_image} alt='download-button' /></div>
                <div style={{ width: '98%' }}>Download Invoice</div>
              </button>
            </div>
            {
              paymentVia === 'Chargebee' &&
                <div>
                  <button disabled={downloadLink === ''} className={classes.btnInsurance} onClick={() => download()}>
                    <div className={classes.btnKoala}><Congrats /></div>
                    <div style={{ width: '98%' }}>Download Insurance doc</div>
                  </button>
                </div>
            }
            {
              isAGENT()
                ? (
                  <>
                    <hr className={classes.hr} />
                    <div className={classes.goToAgent}>
                      <a href={agentDashboardURL}>Go to Dashboard</a>
                    </div>
                  </>
                  )
                : null
            }
          </div>
          {/* <div>
            <div className={classes.text}><span><strong>Login back to your policy by adding your password</strong></span></div>
            <div className={classes.text2}><span>Choose a password for <strong>email@email.com</strong></span></div>
            <div className={classes.passwordInput}>
              <input className={classes.input1} placeholder='Password' />
              <input className={classes.input2} type='submit' value='Save' />
            </div>
            <div className={classes.text3}><span>Your password must be 8 length with a special symbol</span></div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
