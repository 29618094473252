import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Title from 'components/Title'
import './Card.scss'
import { ICard } from 'interfaces/pouch-redux';
import { formatDate } from 'utils/formatDate';

const Card = ({PremiumAmount, downPayment, PolicyEffective_DATE, PeriodLengthMonthly_NUM, PolicyTerm_CHOICE} : ICard) => {
    function createData(
        index: number,
        amount: string,
        description: string,
        date: string
      ) {
        return { index, amount, description, date };
      }

      const addDays = (date: Date, day: any) => {
        date.setDate(date.getDate() + day);
        return date;
      }
    
      const dates = [];
      let stringDate;
      let date;
      if (PeriodLengthMonthly_NUM) {
        stringDate = PolicyEffective_DATE?.toString().substring(0, PolicyEffective_DATE?.toString().indexOf('T'));
        date = new Date(stringDate?.toString() || Date.now())
        console.log(PeriodLengthMonthly_NUM)
        addDays(date, PeriodLengthMonthly_NUM).toString()
        for (let i = 0; i <= 4; i++) {
            dates.push(PeriodLengthMonthly_NUM + PeriodLengthMonthly_NUM) 
            
            console.log(...dates, PeriodLengthMonthly_NUM)           
        }
    }

    const absPeriodLengthMonthly_NUM = PeriodLengthMonthly_NUM || 30;
        
    if (!PolicyTerm_CHOICE || PolicyTerm_CHOICE.trim() === '') {
      PolicyTerm_CHOICE = null
    } else {
      PolicyTerm_CHOICE = PolicyTerm_CHOICE.split(' ')[0]
    }

    let rows 
    const extraPremiumAmnt = Number(PremiumAmount) + 6;
    const formatedDate = String(PolicyEffective_DATE).substring(0, String(PolicyEffective_DATE).indexOf('T')) + ' 01:00:00'
console.log(PolicyEffective_DATE, PeriodLengthMonthly_NUM, absPeriodLengthMonthly_NUM, formatedDate)
    const period1 = new Date(addDays(new Date(String(formatedDate)), absPeriodLengthMonthly_NUM)?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    const period2 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 2))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    const period3 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 3))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    const period4 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 4))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    const period5 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 5))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    if (PolicyTerm_CHOICE == '12'){
      const period6 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 6))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
      const period7 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 7))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
      const period8 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 8))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
      const period9 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 9))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
      const period10 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 10))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
      const period11 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 11))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
      const period12 = new Date(addDays(new Date(String(formatedDate)), (absPeriodLengthMonthly_NUM * 12))?.toString()).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})

      rows = [
        createData(1, downPayment || '', 'Down Payment', 'Today'),
        createData(2, (extraPremiumAmnt.toString()), '1st Installment', period1),
        createData(3, (extraPremiumAmnt.toString()), '2nd Installment', period2),
        createData(4, (extraPremiumAmnt.toString()), '3rd Installment', period3),
        createData(5, (extraPremiumAmnt.toString()), '4th Installment', period4),
        createData(6, (extraPremiumAmnt.toString()), '5th Installment', period5),
        createData(7, (extraPremiumAmnt.toString()), '6th Installment', period6),
        createData(8, (extraPremiumAmnt.toString()), '7th Installment', period7),
        createData(9, (extraPremiumAmnt.toString()), '8th Installment', period8),
        createData(10, (extraPremiumAmnt.toString()), '9th Installment', period9),
        createData(11, (extraPremiumAmnt.toString()), '10th Installment', period10),
        createData(12, (extraPremiumAmnt.toString()), '11th Installment', period11),
        ]
    } else {
      rows = [
        createData(1, downPayment || '', 'Down Payment', 'Today'),
        createData(2, (extraPremiumAmnt.toString()), '1st Installment', period1),
        createData(3, (extraPremiumAmnt.toString()), '2nd Installment', period2),
        createData(4, (extraPremiumAmnt.toString()), '3rd Installment', period3),
        createData(5, (extraPremiumAmnt.toString()), '4th Installment', period4),
        createData(6, (extraPremiumAmnt.toString()), '5th Installment', period5),
        ];
    }

  return (
    <>
        <TableContainer component={Paper} className='custom-table'>
            <Title>Payment Schedule</Title>
            <Table sx={{ minWidth: 650, border: 0 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='text-bold'>#</TableCell>
                        <TableCell align="right" className='text-bold'>Installment Amount</TableCell>
                        <TableCell align="right" className='text-bold'>Description</TableCell>
                        <TableCell align="right" className='text-bold'>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                    key={row.index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {row.index}
                    </TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
    </>
  )
}

export default Card