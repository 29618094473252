import { configureStore, Dispatch } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { quoteSlice } from "./slices/quote/quote";

const persistConfig = {
    key: 'pouch',
    storage,
}
   
const persistedReducer = persistReducer(persistConfig, quoteSlice.reducer)

const store = configureStore({
    reducer: {
        pouch: persistedReducer
    },
    middleware: [thunk]
})

export default store

export type RootState = ReturnType<typeof store.getState>
export const dispatchApp = store.dispatch


export const persistor = persistStore(store)