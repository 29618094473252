import { useFormik } from 'formik'
import { /* get, */ isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { LocalShipping as CarIcon, CheckCircle, RadioButtonUnchecked } from '@material-ui/icons'
import { useState, useEffect, ReactElement } from 'react'
import { Grid, Dialog, DialogContent, Checkbox } from '@material-ui/core'
// Components
import Title from 'components/Title'
import Collapse from './components/Collapse'
import AddButton from './components/AddButton'
import SelectField from 'components/SelectField'
import SelectTruck, { SelectTruckProps } from 'components/SelectTruck'
// Utils
import { VehicleSchema, TrailerSchema } from 'utils/validations'
// Context
import { useFormContext } from 'context/FormContext'
// Hooks
import useFormPercent from 'hooks/useFormPercent'
import useMixpanelTrack from 'hooks/useMixpanelTrack'
// Services
import { devAPI, GetAllYears, GetMakeByYear, GetModelByYearAndMake, GetLocalQuoteData, UpdateQuoteDataNoRate, quoteRef, API, getQuoteMultiItems } from '../../services/instanda'
// Assets
import modalPouch from './assets/modal-pouch.png'
import errorHandler from 'utils/errorHandler'
import { useSnackbar } from 'notistack'

export const vehicleGenerator = (addOffset: number): any => {
  return {
    VehicleMake_TXT: '',
    VehicleModel_TXT: '',
    VehicleYear_NUM: '',
    VehicleIncluded_YN: 'Yes',
    VehicleVIN_TXT: '',
    VehicleVINOverride_TXT: '',
    VehicleUpdate_DATE: Date.now() + addOffset,
    Lienholder_YNP: 'No',
    LienholderName_TXT: '',
    LienholderAddressLine1_TXT: '',
    LienholderCountry_TXT: 'United States',
    LienholderCity_TXT: '',
    LienholderState_TXT: '',
    LienholderZipCode_TXT: '',
    LienholderLoanNumber_TXT: '',
    VehicleAdditionalInsured_YN: 'No',
    VehiclePolicyParticipationTelematics_YN: 'No',
    VehicleOverrideDeductCOLL_CHOICE: '1000',
    VehicleOverrideDeductCOMP_CHOICE: '1000',
    VehicleOverrideLimitUMPD_IL_CHOICE: 'No Coverage'
  }
}

const trailerGenerator = (addOffset: number): any => {
  return {
    TrailerVIN_TXT: '',
    TrailerYear_TXT: '',
    TrailerMake_TXT: '',
    TrailerUpdate_DATE: Date.now() + addOffset,
    TrailerStatedAmount_CHOICE: '$4,000'
  }
}

const useStyles = makeStyles({
  flexMain: {
    display: 'flex',
    paddingBottom: '25px'
  },
  flexImage: {
    flex: '0 0 80px',
    position: 'relative',
    '& img': {
      width: '100%'
    }
  },
  flexTitle: {
    flex: 1,
    padding: '10px 0px 10px 20px',
    '& *:first-child': {
      fontSize: '20px',
      fontWeight: 500
    }
  },
  modalCheckbox: {
    textAlign: 'center',
    alignItems: 'center'
  },
  modalButton: {
    padding: '20px',
    textAlign: 'center',
    '& button': {
      border: 'none',
      color: '#ffffff',
      borderRadius: '5px',
      padding: '7px 18px',
      backgroundColor: '#FF6C19'
    }
  }
})

export default function Page2 (): ReactElement {
  const classes = useStyles()
  useMixpanelTrack('quote2-tracking')
  const formContext = useFormContext()
  const { enqueueSnackbar } = useSnackbar()

  const [modalOpen, setModalOpen] = useState(false)
  const [polkVehicles, setPolkVehicles] = useState<any>([])

  const addEmptyVehicle = (): void => {
    const newVehicles = [] as any

    formContext.quote?.Vehicles?.forEach((vehicle: any, index: number) => {
      const originalValues = vehicleGenerator(index)
      newVehicles[index] = { ...originalValues, ...vehicle }
    })

    if (newVehicles.length > 0) {
      formikFormVehicles
        .setValues(newVehicles)
        .catch((formError) => console.log({ formError }))
    }

    formContext.setLoading(false)
  }

  const getMultiItemValues = async (quoteRef: string) => {
    const response = await getQuoteMultiItems(quoteRef)
    const result = await response.data.data.response
    const trailers = result.Trailers
    const vehicles = result.Vehicles
    trailers.slice(0,formContext?.quote?.Trailers_Count).map((trailer: any, index: number) => {
        formikFormTrailers.setFieldValue(`[${index}].TrailerVIN_TXT`, trailer.TrailerVIN_TXT)
    })
    vehicles.slice(0,formContext?.quote?.Vehicles_Count).map((vehicle: any, index: number) => {
        formikFormVehicles.setFieldValue(`[${index}].VehicleVINOverride_TXT`, vehicle.VehicleVINOverride_TXT)
        formikFormVehicles.setFieldValue(`[${index}].Lienholder_YNP`, vehicle.Lienholder_YNP)
        formikFormVehicles.setFieldValue(`[${index}].LienholderAddressLine1_TXT`, vehicle.LienholderAddressLine1_TXT || '')
        formikFormVehicles.setFieldValue(`[${index}].LienholderCity_TXT`, vehicle.LienholderCity_TXT || '')
        formikFormVehicles.setFieldValue(`[${index}].LienholderCountry_TXT`, vehicle.LienholderCountry_TXT || 'United States')
        formikFormVehicles.setFieldValue(`[${index}].LienholderLoanNumber_TXT`, vehicle.LienholderLoanNumber_TXT || '')
        formikFormVehicles.setFieldValue(`[${index}].LienholderName_TXT`, vehicle.LienholderName_TXT || '')
        formikFormVehicles.setFieldValue(`[${index}].LienholderState_TXT`, vehicle.LienholderState_TXT || '')
        formikFormVehicles.setFieldValue(`[${index}].LienholderZipCode_TXT`, vehicle.LienholderZipCode_TXT || '')
        formikFormVehicles.setFieldValue(`[${index}].VehicleAdditionalInsured_YN`, vehicle.VehicleAdditionalInsured_YN || '')
    })
  }

  useEffect(() => {
    formContext.setLoading(true)
    GetLocalQuoteData()
      .then((response: any) => {
        if (response?.data?.Vehicles?.length > 0) {
          formContext.setQuote(response?.data);   
          const countVehicles = response?.data?.Vehicles_Count;
          const newVehicles = response?.data?.Vehicles?.slice(0,countVehicles).map((vehicle: any, index: number) => {
            const originalValues = vehicleGenerator(index)
            const vehicleVin = vehicle.VehicleVINOverride_TXT ?? vehicle.VehicleVIN_TXT_Default
            const vehicleValues = {
              ...vehicle,              
              VehicleVINOverride_TXT: vehicleVin !== 'not provided' ? vehicleVin : '',
              VehicleVIN_TXT: vehicleVin !== 'not provided' ? vehicleVin : '',
              VehicleAdditionalInsured_YN: response?.data?.VehicleAdditionalInsured_YN ?? 'No',
              VehiclePolicyParticipationTelematics_YN: response?.data?.VehiclePolicyParticipationTelematics_YN ?? 'No',
              VehicleOverrideDeductCOLL_CHOICE: '1000',
              VehicleOverrideDeductCOMP_CHOICE: '1000',
              VehicleOverrideLimitUMPD_IL_CHOICE: 'No Coverage'
            }
            return { ...originalValues, ...vehicleValues }
          })
          formikFormVehicles
            .setValues(newVehicles)
            .catch((formError) => console.log({ formError }))
          formContext.setLoading(false)
        } else {
          addEmptyVehicle()
        }
      })
      .catch((getInitialQuotePage2Error: any) => {
        console.log({ getInitialQuotePage2Error })
        addEmptyVehicle()
      })
  }, [])

  useEffect(() => {
    try {
      const newTrailers = [] as any

      formContext.quote?.Trailers?.slice(0,formContext?.quote?.Trailers_Count).forEach((trailer: any, index: number) => {
        const originalValues = trailerGenerator(index)
        newTrailers[index] = { ...originalValues, ...trailer }
      })

      if (newTrailers.length > 0) {
        formikFormTrailers
          .setValues(newTrailers)
          .catch((formError) => console.log({ formError }))
      }
    } catch (tryErr) {
      console.error({ tryErr })
    }
    const instandaQuoteRef =  localStorage.getItem('instandaQuoteRef')
    getMultiItemValues(formContext.quote?.QuoteRef || instandaQuoteRef) 
  }, [formContext.quote])

  const searchVehicleByVIN = async (vin: string, InputIndex: number) => {
    if (vin.length < 10) {
      await clearVehicleFields(InputIndex)
      // TODO: add same validation for Trailers
      await formikFormVehicles.setFieldTouched(`[${InputIndex}].VehicleVINOverride_TXT`)
      formikFormVehicles.setFieldError(`[${InputIndex}].VehicleVINOverride_TXT`, 'a VIN prefix with at least 10 characters is required')
      return
    }
    API.post('/polkServices/vinDecoderTen', { vin }, { headers: { 'Content-Type': 'application/json' } })
      .then(async (vinVehicle) => {
        const vehicleData = vinVehicle.data
        const polkYear = `${vehicleData?.year as number}`
        const polkMake = `${vehicleData?.make?.toUpperCase() as string}`
        const polkModel = `${vehicleData?.model?.toUpperCase() as string}`
        const validYearArray = await GetAllYears()
        const validMakeArray = await GetMakeByYear(polkYear)
        const validModelArray = await GetModelByYearAndMake(polkYear, polkMake)
        const validYear = validYearArray?.data?.result
        const validMake = validMakeArray?.data?.result
        const validModel = validModelArray?.data?.result
        if (validYear?.includes(polkYear) === true && validMake?.includes(polkMake) === true && validModel?.includes(polkModel) === true) {
          formikFormVehicles.setValues((vehicles) => {
            const newVehicles = vehicles.map((vehicle: any, index: number) => {
              const newVehicle = { ...vehicle, VehicleMake_TXT: polkMake, VehicleModel_TXT: polkModel, VehicleYear_NUM: polkYear, findByVIN: true }
              return InputIndex === index ? newVehicle : vehicle
            })
            return newVehicles
          })
        } else {
          await clearVehicleFields(InputIndex)
          await formikFormVehicles.setFieldTouched(`[${InputIndex}].VehicleVINOverride_TXT`)
          formikFormVehicles.setFieldError(`[${InputIndex}].VehicleVINOverride_TXT`, 'We couldn’t find your VIN, try by adding your vehicle manually')
        }
      })
      .catch(async (error) => {
        await clearVehicleFields(InputIndex)
        await formikFormVehicles.setFieldTouched(`[${InputIndex}].VehicleVINOverride_TXT`)
        formikFormVehicles.setFieldError(`[${InputIndex}].VehicleVINOverride_TXT`, 'We couldn’t find your VIN, try by adding your vehicle manually')
        console.log('Error getting information from polk vinDecoder', error)
      })
  }

  const clearVehicleFields = async (inputIndex: number) => {
    await formikFormVehicles.setValues((vehicles) => {
      const newVehicles = vehicles.map((vehicle: any, index: number) => {
        const newVehicle = { ...vehicle, VehicleMake_TXT: '', VehicleModel_TXT: '', VehicleYear_NUM: '', VehicleBody_TXT: '' }
        return inputIndex === index ? newVehicle : vehicle
      })
      return newVehicles
    })
  }

  const formikFormVehicles = useFormik({
    initialValues: [
      {
        findByVIN: false,
        VehicleMake_TXT: '',
        VehicleModel_TXT: '',
        VehicleYear_NUM: 0,
        VehicleIncluded_YN: 'Yes',
        VehicleVIN_TXT: '',
        VehicleVINOverride_TXT: '',
        VehicleUpdate_DATE: 0,
        Lienholder_YNP: 'No',
        LienholderName_TXT: '',
        LienholderAddressLine1_TXT: '',
        LienholderCountry_TXT: 'United States',
        LienholderCity_TXT: '',
        LienholderState_TXT: '',
        LienholderZipCode_TXT: '',
        LienholderLoanNumber_TXT: '',
        VehicleAdditionalInsured_YN: 'No',
        VehiclePolicyParticipationTelematics_YN: 'No',
        VehicleOverrideDeductCOLL_CHOICE: '1000',
        VehicleOverrideDeductCOMP_CHOICE: '1000',
        VehicleOverrideLimitUMPD_IL_CHOICE: 'No Coverage'
      }
    ],
    enableReinitialize: true,
    validationSchema: VehicleSchema,
    onSubmit: onSubmit
  })

  const PolicyEffective_DATE =  JSON.parse(localStorage.getItem('PolicyEffective_DATE') as string)
  
  async function onSubmit (): Promise<void> {
    console.log('Vehicle Validation: ', formikFormVehicles.validateForm())
    formContext.setLoading(true)
    const VehiclePolicyParticipationTelematics_YN = formikFormVehicles?.values[0].VehiclePolicyParticipationTelematics_YN ?? 'No';
    const validateVehicles = await formikFormVehicles.validateForm()
    const validateTrailers = await formikFormTrailers.validateForm()
    console.log('validate', validateTrailers)
    if (!isEmpty(validateVehicles) || !isEmpty(validateTrailers)) return
    // Setting formContext.quote
    UpdateQuoteDataNoRate({
      Vehicles: formikFormVehicles.values, Trailers: formikFormTrailers.values, VehiclePolicyParticipationTelematics_YN, PolicyEffective_DATE
    }, quoteRef()).then((res) => {
      formContext.setLoading(true)
    })
      .catch(resErr => {
        formContext.setLoading(false)
        errorHandler(resErr, enqueueSnackbar)
      })
      .finally(() => {
        formContext.goNextPage()
      })
    
    const quoteValues = { ...formContext.quote, Vehicles: formikFormVehicles.values, Trailers: formikFormTrailers.values, VehiclePolicyParticipationTelematics_YN, PolicyEffective_DATE }
    formContext.setQuote(quoteValues)
    // formContext.setLoading(false);
    //formContext.goNextPage()
  }

  

  const formikFormTrailers = useFormik<SelectTruckProps[]>({
    initialValues: [],
    enableReinitialize: true,
    validationSchema: TrailerSchema,
    onSubmit: onSubmit
  })

  useFormPercent(33, formikFormVehicles, formContext)

  const addButton = async (): Promise<void> => {
    await formikFormVehicles.setValues([
      ...formikFormVehicles.values,
      vehicleGenerator(formikFormVehicles.values.length)
    ])
  }
  const delButton = async (index: number): Promise<void> => {
    const modArray = [...formikFormVehicles.values]
    modArray.splice(index, 1)
    await formikFormVehicles.setValues(modArray)
  }

  const addButtonTrailer = async (): Promise<void> => {
    await formikFormTrailers.setValues([
      ...formikFormTrailers.values,
      trailerGenerator(formikFormTrailers.values.length)
    ])
  }

  const delButtonTrailer = async (index: number): Promise<void> => {
    const modArray = [...formikFormTrailers.values]
    modArray.splice(index, 1)
    await formikFormTrailers.setValues(modArray)
  }

  useEffect(() => formContext.setForm(formikFormVehicles), []) // ImportantSetForm

  const deactivateVehicle = (index: number): void => {
    setPolkVehicles((previousVehicles: any) => {
      const modArray = [...previousVehicles]
      modArray[index].VehicleActiveCustom = !modArray[index]?.VehicleActiveCustom
      return modArray
    })
  }

  const addPolkVehicles = (): void => {
    setModalOpen(false)
    const modArray = [...polkVehicles].filter((value) => value.VehicleActiveCustom === true)
    formikFormVehicles.setValues(modArray)
  }

  const isAdjust = formContext.adjustPolicy

  return (
    <>
      <form onSubmit={formikFormVehicles.handleSubmit}>
        <Dialog open={modalOpen} onClose={() => console.log('You can not close Polk modal this way')} disableEscapeKeyDown>
          <DialogContent>
            <div className={classes.flexMain}>
              <div className={classes.flexImage}>
                <img src={modalPouch} alt='pouch-icon' />
              </div>
              <div className={classes.flexTitle}>
                <h2>We found the following(s) vehicle(s) that matches your criteria</h2>
              </div>
            </div>
            {
              polkVehicles.map((vehicle: any, index: number) => (
                <Grid container spacing={3} key={index} onClick={() => deactivateVehicle(index)}>
                  <Grid item xs={10}>
                    {vehicle.VehicleYear_NUM_Original ?? ''} {vehicle.VehicleMake_TXT_Original ?? ''} {vehicle.VehicleModel_TXT_Original ?? ''}
                  </Grid>
                  <Grid item xs={2} className={classes.modalCheckbox}>
                    <Checkbox color='primary' checked={vehicle.VehicleActiveCustom} checkedIcon={<CheckCircle htmlColor='#38C7C2' />} icon={<RadioButtonUnchecked />} />
                  </Grid>
                </Grid>
              ))
            }
            <div className={classes.modalButton} onClick={addPolkVehicles}>
              <button>Continue</button>
            </div>
          </DialogContent>
        </Dialog>
        {!isAdjust ? <Title isAdjust>Vehicles information</Title> : <div style={{ marginTop: '2rem' }}><Title>Vehicles & Trailers</Title></div>}
        {
          formikFormVehicles.values?.map((_: any, index: number) => {
            const key = formikFormVehicles.values[index].VehicleUpdate_DATE
            const subtitle = formikFormVehicles.values[index].VehicleModel_TXT
            const hasError =
              formikFormVehicles.submitCount !== 0 &&
              formikFormVehicles?.errors[index] != null

            return (
              <Collapse
                key={key}
                index={index}
                title='Vehicle'
                image={<CarIcon />}
                subtitle={subtitle}
                hasError={hasError}
                delButton={delButton}
                ignoreIndex={formikFormVehicles.values.length > 1}
              >
                <SelectField isAdjust inputIndex={index} form={formikFormVehicles} searchVehicleByVIN={searchVehicleByVIN} />
              </Collapse>
            )
          })
        }
        <AddButton
          text='Add a Vehicle'
          action={addButton}
          disable={!(formikFormVehicles?.values?.length < 50)}
        />
      </form>
      <form onSubmit={formikFormTrailers.handleSubmit}>
        {!isAdjust && <Title>Trailer information</Title>}
        {formikFormTrailers.values?.map((_: any, index: number) => {
          const key = formikFormTrailers?.values[index]?.TrailerUpdate_DATE
          const subtitle = formikFormTrailers?.values[index]?.TrailerMake_TXT
          const hasError =
            formikFormTrailers?.submitCount !== 0 &&
            formikFormTrailers?.errors[index] != null

          return (
            <Collapse
              key={key}
              index={index}
              title='Trailer'
              image={<CarIcon />}
              subtitle={subtitle}
              hasError={hasError}
              delButton={delButtonTrailer}
              ignoreIndex
            >
              <SelectTruck inputIndex={index} form={formikFormTrailers} state={formContext?.quote?.BusinessState_TXT} />
            </Collapse>
          )
        })}
        <AddButton
          text='Add a Trailer'
          action={addButtonTrailer}
          disable={!(formikFormTrailers.values.length < 50)}
        />
      </form>
    </>
  )
}
