import { ReactElement, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '../generalities/Button'
import { makeStyles } from '@material-ui/core/styles'
import Banner from '../../assets/Pouch_head-bkg1.png'
import { envUrl } from 'utils/functions'

const MoneySection = makeStyles(theme => ({
  mainWrapper: {
    margin: '0 auto',
    paddingLeft: '25px',
    paddingRight: '25px',
    maxWidth: '1600px'
  },
  superTitle: {
    textAlign: 'center',
    alignItems: 'center',
    '& h1': {
      fontSize: 50,
      paddingTop: '65px',
      lineHeight: 1.2,
      textAlign: 'left'
    }
  },
  videoPlayer: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: '40px',
    position: 'relative'
  },
  videoButton: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '44%',
    [theme.breakpoints.up('sm')]: {
      top: '46%'
    },
    [theme.breakpoints.up('md')]: {
      top: '48%'
    }
  },
  playSVG: {
    fontSize: '25px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '48px'
    }
  },
}))

export default function Money (): ReactElement {
  const classes = MoneySection()

  const [showImage, setShowImage] = useState(true)
  const goToJoin = (): any => window.location.href = `https://quote${envUrl}-v3.pouchinsurance.com`
  const changeShowImage = (): void => setShowImage(false)

  return (
    <div className={classes.mainWrapper}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3} className={classes.superTitle}>
          <h1>Commercial Auto Insurance from Pouch</h1>
          <Button onClick={goToJoin}>GET A QUOTE</Button>
        </Grid>
        <Grid item xs md lg>
          <div>
              <img className={classes.videoPlayer} src={Banner} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
