const removePesosAndComas = (value: string): string => value.replaceAll('$', '').replaceAll(',', '')

const getBIPDIL = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitBIPD_IL_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}

const getBIPDIAZ = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitBIPD_AZ_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}

const getBIPDGA = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitBIPD_GA_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}

const getBIPDTX = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitBIPD_TX_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}
const getBIPDTN = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitBIPD_TN_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}

const getUMUIMIL = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitUMUIM_IL_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000'
  if (modified === 'n/a') return original
  return modified
}

const getUIMBIDAZ = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitUIMBI_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000'
  if (modified === 'n/a') return original
  return modified
}

const getUMBIAZ = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitUMBI_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000'
  if (modified === 'n/a') return original
  return modified
}

const getUMUIMGA = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitUMBIPD_GA_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}

const getUMCOGA = (response: any): string => {
  const modified = String(response?.UninsuredMotoristCoverageOption_GA_CHOICE ?? 'n/a')
  const original = 'Added On'
  if (modified === 'n/a') return original
  return modified
}

const getMEDPAY = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitMEDPAY_CHOICE ?? 'n/a')
  const original = 'No Coverage'
  if (modified === 'n/a') return original
  return modified
}

const getMEDPAYGA = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitMEDPAY_GA_CHOICE ?? 'n/a')
  const original = '2000'
  if (modified === 'n/a') return original
  return modified
}

const getEmployersNoYN = (response: any): string => {
  const modified = String(response?.EmployersNonOwnershipLiability_YN ?? 'n/a')
  const original = 'No'
  if (modified === 'n/a') return original
  return modified
}

const getCOMP = (vehicle: any): string => {
  const modified = String(vehicle?.VehicleOverrideDeductCOMP_CHOICE ?? 'n/a')
  const original = String(vehicle?.VehicleSelectedDeductibleCOMP_TXT ?? '')
  const originalChanged = removePesosAndComas(original)
  if (modified === 'n/a') return originalChanged
  return modified
}

const getTCOMP = (vehicle: any): string => {
  const modified = String(vehicle?.TrailerComprehensiveDeductible_CHOICE_Default ?? 'n/a')
  const original = 'No Coverage'
  const originalChanged = removePesosAndComas(original)
  if (modified === 'n/a') return originalChanged
  return modified
}

const getCOLL = (vehicle: any): string => {
  const modified = String(vehicle?.VehicleOverrideDeductCOLL_CHOICE ?? 'n/a')
  const original = String(vehicle?.VehicleSelectedDeductibleCOLL_TXT ?? '')
  const originalChanged = removePesosAndComas(original)
  if (modified === 'n/a') return originalChanged
  return modified
}

const getTCOLL = (trailer: any): string => {
  const modified = String(trailer?.TrailerCollisionDeductible_CHOICE_Default ?? 'n/a')
  const original = 'No Coverage'
  const originalChanged = removePesosAndComas(original)
  if (modified === 'n/a') return originalChanged
  return modified
}

const getUMPD = (vehicle: any): string => {
  const modified = String(vehicle?.VehicleOverrideLimitUMPD_IL_CHOICE_Default)
  const original = 'No Coverage'
  return modified === 'n/a' ? original : modified
}

const getTSA = (trailer: any): string => String(trailer?.TrailerStatedAmount_CHOICE_Default)

const getHIRED = (response: any): string => {
  const modified = String(response?.HiredAuto_YNP)
  const original = 'No'
  return modified === 'n/a' ? original : modified
}

const getPolicyTerm = (response: any): string => {
  const modified = String(response?.PolicyTerm_CHOICE)
  const original = '6 Months'
  return modified === 'n/a' ? original : modified
}

const getUMUIMTX = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitUMUIM_TX_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}

const getUMUIMTN = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitUMUIM_TN_CHOICE ?? 'n/a')
  const original = '$50,000/$100,000/$25,000'
  if (modified === 'n/a') return original
  return modified
}

const getMEDPAYTX = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitMEDPAY_TX_CHOICE ?? 'n/a')
  const original = '1000'
  if (modified === 'n/a') return original
  return modified
}
const getMEDPAYTN = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitMEDPAY_TN_CHOICE ?? 'n/a')
  const original = '1000'
  if (modified === 'n/a') return original
  return modified
}

const getPIP = (response: any): string => {
  const modified = String(response?.VehicleOverrideLimitPIP_CHOICE ?? 'n/a')
  const original = 'No Coverage'
  if (modified === 'n/a') return original
  return modified
}

const getACOH = (response: any): any => {
  const modified = String(response?.AnnualCostOfHire_NUM ?? 'n/a')
  const original = 0
  if (modified === 'n/a') return original
  return modified
}

const defaultFunction = (response: any): any => {
  // Root Policy IL
  response.VehicleOverrideLimitBIPD_IL_CHOICE = getBIPDIL(response)
  response.VehicleOverrideLimitUMUIM_IL_CHOICE = getUMUIMIL(response)
  response.VehicleOverrideLimitMEDPAY_CHOICE = getMEDPAY(response) // This is also "3rd" option of AZ
  // Root Policy AZ
  response.VehicleOverrideLimitBIPD_AZ_CHOICE = getBIPDIAZ(response)
  response.VehicleOverrideLimitUMBI_CHOICE = getUMBIAZ(response) // EXTRA: It's the same value and the one that's in next line
  response.VehicleOverrideLimitUIMBI_CHOICE = getUIMBIDAZ(response) // This is the "4th" option of AZ
  // Root Policy GA
  response.VehicleOverrideLimitBIPD_GA_CHOICE = getBIPDGA(response)
  response.VehicleOverrideLimitUMBIPD_GA_CHOICE = getUMUIMGA(response)
  response.UninsuredMotoristCoverageOption_GA_CHOICE = getUMCOGA(response)
  response.VehicleOverrideLimitMEDPAY_GA_CHOICE = getMEDPAYGA(response)
  response.VehicleOverrideLimitPIP_CHOICE = getPIP(response)
  response.EmployersNonOwnershipLiability_YN = getEmployersNoYN(response)
  response.HiredAuto_YNP = getHIRED(response)
  // Root Policy TX
  response.VehicleOverrideLimitBIPD_TX_CHOICE = getBIPDTX(response)
  response.VehicleOverrideLimitUMUIM_TX_CHOICE = getUMUIMTX(response)
  response.VehicleOverrideLimitMEDPAY_TX_CHOICE = getMEDPAYTX(response)
  response.EmployersNonOwnershipLiability_YN = getEmployersNoYN(response)
  response.PolicyTerm_CHOICE = getPolicyTerm(response)
  response.HiredAuto_YNP = getHIRED(response)
  response.AnnualCostOfHire_NUM = getACOH(response)
  // Root Policy TN
  response.VehicleOverrideLimitBIPD_TN_CHOICE = getBIPDTN(response)
  response.VehicleOverrideLimitUMUIM_TN_CHOICE = getUMUIMTN(response)
  response.VehicleOverrideLimitMEDPAY_TN_CHOICE = getMEDPAYTN(response)
  // Vehicle 1
  
  response.Vehicles = response?.Vehicles?.map((vehicle: any) => {
    return {
      ...vehicle,
      VehicleOverrideDeductCOMP_CHOICE: getCOMP(vehicle).trim(),
      VehicleOverrideDeductCOLL_CHOICE: getCOLL(vehicle).trim(),
      VehicleOverrideLimitUMPD_IL_CHOICE: getUMPD(vehicle)
    }
  })
  response.Trailers = response?.Trailers?.map((trailer: any) => {
    return {
      ...trailer,
      TrailerComprehensiveDeductible_CHOICE: getTCOMP(trailer),
      TrailerCollisionDeductible_CHOICE: getTCOLL(trailer),
      TrailerStatedAmount_CHOICE: getTSA(trailer),
      TrailerComprehensiveDeductible_CHOICE_Default: getTCOMP(trailer),
      TrailerCollisionDeductible_CHOICE_Default: getTCOLL(trailer),
      TrailerStatedAmount_CHOICE_Default: getTSA(trailer)
    }
  })
  // Returnable
  return response
}

export default defaultFunction
