import { merge } from 'lodash'
import { useSnackbar } from 'notistack'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useState, useEffect, ReactElement, ReactFragment } from 'react'
import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
// Context
import QuoteContext from 'context/QuoteContext'
import { useFormContext } from 'context/FormContext'
// Services
import { UpdateQuoteData, GetLocalQuoteData, isAGENT } from 'services/instanda'
// Components
import Title from 'components/Title'
import Subtitle from 'components/Subtitle'
import QuoteCard from './components/QuoteCard'
import QuoteSelect from './components/QuoteSelect'
import GenericInput from './components/GenericInput'
// Utils
import joinQuoteFiller from 'utils/joinQuoteFiller'
import errorHandler, { wasDeclined } from 'utils/errorHandler'
import { LimitMEDPAY, DeductCOLL, DeductCOMP, UMPDIL, CardPropsIL, CardPropsAZ, LimitBIPDIL, LimitUMUIMIL, LimitBIPDAZ, LimitUMBIAZ, LimitUIMBIAZ, payPlanOptions, DeductTCOLL, DeductTCOMP, CardPropsTX, CardPropsTN, LimitBIPDTX, LimitMEDPAYTX, LimitEmployers, LimitUMUIMTX, limitPIPTX, LimitHired, CardPropsGA, LimitBIPDGA, LimitUIMBIGA, UMTypeGA, LimitMEDPAYGA, LimitUMCOGA, LimitUIMBITX, LimitUMCOTX, DeductTSA, policyTermOptions, LimitBIPDTN, LimitUMUIMTN, LimitMEDPAYTN } from 'utils/joinQuoteSelect'
// Assets
import icon1 from './assets/quote-icon-1.svg'
import icon2 from './assets/quote-icon-2.svg'
import icon3 from './assets/quote-icon-3.svg'
import icon4 from './assets/quote-icon-4.svg'
import icon4black from './assets/quote-icon-4-black.svg'
import icon5 from './assets/quote-icon-5.svg'
// coterie view
import Coterie from './Coterie'
import useMixpanelTrack from 'hooks/useMixpanelTrack'
import { compareDates } from 'utils/functions'

const useStylesAccordion = makeStyles({
  ourAccordion: {
    padding: '5px 0px'
  },
  boxAccordion: {
    boxShadow: 'none',
    backgroundColor: 'inherit'
  },
  titleAccordion: {
    margin: 0
  },
  textsAccordion: {
    width: '100%'
  }
})

function QuoteAccordion ({ title, children }: { title: string, children: ReactFragment }): ReactElement {
  const classes = useStylesAccordion()
  const [expanded, setExpanded] = useState(true)
  const toggleExpanded = (): void => setExpanded(!expanded)
  return (
    <div className={classes.ourAccordion}>
      <Accordion expanded={expanded} onChange={toggleExpanded} square className={classes.boxAccordion}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Subtitle className={classes.titleAccordion}>{title}</Subtitle>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.textsAccordion}>
            {children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const useStyles = makeStyles({
  quoteMain: {
    height: '425px',
    maxWidth: '500px',
    paddingTop: '5px',
    position: 'relative',
    marginBottom: 55
  },
  quoteDesc: {
    margin: '2px 0px',
    fontSize: '18px'
  },
  MuiSelectTest: {
    color: 'transparent',
    '& input': {
      opacity: 'unset !important',
      border: 'none',
      background: 'none',
      textAlign: 'center'
    }
  },
  updateBtn: {
    border: 0,
    bottom: 0,
    width: '100%',
    color: 'white',
    padding: '15px',
    backgroundColor: '#FE6236',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#FF7C6D'
    }
  },
  customP: {
    marginLeft: '20%',
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  truckSubtitle: {
    paddingLeft: '20px'
  },
  customInput: {
    textAlign: 'center'
  },
  customInputHidden: {
    display: 'none',
    textAlign: 'center'
  }
})

export default function Page4 (): ReactElement {
  useMixpanelTrack('quote4-tracking')

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  // Form context
  const { setDisplayGlBopView, displayGlBopView, ...formContext } = useFormContext()

  // Quote context
  const [policyType, setPolicyType] = useState(localStorage.getItem('policyType'))
  const [actualCard, setActualCard] = useState(policyType === 'glbop' ? 0 : 1) // 0 = GLBOP, 1 = Custom
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const [policyValues, setPolicyValues] = useState<any>({})

  const [costYear, setCostYear] = useState(0)
  const [costMont, setCostMont] = useState(0)
  const [costDown, setCostDown] = useState(0)

  const [isYearly, setIsYearly] = useState(false) // Determine if the quote is yearly or monthly
  const [initialType, setInitialType] = useState(policyType === 'glbop' ? 0 : 1)
  const [initialYear, setInitialYear] = useState(false)

  const [CardProps, setCardProps] = useState<any>(CardPropsIL) // To set cardProps to corresponding usstate
  const [{ hasError, errorMessage }, setumbiUimbiError] = useState<any>({ hasError: false, errorMessage: '' })
  const [errorCoverageMessage, setErrorCoverageMessage] = useState('')
  const [selectedOption, setSelectedOption] = useState('Monthly')
  const [initialSelectedOption, setinitialSelectedOption] = useState('Monthly')
  const [policyHasTelematics, setPolicyHasTelematics] = useState(false)
  const [isHired, setIsHired] = useState(false)
  const [disabledBuyNow, setDisabledBuyNow] = useState(false)
  const [policyTerm, setPolicyTerm] = useState('6 Months')

  const [vehicleTrailerError, setvehicleTrailerError] = useState<any>({
    Vehicles: [{
      hasError: false,
      errorMessage: '',
      index: 0
    }],
    Trailers: [{
      hasError: false,
      errorMessage: ''
    }]
  })

  const priceChanges = (response: any): void => {
    const yearly = response.PayPlan_CHOICE !== 'Monthly - Recurring Credit Card' && response.PayPlan_CHOICE !== 'Offline Monthly' && response.PayPlan_CHOICE !!== 'Monthly - Recurring EFT'
    const option = response.PayPlan_CHOICE === 'Monthly - Recurring Credit Card' ? 'Monthly' : response.PayPlan_CHOICE
    setIsYearly(yearly)
    setinitialSelectedOption(response.PayPlan_CHOICE)
    setSelectedOption(option)
    // Initial value to now force update
    const coverTier = response.SelectedCoverTier_NUM as number
    const type = actualCard === 1 ? 1 : actualCard === 0 ? 0 : (coverTier + 1)
    setInitialType(type)
    setInitialYear(yearly)
    // const anualPremium = response.FinalPremiumAnnualInclTaxes_NUM + (response.FinalPremiumAnnualInclTaxes_NUM * 0.10)
    // const monthlyPremium = response.FinalPremiumMonthlyInclTaxes_NUM + (response.FinalPremiumMonthlyInclTaxes_NUM * 0.10)
    console.log(option)
    setCostYear(response.FinalPremiumAnnualInclTaxes_NUM)
    setCostMont(response.FinalPremiumMonthlyInclTaxes_NUM)
    // Costs
    // option !== 'Monthly'
    //   ? setCostYear(anualPremium)
    //   : setCostYear(response.FinalPremiumAnnualInclTaxes_NUM)
    // option !== 'Monthly'
    //   ? setCostMont(monthlyPremium)
    //   : setCostMont(response.FinalPremiumMonthlyInclTaxes_NUM)
    // setCostDown(response.cb_downpayment_num)
  }

  useEffect(() => {
    formContext.setProgress(100)
    GetLocalQuoteData()
      .then(res => {
        // start same quote filler
        const requiredFields = joinQuoteFiller(res?.data)
        initComprehensiveCollition(requiredFields)
        setPolicyValues(requiredFields) // Forcing added fields to policy
        priceChanges(requiredFields) // Forcing price changes
        // end same quote filler
        setCardProps(requiredFields.BusinessState_TXT === 'AZ' ? CardPropsAZ : requiredFields.BusinessState_TXT === 'TX' ? CardPropsTX : requiredFields.BusinessState_TXT === 'GA' ? CardPropsGA : requiredFields.BusinessState_TXT === 'TN' ? CardPropsTN : CardPropsIL) // set cardProps to corresponding usstate
      })
      .catch(resErr => {
        errorHandler(resErr, enqueueSnackbar)
        formContext.setLoading(false) // Hide loading indicator
      })
  }, [costYear])

  useEffect(() => {
    isHired ? policyValues[custCard.acoh] = '10000' : policyValues[custCard.acoh] = '0'
  }, [isHired])

  useEffect(() => {
    needsUpdate ? setDisabledBuyNow(true) : setDisabledBuyNow(false)
  }, [needsUpdate])

  const custCard = CardProps
  const fullQuote = JSON.parse(localStorage.getItem('instandaFullQuote') as string)

  useEffect(() => {
    setPolicyTerm(policyValues[custCard.pterm])
  }, [custCard.pterm])

  const toggleMM = (): void => { // Toggle for monthly
    setIsYearly(false)
    console.log(isYearly)
    setPolicyValues((current: any) => {
      return { ...current, PayPlan_CHOICE: fullQuote.PayPlan_CHOICE }
    })
  }

  const toggleYY = (): void => { // Toggle for yearly
    setIsYearly(true)
    console.log(isYearly)
    setPolicyValues((current: any) => {
      priceChanges(current)
      return { ...current, PayPlan_CHOICE: 'Paid in Full' }
    })
    console.log(policyValues.PayPlan_CHOICE)
  }

  const changeSelectedOption = (value: any): void => {
    setSelectedOption(value)
    console.log(value)
    switch (value) {
      case 'Paid in Full':
        toggleYY()
        break
      case 'Monthly':
        toggleMM()
        break
      case 'Offline Paid in Full':
        setIsYearly(true)
        setPolicyValues((current: any) => {
          return { ...current, PayPlan_CHOICE: fullQuote.PayPlan_CHOICE }
        })
        break
      case 'Offline Monthly':
        setIsYearly(false)
        setPolicyValues((current: any) => {
          return { ...current, PayPlan_CHOICE: 'Offline Monthly' }
        })
        break
    }
  }

  const clickCrd = (id: number): void => {
    setActualCard(id) // Now the actual card is this one
    if (id !== 1) {
      setumbiUimbiError({
        hasError: false,
        errorMessage: ''
      })
    } else {
      validateUmbiAndUimbi()
    }
    validateComprehensiveCollision()
  }

  const buyNowQT = (): void => {
    formContext.goNextPage()
  }

  const validateILCoverages = (): void => {
    if (LimitBIPDIL.indexOf(policyValues[CardProps.bipd]) < LimitUMUIMIL.indexOf(policyValues[CardProps.umui])) {
      setErrorCoverageMessage(`UM/UIM Can't be higher that BIPD`)
    } else {
      setErrorCoverageMessage('')
    }
  }
  const validateTXCoverages = (): void => {
    if ((policyValues[CardProps.umui].toLowerCase() !== 'no coverage') && LimitBIPDTX.indexOf(policyValues[CardProps.bipd]) < LimitUIMBITX.indexOf(policyValues[CardProps.umui])) {
      setErrorCoverageMessage(`UM/UIM coverage Can't be higher that BIPD`)
    } else {
      setErrorCoverageMessage('')
    }
  }
  const validateTNCoverages = (): void => {
    if ((policyValues[CardProps.umui].toLowerCase() !== 'no coverage') && LimitBIPDTN.indexOf(policyValues[CardProps.bipd]) < LimitUMUIMTN.indexOf(policyValues[CardProps.umui])) {
      setErrorCoverageMessage(`UM/UIM coverage Can't be higher that BIPD`)
    } else {
      setErrorCoverageMessage('')
    }
  }
  const validateGACoverages = (): void => {
    if ((policyValues[CardProps.umui].toLowerCase() !== 'no coverage') && LimitBIPDGA.indexOf(policyValues[CardProps.bipd]) < LimitUIMBIGA.indexOf(policyValues[CardProps.umui])) {
      setErrorCoverageMessage(`UM/UIM coverage Can't be higher that BIPD`)
    } else if ((policyValues[CardProps.umco].toLowerCase() === 'no coverage' && policyValues[CardProps.umui].toLowerCase() !== 'no coverage')) {
      setErrorCoverageMessage(`Uninsured Motoris Coverage Option can't be 'No coverage" when UM/UIM is selected`)
    } else {
      setErrorCoverageMessage('')
    }
  }

  const validateUmbiAndUimbi = (): boolean => {
    if ((policyValues[CardProps?.umbi]?.toLowerCase() === 'no coverage' && policyValues[CardProps.umbi] !== policyValues[CardProps.uimbi])) {
      console.log('Erroor')
      setumbiUimbiError({
        hasError: true,
        errorMessage: 'UMBI and UIMBI coverages must match'
      })
      return false
    } else if(LimitUMBIAZ.indexOf(policyValues[CardProps.umbi]) < LimitUIMBIAZ.indexOf(policyValues[CardProps.uimbi])
        && (policyValues[CardProps.uimbi]?.toLowerCase() !== 'no coverage')
        || (policyValues[CardProps.uimbi]?.toLowerCase() !== 'no coverage' &&
            (LimitUMBIAZ.indexOf(policyValues[CardProps.umbi]) >= 8 &&
            LimitUIMBIAZ.indexOf(policyValues[CardProps.uimbi]) >= 8 &&
            LimitUMBIAZ.indexOf(policyValues[CardProps.umbi]) <= LimitUIMBIAZ.indexOf(policyValues[CardProps.uimbi])))) {
      setumbiUimbiError({
        hasError: true,
        errorMessage: 'Underinsured Motorist must be less than Uninsured Motorist'
      })
      return false
    } else if((LimitBIPDAZ.indexOf(policyValues[CardProps.bipd]) < LimitUMBIAZ.indexOf(policyValues[CardProps.umbi]) && policyValues[CardProps.umbi].toLowerCase() !== 'no coverage')
        || (LimitBIPDAZ.indexOf(policyValues[CardProps.bipd]) < LimitUIMBIAZ.indexOf(policyValues[CardProps.uimbi]) && policyValues[CardProps.uimbi].toLowerCase() !== 'no coverage')) {
      setumbiUimbiError({
        hasError: true,
        errorMessage: `UM/UIM coverage Can't be higher that BIPD`
      })
      return false
    } else {
      setumbiUimbiError({
        hasError: false,
        errorMessage: ''
      })
      return true
    }
  }

  const initComprehensiveCollition = (data: any) => {
    setvehicleTrailerError((state: any) => {
      const vehiclesError = data?.Vehicles?.map(() => ({
        hasError: false,
        errorMessage: ''
      })) ?? state.Vehicles
      const trailersError = data?.Trailers?.map(() => ({
        hasError: false,
        errorMessage: ''
      })) ?? state.Trailers
      return {
        Vehicles: vehiclesError,
        Trailers: trailersError
      }
    })
  }

  const validateComprehensiveCollision = (): boolean => {
    const vehiclesError = policyValues?.Vehicles?.map((vehicle: any) => {
      if (vehicle[custCard.coll].toLowerCase() !== 'no coverage' && vehicle[custCard.comp].toLowerCase() === 'no coverage') {
        return {
          hasError: true,
          errorMessage: 'You can not select a collision coverage without selecting a comprehensive coverage'
        }
      } else {
        return {
          hasError: false,
          errorMessage: ''
        }
      }
    })

    const trailersError = policyValues?.Trailers?.map((trailer: any) => {
      if (trailer[custCard.tcoll].toLowerCase() !== 'no coverage' && trailer[custCard.tcomp].toLowerCase() === 'no coverage') {
        return {
          hasError: true,
          errorMessage: 'You can not select a collision coverage without selecting a comprehensive coverage'
        }
      } else {
        return {
          hasError: false,
          errorMessage: ''
        }
      }
    })

    setvehicleTrailerError((state: any) => ({
      Vehicles: vehiclesError ?? state.vehiclesError,
      Trailers: trailersError ?? state.trailersError
    }))

    return (vehiclesError?.find((vehicle: any) => vehicle.hasError) === undefined) && (trailersError?.find((trailer: any) => trailer.hasError) === undefined)
  }

  const updateQT = (): void => {
    const isComprehensiveValid = validateComprehensiveCollision()
    let isValid = true
    if (actualCard === 1 && policyValues.BusinessState_TXT === 'AZ') {
      isValid = validateUmbiAndUimbi()
    } else {
      isValid = true
      setumbiUimbiError({ hasError: false, errorMessage: '' })
    }

    let newPolicy = {}
    if (policyValues.BusinessState_TXT == 'IL') {
      newPolicy = policyValues.BusinessState_TXT === 'IL' ? {
        [custCard.bipd]: policyValues[custCard.bipd],
        [custCard.umui]: policyValues[custCard.umui],
        [custCard.medp]: policyValues[custCard.medp],
        [custCard.enol]: policyValues[custCard.enol],
        [custCard.hired]: policyValues[custCard.hired],
        [custCard.acoh]: policyValues[custCard.acoh],
        // Payment yearly or monthly
        PayPlan_CHOICE: policyValues.PayPlan_CHOICE,
        // Vehicles with overrides
        Vehicles: policyValues?.Vehicles,
        Trailers: policyValues?.Trailers,
        VehicleOverrideDeductCOLL_CHOICE: policyValues[custCard.coll],
        VehicleOverrideDeductCOMP_CHOICE: policyValues[custCard.comp],
        VehicleOverrideLimitUMPD_IL_CHOICE: policyValues[custCard.umpd]
      } : {}
    } else if (policyValues.BusinessState_TXT == 'AZ') {
      newPolicy = policyValues.BusinessState_TXT === 'AZ' ? {
        [custCard.bipd]: policyValues[custCard.bipd],
        [custCard.umbi]: policyValues[custCard.umbi],
        [custCard.uimbi]: policyValues[custCard.uimbi],
        [custCard.medp]: policyValues[custCard.medp],
        [custCard.enol]: policyValues[custCard.enol],
        [custCard.hired]: policyValues[custCard.hired],
        [custCard.acoh]: policyValues[custCard.acoh],
        // Payment yearly or monthly
        PayPlan_CHOICE: policyValues.PayPlan_CHOICE,
        // Vehicles with overrides
        Vehicles: policyValues?.Vehicles,
        Trailers: policyValues?.Trailers,
        VehicleOverrideDeductCOLL_CHOICE: policyValues[custCard.coll],
        VehicleOverrideDeductCOMP_CHOICE: policyValues[custCard.comp]
      } : {}
      console.log('Hey there policyValues: ', policyValues, )
      console.log('Hey there newPolicy: ', newPolicy, )
    } else if (policyValues.BusinessState_TXT == 'GA') {
      newPolicy = policyValues.BusinessState_TXT === 'GA' ? {
        [custCard.bipd]: policyValues[custCard.bipd],
        [custCard.umui]: policyValues[custCard.umui],
        [custCard.umco]: policyValues[custCard.umco],
        [custCard.medp]: policyValues[custCard.medp],
        [custCard.enol]: policyValues[custCard.enol],
        [custCard.hired]: policyValues[custCard.hired],
        [custCard.acoh]: policyValues[custCard.acoh],
        // Payment yearly or monthly
        PayPlan_CHOICE: policyValues.PayPlan_CHOICE,
        // Vehicles with overrides
        Vehicles: policyValues?.Vehicles,
        Trailers: policyValues?.Trailers,
        VehicleOverrideDeductCOLL_CHOICE: policyValues[custCard.coll],
        VehicleOverrideDeductCOMP_CHOICE: policyValues[custCard.comp]
      }
        : {}
    } else if (policyValues.BusinessState_TXT == 'TX') {
      newPolicy = policyValues.BusinessState_TXT === 'TX' ? {
        [custCard.bipd]: policyValues[custCard.bipd],
        [custCard.umui]: policyValues[custCard.umui],
        [custCard.umco]: policyValues[custCard.umco],
        [custCard.medp]: policyValues[custCard.medp],
        [custCard.pip]: policyValues[custCard.pip],
        [custCard.enol]: policyValues[custCard.enol],
        [custCard.pterm]: policyValues[custCard.pterm],
        [custCard.hired]: policyValues[custCard.hired],
        [custCard.acoh]: policyValues[custCard.acoh],
        [custCard.tsa]: policyValues[custCard.tsa],
        // Payment yearly or monthly
        PayPlan_CHOICE: policyValues.PayPlan_CHOICE,
        // Vehicles with overrides
        Vehicles: policyValues?.Vehicles,
        Trailers: policyValues?.Trailers
      }
        : {}
    } else if (policyValues.BusinessState_TXT == 'TN') {
      newPolicy = policyValues.BusinessState_TXT === 'TN' ? {
        [custCard.bipd]: policyValues[custCard.bipd],
        [custCard.umui]: policyValues[custCard.umui],
        [custCard.medp]: policyValues[custCard.medp],
        [custCard.enol]: policyValues[custCard.enol],
        [custCard.pterm]: policyValues[custCard.pterm],
        [custCard.hired]: policyValues[custCard.hired],
        [custCard.acoh]: policyValues[custCard.acoh],
        [custCard.tsa]: policyValues[custCard.tsa],
        // Payment yearly or monthly
        PayPlan_CHOICE: policyValues.PayPlan_CHOICE,
        // Vehicles with overrides
        Vehicles: policyValues?.Vehicles,
        Trailers: policyValues?.Trailers
      }
        : {}
    }

    if (isValid && isComprehensiveValid) {
      formContext.setLoading(true) // Show loading indicator
      console.log("policy", JSON.stringify(newPolicy))
      UpdateQuoteData(newPolicy) // Sending custom or common policy depending on selected card
        .then((res) => {
          if (wasDeclined(res?.data, formContext.setErrorModal)) return
          // start same quote filler
          const requiredFields = joinQuoteFiller(res?.data)
          setPolicyValues((currentState: any) => {
            priceChanges(requiredFields) // Forcing price changes
            return merge(currentState, requiredFields) // Merged local object + response object
          })
          // end same quote filler
          setPolicyTerm(policyValues[custCard.pterm])
          setNeedsUpdate(false) // After quote gets you can buy it
        })
        .catch(resErr => {
          errorHandler(resErr, enqueueSnackbar)
        })
        .finally(() => {
          formContext.setLoading(false) // Hide loading indicator
        })
    }
  }

  const context = {
    actualCard,
    setActualCard,
    policyValues,
    setPolicyValues,
    needsUpdate,
    setNeedsUpdate,
    isYearly,
    setIsYearly
  }

  const repetitiveProps = {
    toggleMM: toggleMM, // Toggle monthly
    toggleYY: toggleYY, // Toggle yearly
    buyNowQT: buyNowQT, // Buy now button
    updateQT: updateQT, // Update button
    isYearly: isYearly, // Is yearly boolean
    costYear: costYear, // Cost when paid yearly
    costMont: costMont, // Cost when paid monthly
    costDown: costDown, // Upfront cost
    needsUpdate: (needsUpdate && actualCard === 1) || (initialType !== actualCard) || (!isAGENT() && initialYear !== isYearly) || (initialSelectedOption !== policyValues.PayPlan_CHOICE), // Needs update boolean
    isAgentSite: false, // set false by default becasue agent payment options are only shown in page 6
    payPlanOptions,
    selectedOption,
    changeSelectedOption,
    quoteNum2: context.actualCard,
    isArizona: policyValues.BusinessState_TXT === 'AZ',
    isGeorgia: policyValues.BusinessState_TXT === 'GA'
  }

  const handleNextForCoterie = (): void => {
    setDisplayGlBopView(false)
    // updates values in case of policytype changes
    setPolicyType(localStorage.getItem('policyType'))
    setActualCard(localStorage.getItem('policyType') === 'glbop' ? 0 : 1)
    setInitialType(localStorage.getItem('policyType') === 'glbop' ? 0 : 1)
  }

  useEffect(() => {
    localStorage.removeItem('isComingBackFromPage6')
    localStorage.removeItem('agentFinish')
    GetLocalQuoteData()
      .then(res => {
        const fullQuote = res?.data
        localStorage.setItem('instandaFullQuote', JSON.stringify(fullQuote))
        formContext.setQuote(fullQuote)
        const telematics = fullQuote?.VehiclePolicyParticipationTelematics_YN
        if (telematics?.includes('Yes') === true) {
          setPolicyHasTelematics(true)
        }
        updateQT()
      })
      .catch(resErr => {
        errorHandler(resErr, enqueueSnackbar)
        formContext.setLoading(false) // Hide loading indicator
      })
  }, [])

  useEffect(() => {
    policyValues?.Vehicles?.map((vehicle: any) => {
      if (vehicle[custCard.coll] === '' || vehicle[custCard.coll] === 'n/a') {
        vehicle[custCard.coll] = '1000'
      }
      if (vehicle[custCard.comp] === '' || vehicle[custCard.comp] === 'n/a') {
        vehicle[custCard.comp] = '1000'
      }
      if (vehicle[custCard.umpd] === '' || vehicle[custCard.umpd] === 'n/a') {
        vehicle[custCard.coll] = 'No Coverage'
      }
    })
  }, [policyValues?.Vehicles])

  const policyEffectiveDATE = new Date(fullQuote.PolicyEffective_DATE)


  const isAdjust = formContext.adjustPolicy

  if (displayGlBopView) {
    return <Coterie handleNext={handleNextForCoterie} />
  } else {
    return (
      <QuoteContext.Provider value={context}>
        <Grid container spacing={2}>
          {!isAdjust && <Grid item xs={12} sm={12} md>
            <Title>Here is your quote!</Title>
            <p className={classes.customP}>{policyValues?.QuoteRef}</p>
            <section className={classes.quoteMain}>
              {
                policyType === 'glbop' && <QuoteCard typeCard={0} bkgColor='#FCFCFC' {...repetitiveProps} clickCrd={() => clickCrd(0)} disabledBuyNow={disabledBuyNow} policyTerm={policyTerm} />
              }
              <QuoteCard typeCard={1} bkgColor='#FCFCFC' {...repetitiveProps} policyHasTelematics={policyHasTelematics} icon4={icon4black} clickCrd={() => clickCrd(1)} disabledBuyNow={disabledBuyNow} policyTerm={policyTerm} />
            </section>
                        </Grid>}
          <Grid xs={12} sm={12} md>
            {!isAdjust && <p className={classes.quoteDesc}>Customize coverages below to create an apples to apples comparison of the current policy, then click update to see your changes applied.</p>}
            <QuoteAccordion title={!isAdjust ? 'Policy Coverages' : 'Coverages'}>
              {
                policyValues.BusinessState_TXT === 'IL'
                  ? (
                    <>
                      <QuoteSelect isAdjust className={classes.MuiSelectTest} image={icon1} property={custCard.bipd} options={LimitBIPDIL} title='Bodily Injury (BI) Property Damage (PD) Limits' validateField={validateILCoverages} />
                      <QuoteSelect isAdjust image={icon2} property={custCard.umui} options={LimitUMUIMIL} title='Uninsured / Underinsured Motorist (UIMBI) Limits' validateField={validateILCoverages} errorMesage={errorCoverageMessage} />
                      <QuoteSelect isAdjust image={icon3} property={custCard.medp} options={LimitMEDPAY} title='Medical Payments Limit' />
                      <QuoteSelect image={icon2} property={custCard.enol} options={LimitEmployers} title='Employer’s Non-Ownership Liability' />
                      <QuoteSelect image={icon4} property={custCard.hired} options={LimitHired} title='Hired auto' setIsHired={setIsHired} />
                    </>
                    )
                  : policyValues.BusinessState_TXT === 'TX'
                    ? (
                      <>
                        <QuoteSelect className={classes.MuiSelectTest} image={icon1} property={custCard.bipd} options={LimitBIPDTX} title='Bodily Injury (BI) Property Damage (PD) Limits' validateField={validateTXCoverages} />
                        <QuoteSelect image={icon2} property={custCard.umui} options={LimitUIMBITX} title='Uninsured / Underinsured Motorist (UIMBI) Limits' validateField={validateTXCoverages} errorMesage={errorCoverageMessage}/>
                        <QuoteSelect image={icon3} property={custCard.medp} options={LimitMEDPAYTX} title='Medical Payments Limit' />
                        <QuoteSelect image={icon1} property={custCard.pip} options={limitPIPTX} title='Personal Injury Protection Coverage Limits (PIP)' />
                        <QuoteSelect image={icon2} property={custCard.enol} options={LimitEmployers} title='Employer’s Non-Ownership Liability' />
                        <QuoteSelect image={icon4} property={custCard.hired} options={LimitHired} title='Hired auto' setIsHired={setIsHired} />

                        {
                          compareDates(policyEffectiveDATE, '2023-04-30') ?
                            <QuoteSelect image={icon4} property={custCard.pterm} options={policyTermOptions} title='Policy Term' />
                            : <></>
                        }
                      </>
                      )
                    : policyValues.BusinessState_TXT === 'GA'
                      ? (
                        <>
                          <QuoteSelect className={classes.MuiSelectTest} image={icon1} property={custCard.bipd} options={LimitBIPDGA} title='Bodily Injury (BI) Property Damage (PD) Limits' validateField={validateGACoverages} />
                          <QuoteSelect image={icon2} property={custCard.umui} options={LimitUIMBIGA} title='Uninsured / Underinsured Motorist (UIMBI) Limits' validateField={validateGACoverages} errorMesage={errorCoverageMessage} />
                          <QuoteSelect className={classes.MuiSelectTest} image={icon2} property={custCard.umco} options={LimitUMCOGA} title='Uninsured Motorist Coverage Option' validateField={validateGACoverages} errorMesage={errorMessage} />
                          <QuoteSelect image={icon3} property={custCard.medp} options={LimitMEDPAYGA} title='Medical Payments Limit' />
                          <QuoteSelect image={icon4} property={custCard.enol} options={LimitEmployers} title='Employer’s Non-Ownership Liability' />
                          <QuoteSelect image={icon4} property={custCard.hired} options={LimitHired} title='Hired auto' setIsHired={setIsHired} />
                        </>
                        )
                        : policyValues.BusinessState_TXT === 'AZ'
                        ? (
                        <>
                          <QuoteSelect className={classes.MuiSelectTest} image={icon1} property={custCard.bipd} options={LimitBIPDAZ} title='Bodily Injury (BI) Property Damage (PD) Limits' validateField={validateUmbiAndUimbi} />
                          <QuoteSelect image={icon2} property={custCard.umbi} options={LimitUMBIAZ} title='Uninsured Motorist (UMBI) Limits' validateField={validateUmbiAndUimbi} />
                          <QuoteSelect image={icon2} property={custCard.uimbi} options={LimitUIMBIAZ} title='Underinsured Motorist (UIMBI) Limits' validateField={validateUmbiAndUimbi} showError={hasError} errorMesage={errorMessage} />
                          <QuoteSelect image={icon3} property={custCard.medp} options={LimitMEDPAY} title='Medical Payments Limit' />
                          <QuoteSelect image={icon2} property={custCard.enol} options={LimitEmployers} title='Employer’s Non-Ownership Liability' />
                          <QuoteSelect image={icon4} property={custCard.hired} options={LimitHired} title='Hired auto' setIsHired={setIsHired} />
                        </>) :
                        policyValues.BusinessState_TXT === 'TN'
                        ? (
                        <>
                          <QuoteSelect className={classes.MuiSelectTest} image={icon1} property={custCard.bipd} options={LimitBIPDTN} title='Bodily Injury (BI) Property Damage (PD) Limits' validateField={validateTNCoverages} />
                          <QuoteSelect isAdjust image={icon2} property={custCard.umui} options={LimitUMUIMTN} title='Uninsured Motorist (UMBI and UMPD) & Underinsured Motorist (UIMBI) Limits' validateField={validateTNCoverages} errorMesage={errorCoverageMessage} />
                          {/* <QuoteSelect image={icon2} property={custCard.uimbi} options={LimitUIMBIAZ} title='Underinsured Motorist (UIMBI) Limits' validateField={validateUmbiAndUimbi} showError={hasError} errorMesage={errorMessage} /> */}
                          <QuoteSelect image={icon3} property={custCard.medp} options={LimitMEDPAYTN} title='Medical Payments Limit' />
                          <QuoteSelect image={icon2} property={custCard.enol} options={LimitEmployers} title='Employer’s Non-Ownership Liability' />
                          <QuoteSelect image={icon4} property={custCard.hired} options={LimitHired} title='Hired auto' setIsHired={setIsHired} />
                        </>) :
                        <>
                        <QuoteSelect className={classes.MuiSelectTest} image={icon1} property={custCard.bipd} options={LimitBIPDGA} title='Bodily Injury (BI) Property Damage (PD) Limits' />
                        <QuoteSelect image={icon2} property={custCard.umbi} options={LimitUMBIAZ} title='Uninsured Motorist (UMBI) Limits' validateField={validateUmbiAndUimbi} />
                        <QuoteSelect image={icon2} property={custCard.uimbi} options={LimitUIMBIAZ} title='Underinsured Motorist (UIMBI) Limits' validateField={validateUmbiAndUimbi} showError={hasError} errorMesage={errorMessage} />
                        <QuoteSelect image={icon3} property={custCard.medp} options={LimitMEDPAY} title='Medical Payments Limit' />
                      </>
              }
            </QuoteAccordion>
            {
              policyValues?.Vehicles?.slice(0,formContext?.quote?.Vehicles_Count).map((truck: any, index: number) => {
                const comp = `Vehicles[${index}].${custCard.comp as string}`
                const coll = `Vehicles[${index}].${custCard.coll as string}`
                const umpd = `Vehicles[${index}].${custCard.umpd as string}`
                return (
                  <QuoteAccordion key={index} title={`${truck.VehicleMake_TXT as string} ${truck.VehicleModel_TXT as string} ${truck.VehicleYear_NUM as string}`}>
                    <QuoteSelect isAdjust className={classes.MuiSelectTest} image={icon4} property={comp} options={DeductCOLL} title='Comprehensive' validateField={validateComprehensiveCollision} />
                    <QuoteSelect isAdjust className={classes.MuiSelectTest} image={icon5} property={coll} options={DeductCOMP} title='Collision' validateField={validateComprehensiveCollision} showError={vehicleTrailerError?.Vehicles?.[index]?.hasError} errorMesage={vehicleTrailerError?.Vehicles?.[index]?.errorMessage} />
                    {
                      policyValues.BusinessState_TXT === 'IL'
                        ? (
                          <QuoteSelect isAdjust className={classes.MuiSelectTest} image={icon4} property={umpd} options={UMPDIL} title='Uninsured Motorist Property Damage' />
                          )
                        : policyValues.BusinessState_TXT === 'GA'
                          ? (
                            <>
                            </>
                            )
                          : null
                    }
                  </QuoteAccordion>
                )
              })
            }
            {

              policyValues?.Trailers?.slice(0,formContext?.quote?.Trailers_Count).map((truck: any, index: number) => {
                const comp = `Trailers[${index}].${custCard.tcomp as string}`
                const coll = `Trailers[${index}].${custCard.tcoll as string}`
                const tsa = `Trailers[${index}].${custCard.tsa as string}`
                return (
                  <QuoteAccordion key={index} title={`${truck.TrailerMake_TXT as string} ${truck.TrailerYear_TXT as string}`}>
                    <QuoteSelect image={icon4} property={comp} options={DeductTCOLL} title='Comprehensive' validateField={validateComprehensiveCollision} />
                    <QuoteSelect image={icon5} property={coll} options={DeductTCOMP} title='Collision' validateField={validateComprehensiveCollision} showError={vehicleTrailerError?.Trailers?.[index]?.hasError} errorMesage={vehicleTrailerError?.Trailers?.[index]?.errorMessage} />
                    <QuoteSelect image={icon5} property={tsa} options={DeductTSA} title='Stated Amount' />
                  </QuoteAccordion>
                )
              })
            }
            <Grid item xs={12} sm={12} md>
              <button className={classes.updateBtn} onClick={updateQT}>Update Quote</button>
            </Grid>
          </Grid>
        </Grid>
      </QuoteContext.Provider>
    )
  }
}
