import { Grid } from '@material-ui/core'
import { ReactElement, useState } from 'react'
// Components
import Header from './components/global/Header'
import Drawer from './components/global/Drawer'
import { useAboutUsStyles } from './components/styles/AboutUs.styles'
// import Carousel from './components/generalities/Carousel'
// SVG
import Goodie_icon from './assets/Goodie_icon.svg'
import { ReactComponent as SVG_IG } from './assets/IG_Icon.svg'
import { ReactComponent as SVG_FB } from './assets/FB_Icon.svg'
import { ReactComponent as SVG_TW } from './assets/Twitter_icon.svg'
// import { ReactComponent as SVG_LI } from './assets/LeftIcon.svg'
// import { ReactComponent as SVG_RI } from './assets/RightIcon.svg'
import team1 from './assets/team1.png'
import team2 from './assets/team2.png'
import { ReactComponent as SVG_GOODIE } from './assets/Goodie.svg'
// import { AboutItems as items } from 'utils/carouselData'
import Footer from './components/global/Footer'

export function AboutUs (): ReactElement {
  const [openMenu, setOpenMenu] = useState(false)
  const classes = useAboutUsStyles()

  return (
    <>
      <Header setOpenMenu={setOpenMenu} />
      <Drawer isOpen={openMenu} setOpenMenu={setOpenMenu} />
      <div className={classes.aboutUs__main}>
        <div className={classes.aboutUs__container}>
          <div className={classes.hero__image}>
            <Grid
              container
              spacing={2}
              direction='row-reverse'
              alignItems='center'
              className={classes.grid__container}
            >
              <Grid item xs={12} md={7}>
                <h1 className={classes.title}>The commercial auto insurance <b>changed forever.</b></h1>
                <p className={classes.paragraph}>
                  We are here to support the 15 million businesses with under 20 employees. Our focus is to deliver insurance savings through customized product offerings and seamless service by finding the right coverages for your business.
                </p>
                <p className={classes.paragraph}>
                  Leading that effort is a team of insurance professionals who are also tech experts and have a great handle on what it means to make your small business successful.
                </p>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            direction='column'
            alignItems='center'
          >
            <Grid item className={classes.Goodie}>
              <img src={Goodie_icon} alt='Goodie icon' className={classes.Goodie_icon} />
            </Grid>
            <Grid item>
              <h2 className={classes.subtitle}> Why Pouch?</h2>
              <p className={classes.paragraph} style={{ textAlign: 'center' }}>
                The commercial auto insurance market is beset by an arduous sign-up process and rates based on who you are, not how you actually drive. Pouch bypasses the typical sign-up process, which can involve dozens of pages thick with indecipherable jargon, by delivering an instant quote and a clear path to purchase in just a few clicks. Customers provide basic info and Pouch can autofill the rest. At the same time, Pouch replaces the broad buckets that usually dictate rates with actual data about a customer’s driving behavior, offering lower rates for safer driving.
              </p>
              <p className={classes.paragraph} style={{ textAlign: 'center' }}>
                Free GPS vehicle tracking, the first of many rewards Pouch will offer, makes that possible. This software gives business owners more visibility over where their trucks and vans are located at any given time, whether drivers are following safe practices, and which driving routes are most efficient. The resulting analytics offer new insights on employee driving behavior that are key to training. With a closer eye on some of their most valuable assets, business owners can reduce risk and insurance rates.
              </p>
            </Grid>
            <Grid
              item
              container
              justifyContent='center'
              spacing={2}
            >
              <Grid item>
                <a href='https://www.facebook.com/GetPouchInsurance'>
                  <SVG_FB className={classes.aboutUs__socialIcons} />
                </a>
              </Grid>
              <Grid item>
                <a href='https://www.instagram.com/getpouchinsurance/'>
                  <SVG_IG className={classes.aboutUs__socialIcons} />
                </a>
              </Grid>
              <Grid item>
                <a href='https://twitter.com/FromPouch'>
                  <SVG_TW className={classes.aboutUs__socialIcons} />
                </a>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent='center'
              spacing={4}
            >
              <Grid
                item
                container
                direction='column'
                alignContent='center'
                sm={12}
                md={5}
              >
                <Grid container justifyContent='center'>
                  <img src={team1} className={classes.aboutUs__teamImage} alt='team1' />
                </Grid>
                <h3 className={classes.aboutUs__teamName}>Steve McKay</h3>
                <h4 className={classes.aboutUs__teamTitle}>Co-founder CEO</h4>
                <p className={classes.paragraph2}>
                  Steve is an InsurTech enterprenuer with 23 years of experience who likes to use data to make everything work better.
                </p>
              </Grid>
              <Grid
                item
                container
                direction='column'
                alignContent='center'
                sm={12}
                md={5}
              >
                <Grid container justifyContent='center'>
                  <img src={team2} className={classes.aboutUs__teamImage} alt='team2' />
                </Grid>
                <h3 className={classes.aboutUs__teamName}>Gloria Guntinas Vanzo</h3>
                <h4 className={classes.aboutUs__teamTitle}>Co-Founder COO</h4>
                <p className={classes.paragraph2}>
                  Gloria is a Latina innovator with 20 years of experience building exceptional products to help small business thrive.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent='space-around'
              className={classes.goodie__section}
            >
              <Grid
                item
                sm={12}
                md={5}
                container
                justifyContent='center'
              >
                <SVG_GOODIE className={classes.Goddie__image} />
              </Grid>
              <Grid
                item
                sm={12}
                md={5}
              >
                <h3 className={classes.Goodie__name}>Goodie</h3>
                <h4 className={classes.Goodie__subTitle}>Head of Customer Service and Top Safety Officer</h4>
                <p className={classes.Goodie_paragraph}>
                  New to the industry, but not new to protecting what is important to you. Goodie has a history of always being there for those who depend on her. Don’t let her cuddly exterior fool you. Goodie is a fierce and loyal supporter of your business and she would carry you on her own back if needed to protect you.
                </p>
                <p className={classes.Goodie_paragraph}>
                  So, focus on what you do best and leave the insurance to us. We’ve got you covered.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              className={classes.carousel_section}
              container
            >
              {/* <Grid item container>
                <Grid item xs={12} className='mx-auto'>
                  <Carousel
                    title='What our customer say'
                    RIGHTICON={SVG_RI}
                    LEFTICON={SVG_LI}
                    items={items}
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </>
  )
}
