import { ReactElement, useState } from 'react'

import Header from './components/global/Header'
import Drawer from './components/global/Drawer'
import logo from './assets/Fleetr_Blue.png'
import elements from './assets/25-layers.png'
import { makeStyles } from '@material-ui/core'
import Footer from './components/global/Footer'

const useStyles = makeStyles({
  title: {
    fontSize: 62,
    fontFamily: 'Libre Franklin',
    color: '#716262',
    marginBottom: 40,
    '@media (max-width:780px)': {
      fontSize: 41
    },
    '@media (max-width:740px)': {
      fontSize: 31
    },
    '@media (max-width:640px)': {
      fontSize: 27
    }
  },
  paragraph: {
    fontSize: 21,
    fontFamily: 'Libre Franklin',
    textAlign: 'justify',
    color: '#6b6b6b',
    marginBottom: 30,
    '@media (max-width:780px)': {
      fontSize: 16
    },
    '@media (max-width:740px)': {
      fontSize: 14
    }
  },
  bgc: {
    backgroundColor: '#F3F3F3'
  },
  forceByMojioLink: {
    color: '#39c7c2'

  }
})

export default function Partner (): ReactElement {
  const [openMenu, setOpenMenu] = useState(false)
  const classes = useStyles()

  return (
    <>
      <Header setOpenMenu={setOpenMenu} />
      <Drawer isOpen={openMenu} setOpenMenu={setOpenMenu} />
      <div className={classes.bgc}>
        <div className='container-sm'>
          <div className='row d-sm-flex justify-content-center'>
            <div className='d-flex justify-content-center mt-5'>
              <h1 className={classes.title}>Meet our software partner</h1>
            </div>
            <div className='ms-lg-4 mb-5'>
              <p className={classes.paragraph}>Pouch was founded by a team of insurance industry veterans who have been working with vehicle tracking software and telematics since the technologies were invented. We think differently about commercial auto insurance. Most importantly, we understand the role of technology in making insurance more fair to small business. At Pouch, we’ve done the hard work to partner with the best technology providers in the industry. That means you get the best products, the best support and the best perks when you choose to partner and insure your vehicles with us.</p>
            </div>
            <div className='row d-flex justify-content-between mb-5 mt-5'>
              <div className='col-sm-12 col-md-5'>
                <h3 className={classes.title}>Telematics Device by Fleetr</h3>
                <p className={classes.paragraph}>
                  GPS vehicle tracking solution designed specifically for the millions of small businesses who rely on their vehicles to get the job done. Helps business owners track their ﬂeet in real-time, encourage safe driving behavior, monitor health and maintenance, and improve customer service – all while reducing the total cost of operating their vehicles. When business vehicles are insured with Pouch and you sign up for Fleetr you will receive up to 20% discount on your policy! Learn more about the benefits of GPS vehicle tracking at Fleetr <a href='https://fleetr.com/our-products/' className={classes.forceByMojioLink}>https://fleetr.com/our-products/.</a>
                </p>
              </div>
              <div className='col-sm-12 col-md-5 mb-5 align-self-md-center'>
                <img
                  src={logo}
                  alt='ParnerLogo'
                  className='w-100'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
