export const LimitBIPD = [
  '25/50/20',
  '50/100/25',
  '100/300/50',
  '250/500/100',
  '100 CSL',
  '125 CSL'
]

export const LimitUMUIM = [
  '25/50',
  '50/100',
  '100/300',
  '250/500'
]

export const LimitMEDPAY = [
  '1000',
  'No Coverage'
]

export const LimitEmployers = [
  'Yes',
  'No'
]

export const LimitHired = [
  'Yes',
  'No'
]

export const LimitMEDPAYGA = [
  '2000',
  'No Coverage'
]

export const LimitMEDPAYTX = [
  '1000',
  '5000',
  '10000',
  'No Coverage'
]

export const limitPIPTX = [
  '2500',
  '5000',
  '10000',
  '25000',
  'No Coverage'
]

export const DeductCOLL = [
  '100',
  '250',
  '500',
  '1000',
  '2500',
  '5000',
  'No Coverage'
]

export const DeductTCOLL = [
  '$100',
  '$250',
  '$500',
  '$1,000',
  '$2,500',
  '$5,000',
  'No Coverage'
]

export const UMPDIL = [
  'Coverage',
  'No Coverage'
]

export const DeductCOMP = [
  '100',
  '250',
  '500',
  '1000',
  '2500',
  '5000',
  'No Coverage'
]

export const DeductTCOMP = [
  '$100',
  '$250',
  '$500',
  '$1,000',
  '$2,500',
  '$5,000',
  'No Coverage'
]

export const DeductTSA = [
  'Actual Cash Value',
  '$1,000',
  '$2,000',
  '$3,000',
  '$4,000',
  '$5,000',
  '$6,000',
  '$7,000',
  '$8,000',
  '$9,000',
  '$10,000',
  '$11,000',
  '$12,000',
  '$13,000',
  '$14,000',
  '$15,000',
  '$16,000',
  '$17,000',
  '$18,000',
  '$19,000',
  '$20,000',
  '$21,000',
  '$22,000',
  '$23,000',
  '$24,000',
  '$25,000',
  '$26,000',
  '$27,000',
  '$28,000',
  '$29,000',
  '$30,000',
  '$31,000',
  '$32,000',
  '$33,000',
  '$34,000',
  '$35,000',
  '$36,000',
  '$37,000',
  '$38,000',
  '$39,000',
  '$40,000',
  '$41,000',
  '$42,000',
  '$43,000',
  '$44,000',
  '$45,000',
  '$46,000',
  '$47,000',
  '$48,000',
  '$49,000',
  '$50,000',
  '$51,000',
  '$52,000',
  '$53,000',
  '$54,000',
  '$55,000',
  '$56,000',
  '$57,000',
  '$58,000',
  '$59,000',
  '$60,000',
  '$61,000',
  '$62,000',
  '$63,000',
  '$64,000',
  '$65,000',
  '$66,000',
  '$67,000',
  '$68,000',
  '$69,000',
  '$70,000',
  '$71,000',
  '$72,000',
  '$73,000',
  '$74,000',
  '$75,000'
]

export const LimitBIPDIL = [
  '$25,000/$50,000/$20,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL'
]

export const LimitUMUIMIL = [
  '$25,000/$50,000',
  '$50,000/$100,000',
  '$100,000/$300,000',
  '$250,000/$500,000',
  '$500,000/$500,000',
  '$500,000/$1,000,000',
  '$1,000,000/$1,000,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL'
]

export const LimitUMUIMTX = [
  '30,000/60,000/25,000',
  '50,000/100,000/25,000',
  '100,000/300,000/50,000',
  '250,000/500,000/100,000',
  '500,000/500,000/100,000',
  '500,000/1,000,000/100,000',
  '1,000,000/1,000,000/100,000',
  '100,000 CSL',
  '125,000 CSL',
  '250,000 CSL',
  '500,000 CSL',
  '1,000,000 CSL',
  'No Coverage'
]

export const LimitBIPDAZ = [
  '$25,000/$50,000/$15,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL'
]

export const LimitUMBIAZ = [
  '$25,000/$50,000',
  '$50,000/$100,000',
  '$100,000/$300,000',
  '$250,000/$500,000',
  '$500,000/$500,000',
  '$500,000/$1,000,000',
  '$1,000,000/$1,000,000',
  '$2,000,000/$2,000,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL',
  '$2,000,000 CSL',
  'No coverage'
]

export const LimitUIMBIAZ = [
  '$25,000/$50,000',
  '$50,000/$100,000',
  '$100,000/$300,000',
  '$250,000/$500,000',
  '$500,000/$500,000',
  '$500,000/$1,000,000',
  '$1,000,000/$1,000,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL',
  '$2,000,000 CSL',
  'No coverage'
]

export const LimitBIPDGA = [
  '$25,000/$50,000/$25,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL'
]

export const LimitUIMBIGA = [
  '$25,000/$50,000/$25,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL',
  'No Coverage' 
]

export const LimitUMCOGA = [
  'Added On',
  'Reduced',
  'No Coverage'
]

export const UMTypeGA = [
  'Added On',
  'Reduced'
]

export const LimitBIPDTX = [

  '$30,000/$60,000/$25,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL'
]

export const LimitUIMBITX = [
  '$30,000/$60,000/$25,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL',
  'No Coverage'
]

export const LimitUMCOTX = [
  'Added On',
  'Reduced',
  'No Coverage'
]

export const UMTypeTX = [
  'Added On',
  'Reduced'
]

export const LimitMEDPAYGTX = [
  '2000',
  'No Coverage'
]

export const CardPropsIL = {
  bipd: 'VehicleOverrideLimitBIPD_IL_CHOICE',
  umui: 'VehicleOverrideLimitUMUIM_IL_CHOICE',
  medp: 'VehicleOverrideLimitMEDPAY_CHOICE',
  comp: 'VehicleOverrideDeductCOMP_CHOICE',
  coll: 'VehicleOverrideDeductCOLL_CHOICE',
  umpd: 'VehicleOverrideLimitUMPD_IL_CHOICE',
  tcomp: 'TrailerComprehensiveDeductible_CHOICE',
  tcoll: 'TrailerCollisionDeductible_CHOICE',
  tsa: 'TrailerStatedAmount_CHOICE',
  enol: 'EmployersNonOwnershipLiability_YN',
  hired: 'HiredAuto_YNP',
  acoh: 'AnnualCostOfHire_NUM'
}

export const CardPropsAZ = {
  bipd: 'VehicleOverrideLimitBIPD_AZ_CHOICE',
  umbi: 'VehicleOverrideLimitUMBI_CHOICE',
  uimbi: 'VehicleOverrideLimitUIMBI_CHOICE',
  medp: 'VehicleOverrideLimitMEDPAY_CHOICE',
  comp: 'VehicleOverrideDeductCOMP_CHOICE',
  coll: 'VehicleOverrideDeductCOLL_CHOICE',
  tcomp: 'TrailerComprehensiveDeductible_CHOICE',
  tcoll: 'TrailerCollisionDeductible_CHOICE',
  tsa: 'TrailerStatedAmount_CHOICE',
  enol: 'EmployersNonOwnershipLiability_YN',
  hired: 'HiredAuto_YNP',
  acoh: 'AnnualCostOfHire_NUM'
}

export const CardPropsTX = {
  bipd: 'VehicleOverrideLimitBIPD_TX_CHOICE',
  umui: 'VehicleOverrideLimitUMUIM_TX_CHOICE',
  medp: 'VehicleOverrideLimitMEDPAY_TX_CHOICE',
  pip: 'VehicleOverrideLimitPIP_CHOICE',
  enol: 'EmployersNonOwnershipLiability_YN',
  hired: 'HiredAuto_YNP',
  acoh: 'AnnualCostOfHire_NUM',
  comp: 'VehicleOverrideDeductCOMP_CHOICE',
  coll: 'VehicleOverrideDeductCOLL_CHOICE',
  umpd: 'VehicleOverrideLimitUMPD_IL_CHOICE',
  pterm: 'PolicyTerm_CHOICE',
  tcomp: 'TrailerComprehensiveDeductible_CHOICE',
  tcoll: 'TrailerCollisionDeductible_CHOICE',
  tsa: 'TrailerStatedAmount_CHOICE'
}

export const CardPropsGA = {
  bipd: 'VehicleOverrideLimitBIPD_GA_CHOICE',
  umui: 'VehicleOverrideLimitUMBIPD_GA_CHOICE',
  umco: 'UninsuredMotoristCoverageOption_GA_CHOICE',
  medp: 'VehicleOverrideLimitMEDPAY_GA_CHOICE',
  enol: 'EmployersNonOwnershipLiability_YN',
  comp: 'VehicleOverrideDeductCOMP_CHOICE',
  coll: 'VehicleOverrideDeductCOLL_CHOICE',
  hired: 'HiredAuto_YNP',
  acoh: 'AnnualCostOfHire_NUM',
  tcomp: 'TrailerComprehensiveDeductible_CHOICE',
  tcoll: 'TrailerCollisionDeductible_CHOICE',
  tsa: 'TrailerStatedAmount_CHOICE'
}

export const CardPropsTN = {
  bipd: 'VehicleOverrideLimitBIPD_TN_CHOICE',
  umui: 'VehicleOverrideLimitUMUIM_TN_CHOICE',
  medp: 'VehicleOverrideLimitMEDPAY_TN_CHOICE',
  enol: 'EmployersNonOwnershipLiability_YN',
  comp: 'VehicleOverrideDeductCOMP_CHOICE',
  coll: 'VehicleOverrideDeductCOLL_CHOICE',
  hired: 'HiredAuto_YNP',
  acoh: 'AnnualCostOfHire_NUM',
  tcomp: 'TrailerComprehensiveDeductible_CHOICE',
  tcoll: 'TrailerCollisionDeductible_CHOICE',
  tsa: 'TrailerStatedAmount_CHOICE'
}

export const payPlanOptions = [
  { label: 'Paid in Full', value: 'Paid in Full' },
  { label: 'Monthly: Recurring CC/ACH', value: 'Monthly' },
  { label: 'Offline Paid in Full - Agency Billing Paid in Full', value: 'Offline Paid in Full' },
  { label: 'Offline monthly - Agency Monthly', value: 'Offline Monthly' }
]
export const policyTermOptions = [
  '6 Months',
  '12 Months'
]

export const LimitBIPDTN = [
  '$25,000/$50,000/$25,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL'
]

export const LimitUMUIMTN = [
  '$25,000/$50,000/$25,000',
  '$50,000/$100,000/$25,000',
  '$100,000/$300,000/$50,000',
  '$250,000/$500,000/$100,000',
  '$500,000/$500,000/$100,000',
  '$500,000/$1,000,000/$100,000',
  '$1,000,000/$1,000,000/$100,000',
  '$100,000 CSL',
  '$125,000 CSL',
  '$250,000 CSL',
  '$500,000 CSL',
  '$1,000,000 CSL',
  'No Coverage'
]

export const LimitMEDPAYTN = [
  '1000',
  '5000',
  '10000',
  'No Coverage'
]