import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, CircularProgress, ClickAwayListener, Tooltip } from '@material-ui/core'
import './ThankYou.scss'
import Congrats from 'components/StepperContainer/assets/congrats'
import contentCopy from '../../Join/assets/content_copy.svg'
import download_image from '../../Join/assets/download-quote-pdf.png'
import goToDashboard from '../../../components/StepperContainer/assets/GoToDashboard.svg'
import { downloadPolicyService, getSalesPerson, isAGENT } from 'services/instanda'
import { DownloadCreditNote, DownloadPDF } from 'services/chargeBee'
import { useSnackbar } from 'notistack'
import errorHandler from 'utils/errorHandler'
import Navbar from 'UI/Navbar/Navbar'
import { useAppSelector } from 'hooks/useAppSelector'
import { goToDashboard as goToHome } from '../../../utils/functions'
import { getBrandBySalesName, getSalesNamesofBrands } from 'utils/configBrands'

const ThankYou = () => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Thank You for Choosing Pouch!');
  const { quote, previousQuote } = useAppSelector(state=> state.pouch);    
  const [openToolTip, setOpenToolTip] = useState(false)
  const [downloadLink, setDownloadLink] = useState('')
  const [policyLink, setPolicyLink] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const yourPolicy = useRef<any>(null)
  const [documentError, setDocumentError] = useState(false)
  
  
  useEffect(() => {
    if (getSalesNamesofBrands().includes(getSalesPerson())){
        setTitle('Thank You for Choosing us!')
    }
    setLoading(true);    
    async function dwpdf(): Promise<any> {
        try {                        
            if(quote?.dueToday?.MTADueTodayAmount  &&  quote?.dueToday?.MTADueTodayAmount  < 0){
                const resAPI = await DownloadCreditNote(quote?.ChargerBeeDownPaymentID);
                setDownloadLink(resAPI.data.download_url)
            }else{
                const resAPI = await DownloadPDF(quote?.ChargerBeeDownPaymentID,quote?.QuoteRef);
                setDownloadLink(resAPI.data.result.download.download_url)
            }
            
            const docName = getDocumentName()
            if(docName){
                const respPolicy = await downloadPolicyService(quote?.QuoteRef, docName)
                if(respPolicy){
                    setPolicyLink(docName)
                }
            }
            setLoading(false)
            // console.log({ resAPI, respPolicy })
        } catch (err) {
            console.log({ err })
            setDocumentError(true)
            setLoading(false)
        }
    }

    dwpdf().catch((error) => {
        console.log({ error })
        setDocumentError(true)
        setLoading(false)
    })
}, [])

const download = (): void => {
    const newWindow = window.open(downloadLink, '_blank', 'noopener,noreferrer')
    if (newWindow != null) newWindow.opener = null
}

const getDocumentName = () => {
    let docName;
    switch (quote?.BusinessState_TXT) {
        case 'IL':
            docName = 'PCAILPOLPAK_0722';
            break;                
        case 'AZ':
            docName = 'PCAAZPOLPAK_0821';
            break;                
        case 'TX':
            docName = 'PCATXPOLPAK_0822';
            break;                
        case 'TN':
            docName = 'PCATNPOLPAK';
            break;                
        case 'GA':
            docName = 'PCAGAPOLPAK_0822';
            break;                
        default:
            break;
    }
    return docName;
}

const downloadPolicy = (): void => {
    const docName = getDocumentName();
    if(docName){
        downloadPolicyService(quote?.QuoteRef, docName)
            .then((response) => {
                window.open(URL.createObjectURL(response.data));
            })
    }
}

const copyToclipboard = async (): Promise<void> => {
    await navigator.clipboard.writeText(yourPolicy.current.value)
}

const tooltipOpen = (): void => {
    setOpenToolTip(true)
}

const tooltipClose = (): void => {
    setOpenToolTip(false)
}


  
  return (
    <>
      <Navbar />
      <Box maxWidth={860} margin='0 auto' padding={5} className='page flex-center'>
            <Card className='thanks-card'>
                {
                    !loading ?
                        <div className='content'>
                            <div className='thanks-image'><Congrats fill='#37c3b8' /></div>
                            <div>
                                <div className='thanks-title'>
                                    {title}
                                </div>
                                <div style={{ fontWeight: 'bold' }}>YOUR POLICY NUMBER</div>
                                <ClickAwayListener onClickAway={tooltipClose}>
                                    <div className='policyNumber'>
                                        <input disabled className='yourPolicy' ref={yourPolicy} value={quote?.PouchPolicyNumber_TXT ?? ''} />
                                        <Tooltip open={openToolTip} title='Policy Number Copied!' onClose={tooltipClose} disableFocusListener disableHoverListener disableTouchListener>
                                            <button onClick={async () => { await copyToclipboard(); tooltipOpen() }} className='btnContentCopy'><img src={contentCopy} alt='content-copy' className='contentCopy' />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </ClickAwayListener>
                                <div>
                                    <div>You paid</div>
                                    <h3 className='mount'>
                                        { quote?.FinalPremiumAnnualInclTaxes_NUM != previousQuote?.FinalPremiumAnnualInclTaxes_NUM ?
                                            <>
                                                {quote &&
                                                    `$${ quote?.dueToday?.MTADueTodayAmount  ?
                                                            quote?.dueToday?.MTADueTodayAmount 
                                                            :
                                                            (quote?.PayPlan_CHOICE === 'Paid in Full' || quote?.PayPlan_CHOICE === 'Offline Paid in Full')
                                                            ? quote.FinalPremiumAnnualInclTaxes_NUM?.toFixed(2) 
                                                            : quote.cb_downpayment_num?.toFixed(2) 
                                                    }`}
                                                {!quote && ', click to donwload your invoice.'}
                                            </>
                                            :
                                            <>
                                                No Premium Change
                                            </>
                                        }
                                    </h3>
                                    <div>
                                        <button disabled={downloadLink  === ''} className='btnpdf' onClick={() => download()}>
                                            <div><img className='imgdownload' src={download_image} alt='download-button' /></div>
                                            <div style={{ width: '98%' }}>Download Invoice</div>
                                        </button>
                                    </div>
                                    
                                    {
                                        !documentError ?
                                        <>
                                            <div>
                                                <button disabled={policyLink === ''} className='btnInsurance' onClick={() => downloadPolicy()}>
                                            <div className='btnKoala'><Congrats /></div>
                                                    <div style={{ width: '98%' }}>Download Insurance doc</div>
                                                </button>
                                            </div>
                                        </> : <></>
                                    }
                                    
                                    {
                                        isAGENT() || getSalesNamesofBrands().includes(getSalesPerson())?
                                            <button className='btnGoToDashboard' onClick={() => goToHome()}>
                                                <div><img className='goToDashboard' src={goToDashboard} alt='GoToDashboard-button' /></div>
                                                <div style={{ width: '98%' }}>Go to { getBrandBySalesName(getSalesPerson())?.name ?? "Dashboard" }</div>
                                            </button>
                                        :
                                        <>
                                        </>
                                    }
                                </div>
                            </div>
                        </div> :
                        <CircularProgress />
                }

            </Card>
        </Box>
    </>
  )
}

export default ThankYou