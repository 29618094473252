import { ReactElement, useEffect, useState } from 'react'
import { Button, IconButton, InputLabel } from '@material-ui/core'
import './Select.scss'
import { Search } from '@material-ui/icons'
import { ErrorMessage, InputContainer, Label, SelectImput, ToggleContainer } from '../../../../UI/CustomElement'
import TypedInput from '../TypedInput/TypedInput'
import { ISelect } from 'interfaces/pouch-redux'
import 'UI/Error/error-styles.scss'
import { validateError } from 'utils/validations'
import {
  GetAllYears,
  GetMakeByYear,
  GetModelByYearAndMake,
  GetTrim
} from 'services/instanda'
import { DeductCOLL, DeductCOMP, UMPDIL } from 'utils/joinQuoteSelect'
import { useAppSelector } from 'hooks/useAppSelector'


const Select = ({
  inputIndex = 1,
  inputLabel = '',
  inputPlaceholder = '',
  isAdjust,
  isDisabled,
  inputValue,
  formikForm,
  hasErrors,
  setVerifyErrors,
  telematics,
  setTelematics,
  searchVehicleByVIN
}: ISelect): ReactElement => {
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch);
  const [isActiveYesBtn, setIsActiveYesBtn] = useState(false);
  const [isActiveNoBtn, setIsActiveNoBtn] = useState(false);
  const [isActiveTelematicsYesBtn, setIsActiveTelematicsYesBtn] = useState(false);
  const [isActiveTelematicsNoBtn, setIsActiveTelematicsNoBtn] = useState(false);
  const [showVinInput, setShowVinInput] = useState<boolean>(false)

  const [yearList, setYearList] = useState([])
  const [selectedYear, setSelectedYear] = useState('')
  const [selectedMake, setSelectedMake] = useState('')
  const [makeList, setMakeList] = useState([])
  const [modelList, setModelList] = useState([])
  const [bodyList, setBodyList] = useState<string[]>([])

  const getTrimValueStart = async (
    year: string,
    make: string,
    model: string
  ): Promise<void> => {
    try {
      formikForm.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, model)
      const result = await GetTrim(year, make, model)
      const trimsList = result.data.result?.map((vehicle: any) => vehicle.trim)
      setBodyList(trimsList)
      formikForm.setFieldValue(
        `[${inputIndex}].VehicleBody_TXT`,
        trimsList[0] || ''
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getAllMakes = async (
    year: string,
    selected = false
  ): Promise<void> => {
    const result = await GetMakeByYear(year)
    setMakeList(result.data.result)
    setSelectedYear(year)

    if (selected) {
      formikForm.setFieldValue(`[${inputIndex}].VehicleYear_NUM`, year)
      formikForm.setFieldValue(`[${inputIndex}].VehicleMake_TXT`, '')
      formikForm.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
      formikForm.setFieldValue(`[${inputIndex}].VehicleBody_TXT`, '')
    }
  }

  const getAllModels = async (
    make: string,
    year?: string,
    selected = false
  ): Promise<void> => {
    const result = await GetModelByYearAndMake(year || selectedYear, make)
    setModelList(result.data.result)
    setSelectedMake(make)
    if (selected) {
      formikForm.setFieldValue(`[${inputIndex}].VehicleMake_TXT`, make)
      formikForm.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
      formikForm.setFieldValue(`[${inputIndex}].VehicleBody_TXT`, '')
    }
  }

  async function getAllYears(): Promise<any> {
    const result = await GetAllYears()
    setYearList(result.data.result.reverse())
    if (formikForm.values[inputIndex].VehicleYear_NUM) {
      setSelectedYear(formikForm.values[inputIndex].VehicleYear_NUM)
      await getAllMakes(formikForm.values[inputIndex].VehicleYear_NUM)
    }
    if (formikForm.values[inputIndex].VehicleMake_TXT) {
      setSelectedMake(formikForm.values[inputIndex].VehicleMake_TXT)
      await getAllModels(
        formikForm.values[inputIndex].VehicleMake_TXT,
        formikForm.values[inputIndex].VehicleYear_NUM
      )
    }

    if (formikForm.values[inputIndex].VehicleModel_TXT) {
      await getTrimValueStart(
        formikForm.values[inputIndex].VehicleYear_NUM,
        formikForm.values[inputIndex].VehicleMake_TXT,
        formikForm.values[inputIndex].VehicleModel_TXT
      )
    }

    setTimeout(() => {
      // setIsLoading(false)
    }, 2000)
  }

  const getTrimValue = async (model: string): Promise<void> => {
    formikForm.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, model)
    const result = await GetTrim(selectedYear, selectedMake, model)
    const trimsList = result.data.result?.map((vehicle: any) => vehicle.trim)
    setBodyList(trimsList)
    formikForm.setFieldValue(
      `[${inputIndex}].VehicleBody_TXT`,
      trimsList[0] || ''
    )
  }

  useEffect(() => {
    // formContext.setLoading(true)
    getAllYears()
  }, [
    formikForm.values[inputIndex].VehicleYear_NUM,
    formikForm.values[inputIndex].VehicleMake_TXT,
    formikForm.values[inputIndex].VehicleModel_TXT
  ])
  const toggleActiveNoBtn = async (active: boolean) => {
    await formikForm?.setFieldValue(`[${inputIndex}].Lienholder_YNP`, 'No');
    setIsActiveNoBtn(active);
    setIsActiveYesBtn(!active);
    cleanLienHolderValues();
  }

  const toggleActiveYesBtn = (active: boolean) => {
    formikForm?.setFieldValue(`[${inputIndex}].Lienholder_YNP`, 'Yes');
    setIsActiveYesBtn(active);
    setIsActiveNoBtn(!active);
  }
  const toggleActiveTelematicsNoBtn = (active: boolean) => {
    setIsActiveTelematicsNoBtn(active);
    setIsActiveTelematicsYesBtn(!active);
    setTelematics('No')
    formikForm?.setFieldValue(`[${0}].VehiclePolicyParticipationTelematics_YN`, 'No');
  }

  const toggleActiveTelematicsYesBtn = (active: boolean) => {
    setIsActiveTelematicsYesBtn(active);
    setIsActiveTelematicsNoBtn(!active);
    setTelematics('Yes')
    formikForm?.setFieldValue(`[${0}].VehiclePolicyParticipationTelematics_YN`, 'Yes');
  }
  const cleanLienHolderValues = () => {
    formikForm.setFieldValue(`[${inputIndex}].LienholderName_TXT`, '')
    formikForm.setFieldValue(`[${inputIndex}].LienholderAddressLine1_TXT`, '')
    formikForm.setFieldValue(`[${inputIndex}].LienholderCity_TXT`, '')
    formikForm.setFieldValue(`[${inputIndex}].LienholderState_TXT`, '')
    formikForm.setFieldValue(`[${inputIndex}].LienholderZipCode_TXT`, '')
    formikForm.setFieldValue(`[${inputIndex}].LienholderLoanNumber_TXT`, '')
    formikForm.setFieldValue(`[${inputIndex}].VehicleAdditionalInsured_YN`, 'No')
  }

  const changeVehicleAdditionalInsured_YN = (change: string): void => {
    formikForm.setFieldValue(
      `[${inputIndex}].VehicleAdditionalInsured_YN`,
      change
    )
  }

  useEffect(() => {
    quote?.VehiclePolicyParticipationTelematics_YN === 'Yes' ? setTelematics('Yes') : setTelematics('No');
    formikForm.values[inputIndex].VehiclePolicyParticipationTelematics_YN === 'Yes' ? setIsActiveTelematicsYesBtn(true) : setIsActiveTelematicsYesBtn(false);
    formikForm.values[inputIndex].VehiclePolicyParticipationTelematics_YN === 'No' ? setIsActiveTelematicsNoBtn(true) : setIsActiveTelematicsNoBtn(false);
    formikForm.values[inputIndex].Lienholder_YNP === 'Yes' ? setIsActiveYesBtn(true) : setIsActiveYesBtn(false);
    formikForm.values[inputIndex].Lienholder_YNP === 'No' ? setIsActiveNoBtn(true) : setIsActiveNoBtn(false);

    if (isAdjust) {
      setShowVinInput(true)
    }
  }, []);

  const showVin = () => setShowVinInput(!showVinInput);

  return (
    <>
      <div className='col-12'>
        {
          !isAdjust ?
            <div>
              <button
                type='button'
                className='search-vin-btn'
                onClick={showVin}
              >
                {!showVinInput ? 'Search by Vin' : 'Search by year, make & model'}
              </button>
            </div> : <></>
        }
      </div>
      {!showVinInput
        ? null
        :
        <div className='row'>
          <div className='col-12 vin-title'>
            <Label>VIN</Label>
          </div>
          <div className='col-12 vin-div' style={{ position: 'relative', display: 'flex' }}>
            <TypedInput
              className='search-input'
              inputName='VehicleVINOverride_TXT'
              inputPlaceholder='Vin (optional)'
              setVerifyErrors={setVerifyErrors}
              inputValue={`[${inputIndex}].VehicleVINOverride_TXT`}
              hasErrors={inputIndex && formikForm.errors[inputIndex] && formikForm.errors[inputIndex].VehicleVINOverride_TXT ? validateError(formikForm.errors[inputIndex].VehicleVINOverride_TXT) : false}
              formikForm={formikForm}
              disabled={isDisabled}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  searchVehicleByVIN(formikForm.values[inputIndex].VehicleVINOverride_TXT, inputIndex)
                }
              }}
            />
            <IconButton className='search' onClick={() => { searchVehicleByVIN(formikForm.values[inputIndex].VehicleVINOverride_TXT, inputIndex) }}>
              <span style={{fontSize: '16px'}}>Search</span>
            </IconButton>
            {formikForm.errors[inputIndex] && formikForm.errors[inputIndex].VehicleVINOverride_TXT && validateError(formikForm.errors[inputIndex].VehicleVINOverride_TXT) && (
              <ErrorMessage>{formikForm.errors[inputIndex].VehicleVINOverride_TXT}</ErrorMessage>
            )}
          </div>
        </div>
      }
      <div className='row'>
        <div className='col-4'>
          <Label>Year</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.VehicleYear_NUM ? 'border-red' : ''}
          >
            <SelectImput
              disabled={isDisabled}
              onChange={async (evt) => {
                formikForm.setFieldValue(`[${inputIndex}].VehicleMake_TXT`, '')
                formikForm.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
                await getAllMakes(evt.target.value, true)
              }}
              value={formikForm.values[inputIndex].VehicleYear_NUM}
            >
              <option value=''>Select an option...</option>
              {yearList.map((val: number) => ( // Temporary Dummy Data
                <option key={val} value={val}>
                  {val}
                </option>
              ))}

            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-4'>
          <Label>Make</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.VehicleMake_TXT ? 'border-red' : ''}
          >
            <SelectImput
              disabled={isDisabled}
              onChange={async (evt) => {
                formikForm.setFieldValue(`[${inputIndex}].VehicleModel_TXT`, '')
                await getAllModels(
                  evt.target.value,
                  formikForm.values[inputIndex].VehicleYear_NUM,
                  true
                )
              }}
              value={formikForm.values[inputIndex].VehicleMake_TXT}
            >
              <option value=''>Select an option...</option>
              {makeList.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-4'>
          <Label>Model</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.VehicleModel_TXT ? 'border-red' : ''}
          >
            <SelectImput
              disabled={isDisabled}
              onChange={async (evt) => {
                await getTrimValue(evt.target.value)
              }}
              value={formikForm.values[inputIndex].VehicleModel_TXT}
            >
              <option value=''>Select an option...</option>
              {modelList.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-4'>
          <Label>Trim</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.VehicleBody_TXT ? 'border-red' : ''}
          >
            <SelectImput
              disabled={isDisabled}
              onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].VehicleBody_TXT`, evt.target.value) }}
              value={formikForm.values[inputIndex].VehicleBody_TXT}
            >
              <option value=''>Select an option...</option>
              {bodyList.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        <div className='col-4 mt-2 mb-3 align-items-center'>
          <Label>
            {!isAdjust ? 'Do you have a Lienholder?' : 'Have a car loan or leased?'}&nbsp; &nbsp;
          </Label>
          <ToggleContainer>
            <Button
              classes={{ root: 'yes-btn' }}
              disabled={isDisabled}
              onClick={() => toggleActiveYesBtn(true)}
              className={formikForm.values[inputIndex].Lienholder_YNP === 'Yes' ? 'active' : 'not-active'}
            >
              Yes
            </Button>
            <Button
              classes={{ root: 'no-btn' }}
              disabled={isDisabled}
              onClick={() => toggleActiveNoBtn(true)}
              className={formikForm.values[inputIndex].Lienholder_YNP === 'No' ? 'active' : 'not-active'}
            >
              No
            </Button>
          </ToggleContainer>
        </div>
        {
          inputIndex === 0 &&
          <div className='col-4 mt-2 mb-3 align-items-center telematics'>
            <Label>Telematics &nbsp;</Label>
            <ToggleContainer>
              <Button
                classes={{ root: 'yes-telematics-btn' }}
                disabled={isDisabled}
                onClick={() => toggleActiveTelematicsYesBtn(true)}
                //   onMouseEnter={e => formContext.setSideText('When the business vehicles are insured with Pouch and sign up for Force by Mojio you will receive a 15% discount on the policy!')}
                //   onMouseLeave={e => formContext.setSideText('')}
                className={telematics === 'Yes' ? 'active' : 'not-active'}
              >
                Yes all
              </Button>
              <Button
                classes={{ root: 'no-telematics-btn' }}
                disabled={isDisabled}
                onClick={() => toggleActiveTelematicsNoBtn(true)}
                className={telematics === 'No' ? 'active' : 'not-active'}
              >
                No all
              </Button>
            </ToggleContainer>
          </div>
        }
        {
          (formikForm.values[inputIndex].Lienholder_YNP === 'Yes') ?
            (
              <>
                <div className='col-6'>
                  <TypedInput
                    disabled={isDisabled}
                    inputName='LienholderName_TXT'
                    formikForm={formikForm}
                    // onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].LienholderName_TXT`, evt.target.value) }}
                    hasErrors={validateError(formikForm.errors[inputIndex]?.LienholderName_TXT)}
                    //   inputIndex={inputIndex}
                    inputTitle='Full name of Lienholder/Loss Payee'
                    inputPlaceholder='Name'
                    //   form={form}
                    inputValue={`[${inputIndex}].LienholderName_TXT`}
                  />
                </div>
                <div className='col-6'>
                  <TypedInput
                    disabled={isDisabled}
                    inputName='LienholderAddressLine1_TXT'
                    formikForm={formikForm}
                    onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].LienholderAddressLine1_TXT`, evt.target.value) }}
                    hasErrors={validateError(formikForm.errors[inputIndex]?.LienholderAddressLine1_TXT)}
                    //   inputIndex={inputIndex}
                    inputTitle='Lienholder/Loss Payee Address'
                    inputPlaceholder='Address'
                    //   form={form}
                    inputValue={`[${inputIndex}].LienholderAddressLine1_TXT`}
                    // placePicker
                    isLienholder
                  />
                </div>
                <div className='col-6'>
                  <TypedInput
                    disabled={isDisabled}
                    inputName='LienholderCity_TXT'
                    formikForm={formikForm}
                    // onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].LienholderCity_TXT`, evt.target.value) }}
                    hasErrors={validateError(formikForm.errors[inputIndex]?.LienholderCity_TXT)}
                    //   inputIndex={inputIndex}
                    inputTitle='Lienholder/Loss Payee City'
                    inputPlaceholder='City'
                    //   form={form}
                    inputValue={`[${inputIndex}].LienholderCity_TXT`}
                  />
                </div>
                <div className='col-6'>
                  <TypedInput
                    disabled={isDisabled}
                    inputName='LienholderState_TXT'
                    formikForm={formikForm}
                    // onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].LienholderState_TXT`, evt.target.value) }}
                    hasErrors={validateError(formikForm.errors[inputIndex]?.LienholderState_TXT)}
                    //   inputIndex={inputIndex}
                    inputTitle='Lienholder/Loss Payee State'
                    inputPlaceholder='State'
                    //   form={form}
                    inputValue={`[${inputIndex}].LienholderState_TXT`}
                  />
                </div>
                <div className='col-6'>
                  <TypedInput
                    disabled={isDisabled}
                    inputName='LienholderZipCode_TXT'
                    formikForm={formikForm}
                    // onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].LienholderZipCode_TXT`, evt.target.value) }}
                    hasErrors={validateError(formikForm.errors[inputIndex]?.LienholderZipCode_TXT)}
                    //   inputIndex={inputIndex}
                    inputTitle='Lienholder/Loss Payee ZIP'
                    inputPlaceholder='ZIP Code'
                    //   form={form}
                    inputValue={`[${inputIndex}].LienholderZipCode_TXT`}
                  />
                </div>
                <div className='col-6'>
                  <TypedInput
                    disabled={isDisabled}
                    inputName='LienholderLoanNumber_TXT'
                    formikForm={formikForm}
                    // onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].LienholderLoanNumber_TXT`, evt.target.value) }}
                    hasErrors={validateError(formikForm.errors[inputIndex]?.LienholderLoanNumber_TXT)}
                    //   inputIndex={inputIndex}
                    //   inputTitle='Loan'
                    inputTitle='Lienholder/Loss Payee Loan Number'
                    inputPlaceholder='Loan (optional)'
                    //   form={form}
                    inputValue={`[${inputIndex}].LienholderLoanNumber_TXT`}
                  />
                </div>
                <div className='col-6'>
                  <Label>
                    Need Additional Insured (Leased Vehicle) ? &nbsp;
                  </Label>
                  <Button
                    classes={{ root: 'yes-btn' }}
                    onClick={() => { changeVehicleAdditionalInsured_YN('Yes') }}
                    className={formikForm.values[inputIndex].VehicleAdditionalInsured_YN === 'Yes' ? 'active' : 'not-active'}
                  >
                    Yes
                  </Button>
                  <Button
                    classes={{ root: 'no-btn' }}
                    onClick={() => { changeVehicleAdditionalInsured_YN('No') }}
                    className={formikForm.values[inputIndex].VehicleAdditionalInsured_YN === 'No' ? 'active' : 'not-active'}
                  >
                    No
                  </Button>
                </div>
                <div className='col-12'>
                  <hr />
                </div>
              </>
            ) : null
        }
        <div className={quote?.BusinessState_TXT === 'IL' ? 'col-4' : 'col-6'}>
          <Label>Collision Deductible</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.VehicleOverrideDeductCOLL_CHOICE ? 'border-red' : ''}
          >
            <SelectImput
              disabled={isDisabled}
              onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].VehicleOverrideDeductCOLL_CHOICE`, evt.target.value) }}
              value={formikForm.values[inputIndex].VehicleOverrideDeductCOLL_CHOICE}
            >
              <option value=''>Select an option...</option>
              {DeductCOLL.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}

            </SelectImput>
          </InputContainer>
        </div>
        <div className={quote?.BusinessState_TXT === 'IL' ? 'col-4' : 'col-6'}>
          <Label>Comprehensive Deductible</Label>
          <InputContainer
            className={formikForm.errors[inputIndex]?.VehicleOverrideDeductCOMP_CHOICE ? 'border-red' : ''}
          >
            <SelectImput
              disabled={isDisabled}
              onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].VehicleOverrideDeductCOMP_CHOICE`, evt.target.value) }}
              value={formikForm.values[inputIndex].VehicleOverrideDeductCOMP_CHOICE}
            >
              <option value=''>Select an option...</option>
              {DeductCOMP.map((val: string) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </SelectImput>
          </InputContainer>
        </div>
        {
          quote?.BusinessState_TXT === 'IL' && (
            <div className='col-4'>
              <Label>Uninsured Motoris (UMPD)</Label>
              <InputContainer
                className={formikForm.errors[inputIndex]?.VehicleOverrideLimitUMPD_IL_CHOICE ? 'border-red' : ''}
              >
                <SelectImput
                  disabled={isDisabled}
                  onChange={(evt) => { formikForm.setFieldValue(`[${inputIndex}].VehicleOverrideLimitUMPD_IL_CHOICE`, evt.target.value) }}
                  value={formikForm.values[inputIndex].VehicleOverrideLimitUMPD_IL_CHOICE}
                >
                  <option value=''>Select an option...</option>
                  {UMPDIL.map((val: string) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </SelectImput>
              </InputContainer>
            </div>
          )
        }
      </div>
    </>
  )
}

export default Select