import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import { Person as UserIcon } from '@material-ui/icons'
import Title from 'components/Title'
import AddButton from 'views/Join/components/AddButton'
import { AddlInsuredSchema, errOB, validateError } from 'utils/validations'
import { IAddlInsured } from 'interfaces/pouch-redux'
import * as Yup from 'yup'
import { generateId } from 'utils/functions'
import errorHandler from 'utils/errorHandler'
import { useSnackbar } from 'notistack'
import { useAppSelector } from 'hooks/useAppSelector'
import { updateQuotePouch } from 'redux/slices/quote';
import { dispatchApp } from 'redux/store'
import TypedInput from '../components/TypedInput/TypedInput'
import './AdditionalInsuredInfo.scss'
import Collapse from '../components/Collapse/Collapse'

const addInsuredGenerator = (addOffset: number): any => {
  return {
    PolAddlInsuredName_TXT: '',
    PolAddlInsuredAddressLine1_TXT: '',
    PolAddlInsuredAddressLine2_TXT: '',
    PolAddlInsuredCity_TXT: '',
    PolAddlInsuredState_TXT: '',
    PolAddlInsuredZipCode_TXT: '',
    PolAdditionalInsuredCountry_TXT: ''
  }
}

const pageValues: IAddlInsured = {
  PolAddlInsuredName_TXT: '',
  PolAddlInsuredAddressLine1_TXT: '',
  PolAddlInsuredAddressLine2_TXT: '',
  PolAddlInsuredCity_TXT: '',
  PolAddlInsuredState_TXT: '',
  PolAddlInsuredZipCode_TXT: '',
  PolAdditionalInsuredCountry_TXT: ''
}


const AdditionalInsuredInfo = ({ isAdjust, verifyErrors, setVerifyErrors, isSending }: any) => {

  const [id, setId] = useState('');
  const { loading, QuoteRefURL, quote, previousQuote, isViewMode } = useAppSelector(state => state.pouch);
  const { enqueueSnackbar } = useSnackbar();

  const validateField = Yup.object().shape({
    Dirver: Yup.array().of(
      Yup.object().shape({
        DriverFirstName_TXT: Yup.string()
          .required(errOB.required)
      })
    )
  })

  const formikFormAdditionalInsured = useFormik({
    // validateOnChange: true,
    // enableReinitialize: true,
    initialValues: [pageValues],
    validationSchema: AddlInsuredSchema,
    onSubmit: () => {
      //complete();
    }
  })

  const setValues = async () => {
    const additionalInsuredValues = { AdditionalInsured_MI: formikFormAdditionalInsured.values }
    dispatchApp(updateQuotePouch({
      ...quote,
      AdditionalInsured_MI: additionalInsuredValues.AdditionalInsured_MI
    }))
  }

  const addButtonDriver = async (): Promise<void> => {
    await formikFormAdditionalInsured.setValues([
      ...formikFormAdditionalInsured.values,
      addInsuredGenerator(formikFormAdditionalInsured.values.length)
    ])
  }

  const delButtonAddInsured = async (index: number): Promise<void> => {
    const modArray = [...formikFormAdditionalInsured.values]
    modArray.splice(index, 1)
    await formikFormAdditionalInsured.setValues(modArray)
  }

  useEffect(() => {
    setId(generateId() + 'AdditionalInsuredInfo');

    try {
      setTimeout(() => {
        if (quote?.AdditionalInsured_MI) {
          const newDrivers = quote?.AdditionalInsured_MI?.map((driver: any, index: number) => {
            const originalValues = addInsuredGenerator(index)
            const driverValues = {
              ...driver,
              PolAddlInsuredName_TXT: driver.PolAddlInsuredName_TXT || '',
              PolAddlInsuredAddressLine1_TXT: driver.PolAddlInsuredAddressLine1_TXT || '',
              PolAddlInsuredAddressLine2_TXT: driver?.PolAddlInsuredAddressLine2_TXT || '',
              PolAddlInsuredCity_TXT: driver?.PolAddlInsuredCity_TXT || '',
              PolAddlInsuredState_TXT: driver?.PolAddlInsuredState_TXT || '',
              PolAddlInsuredZipCode_TXT: driver?.PolAddlInsuredZipCode_TXT || '',
              PolAdditionalInsuredCountry_TXT: driver?.PolAdditionalInsuredCountry_TXT || '',
            }
            return { ...originalValues, ...driverValues }
          })
          formikFormAdditionalInsured
            .setValues(newDrivers)
            .catch((formError: any) => console.log({ formError }))
        } else if (previousQuote?.AdditionalInsured_MI) {
          const newInsured = previousQuote?.AdditionalInsured_MI?.map((insured: any, index: number) => {
            const originalValues = addInsuredGenerator(index)
            const insuredValues = {
              ...insured,
              PolAddlInsuredName_TXT: insured.PolAddlInsuredName_TXT || '',
              PolAddlInsuredAddressLine1_TXT: insured.PolAddlInsuredAddressLine1_TXT || '',
              PolAddlInsuredAddressLine2_TXT: insured?.PolAddlInsuredAddressLine2_TXT || '',
              PolAddlInsuredCity_TXT: insured?.PolAddlInsuredCity_TXT || '',
              PolAddlInsuredState_TXT: insured?.PolAddlInsuredState_TXT || '',
              PolAddlInsuredZipCode_TXT: insured?.PolAddlInsuredZipCode_TXT || '',
              PolAdditionalInsuredCountry_TXT: insured?.PolAdditionalInsuredCountry_TXT || '',
            }
            return { ...originalValues, ...insuredValues }
          })
          formikFormAdditionalInsured
            .setValues(newInsured)
            .catch((formError: any) => console.log({ formError }))
        }
      }, 100);
    } catch (error) {
      errorHandler(error, enqueueSnackbar)
    }

  }, []);

  useEffect(() => {
    if (id !== '') {
      verifyErrors[id] = Object.keys(formikFormAdditionalInsured.errors).length > 0 ? true : false;
      console.log('AdditionalInsuredInfo', id, verifyErrors[id])
      setVerifyErrors(verifyErrors);
    }
  }, [formikFormAdditionalInsured.errors]);

  useEffect(() => {
    setValues();
  }, [formikFormAdditionalInsured.values])

  return (
    <>
      <form
        onSubmit={formikFormAdditionalInsured.handleSubmit}
      >
        <Box maxWidth={860} margin='0 auto' padding={5}>
          {!isAdjust && <Title>Additional Insured Information</Title>}
          {
            formikFormAdditionalInsured.values?.map((_: any, index: number) => {
              const key = String(formikFormAdditionalInsured?.values[index]?.PolAddlInsuredZipCode_TXT)
              const subtitle = formikFormAdditionalInsured?.values[index]?.PolAddlInsuredName_TXT
              const hasError =
                formikFormAdditionalInsured?.submitCount !== 0 &&
                formikFormAdditionalInsured?.errors[index] != null

              return (
                <Collapse
                  key={index}
                  index={index}
                  title='Additional Insured'
                  image={<UserIcon />}
                  subtitle={subtitle}
                  hasError={hasError}
                  delButton={delButtonAddInsured}
                  className='multi-item-card'
                  ignoreIndex
                >
                  <div className="row">
                    <div className='col-12' style={{ position: 'relative' }}>
                      <TypedInput
                        className='search-input'
                        inputName='PolAddlInsuredName_TXT'
                        inputTitle='Full Name'
                        inputPlaceholder='Full Name'
                        formikForm={formikFormAdditionalInsured}
                        hasErrors={validateError(formikFormAdditionalInsured.errors[index]?.PolAddlInsuredName_TXT)}
                        inputValue={`[${index}].PolAddlInsuredName_TXT`}
                        disabled={isViewMode}
                      />
                    </div>
                    <div className='col-12' style={{ position: 'relative' }}>
                      <TypedInput
                        index={index}
                        // inputTitle='Address'
                        inputName='PolAddlInsuredAddressLine1_TXT'
                        inputTitle='Address'
                        inputPlaceholder='Address'
                        placePicker
                        formikForm={formikFormAdditionalInsured}
                        inputValue={`[${index}].PolAddlInsuredAddressLine1_TXT`}
                        isAddInsured
                        disabled={isViewMode}
                      />
                    </div>
                  </div>
                </Collapse>
              )
            })}
          <AddButton
            text='Additional Insured'
            action={addButtonDriver}
            disable={!(formikFormAdditionalInsured.values.length < 10) || isViewMode}
          />
        </Box>
      </form>
    </>
  )
}

export default AdditionalInsuredInfo