import { get, set } from 'lodash'
import { useState, ReactElement } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
// import { Help as HelpIcon } from '@material-ui/icons'
import { FormControl, MenuItem, Select, FormHelperText } from '@material-ui/core'
// Context
import { useQuoteContext } from 'context/QuoteContext'

const sharedSize = {
  height: '32px',
  verticalAlign: 'top',
  display: 'inline-block'
}

interface ComponentProps {
  image: string
  isAdjust: boolean | undefined
}

const useStyles = makeStyles<Theme, ComponentProps>((theme) => ({
  main: {
    display: 'flex',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  flex1: {
    flex: 1.5,
    position: 'relative',
    '@media (max-width:760px)': {
      minWidth: '58%',
      marginBottom: 15
    }
  },
  image: (props: ComponentProps) => ({
    ...sharedSize,
    width: '45px',
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${props.image})`
  }),
  title: (props: ComponentProps) => ({
    ...sharedSize,
    lineHeight: 1.3,
    color: 'black',
    fontSize: '12px',
    fontWeight: 'bold',
    width: 'calc(100% - 80px)',
    '& *': {
      height: '100%',
      display: 'flex',
      alignContent: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px'
    }
  }),
  flex2: {
    flex: 1,
    maxWidth: '43%',
    '@media (max-width:760px)': {
      maxWidth: '42%'
    }
  },
  question: {
    top: '3px',
    right: '7px',
    color: '#e2e2e2',
    position: 'absolute',
    display: 'inline-block'
  },
  select: {
    width: '100%',
    textAlign: 'center',
    '& .MuiSelect-select': {
      padding: '5px',
      backgroundColor: 'initial'
    },
    '& fieldset': {
      top: '0px',
      bottom: '-2px'
    },
    '& .MuiSelect-icon': {
      position: 'relative',
      right: 0,
      marginLeft: '-24px',
      '@media (max-width: 780px)': {
        marginLeft: '-12px'
      },
      '@media (max-width: 420px)': {
        marginLeft: '-4px'
      }
    }
  },
  errorMessage: {
    fontSize: '0.57rem',
    color: 'tomato'
  }
}))

interface IQuote {
  property: any
  options: string[]
  image: string
  title: string
  disabled?: boolean
  showError?: boolean
  className?: string
  errorMesage?: string
  validateField?: () => void
  isAdjust?: boolean
  setIsHired?: (value: boolean) => void
}

export default function QuoteSelect ({ property, options, image, title, disabled = false, showError = false, errorMesage, className, validateField, isAdjust, setIsHired }: IQuote): ReactElement {
  const classes = useStyles({ image, isAdjust })
  const quoteContext = useQuoteContext()
  const [selectValue, setSelectValue] = useState('')

  const handleChange = (event: any): void => {
    // Change policy with new value
    if (property === 'HiredAuto_YNP') {
      event.target.value === 'Yes' ? setIsHired?.(true) : setIsHired?.(false)
    }
    quoteContext.setPolicyValues((currentPolicy: any) => {
      // Change label to fix not refresing select value
      setSelectValue(get(quoteContext.policyValues, property))
      // console.log(property, event.target.value)
      return set(currentPolicy, property, event.target.value)
    })
    // Show update button instead of buy now button
    quoteContext.setNeedsUpdate(true)
  }

  const usableProp = get(quoteContext.policyValues, property)

  return (
    <div className={classes.main}>
      <div className={classes.flex1}>
        <div className={classes.image} />
        <div className={classes.title}>
          <div>{title}</div>
        </div>
        {/* <Tooltip title='Lorem ipsum dolor' className={classes.question}>
          <HelpIcon />
        </Tooltip> */}
      </div>
      <div className={classes.flex2}>
        {/* <p>{selectValue} - {usableProp}</p> */}
        <FormControl variant='outlined' size='small' className={classes.select} disabled={disabled} error={showError}>
          <Select
            className={className}
            value={usableProp ?? selectValue} onChange={(event: any) => {
              handleChange(event)
              if (validateField != null) validateField()
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              getContentAnchorEl: null
            }}
          >
            {
              options?.map((value, index) => (
                <MenuItem key={index} value={value}>{value}</MenuItem>
              ))
            }
          </Select>
          <FormHelperText className={classes.errorMessage}>
            {
              errorMesage && errorMesage
            }
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}
