import '../styles/Loader.css'
const Loader: React.FC = () => {
  return (
    <div className='lds-roller'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default Loader
