import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSnackbar } from 'notistack'
// import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import TextField from 'components/TextField'
// Context
import { useFormContext } from 'context/FormContext'
import { getCoterieQuestions, createCoterieQuote } from '../../services/coterie'
// Utils
import { getCoteriePayload, coterieInitialState, questionsHidden, setCoterieState } from '../../utils/coterieUtils'
import { glSchema, bopSchema } from 'utils/validations'
import { Button, InputLabel } from '@material-ui/core'
import { Join } from 'components/StepperContainer'
import styled from 'styled-components'
// import UnsupportedClassModal from './components/UnsupportedClassModal'
// import { ReactComponent as GOODIE_P1 } from './assets/Goodie_p1_icon.svg'

const QuestionContainer = styled.div<{ labelHeight: number, slider: boolean }>`
padding-top: ${(props) => props.slider ? '15' : props.labelHeight }px;
input {
    width: '100%';
  }
  div {
    div {
      label {
        font-family: 'Libre Franklin';
        font-weight: 501 !important;
        font-size: 1rem;
        color: #000;
        font-style:  normal;
        letter-spacing:  normal;
        line-height:  normal;
        top: ${ (props) => props.labelHeight < 21 ? '-13px' : props.labelHeight < 40 ?`-${props.labelHeight/2+9}px`:`-${props.labelHeight/2+20}px`};
        white-space: normal;
        max-width: 90%;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    padding: '1.8em',
    textAlign: 'center'
  },
  radioWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  radioGroup: {
    width: '40%',
    margin: '2.5em 0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  radioElement: {
    fontWeight: 600,
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center'
  },
  continueBtn: {
    padding: '10px 30px',
    border: 'none',
    marginTop: '25px',
    borderRadius: '12px',
    fontWeight: 500,
    color: '#ffffff',
    backgroundColor: '#ff6c1a'
  },
  radioItem: {
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    '&:checked': {
      color: 'red'
    }
  },
  goodieModalIcon: {
    textAlign: 'center',
    '& svg': {
      width: 130,
      height: 130
    },
    '& .st0': {
      fill: '#4FBCBC'
    },
    '& .st1': {
      fill: '#ED6F29'
    }
  },
  Subtitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    marginRight: 20
    // width: 200
  },
  active: {
    color: '#000',
    '& svg': {
      '& .st0': {
        fill: '#000'
      }
    }
  },
  inactive: {
    opacity: 0.44,
    '& svg': {
      '& .st0': {
        opacity: 0.44
      }
    }
  },
  yesBtn: {
    border: '1px solid #E5E5E5',
    padding: '5px 30px',
    textTransform: 'capitalize',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  noBtn: {
    border: '1px solid #E5E5E5',
    padding: '5px 30px',
    textTransform: 'capitalize',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  btnActive: {
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold'
  },
  btnNotActive: {
    backgroundColor: '#E5E5E5',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#EEEEEE',
      fontWeight: 'bold'
    }
  },
  labelToggle: {
    fontSize: '1rem',
    color: '#000',
    fontWeight: 501,
    fontFamily: 'Libre Franklin'
  }
})

interface Props {
  handleNext?: () => void
}

const CoterieIntegration = ({ handleNext = () => {} }: Props): ReactElement => {
  const [data, setData] = useState(null) as any
  const [selectedOption, setSelectedOption] = useState('GL')
  const { enqueueSnackbar } = useSnackbar()
  const [questionsToHide, setQuestionsToHide] = useState(questionsHidden)
  const classes = useStyles()
  const formContext = useFormContext()
  // const [openDialog, setOpenDialog] = useState(true)
  const QuestionsRef = useRef<any>([])
  const [labelsHeight, setLabelsHeight] = useState<number[]>([])

  const businessClassType = formContext.quote.BusinessSubClass_CHOICE ?? null
  const industryId = data?.BUSINESSCLASSES[businessClassType]?.industryId ?? null
  const akHash = data?.BUSINESSCLASSES[businessClassType]?.akHash ?? null
  const selectedQuestions = `${selectedOption.toLowerCase()}Questions`

  const policyStartDate = formContext.quote?.PolicyEffective_DATE

  let refIndex = 0

  const formikFormAnswers = useFormik({
    initialValues: {
      ...coterieInitialState,
      POLICY_START: policyStartDate,
      INSADDR: formContext.quote.BusinessAddressLine1_TXT,
      INSCITY: formContext.quote.BusinessCity_TXT,
      INSSTATE: formContext.quote.BusinessState_TXT,
      INSZIP: formContext.quote.BusinessZip_TXT,
      EMPS: formContext.quote.numEmployees
    } as any,
    enableReinitialize: true,
    validationSchema: selectedOption === 'GL' ? glSchema : bopSchema,
    onSubmit: async (values) => {
      formContext.setLoading(true)

      const payloadProps = getCoteriePayload(selectedOption, {
        ...values,
        INSADDR: formContext.quote.BusinessAddressLine1_TXT,
        INSCITY: formContext.quote.BusinessCity_TXT,
        INSSTATE: formContext.quote.BusinessState_TXT,
        INSZIP: formContext.quote.BusinessZip_TXT,
        EMPS: formContext.quote.numEmployees
      })

      const coteriePayload = {
        ...payloadProps,
        applicationTypes: [selectedOption],
        AKHash: akHash,
        industryId,
        businessName: formContext.quote.BusinessNameDBA_TXT,
        contactFirstName: formContext.quote.ContactFirstName_TXT,
        contactLastName: formContext.quote.ContactLastName_TXT,
        contactEmail: formContext.quote.ContactEmail_TXT,
        contactPhone: formContext.quote.ContactPhone_TXT,
        mailingAddressStreet: formContext.quote.BusinessAddressLine1_TXT,
        mailingAddressCity: formContext.quote.BusinessCity_TXT,
        mailingAddressState: formContext.quote.BusinessState_TXT,
        mailingAddressZip: formContext.quote.BusinessZip_TXT
      }

      formContext.setCoterieValues(coteriePayload)

      try {
        const { data } = await createCoterieQuote(coteriePayload)
        if (!data.isSuccess) {
          // TODO: hide loading show error message
          formContext.setLoading(false)
          const error = data.errors[1] ?? data.errors[0]
          enqueueSnackbar(error, { variant: 'error' })
        } else {
          localStorage.setItem('coterieUrl', data.quote.applicationUrl)
          localStorage.setItem('coterieInfo', JSON.stringify({ monthly: data.quote.monthlyPremium, premium: data.quote.premium }))
          nextPage()
        }
      } catch (resError: any) {
        formContext.setLoading(false)
        enqueueSnackbar(resError.message, { variant: 'error' })
      }
    }
  })

  const getQuestions = async (): Promise<void> => {
    try {
      const questions = await getCoterieQuestions()
      setData(questions)
    } catch (error) {
      enqueueSnackbar('There was an error retrieving coterie question ', { variant: 'error' })
    }
  }

  useEffect(() => {
    getQuestions()
  }, [])

  useEffect(() => {
    formContext.setForm(formikFormAnswers)
  }, [selectedOption])

  useEffect(() => {
    if (formContext.coterieValues !== null && Object.keys(formContext.coterieValues).length !== 0) {
      setSelectedOption(formContext.coterieValues.applicationTypes[0] ?? 'GL')
      const values = { ...coterieInitialState, ...setCoterieState(formContext.coterieValues) }
      formikFormAnswers.setValues(values).catch((formError) => console.log(formError))
      if (formContext.coterieValues.previousLosses.length > 0) {
        setQuestionsToHide(qth => {
          return qth.filter(q => q !== 'CLAIMAMT' && q !== 'CLAIMDESC')
        })
      } else {
        setQuestionsToHide(qth => {
          return [...qth, 'CLAIMAMT', 'CLAIMDESC']
        })
      }
    }
  }, [formContext.coterieValues])

  useEffect(() => {
    const heights = QuestionsRef.current?.map( (question: any) => {
      return question?.children?.[0]?.children?.[0]?.children?.[0]?.getBoundingClientRect()?.height ?? 20
    })
    setLabelsHeight(heights)
  }, [selectedOption, data])

  // const handleCloseDialog = () => {
  //   setOpenDialog( ( isOpen ) => !isOpen )
  //   localStorage.setItem('policyType', 'commercial')
  //   handleNext()
  // }

  // if( data?.BUSINESSCLASSES[businessClassType] === undefined ) {
  //   return <UnsupportedClassModal open={ openDialog } onClose={ handleCloseDialog } />
  // }

  const nextPage = (): void => {
    formContext.setLoading(false)
    handleNext()
  }

  const changeClaims = (value: string) => {
    formikFormAnswers.setFieldValue('CLAIMSINTHEPASTYEARS', value)
    if (value === 'Yes') {
      setQuestionsToHide(qth => {
        return qth.filter(q => q !== 'CLAIMAMT' && q !== 'CLAIMDESC')
      })
    } else {
      setQuestionsToHide(qth => {
        return [...qth, 'CLAIMAMT', 'CLAIMDESC']
      })
    }
  }

  const ToggleClaims = ({ claimsinThePastYears }: {claimsinThePastYears: string}) => {
    return (
      <>
        <div className='row mb-3'>
          <div className='col-12 d-flex align-items-center'>
            <InputLabel className={classes.labelToggle}>
              Have you had any insurance claims in the past 5 years? &nbsp;
            </InputLabel>
            <Button
              classes={{ root: classes.yesBtn }}
              onClick={() => { changeClaims('Yes') }}
              className={claimsinThePastYears === 'Yes' ? classes.btnActive : classes.btnNotActive}
            >
              Yes
            </Button>
            <Button
              classes={{ root: classes.noBtn }}
              onClick={() => { changeClaims('No') }}
              className={claimsinThePastYears === 'Yes' ? classes.btnNotActive : classes.btnActive}
            >
              No
            </Button>
          </div>
        </div>
      </>
    )
  }


  const renderQuestions = data?.BUSINESSCLASSES[businessClassType][selectedQuestions].map((question: string, i: number) => {
    const questionType = data.QUESTIONS[question].questionType.toLowerCase()
    const placeholder = questionType === 'positivedecimal' ? '$ 00,00' : ''
    const typeSelect = {
      select: questionType === 'multiplechoice' && data.QUESTIONS[question].options.length <= 2,
      toggleSelect: questionType === 'multiplechoice' && data.QUESTIONS[question].options.length > 2 && data.QUESTIONS[question].options.length <= 4,
      sliderSelect: questionType === 'multiplechoice' && data.QUESTIONS[question].options.length > 4
    }
    const type = questionType !== 'date' ? 'text' : 'date'
    const hide = questionsToHide.includes(question)
    const ri = refIndex
    refIndex = hide ? refIndex : refIndex + 1
    return (
      <React.Fragment key={`claim-${i}`}>
        {
          question === 'CLAIMAMT' && <ToggleClaims claimsinThePastYears={formikFormAnswers.values.CLAIMSINTHEPASTYEARS} />
        }
        {
          !hide &&
            <QuestionContainer
              key={i}
              ref={ (el: any) => {
                 if (!QuestionsRef.current.includes(el)) {
                  if (el !== null) {
                    QuestionsRef.current = [...QuestionsRef.current, el]
                  }
                 }
                }
               }
              labelHeight={labelsHeight?.[ri] ?? 20}
              slider={typeSelect.sliderSelect}
            >
              <TextField
                name={question}
                form={formikFormAnswers}
                inputLabel={data.QUESTIONS[question].questionText}
                options={data.QUESTIONS[question].options}
                {...typeSelect}
                {...{ type, placeholder }}
              />
            </QuestionContainer>
        }
      </React.Fragment>
    )
  })

  const handleChangeApplicationType = (type: string) => {
    setSelectedOption(type)
    formContext.setSideText((type === 'BOP' ? Join[3].rightTooltip?.BOP : Join[3].rightTooltip?.GL) ?? Join[3]?.sideText)
    QuestionsRef.current = []
  }

  if (!formContext.quote || !businessClassType) {
    return <Redirect to='/join/about-you' />
  }
  return (
    <>
      <div className={classes.radioWrapper}>
        <section className={classes.radioGroup}>
          <div className={classes.radioElement}>
            <div className={`${classes.Subtitle} ${selectedOption === 'GL' ? classes.active : classes.inactive}`}>
              <label>GL</label>
            </div>
            <input
              name='insuranceType'
              type='radio'
              value='GL'
              checked={selectedOption === 'GL'}
              className={classes.radioItem}
              onChange={() => handleChangeApplicationType('GL')}
            />
          </div>
          <div className={classes.radioElement}>
            <div className={`${classes.Subtitle} ${selectedOption === 'BOP' ? classes.active : classes.inactive}`}>
              <label>BOP</label>
            </div>
            <input
              name='insuranceType'
              type='radio'
              value='BOP'
              checked={selectedOption === 'BOP'}
              className={classes.radioItem}
              onChange={() => handleChangeApplicationType('BOP')}
            />
          </div>
        </section>
      </div>
      <section>
        <form onSubmit={formikFormAnswers.handleSubmit}>
          {renderQuestions}
        </form>
      </section>
    </>
  )
}

export default CoterieIntegration
