import { ReactElement } from 'react'
import './PouchLabel.css'

export default function PouchLabel ({ labelItems }: {labelItems: {title: string, subtitle: string}}): ReactElement {
  const { title, subtitle } = labelItems
  return (
    <>
      <div className='pouch-label-container'>
        <div>
          <span className='pouch-label-title'>{title}</span>
        </div>
        <div>
          <span className='pouch-label-text'>{subtitle}</span>
        </div>
      </div>
    </>
  )
}
