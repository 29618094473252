import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { ICollapse } from 'interfaces/pouch-redux'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './MVRCollapse.scss'
import Table from '@mui/material/Table'
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { IDriver } from '../../../../interfaces/pouch-redux'
import { useFormContext } from 'context/FormContext'
import { useAppSelector } from 'hooks/useAppSelector'
import { UpdateQuoteData, getQuoteMultiItems } from 'services/instanda'
import errorHandler, { wasDeclined } from 'utils/errorHandler'
import { useSnackbar } from 'notistack'
import { isEmpty, merge } from 'lodash'
import { useFormik } from 'formik'

const MVRColapse = ({
    index = 0,
    ignoreIndex = false,
    title,
    image,
    subtitle = '',
    className,
    children,
    hasError = false,
    delButton = () => null,
    drivers = [],
    violations = [],
    setMVRError,
    driverIndex = 0,
    accidents = [],
    quoteData }: ICollapse) => {
    const [expanded, setExpanded] = useState(false)
    const [initial, setInitial] = useState(true)
    const [id, setId] = useState('')
    const [hasViolations, setHasViolations] = useState(false)
    const [driversList, setDriversList] = useState<IDriver[]>([])
    const [violationsList, setViolationsList] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const { quote } = useAppSelector(state => state.pouch)
    const formContext = !quote?.IsMTA ? useFormContext() : undefined
    const quoteToSend = quote?.IsMTA ? quote : quoteData
    const { enqueueSnackbar } = useSnackbar()

    const toggleExpanded = (idx: string): void => {
        setExpanded(!expanded)
        setInitial(false)
        id !== idx && !initial ? setId(idx) : setId('')
        console.log(id, idx)
    }

    const goSubmitInformation = async (): Promise<void> => {
            formContext?.setLoading(true)
            getMultiItemValues()            
              .then((res) => {
              })
              .catch((resErr) => {
                formContext?.setLoading(false)
                errorHandler(resErr, enqueueSnackbar)
              })
      }

    const getMultiItemValues = async () => {
        formContext?.setLoading(true)
        const response = await getQuoteMultiItems(quoteToSend.QuoteRef)
        const result = await response.data.data.response
        const driverList = merge(result.Drivers, drivers)
        console.log(driverList)
        const fullQuote = merge(quoteToSend, result)
        const newQuote = {
            ...fullQuote,
            ...result.AdditionalInsured_MI,
            Violations: violations,
            Accidents: accidents,
            Drivers: driverList
        }
        console.log(newQuote)
        UpdateQuoteData(newQuote)
            .then((res) => {
                formContext?.setLoading(true)
            })
            .catch(resErr => {
                errorHandler(resErr, enqueueSnackbar)
            })
            .finally(() => {
                formContext?.goNextPage()
            })
    }
    const formikFormQuote = useFormik({
        enableReinitialize: true,
        initialValues: [quoteToSend],
        onSubmit: goSubmitInformation
    })

    useEffect(() => {
        formContext?.setForm(formikFormQuote)
        formContext?.setQuote(quoteToSend)
        console.log(formContext)
    }, [])

    useEffect(() => {
        console.log(violations, accidents)
        const anyViolations = violations.some(violation => violation.violations?.length)
        setHasViolations(anyViolations)
        if (violations.length < 1) {
            formContext?.setLoading(true)
        } else {
            const violationsAndAccidents = violations.map((violation: any, index: number) => {
                return {
                    driverLicense: violation?.driverLicense,
                    violationDate: violation?.violations[index]?.violationDate || '',
                    violationDescription: violation?.violations[index]?.violationDescription || '',
                    violations: violation?.violations || [],
                    points: violation?.violations[index]?.points || 0,
                    accidents: accidents[index] || [],
                    totalPoints: violation?.totalPoints + accidents[index]
                }
            })
            setViolationsList(violationsAndAccidents)
            console.log(violationsList)
            const someExcededPoint = violationsAndAccidents.some(violation => violation.totalPoints > 5)
            if (someExcededPoint) {
                setMVRError?.(true)
            } else {
                setMVRError?.(false)
            }
            formContext?.setLoading(false)
        }
        formContext?.setLoading(false)
    }, [violations])

    useEffect(() => setExpanded(true), [hasError])

    useEffect(() => {
        setDriversList(drivers)
        console.log(drivers)
    }, [driversList])

    return (
        <form
            onSubmit={formikFormQuote.handleSubmit}
        >
            <>
                <Typography className='driver-title'>Driver{"'"}s Report</Typography>
                {
                    driversList.slice(0, driverIndex).map((driver: any, index: number) => (
                        <div key={`driver${index}`} className={`form-container ${className}`}>
                            <Accordion
                                className='mvr-form form'
                                expanded={hasError || initial || id === `driver${index}`}
                                onChange={hasError ? () => null : () => toggleExpanded(`driver${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                    className='summary'
                                >
                                    <div className={`driver ${!initial && id !== `driver${index}` ? 'extra-padding' : ''}`}>
                                        <span>{image}</span>
                                        <Typography className={`driver-title ${!initial && id !== `driver${index}` ? 'extra-padding' : ''}`}><strong>{title} {!ignoreIndex && index + 1}</strong></Typography>
                                        {
                                            (!initial && id !== `driver${index}`) ?
                                                <div className='table-container' >
                                                    <Table sx={{
                                                        [`& .${tableCellClasses.root}`]: {
                                                            borderBottom: "none"
                                                        }
                                                    }}
                                                        className='table-summary'>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className='pb-0'><strong>Name</strong></TableCell>
                                                                <TableCell className='pb-0'><strong>DL State</strong></TableCell>
                                                                <TableCell className='pb-0'><strong>DL Number</strong></TableCell>
                                                                {
                                                                    violationsList[index]?.totalPoints > 0 ?
                                                                        <TableCell className='pb-0'><strong>Total Points</strong></TableCell>
                                                                        :
                                                                        <TableCell className='no-violations pyx-0'><strong>No violations or accidents found</strong></TableCell>
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className='py-0'>{driver.DriverFullName_TXT}</TableCell>
                                                                <TableCell className='py-0'>{driver.DriverLicenseStateFinal_TXT}</TableCell>
                                                                <TableCell className='py-0'>{driver.DriverLicenseFinal_TXT}</TableCell>
                                                                {
                                                                    violationsList[index]?.totalPoints > 0 ?
                                                                        <TableCell className={`${violationsList[index]?.totalPoints > 5 ? 'text-error' : ''} pyx-0`} colSpan={2}>
                                                                            {
                                                                                violations.length > 0 ?
                                                                                    violationsList[index].totalPoints
                                                                                    : 'loading...'
                                                                            }
                                                                        </TableCell>
                                                                        :
                                                                        <></>
                                                                }
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    {
                                                        violationsList[index]?.totalPoints > 5 ?
                                                            <Typography className='not-elegible'>** Not elegible to Quote with us **</Typography>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <section className='children'>
                                        {initial || expanded || id === `driver${index}` ?
                                            <>
                                                <div className='table-container' >
                                                    <Table sx={{
                                                        [`& .${tableCellClasses.root}`]: {
                                                            borderBottom: "none"
                                                        }
                                                    }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className='pb-0'><strong>Name</strong></TableCell>
                                                                <TableCell className='pb-0'><strong>DL State</strong></TableCell>
                                                                <TableCell className='pb-0'><strong>DL Number</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className='py-0'>{driver.DriverFullName_TXT}</TableCell>
                                                                <TableCell className='py-0'>{driver.DriverLicenseStateFinal_TXT}</TableCell>
                                                                <TableCell className='py-0'>{driver.DriverLicenseFinal_TXT}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Typography className='accidents'><strong>Accidents and Violations</strong></Typography>
                                                    <Table sx={{
                                                        [`& .${tableCellClasses.root}`]: {
                                                            borderBottom: 'none'
                                                        }
                                                    }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className='pb-0'><strong>Description</strong></TableCell>
                                                                <TableCell className='pb-0'><strong>Date</strong></TableCell>
                                                                <TableCell className='pb-0'><strong>Points</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>

                                                                <TableCell className='py-0'>
                                                                    {
                                                                        violationsList.length > 0 ?
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        violationsList[index].violations.map((violation: any) => (
                                                                                            <>
                                                                                                <span>{violation.violationDescription}</span>
                                                                                                <br />
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                            )
                                                                            : 'loading...'
                                                                    }
                                                                </TableCell>
                                                                <TableCell className='py-0'>
                                                                    {
                                                                        violationsList.length > 0 ?
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        violationsList[index]?.violations?.map((violation: any) => (
                                                                                            <>
                                                                                                <span>{violation.violationDate}</span>
                                                                                                <br />
                                                                                            </>
                                                                                        )) || ''
                                                                                    }
                                                                                </>
                                                                            )
                                                                            : 'loading...'
                                                                    }
                                                                </TableCell>
                                                                <TableCell className='py-0'>
                                                                    {
                                                                        violationsList.length > 0 ?
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        violationsList[index]?.violations?.map((violation: any) => (
                                                                                            <>
                                                                                                <span>{violation.points}</span>
                                                                                                <br />
                                                                                            </>
                                                                                        )) || ''
                                                                                    }
                                                                                </>
                                                                            )
                                                                            : 'loading...'
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>

                                                                <TableCell className='py-0'>
                                                                    {
                                                                        violationsList[index]?.accidents > 0 ?
                                                                            (
                                                                                <>
                                                                                    <span className='text-accidents'>Self reported Accident</span>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                            : ''
                                                                    }
                                                                </TableCell>
                                                                <TableCell className='py-0'>
                                                                    {
                                                                        violationsList[index]?.accidents > 0 ?
                                                                            (
                                                                                <>
                                                                                    <span className='text-accidents'>-</span>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                            : ''
                                                                    }
                                                                </TableCell>
                                                                <TableCell className='py-0'>
                                                                    {
                                                                        violationsList[index]?.accidents > 0 ?
                                                                            (
                                                                                <>
                                                                                    <span className='text-accidents'>{violationsList[index]?.accidents}</span>
                                                                                    <br />
                                                                                </>
                                                                            )
                                                                            : ''
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <hr className='split' />
                                                    <Table sx={{
                                                        [`& .${tableCellClasses.root}`]: {
                                                            borderBottom: 'none',
                                                            padding: 0
                                                        }
                                                    }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className='pyx-0' colSpan={3} align='right'><strong>Total Points</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className={violationsList[index]?.totalPoints > 5 ? 'text-error' : ''} colSpan={3} align='right'>
                                                                    {
                                                                        violationsList.length > 0 ?
                                                                            violationsList[index].totalPoints
                                                                            : 'loading...'
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <div>
                                                        {
                                                            violationsList[index]?.totalPoints > 5 ?
                                                                <Typography className='not-elegible'>** Not elegible to Quote with us **</Typography>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                    </section>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))
                }
            </>
        </form>
    );
}

export default MVRColapse