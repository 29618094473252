import { /* Link, */ Link } from 'react-router-dom'
import List from '@material-ui/core/List'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import BtnPrimary from '../generalities/BtnPrimary'
import { ListItem, ListItemText } from '@material-ui/core'
import { envUrl } from 'utils/functions'

export default function Menu () {


  const goToQuote = (): void => {
    window.location.href = `https://quote${envUrl}-v3.pouchinsurance.com`
  }

  return (
    <div>
      <List>
        {/* <ListItem button key={1}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            <ListItemText primary={"OUR SERVICES"} />
          </Link>
        </ListItem> */}
        <ListItem button key={2}>
          <Link style={{ textDecoration: 'none', color: 'black' }} to='/about-us'>
            <ListItemText primary='ABOUT US' />
          </Link>
        </ListItem>
        <ListItem button key={2}>
          <Link style={{ textDecoration: 'none', color: 'black' }} to='/partners'>
            <ListItemText primary='PARTNERS' />
          </Link>
        </ListItem>
        {/* <ListItem button key={3}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            <ListItemText primary={"FAQ"} />
          </Link>
        </ListItem>
        <ListItem button key={4}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            <ListItemText primary={"FOR AGENTS"} />
          </Link>
        </ListItem>
        <Divider />
        <ListItem button key={4}>
          <Link style={{ textDecoration: "none", color: "#39C7C2" }} to="/">
            <ListItemText primary={"SIGN IN"} />
          </Link>
        </ListItem> */}
        <BtnPrimary children='GET A QUOTE' onClick={goToQuote} />
      </List>

    </div>
  )
}
