import { FormikProps } from 'formik';
import { ReactElement } from "react";
import CardNumberFormater from './CardNumberFormater';
import TextField from './TextField';
import '../styles/chargerbee.css'
export interface IChargebeeFields {
  card_name: String
  card_lastname: String
  number: number
  expiry_month: number
  expiry_year: number
  cvv: number
}

interface ITChargebee {
  formikForm: FormikProps<IChargebeeFields> | any
}

export const CardForm = ({formikForm}: ITChargebee): ReactElement => {       
    const curentYear = new Date().getFullYear().toString()

    const changeMonth = (event: any) => {
        let value = event.replace(/[^\d]/g, '')
        if ( (value <= 99 && value >= 0) || value === '' ) {
        value = (value.length >= 3 ? value.substring(1,3) : value )
        formikForm.setFieldValue('expiry_month', value)
        return
        }
        formikForm.setFieldValue('expiry_month', 12)
    }


    const changeYear = (event: any) => {
        let value = event.replace(/[^\d]/g, '')
        if ( (value <= 99 && value >= 0  ) || value === '') {
            value = (value.length >= 3 ? value.substring(1,3) : value )
            formikForm.setFieldValue('expiry_year', value)
        return
        }
        formikForm.setFieldValue('expiry_year', curentYear.slice(2,4))
    }

    const changeCVV = (value: any) => {        
        if ( value.length < 4) {
            formikForm.setFieldValue('cvv', value.replace(/[^\d]/g, ''))
            return
        }
        formikForm.setFieldValue('cvv', formikForm.values.cvv)
    }

    return (
        <>
        <div className='chargerbee_container'>
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>                
                <div style={{width: '47.5%'}}>
                    <TextField form={formikForm} name="card_name" inputLabel="First Name" placeholder='First Name'/>
                </div>
                <div style={{width: '47.5%'}}>
                    <TextField form={formikForm} name="card_lastname" inputLabel="Last name" placeholder='Last name'/>
                </div>
            </div>
        </div>
        <div className='chargerbee_container'>
        
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>                
                <div style={{width: '70%'}}>
                    <CardNumberFormater form={formikForm} name="number" inputLabel="Card number" placeholder="4111 1111 1111 1111"/>
                </div>
                <div style={{width: '25%'}}>
                    <TextField form={formikForm} name="cvv" inputLabel="CVV" placeholder='cvv' onChange={changeCVV}/>
                </div>
            </div>
        </div>
        <div className='chargerbee_container'>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                <div style={{width: '47.5%'}}>
                    <TextField form={formikForm} name="expiry_month" inputLabel={'Expire date'} onChange={changeMonth} placeholder="MM" type="number" max={12}/>
                </div>
                <div style={{width: '47.5%', height: '100%', display: 'flex', alignItems: 'flex-end'}}>
                    <TextField form={formikForm} name="expiry_year" inputLabel={'Expire year'} onChange={changeYear} placeholder="YY" type="number" max={99}/>
                </div>
            </div>
        </div>
        </>
    )
}
