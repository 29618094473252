import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Menu from './Menu'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: 300,
    flexShrink: 0
  }
}))

export default function DrawerComponent ({
  isOpen,
  setOpenMenu
}: {
  isOpen: boolean
  setOpenMenu: (status: boolean) => void
}): ReactElement {
  const classes = useStyles()

  return (
    // This react component has all the sections of the landing page
    <Drawer
      className={classes.drawer}
      anchor='top'
      open={isOpen}
      onClose={() => { setOpenMenu(false) }}
    >
      <Menu />
    </Drawer>
  )
}
