import { ReactElement, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Hooks
import useMixpanelTrack from 'hooks/useMixpanelTrack'
import useFormPercent from 'hooks/useFormPercent'
// Components
import Title from '../../components/Title'
import { PayWithChargebee } from '../../services/chargeBee'
import { BindExistingQuote, GetLocalQuoteData, isDEV, isLOCAL, updateQuoteChargerBee } from '../../services/instanda'
import { useFormContext } from '../../context/FormContext'
import PaymentMethod from '../Standalone/components/PaymentMethod'
import AchForm from './components/AchForm'
import { API } from 'services/instanda'
import StandaloneModal, { IOpenProps } from '../Standalone/components/StandaloneModal'
import { ChargebeeInitialValues, chargebeeSchema, errOB, numbersRegex } from 'utils/validations'
import ChargeBeeTokenize from 'views/ChargeBeeTokenize'
import { getQuoteValuesChargerBee } from 'interfaces/chargerbee'
import { CardForm } from 'components/CardForm'

const useStyles = makeStyles({
  cardTitle: {
    fontWeight: 700,
    margin: '0 0 20px 0',
    fontSize: '3.1em',
    fontStyle: 'normal'
  },
  titleContainer: {
    paddingBottom: 20
  },
  cardContainer: {
    border: '1px solid #dddddd',
    paddingTop: '20px'
  },
  cardTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  payButton: {
    width: '100%',
    backgroundColor: '#ff6c1a',
    border: 'none',
    outline: 'none',
    color: '#FFF',
    padding: '10px'
  },
  emailButton: {
    width: '100%',
    backgroundColor: '#ff6c1a',
    border: 'none',
    outline: 'none',
    color: '#FFF',
    padding: '10px',
    marginTop: '5px'
  },
  bodyContainer: {
    padding: '0 40px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  descriptionText: {
    fontSize: '0.8em'
  },
  acceptText: {
    paddingLeft: '10px',
    fontSize: '0.8em'
  },
  errorMessage: {
    fontSize: '0.8em',
    color: 'red'
  }
})

export default function Page8 (): ReactElement {
  useMixpanelTrack('bind4-tracking')

  const [accepted, setAccepted] = useState(false)
  const [quoteData, setQuoteData] = useState({});
  const [errorAccepted, setErrorAccepted] = useState(false)
  const [paymentMethodLoad, paymentMethodLoadSet] = useState(false)
  const [paymentMethod, paymentMethodSet] = useState(false)
  const styleClass = useStyles()
  const formContext = useFormContext()
  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()
  const checkIfImInPayment = (): boolean => {
    if (history.location.pathname === '/join/payment') {
      return true
    }
    return false
  }

  const choosePaymentMethod = (): void => {
    //Aaron - ACH Comment
    //paymentMethodSet(!paymentMethod)
  }

  useEffect(() => {
    if (checkIfImInPayment()) {
      paymentMethodLoadSet(true)
      paymentMethodSet(true)
    }
  }, [])

  const callBindExistingQuoteService = async (): Promise<void> => {
    formContext.setLoading(true)
    BindExistingQuote({})
      .then((res: any) => {
        formContext.savePolicyNumber(res.data.policyNumber)
        formContext.setProgress(100)
        formContext.goNextPage()
      })
      .catch((err: any) => {
        formContext.setLoading(false)
        enqueueSnackbar(err.message, { variant: 'error' })
      }).finally(() => {
        formContext.setLoading(false)
      })
  }

  const [triggerSubmit, setTriggerSubmit] = useState<string | boolean>(false)

  //GetQuoteData
  useEffect(()=>{
    GetLocalQuoteData()
      .then((res) => {
        const result = res.data;        
        setQuoteData(result);
      })
      .catch((resErr) => {
        formContext.setLoading(false)
        console.error({ resErr })
      })
  },[])

  const onSubmitChargebee = (): void => {    
    localStorage.setItem('paymentVia', 'Chargebee');
    formContext.setLoading(true);
      
    PayWithChargebee({
      ...formikChargerBee.values,
      ...getQuoteValuesChargerBee(quoteData)
    })
      .then(async (res) => {
        console.log('payWithChargeBeeRes', res)
        formContext.savePolicyNumber(res.data.SubscriptionID_TXT)
        formContext.setProgress(100)
        await updateQuoteChargerBee({ ChargeBeeID_TXT: res.data.SubscriptionID_TXT, ChargeBeeInvoice_TXT: res.data.invoice_id})
        await callBindExistingQuoteService()
      })
      .catch((err) => {
        console.log('payWithChargeBeeErr', err)
        formContext.setLoading(false)
        enqueueSnackbar(err.message, { variant: 'error' })
      });
    
  }

  const [openStandalone, setOpenStandalone] = useState<IOpenProps>({ title: null, content: null, isSuccess: false })

  const paymentDeclined = (): void => {
    setOpenStandalone({
      title: 'Payment Declined',
      content: 'Oh snap! The ACH payment was declined, try again with another payment method.',
      isSuccess: false
    })
  }

  const bankAccountSchema = Yup.object().shape({
    bankAccountHolder: Yup.string().required('Account holder name is needed'),
    routingNumber: Yup.string()
      .required('Routing Number is needed')
      .matches(numbersRegex, errOB.isNumeric),
    accountNumber: Yup.string()
      .required('Account number is needed')
      .matches(numbersRegex, errOB.isNumeric),
    confirmAccountNumber: Yup.string().oneOf(
      [Yup.ref('accountNumber'), null],
      'Account numbers must match'
    )
      .matches(numbersRegex, errOB.isNumeric),
    accountType: Yup.string().required('Account holder name is needed')
  })

  const formik = useFormik({
    initialValues: {
      bankAccountHolder: '',
      routingNumber: '',
      accountNumber: '',
      confirmAccountNumber: '',
      accountType: ''
    },
    validationSchema: bankAccountSchema,
    onSubmit: (values) => sumbitAch(values)
  })

  const formikChargerBee = useFormik({
    initialValues: ChargebeeInitialValues,
    validationSchema: chargebeeSchema,
    onSubmit: (values) => console.log(values)
  })

  useEffect(() => {
    const fromLocalStorage = localStorage.getItem('instandaFullQuote')
    const quoteRef = fromLocalStorage !== null ? JSON.parse(fromLocalStorage) : null
    dataPolicySet({ ...quoteRef })
  }, [])

  const [dataPolicy, dataPolicySet] = useState<any>({})

  const sumbitAch = (values: any): void => {
    console.log('submitForm', formik.values)
    localStorage.setItem('paymentVia', 'ACH')
    if (accepted) {
      if (isLOCAL || isDEV) {
        setOpenStandalone({
          title: 'Payment Complete',
          content: null,
          isSuccess: true
        })
      } else {
        API.post('/quickbooks/createDebit', {
          quoteRef: `${dataPolicy.QuoteRef as string}-${Date.now()}`,
          debitBody: {
            bankAccount: {
              phone: dataPolicy?.ContactPhone_TXT,
              routingNumber: formik.values?.routingNumber,
              name: formik.values?.bankAccountHolder,
              accountType: formik.values?.accountType ?? 'PERSONAL_CHECKING',
              accountNumber: formik.values?.accountNumber
            },
            description: dataPolicy?.ContactFullName_TXT,
            checkNumber: '12345678',
            paymentMode: 'WEB',
            amount: dataPolicy?.cb_downpayment_num
          }
        })
          .then((res) => {
            console.log('resSend', res)
            if (res?.data?.status === 'DECLINED') {
              paymentDeclined()
            } else if (res?.data?.status === 'PENDING') {
              setOpenStandalone({
                title: 'Your payment is being processed!',
                content: '',
                isSuccess: true
              })
            } else {
              setOpenStandalone({
                title: 'Payment Complete',
                content: null,
                isSuccess: true
              })
              // handleQuickbooks(res.data)
            }
          })
          .catch((err) => {
            console.log('errSend', err)
            console.log('errSendSmall', err?.response?.data)
            paymentDeclined()
          })
      }
    } else {
      setErrorAccepted(true)
    }
  }

  // const sendEmail = (): void => {
  //   enqueueSnackbar('Quote Sent', { variant: 'success' })
  // };

  useFormPercent(67, 0, formContext)
  useEffect(() => {
    formContext.updateLocalQuoteData()
  }, [])

  useEffect(() => {
    if (formContext.quote?.PayPlan_CHOICE === 'Offline Paid in Full' || formContext.quote?.PayPlan_CHOICE === 'Offline Monthly') {
      formContext.goNextPage()
    }
  }, [formContext.quote])

  if (formContext.loading) {
    return <div>loading...</div>
  } else {
    return (
      <div className='container row'>
        <div className='col-12 col-md-8'>
          <form>
            <div className='row'>
              <div className={styleClass.titleContainer}>
                <Title>How Would You Like to Pay?</Title>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <PaymentMethod
                    paymentMethodChoice={paymentMethod}
                    selectPaymentMethod={choosePaymentMethod}
                    hiddenClassName={paymentMethodLoad}
                  />
                </div>
              </div>
              <StandaloneModal
                openStandalone={openStandalone}
                setOpenStandalone={setOpenStandalone}
                email={dataPolicy?.ContactEmail_TXT}
                callBindExistingQuoteService={callBindExistingQuoteService}
              />
              {paymentMethod
                ? (
                    <CardForm formikForm={formikChargerBee} />
                  )
                : (
                  <div>
                    <AchForm
                      sumbitAch={sumbitAch}
                      onChange={formik.handleChange}
                      values={formik.values}
                      touched={formik.touched}
                      errors={formik.errors}
                    />
                  </div>
                  )}
            </div>
          </form>
        </div>
        <div className='col-12 col-md-4'>
          <div className={styleClass.cardContainer}>
            <div className={styleClass.cardTitleContainer}>
              <h1 className={styleClass.cardTitle}>
                {formContext.quote?.PayPlan_CHOICE === 'Paid in Full'
                  ? `$${
                      formContext.quote?.FinalPremiumAnnualInclTaxes_NUM?.toFixed(2) as number
                    }`
                  : `$${
                      formContext.quote?.FinalPremiumMonthlyInclTaxes_NUM?.toFixed(2) as number
                    }`}
              </h1>
            </div>
            <div className={styleClass.bodyContainer}>
              <div>
                <b>
                  {formContext.quote?.PayPlan_CHOICE === 'Paid in Full'
                    ? 'Per 6 months'
                    : 'Per month'}
                </b>
                <p className={styleClass.descriptionText}>
                  {formContext.quote?.PayPlan_CHOICE === 'Paid in Full'
                    ? `Paid in full of ${formContext.quote?.FinalPremiumAnnualInclTaxes_NUM as string}`
                    : `Plus a ${formContext.quote?.cb_downpayment_num as string} down payment`}
                </p>
              </div>
              <div>
                {
                  formContext.quote?.PayPlan_CHOICE?.includes('Monthly') && (
                    <p className={styleClass.descriptionText}>General Agent Installment Fee $6 included</p>
                  )
                }
                {
                  formContext.quote?.BusinessState_TXT === 'TX' && (
                    <p className={styleClass.descriptionText}>Taxes included ${
                      formContext.quote?.PayPlan_CHOICE?.includes('Monthly') ? (
                        formContext.quote?.FinalPremiumMonthlyInclTaxes_NUM - formContext.quote?.FinalPremiumMonthly_NUM
                      ) : (
                        formContext.quote?.FinalPremiumAnnualInclTaxes_NUM - formContext.quote?.FinalPremiumAnnual_NUM
                      )
                    }</p>
                  )
                }
                <p className={styleClass.descriptionText}>Verify the amount</p>
                <p className={styleClass.descriptionText}>Add a valid card</p>
                <p className={styleClass.descriptionText}>Click next to confirm pay to confirm payment</p>
              </div>
              <div>
                {errorAccepted && (
                  <p className={styleClass.errorMessage}>You must accept</p>
                )}
                <p>
                  <input
                    type='radio'
                    checked={accepted}
                    onChange={(evt) => {
                      setErrorAccepted(false)
                      setAccepted(evt.target.checked)
                    }}
                  />
                  <span className={styleClass.acceptText}>
                    <a href='https://www.chargebee.com/company/terms/' target='_blank' rel='noreferrer'>I Agree to the Terms and Conditions</a>
                  </span>
                </p>
              </div>
            </div>

            {paymentMethod
              ? (
                <button onClick={onSubmitChargebee} className={styleClass.payButton}>
                  Pay
                </button>
                )
              : (
                <button className={styleClass.payButton} onClick={sumbitAch} disabled={formik.values?.confirmAccountNumber !== formik.values?.accountNumber}>
                  Pay
                </button>
                )}

            {/* <button onClick={sendEmail} className={styleClass.emailButton}>
              Email formContext.quote
            </button> */}
          </div>
        </div>
      </div>
    )
  }
}
